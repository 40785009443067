import { Box, Tooltip } from '@mui/material';

/**
 * Composant permettant l'affichage d'une petite pastille avec une couleur
 * et une infobulle en fonction du type spécifié en props.
 * Props possible :
 * ferie (vert)
 * @type {Object}
 */
const PastilleComponent = (props) => {
    return (
        <Tooltip
            placement="top"
            title={props.texte}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: props.type === 'ferie' ? 'success.light' : 'grey',
                    },
                },
            }}
        >
            {props.texte === undefined ? (
                <Box
                    sx={{
                        borderRadius: '50%',
                        backgroundColor: props.type === 'ferie' ? 'success.light' : 'grey',
                        width: '10px',
                        height: '10px',
                    }}
                ></Box>
            ) : (
                <Box
                    sx={{
                        borderRadius: '50%',
                        backgroundColor: props.type === 'ferie' ? 'success.light' : 'grey',
                        width: '10px',
                        height: '10px',
                    }}
                    data-for={props.type}
                    data-tip={props.texte}
                ></Box>
            )}
        </Tooltip>
    );
};

export default PastilleComponent;
