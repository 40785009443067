import { React, useState, useEffect } from 'react';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import activityService from '../../services/activity.service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HorizontalCalendarComponent from '../calendar/calendar-horizontal.component';
import congeService from '../../services/conge.service';
import { getTypeTrigramme } from '../../helpers/conge.helper';
import userService from '../../services/user.service';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

const PlanningManagerComponent = (props) => {
    const location = useLocation();

    const paramManager = location.state ? location.state.manager || null : null;

    let baseDate = new Date();
    let baseManager =
        paramManager !== null
            ? {
                  label: paramManager.nom + ' ' + paramManager.prenom,
                  value: paramManager.email,
              }
            : {
                  label: `${JSON.parse(localStorage.getItem('user')).nom} ${
                      JSON.parse(localStorage.getItem('user')).prenom
                  }`,
                  value: JSON.parse(localStorage.getItem('user')).email,
              };

    const [allJoursFeriesState, setAllJoursFeriesState] = useState([]);
    const [actualDateState, setActualDateState] = useState(baseDate);
    const [actualManagerState, setActualManagerState] = useState(baseManager);
    const [activitiesState, setActivitiesState] = useState('');
    const [teamMemberArrayState, setTeamMemberArrayState] = useState([]);
    const [managersState, setManagersState] = useState([]);
    const [keyState, setKeyState] = useState(0);
    const [errorState, setErrorState] = useState(null);

    const refreshJoursFeries = () => {
        let result = [];
        fetch(
            'https://calendrier.api.gouv.fr/jours-feries/metropole/' +
                actualDateState.getFullYear() +
                '.json',
        ).then((response) => {
            response.json().then((response) => {
                for (var i in response) result[i] = response[i];
                setAllJoursFeriesState(result);
            });
        });
    };

    /** ACTIONS */
    /**
     * Permet d'aller au mois suivant en prenant le mois actuel +1
     */
    const nextMonth = () => {
        let actualDate = actualDateState;
        let nextMonth = new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 1);

        // on construit le mois à partir de la date avec mois +1
        setActualDateState(nextMonth);
    };
    /**
     * Permet d'aller au mois précédent en prenant le mois actuel -1
     */
    const previousMonth = () => {
        let actualDate = actualDateState;
        let nextMonth = new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1);

        setActualDateState(nextMonth);
    };
    const setMonth = (event) => {
        let actualDate = actualDateState;
        let newMonth = new Date(actualDate.getFullYear(), event.target.value, 1);
        setActualDateState(newMonth);
    };
    const setYear = (event) => {
        let actualDate = actualDateState;
        let newYear = new Date(event.target.value, actualDate.getMonth(), 1);
        setActualDateState(newYear);
    };

    const setManager = (event) => {
        if (event === null) {
            setActualManagerState(baseManager);
        } else {
            const targetedManager = event;
            setActualManagerState(targetedManager);
        }
    };
    const rerenderParentCallback = () => {
        setKeyState(keyState + 1);
    };

    /** REACT FUNCTIONS */
    const handleRefreshDatas = async (actualDate, actualManager) => {
        try {
            let activitiesAndMembers = await activityService.getActivitiesByManager(
                actualDate.getMonth() + 1,
                actualDate.getFullYear(),
                actualManager.value,
            );

            let conges = await congeService.getCongesByManager(
                actualDate.getMonth(),
                actualDate.getFullYear(),
                actualManager.value,
            );

            let managerList = await userService.getAllUsersAccessible();

            let managerOptions = managerList.map((manager) => {
                return {
                    value: manager.email,
                    label: `${manager.nom} ${manager.prenom}`,
                    userObject: manager,
                };
            });

            let legendConge = await activityService.getTypeFacturationConge();

            conges = conges.conges.map((conge) => {
                return (conge = {
                    ...conge,
                    projet: {
                        couleur: '#2b6955',
                        trigramme: getTypeTrigramme(conge),
                        fk_activity_type: 0,
                        type_facturation: legendConge,
                    },
                });
            });

            let activitiesAndConge = activitiesAndMembers.activities.concat(conges);
            setActivitiesState(activitiesAndConge);
            setTeamMemberArrayState(activitiesAndMembers.teamMemberArray);
            setManagersState(managerOptions);
        } catch (error) {
            // si une erreur est détectée, on la renseigne
            setErrorState(error);
        }
    };

    /**
     * Fonction appelée au changement d'une props ou state de notre objet
     */
    useEffect(() => {
        props.onLoad('Planning manager');
        setActivitiesState('');
        setTeamMemberArrayState([]);
        refreshJoursFeries();
        handleRefreshDatas(actualDateState, actualManagerState);
    }, [actualDateState, actualManagerState, keyState]);

    const errorToast = () => {
        toast.error(errorState);
    };
    const error = errorState;
    if (error) {
        errorToast();
    }
    return activitiesState !== '' && teamMemberArrayState.length > 0 ? (
        <HorizontalCalendarComponent
            key={actualDateState + activitiesState + (actualManagerState || '')}
            actualDate={actualDateState}
            activities={activitiesState}
            employees={teamMemberArrayState}
            managers={managersState}
            actualManager={actualManagerState}
            nextMonth={nextMonth}
            previousMonth={previousMonth}
            setMonth={setMonth}
            setYear={setYear}
            setManager={setManager}
            rerenderParentCallback={rerenderParentCallback}
            allJoursFeries={allJoursFeriesState}
        />
    ) : (
        <CircularProgress />
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedPlanningManagerComponent = connect(
    null,
    mapDispatchToProps,
)(PlanningManagerComponent);
