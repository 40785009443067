import { FormControlLabel, FormHelperText, Grid, Paper, Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { GridCalendar } from '../../../theming/customTheme';
import * as MiniCalendarHelper from '../../../helpers/mini_calendar.helper';
import { getClassNameMiniCalendar } from '../../../helpers/conge.helper';

/**
 * Composant du miniCalendrier réutilisable dans un formulaire
 *      --> peut être à retravailler pour être réutilisable hors react-hook-form mais pas sur que ce soit utile
 * Props à fournir :
 * control
 * monthGlobal
 * errors
 */
const InputMiniCalendarComponent = (props) => {
    const [isMonthCheckedState, setIsMonthCheckedState] = useState(false);

    const onChangeCheckMonth = (event, field) => {
        setIsMonthCheckedState(event);
        let arrayAllDayMonth = [];
        props.monthGlobal.forEach((week) => {
            let toDisplay = week.jours.filter((jour) => jour.status > 0);
            if (toDisplay.length > 0) {
                week.isChecked = event;
                week.jours.forEach((day) => {
                    arrayAllDayMonth.push(day);
                });
            }
        });
        if (event) {
            field.onChange(
                MiniCalendarHelper.addDaysFromArray(field.value, props.jour, arrayAllDayMonth),
            );
        } else {
            field.onChange(
                MiniCalendarHelper.addDaysFromArray(
                    field.value,
                    props.jour,
                    arrayAllDayMonth,
                    0,
                    true,
                ),
            );
        }
    };

    useEffect(() => {
        /**
         * Comme la props defaultDays est un state dans le composant parent, on écoute un éventuel changement pour set la valeur de l'input
         * En effet, la props étant initialisé comme une defaultValue dans l'input, le formulaire gardera la valeur initial au chargement de l'input
         * On a donc besoin de set la valeur manuellement vu qu'elle ne se fera pas automatiquement
         */
        props.setValue('inputMiniCalendar', props.defaultDays);
    }, [props.defaultDays]);

    useEffect(() => {
        props.setValue('inputMiniCalendar', [props.jour]);
    }, [props.jour]);

    return (
        <Controller
            key={'inputMiniCalendar' + [props.jour]}
            name="inputMiniCalendar"
            control={props.control}
            defaultValue={props.defaultDays || [props.jour]}
            rules={{
                required: {
                    value: true,
                    message: 'Le champ calendrier multiple doit être saisi',
                },
            }}
            render={({ field }) => (
                <div>
                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                            <p>
                                {props.monthGlobal[0].monthName} {props.monthGlobal[2].year}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() => {
                                            onChangeCheckMonth(!isMonthCheckedState, field);
                                        }}
                                        checked={isMonthCheckedState}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        component={Paper}
                        sx={{
                            p: 1,
                        }}
                    >
                        {props.monthGlobal.map((week, indexWeek) => {
                            let toDisplay = week.jours.filter((jour) => jour.status > 0);
                            if (toDisplay.length > 0) {
                                return (
                                    <>
                                        <Grid item xs={12} key={'grid-item' + indexWeek}>
                                            {
                                                <Grid
                                                    container
                                                    key={'grid-container' + indexWeek}
                                                    columns={9}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    {indexWeek === 0 && ( //On ne rend les jours de la semaine que pour la première ligne
                                                        <>
                                                            <GridCalendar
                                                                className="monthName"
                                                                item
                                                                xs={1}
                                                            >
                                                                L
                                                            </GridCalendar>
                                                            <GridCalendar
                                                                className="monthName"
                                                                item
                                                                xs={1}
                                                            >
                                                                M
                                                            </GridCalendar>
                                                            <GridCalendar
                                                                className="monthName"
                                                                item
                                                                xs={1}
                                                            >
                                                                M
                                                            </GridCalendar>
                                                            <GridCalendar
                                                                className="monthName"
                                                                item
                                                                xs={1}
                                                            >
                                                                J
                                                            </GridCalendar>
                                                            <GridCalendar
                                                                className="monthName"
                                                                item
                                                                xs={1}
                                                            >
                                                                V
                                                            </GridCalendar>
                                                            <GridCalendar
                                                                className="monthName"
                                                                item
                                                                xs={1}
                                                            >
                                                                S
                                                            </GridCalendar>
                                                            <GridCalendar
                                                                className="monthName"
                                                                item
                                                                xs={1}
                                                            >
                                                                D
                                                            </GridCalendar>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <hr
                                                                        style={{
                                                                            border: '0.5px solid',
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {week.jours.map((jour, index) => {
                                                        let isSelected = false;
                                                        field.value.forEach((jourSel) => {
                                                            if (
                                                                jourSel.date.getTime() ===
                                                                jour.date.getTime()
                                                            ) {
                                                                isSelected = true;
                                                            }
                                                        });
                                                        return (
                                                            <>
                                                                <GridCalendar
                                                                    key={
                                                                        'create_activity_container' +
                                                                        index
                                                                    }
                                                                    item
                                                                    xs={1}
                                                                    className={getClassNameMiniCalendar(
                                                                        jour,
                                                                        props.jour.date.getMonth(),
                                                                        isSelected,
                                                                    )}
                                                                    onClick={() => {
                                                                        if (!isSelected) {
                                                                            field.onChange(
                                                                                MiniCalendarHelper.addDaysFromArray(
                                                                                    field.value,
                                                                                    props.jour,
                                                                                    [jour],
                                                                                    0,
                                                                                    false,
                                                                                    true,
                                                                                ),
                                                                            );
                                                                        } else {
                                                                            field.onChange(
                                                                                MiniCalendarHelper.addDaysFromArray(
                                                                                    field.value,
                                                                                    null,
                                                                                    [jour],
                                                                                    0,
                                                                                    true,
                                                                                    true,
                                                                                ),
                                                                            );
                                                                            setIsMonthCheckedState(
                                                                                false,
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    {jour.dayNumber}
                                                                </GridCalendar>
                                                                {index === 6 && (
                                                                    <Grid
                                                                        item
                                                                        xs={1}
                                                                        sx={{ ml: 1 }}
                                                                    >
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    size="small"
                                                                                    key={
                                                                                        week.jours +
                                                                                        week.isChecked
                                                                                    }
                                                                                    checked={
                                                                                        week.isChecked
                                                                                    }
                                                                                    onChange={() => {
                                                                                        week.isChecked =
                                                                                            !week.isChecked;
                                                                                        if (
                                                                                            week.isChecked
                                                                                        ) {
                                                                                            field.onChange(
                                                                                                MiniCalendarHelper.addDaysFromArray(
                                                                                                    field.value,
                                                                                                    props.jour,
                                                                                                    week.jours,
                                                                                                ),
                                                                                            );
                                                                                        } else {
                                                                                            field.onChange(
                                                                                                MiniCalendarHelper.addDaysFromArray(
                                                                                                    field.value,
                                                                                                    props.jour,
                                                                                                    week.jours,
                                                                                                    0,
                                                                                                    true,
                                                                                                ),
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </Grid>
                                            }
                                        </Grid>
                                    </>
                                );
                            } else {
                                return '';
                            }
                        })}
                        {props.errors.inputMiniCalendar ? (
                            <FormHelperText error={!!props.errors.inputMiniCalendar}>
                                {props.errors.inputMiniCalendar.message}
                            </FormHelperText>
                        ) : null}
                    </Grid>
                </div>
            )}
        />
    );
};

export default InputMiniCalendarComponent;
