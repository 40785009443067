import activityService from '../../services/activity.service';
import { toast } from 'react-toastify';
import * as MiniCalendarHelper from '../../helpers/mini_calendar.helper';

import { useState } from 'react';
import ResultFormComponent from '../assets/container/resultForm.component';
import FormActivityComponent from './form-activity.component';

const UpdateActivityComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    /** Props :
     * activities => toutes les activités qui sont les mêmes que la courante, notamment utile pour le formulaire
     * activity => activité courante
     * closeRightPanel
     * employe => undefined si mes temps
     * jour => jour saisi
     * monthCalendar => tout les jour du mois pour le mini calendrier
     * openRightPanel
     * rerenderParentCallback
     */

    // TODO : faire en sorte que update activity utilise le form activity. Ensuite résoudre les bugs de préprod notamment cité dans le mail de Julien et dans mon bloc note perso

    /** HANDLERS */
    const handleDeleteActivity = (dataForm) => {
        const days = !dataForm.inputDisplayCalendar
            ? [props.jour]
            : [...dataForm.inputMiniCalendar];
        if (days.length > 0) {
            if (window.confirm('Êtes-vous sûr de vouloir supprimer la ou les activité(s) ?')) {
                setLoadingState(true);
                setSuccessState(false);
                setSubmittedState(true);
                let allSuccess = 0;
                if (days.length > 0) {
                    days.forEach((day, index) => {
                        allSuccess = deleteActivityFromDay(day, index, allSuccess, days);
                    });
                } else {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState('Aucun jour sélectionné');
                    toast.error('Aucun jour sélectionné');
                }
            }
        } else {
            setSuccessState(false);
            setMessageState('Aucun jour sélectionné');
            setLoadingState(false);
            toast.error(false);
            // this.props.rerenderParentCallback();
        }
    };

    const deleteActivityFromDay = (day, index, allSuccess, days, refresh = false) => {
        const activityToDelete = MiniCalendarHelper.getActivityFromDay(day, props.activity);

        if (activityToDelete !== null) {
            activityService
                .deleteOneActivity(activityToDelete.activity_id)
                .then((response) => {
                    if (days.length === 1) {
                        setSuccessState(true);
                        setLoadingState(false);
                        setMessageState(response.message);
                        toast.success('Activité supprimée');
                        props.rerenderParentCallback();
                    } else {
                        allSuccess++;
                    }
                    if (index === days.length - 1) {
                        if (allSuccess > 0) {
                            setSuccessState(true);
                            setLoadingState(false);
                            setMessageState('Les Activités ont été supprimées');
                            toast.success('Les Activités ont été supprimées');
                            props.rerenderParentCallback();
                        }
                    }
                })
                .catch((err) => {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(err.message || err);
                    toast.error(err.message || err);
                    if (refresh) {
                        props.rerenderParentCallback();
                    }
                });
        } else {
            allSuccess++;
            if (index === days.length - 1) {
                if (allSuccess > 0) {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState('Les Activités ont été supprimées');
                    toast.success('Les Activités ont été supprimées');
                    props.rerenderParentCallback();
                }
            }
        }
        return allSuccess;
    };

    const handleForm = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        const {
            inputDisplayCalendar,
            inputProjet,
            inputSelectedLieu,
            inputCommentaire,
            inputValeur,
            inputMiniCalendar,
        } = dataForm;

        let allSuccess = 0;
        const days = !inputDisplayCalendar ? [props.jour] : [...inputMiniCalendar];

        //Toute cette partie test si on a des jours saisie qui ont été enlevé de la sélection, si oui on les supprime
        let allDaysWithActivity = [];
        props.monthCalendar.forEach((week) => {
            week.jours.forEach((day) => {
                if (MiniCalendarHelper.getActivityFromDay(day, props.activity) !== null) {
                    allDaysWithActivity.push(day);
                }
            });
        });
        let willDeleteArray = allDaysWithActivity.filter((day) => {
            /**
             * Parmi les jours avec des activités, on cherche ceux qui ne sont pas dans notre tableau de sélection et on les met dans un tableau willDeleteArray
             * Traduction : on cherche les jours qui auraient du être présent dans la sélection mais qui ne le sont pas
             * et on les met dans un tableau qui servira a les suppimer
             */
            return !days.find((sDay) => day.date.getTime() === sDay.date.getTime());
        });
        if (willDeleteArray.length > 0 && inputDisplayCalendar) {
            if (
                window.confirm(
                    'Vous allez supprimer ' +
                        willDeleteArray.length +
                        ' activité(s) car vous les avez désélectionnée(s), confirmer ?',
                )
            ) {
                willDeleteArray.forEach((day, index) => {
                    // On supprime les jours qui sont dans le tableau willDeleteArray
                    allSuccess = deleteActivityFromDay(day, index, allSuccess, days, true);
                });
            }
        }

        days.forEach((day, index) => {
            let dateJSON = new Date(
                day.date.getTime() - day.date.getTimezoneOffset() * 60000,
            ).toJSON();
            if (MiniCalendarHelper.getActivityFromDay(day, props.activity) === null) {
                let insertActivityPromise = null;
                if (props.employe !== undefined) {
                    insertActivityPromise = activityService.insertMyActivity(
                        dateJSON,
                        inputValeur,
                        inputCommentaire || '',
                        inputProjet.value,
                        inputSelectedLieu,
                        props.employe.email,
                    );
                } else {
                    insertActivityPromise = activityService.insertMyActivity(
                        dateJSON,
                        inputValeur,
                        inputCommentaire || '',
                        inputProjet.value,
                        inputSelectedLieu,
                    );
                }
                insertActivityPromise.then((response) => {
                    if (!response.error) {
                        if (days.length === 1) {
                            setSuccessState(true);
                            setLoadingState(false);
                            setMessageState(response.message);
                            props.rerenderParentCallback();
                            toast.success(response.message);
                        } else {
                            allSuccess++;
                        }
                    } else {
                        // une erreur est retournée
                        setSuccessState(false);
                        setMessageState(response.error);
                        setLoadingState(false);
                        toast.error(response.error);
                        props.rerenderParentCallback();
                    }

                    if (index === days.length - 1) {
                        if (allSuccess > 0) {
                            setSuccessState(true);
                            setLoadingState(false);
                            setMessageState('Les activités ont bien été ajouté');
                            toast.success('Les activitiés ont bien été ajouté');
                            props.rerenderParentCallback();
                        }
                    }
                });
            } else {
                activityService
                    .updateMyActivity(
                        MiniCalendarHelper.getActivityFromDay(day, props.activity).activity_id,
                        dateJSON,
                        inputValeur,
                        inputCommentaire || '',
                        inputProjet.value,
                        inputSelectedLieu,
                    )
                    .then((response) => {
                        if (days.length === 1) {
                            setSuccessState(true);
                            setLoadingState(false);
                            setMessageState(response.message);
                            toast.success(response.message);
                            props.rerenderParentCallback();
                        } else {
                            allSuccess++;
                        }
                        if (index === days.length - 1) {
                            if (allSuccess > 0) {
                                setSuccessState(true);
                                setLoadingState(false);
                                setMessageState('Les Activités ont été modifiées');
                                toast.success('Les Activités ont été modifiées');
                                props.rerenderParentCallback();
                            }
                        }
                    })
                    .catch((err) => {
                        setSuccessState(false);
                        setLoadingState(false);
                        setMessageState(err.response.message);
                        toast.error('La modification a échoué');
                        props.rerenderParentCallback();
                    });
            }
        });
    };

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
        >
            <FormActivityComponent
                monthCalendar={props.monthCalendar}
                jour={props.jour}
                openRightPanel={props.openRightPanel}
                closeRightPanel={props.closeRightPanel}
                rerenderParentCallback={props.rerenderParentCallback}
                activities={props.activities}
                activity={props.activity}
                employe={props.employe}
                isUpdate={true}
                inputProjet={{
                    value: props.activity.projet.project_id,
                    label: props.activity.projet.code_projet + ' - ' + props.activity.projet.nom,
                }}
                inputValeur={props.activity.valeur}
                inputLieuActivity={props.activity.lieu_activity.lieu_activity_id}
                inputCommentaire={props.activity.commentaire}
                handleForm={handleForm}
                handleDelete={handleDeleteActivity}
            />
        </ResultFormComponent>
    );
};

export default UpdateActivityComponent;
