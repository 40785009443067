import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { H2Lighter } from '../../../theming/customTheme';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FolderZipIcon from '@mui/icons-material/FolderZip';

const FormFonctionComponent = (props) => {
    const isUpdate = props.isUpdate || false;
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    return (
        <CenteredPaper size="large" withAlign={false} withMargin={false}>
            <H2Lighter>{isUpdate ? 'Modifier la ' : 'Créer une '}fonction</H2Lighter>
            <form onSubmit={handleSubmit(props.handleForm)}>
                <Controller
                    name="inputName"
                    control={control}
                    required={{
                        required: { value: true, message: 'Le champ nom est obligatoire' },
                    }}
                    defaultValue={props.inputName || null}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Nom de la fonction"
                            error={errors.inputName}
                            helperText={errors.inputName && errors.inputName.message}
                            fullWidth
                        />
                    )}
                />
                <div style={{ textAlign: 'center' }}>
                    <Button type="submit" variant="contained" sx={{ mr: 1, mt: 1 }}>
                        {isUpdate ? <EditIcon sx={{ mr: 1 }} /> : <AddIcon sx={{ mr: 1 }} />}
                        {isUpdate ? 'Modifier' : 'Créer'}
                    </Button>
                    {isUpdate && (
                        <Button
                            type="button"
                            onClick={handleSubmit(props.handleDelete)}
                            color="secondary"
                            variant="contained"
                            sx={{ mr: 1, mt: 1 }}
                        >
                            <FolderZipIcon sx={{ mr: 1 }} />
                            Archiver
                        </Button>
                    )}
                </div>
            </form>
        </CenteredPaper>
    );
};

export default FormFonctionComponent;
