/** API services */
import clientService from '../../../services/client.service';

import { useState } from 'react';

import ResultFormComponent from '../../assets/container/resultForm.component';
import FormClientComponent from './form-client.component';

const CreateClientComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const isModule = props.isModule || false;

    const handleCreateClient = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        const { inputNom, inputVille, inputCommentaire, inputRegion } = dataForm;
        clientService
            .create(inputNom, inputVille, inputCommentaire, inputRegion)
            .then((response) => {
                let isSuccess = true;
                let message = '';
                if (response.error !== undefined) {
                    isSuccess = false;
                    message = response.error;
                } else {
                    message = 'Client créé avec succès !';
                }
                setSuccessState(isSuccess);
                setLoadingState(false);
                setMessageState(message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
            link={isModule ? null : '/admin/clients'}
        >
            <FormClientComponent handleForm={handleCreateClient} />
        </ResultFormComponent>
    );
};

export default CreateClientComponent;
