import { React } from 'react';

// React Hook Form
import { useForm, Controller } from 'react-hook-form';

// MUI
import { TextField, Grid, Button, FormControlLabel, Switch, Autocomplete } from '@mui/material';
import { H2Lighter } from '../../../theming/customTheme';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import { useState } from 'react';
import MenuItemUserComponent from '../../assets/menuitem/MenuItemUser.component';

const FormUserComponent = (props) => {
    const [soldeState, setSoldeState] = useState(props.solde || null);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    let isUpdate = props.isUpdate || false;

    const handleChangeSolde = (event) => {
        setSoldeState(event.target.value);
    };

    return (
        <CenteredPaper size="large" withMargin={false} withAlign={false}>
            <H2Lighter sx={{ textAlign: 'center' }}>Création d'un utilisateur NeoCRA</H2Lighter>

            <form onSubmit={handleSubmit(props.handleForm)}>
                <Controller
                    key="inputNom"
                    name="inputNom"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ nom de famille est obligatoire',
                        },
                        minLength: 2,
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id="inputNom"
                            label="Nom de famille"
                            sx={{ mt: 1 }}
                            error={!!errors.inputNom}
                            helperText={!!errors.inputNom && errors.inputNom.message}
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    defaultValue={props.nom || null}
                />
                <Controller
                    key="inputPrenom"
                    name="inputPrenom"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ prénom est obligatoire',
                        },
                        minLength: 2,
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id="inputPrenom"
                            label="Prénom"
                            sx={{ mt: 1 }}
                            error={!!errors.inputPrenom}
                            helperText={!!errors.inputPrenom && errors.inputPrenom.message}
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    defaultValue={props.prenom || null}
                />
                <Controller
                    key="inputEmail"
                    name="inputEmail"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ email est obligatoire',
                        },
                        pattern: {
                            value: /^\S+@\S+$/i,
                            message: "La valeur entrée n'est pas un email",
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id="inputEmail"
                            label="Email"
                            type="email"
                            sx={{ mt: 1 }}
                            error={!!errors.inputEmail}
                            helperText={!!errors.inputEmail && errors.inputEmail.message}
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    defaultValue={props.email || null}
                />
                {/* <Controller
                            key="inputPassword"
                            name="inputPassword"
                            control={control}
                            rules={{
                                required: {
                                    value: isUpdate ? false : true,
                                    message: 'Le champ mot de passe est obligatoire',
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-./]).{8,}$/,
                                    message:
                                        'Le mot-de-passe doit contenir minimum 8 caractères, 1 majuscule, 1 minuscule et 1 nombre et 1 caractère spécial.',
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Le mot-de-passe doit faire au moins 8 caractères.',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="inputPassword"
                                    label={
                                        isUpdate
                                            ? 'Nouveau mot de passe'
                                            : "Mot de passe par défaut (devra être modifié par l'utilisateur)"
                                    }
                                    type="password"
                                    sx={{ mt: 1 }}
                                    error={!!errors.inputPassword}
                                    helperText={
                                        !!errors.inputPassword && errors.inputPassword.message
                                    }
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                            defaultValue=""
                        /> */}
                <Controller
                    key="inputEstInterne"
                    name="inputEstInterne"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    {...field}
                                    key="inputEstInterne"
                                    size="small"
                                    checked={!!value}
                                />
                            }
                            sx={{ mt: 1 }}
                            label="L'utilisateur est interne à NeoDT ?"
                        />
                    )}
                    defaultValue={props.estInterne || null}
                />
                {isUpdate && (
                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={soldeState !== props.solde ? 6 : 12}>
                            <Controller
                                key="inputSolde"
                                name="inputSolde"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le champ solde est requis.',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="inputSolde"
                                        label="Solde de congé actuel"
                                        type="number"
                                        onChange={(event) => {
                                            field.onChange(event);
                                            handleChangeSolde(event);
                                        }}
                                        error={!!errors.inputSolde}
                                        helperText={
                                            !!errors.inputSolde && errors.inputSolde.message
                                        }
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                defaultValue={soldeState}
                            />
                        </Grid>
                        {soldeState !== props.solde && (
                            <Grid item xs={6}>
                                <Controller
                                    key="inputCommentaireSolde"
                                    name="inputCommentaireSolde"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message:
                                                'Le champ de commentaire de solde est requis si le solde a été modifié',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Commentaire pour la modification du solde"
                                            error={!!errors.inputCommentaireSolde}
                                            helperText={
                                                !!errors.inputCommentaireSolde &&
                                                errors.inputCommentaireSolde.message
                                            }
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                <Controller
                    key="inputRole"
                    name="inputRole"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ Rôle est obligatoire',
                        },
                    }}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            disablePortal
                            fullWidth
                            options={props.roles}
                            onChange={(_, value) => field.onChange(value)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Rôle au sein de l'entreprise"
                                    sx={{ mt: 1 }}
                                    variant="outlined"
                                    error={!!errors.inputRole}
                                    helperText={
                                        !!errors.inputRole ? errors.inputRole.message : null
                                    }
                                />
                            )}
                        />
                    )}
                    defaultValue={props.role || null}
                />
                <Controller
                    key="inputFonction"
                    name="inputFonction"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ fonction est obligatoire',
                        },
                    }}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            disablePortal
                            fullWidth
                            options={props.fonctions}
                            onChange={(_, value) => field.onChange(value)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Fonction de l'utilisateur"
                                    sx={{ mt: 1 }}
                                    variant="outlined"
                                    error={!!errors.inputFonction}
                                    helperText={
                                        !!errors.inputFonction ? errors.inputFonction.message : null
                                    }
                                />
                            )}
                        />
                    )}
                    defaultValue={props.fonction || null}
                />
                <Controller
                    key="inputManager"
                    name="inputManager"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ manager est obligatoire',
                        },
                    }}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            disablePortal
                            fullWidth
                            options={props.users}
                            onChange={(_, value) => field.onChange(value)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderOption={(autoProps, option) => (
                                <MenuItemUserComponent
                                    autoCompleteProps={autoProps}
                                    userObject={option.userObject}
                                    value={option.value}
                                />
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Manager attitré"
                                    sx={{ mt: 1 }}
                                    variant="outlined"
                                    error={!!errors.inputManager}
                                    helperText={
                                        !!errors.inputManager ? errors.inputManager.message : null
                                    }
                                />
                            )}
                        />
                    )}
                    defaultValue={props.manager || null}
                />
                <Controller
                    key="inputBusinessUnit"
                    name="inputBusinessUnit"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ business unit est obligatoire',
                        },
                    }}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            disablePortal
                            fullWidth
                            options={props.bus}
                            onChange={(_, value) => field.onChange(value)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Business Unit"
                                    sx={{ mt: 1 }}
                                    variant="outlined"
                                    error={!!errors.inputBusinessUnit}
                                    helperText={
                                        !!errors.inputBusinessUnit
                                            ? errors.inputBusinessUnit.message
                                            : null
                                    }
                                />
                            )}
                        />
                    )}
                    defaultValue={props.bu || null}
                />
                {isUpdate ? (
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                            <Button type="submit" variant="contained" fullWidth>
                                <ModeEditIcon /> Modifier l'utilisateur
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="button"
                                variant="contained"
                                color="error"
                                fullWidth
                                onClick={() => {
                                    props.handleDelete(123);
                                }}
                            >
                                <DeleteIcon /> Sortir des effectifs
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <center>
                        <Button type="submit" variant="contained" size="medium" sx={{ mt: 2 }}>
                            <AddIcon /> Créer l'utilisateur
                        </Button>
                    </center>
                )}
            </form>
        </CenteredPaper>
    );
};

export default FormUserComponent;
