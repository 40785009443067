import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import fichiersService from '../../../services/fichiers.service';
import TabPanel from '../../assets/container/tabPanel.component';
import CrudPageComponent from '../../assets/page/crud-page.component';
import ListCategoryFichiersComponent from './category_fichiers/list-category-fichiers.component';

const ListFichiersComponent = (props) => {
    const [contentState, setContentState] = useState([
        {
            fichiers_id: '',
            name: '',
            description: '',
            fk_owner: { nom: '', prenom: '' },
            createdAt: '',
        },
    ]);

    const [errorState, setErrorState] = useState(null);
    const [valueTabState, setValueTabState] = useState(0);

    const handleFetchFichiers = () => {
        fichiersService.getAll().then((fichiers) => {
            if (fichiers.error) {
                setErrorState(fichiers.error);
            } else {
                setContentState(fichiers);
            }
        });
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChangeTab = (event, newValue) => {
        setValueTabState(newValue);
    };

    useEffect(() => {
        props.onLoad('Administrateur - Tous les fichiers');
        handleFetchFichiers();
    }, []);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={valueTabState}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                >
                    <Tab label="Fichiers" {...a11yProps(0)} />
                    <Tab label="Catégorie de fichiers" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={valueTabState} index={0}>
                <CrudPageComponent
                    objectName="fichiers"
                    title="Gestion des fichiers"
                    titleAdd="Ajouter un fichier"
                    linkAdd={'/admin/create-fichiers'}
                    displayType={true}
                    error={errorState}
                    headers={['Nom', 'Créateur', 'Créé le']}
                    dataContent={contentState.map((fichier) => ({
                        id: fichier.fichiers_id,
                        nom: fichier.name,
                        owner: fichier.fk_owner.nom.toUpperCase() + ' ' + fichier.fk_owner.prenom,
                        createdAt: new Date(fichier.createdAt).toLocaleDateString('fr'),
                    }))}
                    linkEdit="/admin/update-fichiers"
                />
            </TabPanel>
            <TabPanel value={valueTabState} index={1}>
                <ListCategoryFichiersComponent />
            </TabPanel>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedListFichiersComponent = connect(
    null,
    mapDispatchToProps,
)(ListFichiersComponent);
