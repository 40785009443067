import { React, useState } from 'react';
import congeService from '../../services/conge.service';

// import { getClassNameMiniCalendar } from '../../helpers/conge.helper';
// import { getMonthName } from '../../helpers/date.helper';
//images
import { toast } from 'react-toastify';

// MUI
// import { GridCalendar } from '../../customTheme';
import ResultFormComponent from '../assets/container/resultForm.component';
import FormCongesComponent from './form-conge.component';

const CreateCongeComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    const createConges = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        congeService
            .insertConge({
                type_conges: dataForm.inputType.value,
                date_debut: new Date(
                    dataForm.inputDateDebut.getTime() -
                        dataForm.inputDateDebut.getTimezoneOffset() * 60000,
                ),
                date_fin: new Date(
                    dataForm.inputDateFin.getTime() -
                        dataForm.inputDateFin.getTimezoneOffset() * 60000,
                ),
                periode_debut: +dataForm.inputPeriodeDebut,
                periode_fin: +dataForm.inputPeriodeFin,
                commentaire: dataForm.inputCommentaire,
                user_id: props.employe ? props.employe.user_id : null,
            })
            .then((response) => {
                if (!response.error) {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                    props.rerenderParentCallback();
                    toast.success(response.message);
                } else {
                    // une erreur est retournée
                    setSuccessState(false);
                    setMessageState(response.error);
                    setLoadingState(false);
                    toast.error(response.error);
                    props.rerenderParentCallback();
                }
            });
    };

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
        >
            <FormCongesComponent
                monthCalendar={props.monthCalendar}
                jour={props.jour}
                openRightPanel={props.openRightPanel}
                closeRightPanel={props.closeRightPanel}
                rerenderParentCallback={props.rerenderParentCallback}
                activities={props.activities}
                employe={props.employe}
                handleForm={createConges}
            />
        </ResultFormComponent>
    );
};
export default CreateCongeComponent;
