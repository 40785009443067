import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    SvgIcon,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ActivityComponent from './activitys.component';
import { H2Lighter } from '../../theming/customTheme';
import CenteredPaper from '../assets/container/centeredPaper.component';
import { getMonthName } from '../../helpers/date.helper';
import { Controller, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import projetService from '../../services/projet.service';
import activityService from '../../services/activity.service';
import InputMiniCalendarComponent from '../assets/inputs/inputMiniCalendar.component';
import MenuItemProjetComponent from '../assets/menuitem/MenuItemProjet.component';
import MenuItemClientComponent from '../assets/menuitem/MenuItemClient.component';

const FormActivityComponent = (props) => {
    const [clientOptionsState, setClientOptionsState] = useState([]);
    const [availableProjetsState, setAvailableProjetsState] = useState([]);
    const [lieuListeState, setLieuListeState] = useState([]);
    const [optionsState, setOptionsState] = useState([]);
    const [commentaireCheckState, setCommentaireCheckState] = useState(
        !!props.inputCommentaire ? true : false,
    );
    const [isCalendarDisplayedState, setIsCalendarDisplayedState] = useState(
        !!props.isUpdate ? false : true,
    );
    const [defaultDaysState, setDefaultDaysState] = useState([props.jour]);

    const isUpdate = props.isUpdate || false;

    // Création des constantes pour le formulaire
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm();

    let monthGlobal = props.monthCalendar;
    const date = new Date(props.jour.date + 1);
    const monthName = getMonthName(date);

    /** onChange fonctions */
    const onChangeClient = (event) => {
        handleFetchProjets(event);
    };

    /** HANDLERS */
    const handleFetchProjetClient = () => {
        projetService.getAvailableProjetClient().then((res) => {
            let clientOptions = res.clients.map((client) => {
                return {
                    value: client.client_id,
                    label: `${client.nom} - ${client.ville}`,
                    nom: client.nom,
                    ville: client.ville,
                };
            });
            setClientOptionsState(clientOptions);
            setAvailableProjetsState(res.projets);
        });
    };

    /** Récupération des projets et suppression de la liste de ceux déjà sélectionnés pour ce jour*/
    const handleFetchProjets = (client_id) => {
        if (client_id !== null) {
            let projetOptions = availableProjetsState
                .filter((projet) => {
                    return projet.client.client_id === client_id.value;
                })
                .map((projet) => {
                    return {
                        value: projet.project_id,
                        label: `${projet.code_projet} - ${projet.nom}`,
                        code_projet: projet.code_projet,
                        nom: projet.nom,
                    };
                });
            setOptionsState(projetOptions);
        } else {
            setValue('inputProjet', null);
            setOptionsState([]);
        }
    };

    const fetchActivityLieu = () => {
        activityService.getAllActivityLieu().then((response) => {
            setLieuListeState(response);
        });
    };

    /** REACT LIFECYCLE */
    useEffect(() => {
        monthGlobal.forEach((week) => {
            week['isChecked'] = false;
        });
        handleFetchProjetClient();
        fetchActivityLieu();
    }, []);

    useEffect(() => {
        if (isUpdate) {
            handleFetchProjets({ value: props.activity.projet.fk_client });
        }
    }, [availableProjetsState]);

    useEffect(() => {
        if (isUpdate && isCalendarDisplayedState) {
            let tempArray = [];
            monthGlobal.forEach((week) => {
                week.jours.forEach((jour) => {
                    let toDisplay = week.jours.filter((jour) => jour.status > 0);
                    if (toDisplay.length > 0) {
                        if (jour.activities.length > 0) {
                            jour.activities.forEach((activity) => {
                                if (
                                    activity.activity.projet.project_id ===
                                        props.activity.projet.project_id &&
                                    jour.date.getTime() !== props.jour.date.getTime()
                                ) {
                                    tempArray.push(jour);
                                }
                            });
                        }
                    }
                });
            });
            setDefaultDaysState([...defaultDaysState, ...tempArray]);
        }
    }, [isCalendarDisplayedState]);

    return (
        <CenteredPaper size="all" withMargin={false} withAlign={false} elevation={0}>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                        <ArrowCircleLeftIcon
                            sx={{ '&:hover': { color: 'primary.main' } }}
                            onClick={(e) =>
                                props.openRightPanel(
                                    e,
                                    <ActivityComponent
                                        jour={props.jour}
                                        openRightPanel={props.openRightPanel}
                                        closeRightPanel={props.closeRightPanel}
                                        rerenderParentCallback={props.rerenderParentCallback}
                                        activities={props.activities}
                                        monthCalendar={props.monthCalendar}
                                    />,
                                )
                            }
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <H2Lighter>
                        {props.jour.nom} {props.jour.dayNumber} {monthName}
                    </H2Lighter>
                </Grid>
                <Grid item>
                    <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
                        <CancelIcon
                            sx={{ '&:hover': { color: 'primary.main' } }}
                            onClick={props.closeRightPanel}
                        />
                    </Box>
                </Grid>
            </Grid>
            {props.employe && (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary" style={{ textAlign: 'center' }}>
                            {props.employe.nom + ' ' + props.employe.prenom}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <form onSubmit={handleSubmit(props.handleForm)}>
                {!isUpdate && (
                    <Controller
                        key="inputClient"
                        name="inputClient"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Le champ client doit être saisi',
                            },
                        }}
                        defaultValue={props.inputClient || null}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                disablePortal
                                fullWidth
                                options={clientOptionsState}
                                renderOption={(autoProps, option) => (
                                    <MenuItemClientComponent
                                        autoCompleteProps={autoProps}
                                        value={option.value}
                                        nom={option.nom}
                                        ville={option.ville}
                                    />
                                )}
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                    onChangeClient(!!value ? value : null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Sélectionner un client"
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        error={!!errors.inputClient}
                                        helperText={
                                            !!errors.inputClient && errors.inputClient.message
                                        }
                                    />
                                )}
                            />
                        )}
                    />
                )}

                <Controller
                    key="inputProjet"
                    name="inputProjet"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le champ projet doit être saisi',
                        },
                    }}
                    defaultValue={props.inputProjet || null}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            disablePortal
                            fullWidth
                            options={optionsState}
                            getOptionLabel={(option) => option.label || null}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            renderOption={(autoProps, option) => {
                                return (
                                    <MenuItemProjetComponent
                                        autoCompleteProps={autoProps}
                                        value={option.value}
                                        nom={option.nom}
                                        code_projet={option.code_projet}
                                    />
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Projet lié"
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    error={!!errors.inputProjet}
                                    helperText={!!errors.inputProjet && errors.inputProjet.message}
                                />
                            )}
                        />
                    )}
                />

                <Box>
                    <Controller
                        key="inputValeur"
                        name="inputValeur"
                        defaultValue={props.inputValeur || '1'}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Ce champ doit être saisi',
                            },
                            max: { value: 3 },
                            min: { value: 0.125 },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="inputValeur"
                                label="Activité à pointer"
                                type="number"
                                sx={{ mt: 2 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputProps: {
                                        max: 3,
                                        min: 0.125,
                                        step: 0.125,
                                    },
                                }}
                                error={!!errors.inputValeur}
                                helperText={
                                    !!errors.inputValeur ? errors.inputValeur.message : null
                                }
                                variant="outlined"
                            />
                        )}
                    />
                    {lieuListeState.length > 0 ? (
                        <FormControl sx={{ mt: 1, ml: 2 }} error={!!errors.inputSelectedLieu}>
                            <FormLabel>Sélectionner un lieu</FormLabel>
                            <Controller
                                key="inputSelectedLieu"
                                name="inputSelectedLieu"
                                defaultValue={
                                    props.inputLieuActivity || lieuListeState[0].lieu_activity_id
                                }
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le champ lieu doit être saisi',
                                    },
                                }}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        aria-labelledby="selected-lieu-radio-buttons-group-label"
                                        row
                                    >
                                        {lieuListeState && lieuListeState.length > 0
                                            ? lieuListeState.map((lieu, idLieu) => (
                                                  <Tooltip
                                                      title={lieu.nom}
                                                      key={'itemLieu_' + idLieu}
                                                      placement="top"
                                                  >
                                                      <FormControlLabel
                                                          value={lieu.lieu_activity_id}
                                                          control={<Radio />}
                                                          label={
                                                              <SvgIcon>
                                                                  <path d={lieu.logo} />
                                                              </SvgIcon>
                                                          }
                                                      />
                                                  </Tooltip>
                                              ))
                                            : null}
                                    </RadioGroup>
                                )}
                            />
                            <FormHelperText>
                                {!!errors.inputSelectedLieu && errors.inputSelectedLieu.message}
                            </FormHelperText>
                        </FormControl>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>

                <FormControlLabel
                    control={
                        <Switch
                            onChange={() => {
                                setCommentaireCheckState(!commentaireCheckState);
                            }}
                            checked={commentaireCheckState}
                        />
                    }
                    label="Commentaire"
                />

                {commentaireCheckState && (
                    <Controller
                        key="inputCommentaire"
                        name="inputCommentaire"
                        defaultValue={props.inputCommentaire || ''}
                        control={control}
                        rules={{
                            required: {
                                value: commentaireCheckState,
                                message: 'Le champ commentaire doit être saisi',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                key={'inputCommentaire'}
                                label="Commentaire"
                                placeholder="Intervention client pour ..."
                                error={!!errors.inputCommentaire}
                                helperText={
                                    !!errors.inputCommentaire && errors.inputCommentaire.message
                                }
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                            />
                        )}
                    />
                )}

                {
                    /**
                                Dans le cas où on est dans un formulaire d'update, on affiche le switch pour activer ou non la sélection multiple. On utilise un Controller afin de passer à la fonction d'update la valeur de ce switch
                                La valeur de ce switch permettra de déterminer si on prend en compte ou non le tableau de jour fournis par le mini calendrier.
                            */
                    isUpdate && (
                        <Controller
                            name="inputDisplayCalendar"
                            defaultValue={isCalendarDisplayedState}
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() => {
                                                field.onChange(!field.value);
                                                setIsCalendarDisplayedState(
                                                    !isCalendarDisplayedState,
                                                );
                                            }}
                                            checked={field.value}
                                        />
                                    }
                                    label="Activer la sélection multiple"
                                />
                            )}
                        />
                    )
                }

                {
                    /**
                            Le mini calendrier est affiché en fonction de la valeur de isCalendarDisplayedState. 
                                En mode update, cela dépendra du switch et isCalendarDisplayedState est par défaut à false.
                                En mode insert isCalendarDisplayedState est par défaut à true et la valeur ne peux être changé (switch non affiché)
                             */
                    isCalendarDisplayedState && (
                        <InputMiniCalendarComponent
                            control={control}
                            errors={errors}
                            monthGlobal={monthGlobal}
                            jour={props.jour}
                            defaultDays={defaultDaysState}
                            setValue={setValue}
                        />
                    )
                }

                <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
                    {!isUpdate ? (
                        <>
                            Valider <AddIcon sx={{ ml: 1 }} />
                        </>
                    ) : (
                        <>
                            Modifier <EditIcon sx={{ ml: 1 }} />
                        </>
                    )}
                </Button>
                {isUpdate && (
                    <Button
                        type="button"
                        variant="contained"
                        sx={{ mt: 2 }}
                        color="error"
                        fullWidth
                        onClick={() => {
                            props.handleDelete(getValues());
                        }}
                    >
                        Supprimer {isCalendarDisplayedState ? 'l(es) activité(s)' : "l'activité"}{' '}
                        <DeleteIcon sx={{ ml: 1 }} />
                    </Button>
                )}
            </form>
        </CenteredPaper>
    );
};

export default FormActivityComponent;
