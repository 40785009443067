import { Grid, Paper } from '@mui/material';

/**
 * Composant retournant un Paper centré sur l'écran.
 * Le composant peux prendre aussi en paramètres une props size qui prend en valeur "large", "medium", et "small"
 * définissant la taille que prendre le Paper sur la page, medium étant la taille par défaut
 * Le composant peux prendre aussi en paramètre les props withMargin et withAlign afin d'activer / désactiver
 * le margin top ou le textAlign center
 */
const CenteredPaper = (props) => {
    const size = props.size || 'medium';
    const withMargin = props.withMargin === undefined ? 10 : props.withMargin;
    const withAlign = props.withAlign === undefined ? true : props.withAlign;
    const elevation = props.elevation === undefined ? 4 : props.elevation;
    const withPadding = props.withPadding === undefined ? true : props.withPadding;

    const allWidth = {
        small: 4,
        medium: 6,
        large: 8,
        'extra-large': 10,
        all: 12,
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                mt: withMargin === false ? 0 : withMargin,
                textAlign: withAlign ? 'center' : 'inherit',
            }}
        >
            <Grid item xs={12} md={allWidth[size]}>
                <Paper sx={{ p: withPadding ? 2 : 0, ...props.addedPaperSx }} elevation={elevation}>
                    {props.content || props.children}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default CenteredPaper;
