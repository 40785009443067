import { Paper, Button, Grid, Drawer, TableRow, TableCell, Badge, Switch } from '@mui/material';
import { getContrastRatio, styled, useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
// import components from './components';
// import * as palette from './palette';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.primary.main
                : theme.palette.primary.light,
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const WidgetCalendarBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        minWidth: '30px',
    },
}));

const Item = styled('p')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// Paper avec tout centré et background en primary
const ContainerPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    margin: 'auto',
    backgroundColor: theme.palette.primary.light,
}));

//Paper avec padding
const ContainerPaperPadding = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: '10px',
}));

const PaperPrimary = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
}));

const ContainerPaperPaddingCentered = styled(ContainerPaperPadding)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    margin: 'auto',
}));

const ButtonLight = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const GridCalendar = styled(Grid)(({ theme }) => ({
    textAlign: 'center',
    verticalAlign: 'middle',
    margin: '1px',
    borderRadius: '50%',
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center' /* aligner verticalement */,
    justifyContent: 'center' /* aligner horizontalement */,
    maxHeight: '37.55px !important',
    minHeight: '37.55px !important',
    maxWidth: '37.55px !important',
    minWidth: '37.55px !important',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
    '&.monthName': {
        backgroundColor: 'transparent',
    },
    '&.invisible': {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    '&.light': {
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    '&.otherMonth': {
        fontWeight: 'bold',
    },
    '&.selected': {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    '&.ferie': {
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            backgroundColor: theme.palette.success.main,
        },
        '&.selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.success.light,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
    },
}));

// Obligatoire pour tout les h2 afin de garder un tout uniforme
const H2Lighter = styled('h2')(({ theme }) => ({
    fontWeight: 'lighter',
    color:
        theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
}));

/** Utile pour forcer le mode primary dark même en mode nuit */
const H2LighterDark = styled('h2')(({ theme }) => ({
    fontWeight: 'lighter',
    color: theme.palette.primary.dark,
}));

const H2Title = styled('h2')(({ theme }) => ({
    fontWeight: '600',
    fontSize: '1.2rem',
    borderBottom: '2px solid ' + theme.palette.primary.main,
    width: '7rem',
    color:
        theme.palette.mode === 'light'
            ? theme.palette.secondary.main
            : theme.palette.secondary.veryLight,
}));

//Composant utilisé pour le panleft se basant sur un Drawer avec des couleurs custom
const DrawerPanLeft = styled(Drawer)(({ theme }) => ({
    marginTop: '50px',
    '.MuiDrawer-paper': {
        margin: '5px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    '& .MuiListItemIcon-root': {
        color: 'inherit',
    },
}));

const StripedTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.isDelete': {
        backgroundColor: theme.palette.secondary.light,
    },
}));

const TableCellHead = styled(TableCell)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const MUIToastContainer = styled(ToastContainer)(() => {
    const theme = useTheme();

    return {
        '.Toastify__progress-bar-theme--light': { backgroundColor: theme.palette.primary.main },
        '.Toastify__progress-bar-theme--dark ': { backgroundColor: theme.palette.primary.main },
        '.Toastify__progress-bar--info': { backgroundColor: theme.palette.info.main },
        '.Toastify__progress-bar--success': { backgroundColor: theme.palette.success.main },
        '.Toastify__progress-bar--warning': { backgroundColor: theme.palette.warning.main },
        '.Toastify__progress-bar--error': { backgroundColor: theme.palette.error.main },
    };
});

const getConstrastColor = (color) => {
    return getContrastRatio(color, '#fff') > 3 ? '#fff' : '#111';
};

export {
    WidgetCalendarBadge,
    getConstrastColor,
    MaterialUISwitch,
    Item,
    ContainerPaper,
    ContainerPaperPadding,
    ContainerPaperPaddingCentered,
    ButtonLight,
    GridCalendar,
    H2Lighter,
    H2LighterDark,
    DrawerPanLeft,
    PaperPrimary,
    StripedTableRow,
    TableCellHead,
    H2Title,
    StyledBadge,
    MUIToastContainer,
};
