import React, { Component } from 'react';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';

import CreateClientComponent from './create-client.component';

export default class CreateClientPageComponent extends Component {
    componentDidMount() {
        this.props.onLoad('Administrateur - Créer un client');
    }
    render() {
        return <CreateClientComponent />;
    }
}

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateClientComponent = connect(
    null,
    mapDispatchToProps,
)(CreateClientPageComponent);
