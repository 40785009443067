import './toggle.css';

const Toggle = ({ active, setActive }) => {
    return (
        <button className={`toggle ${active ? 'active' : ''}`} onClick={() => setActive(!active)}>
            <div className="thumb" />
        </button>
    );
};

export default Toggle;
