import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { H2Lighter } from '../../../../theming/customTheme';
import { setPageTitle } from '../../../../redux/actions/scopeAction';
import soldesService from '../../../../services/soldes.service';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WaitingScreenComponent from '../../../assets/waiting-screen.component';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EditIcon from '@mui/icons-material/Edit';
import SimpleModalComponent from '../../../assets/modal/simple-modal.component';
import SoldeUserFormComponent from '../../../assets/form/solde-user-form.component';
import { toast } from 'react-toastify';
import ListLogSoldesComponent from '../../../assets/list/list-log-soldes.component';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import ResultFormComponent from '../../../assets/container/resultForm.component';
import DisplayAvatarComponent from '../../../assets/infos/display-avatar.component';

const SoldesComponent = (props) => {
    const [soldesState, setSoldesState] = useState([]);
    const [expandedState, setExpandedState] = useState(false);
    const [contentState, setContentState] = useState([]);
    const [contentModalState, setContentModalState] = useState('');
    const [contentModalListState, setContentModalListState] = useState('');
    const [isOpenState, setIsOpenState] = useState(false);
    const [isOpenListState, setIsOpenListState] = useState(false);
    const [showFormState, setShowFormState] = useState(false);
    const [anneeDebutState, setAnneeDebutState] = useState(new Date().getFullYear());
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const theme = useTheme();

    // Création des constantes pour le formulaire
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handleFetchSoldes = () => {
        soldesService.getAllSoldes().then((response) => {
            if (response.error) {
                toast.error(response.error);
            } else {
                setSoldesState(response);
            }
        });
    };

    const handleFetchUserForSoldes = (index) => {
        soldesService.getUsersForPeriodeSoldes(index).then((response) => {
            if (response.error) {
                toast.error(response.error);
            } else {
                setContentState(response);
            }
        });
    };

    const handleCloseModal = () => {
        setExpandedState(false);
        setIsOpenState(false);
    };

    const handleCloseModalList = () => {
        setIsOpenListState(false);
    };

    const handleOpenModal = (content) => {
        setContentModalState(content);
        setIsOpenState(true);
    };

    const handleOpenModalList = (content) => {
        setContentModalListState(content);
        setIsOpenListState(true);
    };

    const handleChangeAccordion = (index) => (event, isExpanded) => {
        setContentState('');
        if (isExpanded) {
            setExpandedState('panel' + index);
            handleFetchUserForSoldes(index);
        } else {
            setExpandedState(false);
        }
    };

    const handleCreatePeriode = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        soldesService.createPeriodeSoldes(dataForm.inputAnneeDebut).then((response) => {
            if (!response.error) {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
                toast.success(response.message);
                handleFetchSoldes();
            } else {
                setSuccessState(false);
                setMessageState(response.error);
                setLoadingState(false);
                toast.error(response.error);
                handleFetchSoldes();
            }
        });
    };

    const handleShowForm = (value = !showFormState) => {
        setShowFormState(value);
    };

    useEffect(() => {
        props.onLoad('Manager - Gestion des soldes de congés');
        handleFetchSoldes();
    }, []);

    return (
        <>
            <H2Lighter>Gestion des soldes</H2Lighter>

            <form onSubmit={handleSubmit(handleCreatePeriode)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <Button type="button" variant="contained" onClick={() => handleShowForm()}>
                            Générer une nouvelle période de soldes
                        </Button>
                    </Grid>
                    {showFormState && (
                        <ResultFormComponent
                            submitted={submittedState}
                            loading={loadingState}
                            success={successState}
                            message={messageState}
                            addedContent={
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        setLoadingState(false);
                                        setSubmittedState(false);
                                        setSuccessState(false);
                                    }}
                                >
                                    Fermer
                                </Button>
                            }
                        >
                            <Grid item>
                                <Controller
                                    name="inputAnneeDebut"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Le champ année de début est requis.',
                                        },
                                        min: {
                                            value: 2000,
                                            message:
                                                'La valeur de la période doit être supérieur à 2000',
                                        },
                                        max: {
                                            value: new Date().getFullYear() + 50,
                                            message:
                                                "N'exagérons pas, plus de 50 ans ça fait beaucoup",
                                        },
                                    }}
                                    defaultValue={anneeDebutState}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            name="inputAnneeDebut"
                                            label="Année de début"
                                            type="number"
                                            size="small"
                                            onChange={(value) => {
                                                field.onChange(value);
                                                setAnneeDebutState(parseInt(value.target.value));
                                            }}
                                            error={!!errors.inputAnneeDebut}
                                            helperText={
                                                !!errors.inputAnneeDebut &&
                                                errors.inputAnneeDebut.message
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained">
                                    Créer la période {anneeDebutState || ''} -{' '}
                                    {anneeDebutState + 1 || ''}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="text">
                                    <CloseIcon onClick={() => handleShowForm(false)} />
                                </Button>
                            </Grid>
                            <Grid item>
                                <ResultFormComponent />
                            </Grid>
                        </ResultFormComponent>
                    )}
                </Grid>
            </form>

            <SimpleModalComponent isOpen={isOpenState} handleCloseModal={handleCloseModal}>
                <SoldeUserFormComponent content={contentModalState} />
            </SimpleModalComponent>
            <SimpleModalComponent isOpen={isOpenListState} handleCloseModal={handleCloseModalList}>
                <ListLogSoldesComponent content={contentModalListState} />
            </SimpleModalComponent>

            <Box sx={{ mt: 2 }}>
                {soldesState.map((solde) => (
                    <Accordion
                        expanded={!!contentState && expandedState === 'panel' + solde.periode_id}
                        key={'accordion_soldes_' + solde.periode_id}
                        onChange={handleChangeAccordion(solde.periode_id)}
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{ mt: 0.5 }}
                        elevation={1}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                                Période du <b>{new Date(solde.date_debut).toLocaleDateString()}</b>{' '}
                                au <b>{new Date(solde.date_fin).toLocaleDateString()}</b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor:
                                    theme.palette.mode === 'light' && 'secondary.veryLight',
                            }}
                        >
                            {!!contentState ? (
                                <List dense={false}>
                                    {contentState.map((content, index) => (
                                        <>
                                            <ListItem
                                                key={'list_item_' + content.soldes_utilisateurs_id}
                                                secondaryAction={
                                                    <>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="edit"
                                                            onClick={() => handleOpenModal(content)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            sx={{ ml: 1 }}
                                                            edge="end"
                                                            aria-label="seelogger"
                                                            onClick={() =>
                                                                handleOpenModalList(content)
                                                            }
                                                        >
                                                            <ReceiptLongIcon />
                                                        </IconButton>
                                                    </>
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <DisplayAvatarComponent
                                                        user={content.utilisateur}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        content.utilisateur.nom.toUpperCase() +
                                                        ' ' +
                                                        content.utilisateur.prenom
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        content.utilisateur.prenom.slice(1)
                                                    }
                                                    secondary={'Solde : ' + content.solde}
                                                />
                                            </ListItem>
                                            {index !== contentState.length - 1 && (
                                                <Divider variant="middle" />
                                            )}
                                        </>
                                    ))}
                                </List>
                            ) : (
                                <WaitingScreenComponent withPaper={false} />
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </>
    );
};

/** REDUX */
const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};
// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedSoldesComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SoldesComponent);
