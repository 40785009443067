import { getMonthName } from '../../helpers/date.helper';
import DetailsPinComponent from '../calendar/details-pin.component';
import CreateActivityComponent from './create-activity.component';
import './activity.style.scss';
import CreateCongeComponent from '../conge/create-conge.component';
// import './mini-calendar.scss';
import CancelIcon from '@mui/icons-material/Cancel';
import CenteredPaper from '../assets/container/centeredPaper.component';
import { Box, Button, Grid } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import { H2Lighter } from '../../theming/customTheme';

const ActivityComponent = (props) => {
    const activitesAll = props.activities;

    const dayItem = props.jour;
    let month = getMonthName(new Date(dayItem.date));

    return (
        <CenteredPaper
            size="all"
            withMargin={false}
            withAlign={false}
            elevation={0}
            addedPaperSx={{ backgroundColor: 'transparent' }}
        >
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <H2Lighter>
                        {props.jour.nom} {props.jour.dayNumber} {month}
                    </H2Lighter>
                    {props.employe && (
                        <b>
                            <p>{props.employe.nom + ' ' + props.employe.prenom}</p>
                        </b>
                    )}
                </Grid>
                <Grid item>
                    <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
                        <CancelIcon
                            sx={{
                                '&:hover': {
                                    color: 'primary.main',
                                },
                            }}
                            icon="window-close"
                            onClick={props.closeRightPanel}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {dayItem.activities !== undefined &&
                    dayItem.activities.length > 0 &&
                    dayItem.activities.map((activity, index) => {
                        return (
                            activity !== false && (
                                <Grid item xs={12}>
                                    <DetailsPinComponent
                                        key={index + '_' + activity.activity.activity_id}
                                        employe={props.employe}
                                        activityItem={activity}
                                        dayItem={dayItem}
                                        openRightPanel={props.openRightPanel}
                                        closeRightPanel={props.closeRightPanel}
                                        rerenderParentCallback={props.rerenderParentCallback}
                                        activities={activitesAll}
                                        monthCalendar={props.monthCalendar}
                                    />
                                </Grid>
                            )
                        );
                    })}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 1 }}
                        style={{ justifyContent: 'flex-start' }}
                        startIcon={<HourglassBottomIcon />}
                        onClick={(e) => {
                            props.openRightPanel(
                                e,
                                <CreateActivityComponent
                                    key={dayItem.dayNumber + 'createAct'}
                                    employe={props.employe}
                                    jour={dayItem}
                                    jours={props.jours}
                                    openRightPanel={props.openRightPanel}
                                    closeRightPanel={props.closeRightPanel}
                                    rerenderParentCallback={props.rerenderParentCallback}
                                    activities={activitesAll}
                                    monthCalendar={props.monthCalendar}
                                />,
                            );
                        }}
                    >
                        Ajouter une activité
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 1 }}
                        style={{ justifyContent: 'flex-start' }}
                        startIcon={<BeachAccessIcon />}
                        color="success"
                        onClick={(e) => {
                            props.openRightPanel(
                                e,
                                <CreateCongeComponent
                                    key={dayItem.dayNumber + 'createCon'}
                                    employe={props.employe}
                                    jour={dayItem}
                                    jours={props.jours}
                                    openRightPanel={props.openRightPanel}
                                    closeRightPanel={props.closeRightPanel}
                                    rerenderParentCallback={props.rerenderParentCallback}
                                    activities={activitesAll}
                                    monthCalendar={props.monthCalendar}
                                />,
                            );
                        }}
                    >
                        Poser un congé
                    </Button>
                </Grid>
            </Grid>
        </CenteredPaper>
    );
};

export default ActivityComponent;
