import { Avatar, Button, FormHelperText, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import ImageIcon from '@mui/icons-material/Image';
import CheckIcon from '@mui/icons-material/Check';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const InputFichierComponent = (props) => {
    const { onChange, value, name, accept, errors } = props;
    const isUpdate = props.isUpdate || false;
    const filename = props.filename || null;
    const avatarMode = props.avatarMode || false;

    const [inputValueState, setInputValueState] = useState('');
    const [imagePreviewState, setImagePreviewState] = useState('');

    const changeInputValue = (value, ignoreUpdate = false) => {
        if (value !== '') {
            if (ignoreUpdate === false) {
                if (isUpdate) {
                    setInputValueState(value);
                    setImagePreviewState(value);
                } else {
                    setInputValueState(value);
                    setImagePreviewState(URL.createObjectURL(value));
                }
            } else {
                setInputValueState(value);
                setImagePreviewState(URL.createObjectURL(value));
            }
        } else {
            onChange(-1);
        }
    };

    useEffect(() => {
        changeInputValue(value || '');
    }, []);

    return (
        <Grid
            container
            direction={avatarMode ? 'column' : 'row'}
            justifyContent="flex-start"
            alignItems={avatarMode ? 'center' : 'flex-end'}
            spacing={2}
            sx={{ mt: 2 }}
        >
            {imagePreviewState && (
                <Grid item xs={2}>
                    {inputValueState && inputValueState.type ? (
                        inputValueState.type.split('/')[0] === 'image' ? (
                            avatarMode ? (
                                <Avatar
                                    src={imagePreviewState || ''}
                                    sx={{ width: 150, height: 150 }}
                                />
                            ) : (
                                <img
                                    src={imagePreviewState || ''}
                                    alt="News illustration"
                                    width="100%"
                                />
                            )
                        ) : inputValueState.type === 'application/pdf' ? (
                            <PictureAsPdfIcon sx={{ fontSize: 60 }} />
                        ) : (
                            <>
                                <AttachFileIcon sx={{ fontSize: 60 }} />
                                {filename !== null && <p>{filename}</p>}
                            </>
                        )
                    ) : filename ? (
                        ['png', 'jpg', 'gif', 'jpeg'].includes(
                            filename.split('.')[filename.split('.').length - 1],
                        ) ? (
                            avatarMode ? (
                                <Avatar
                                    src={imagePreviewState || ''}
                                    sx={{ width: 150, height: 150 }}
                                />
                            ) : (
                                <img
                                    src={imagePreviewState || ''}
                                    alt="News illustration"
                                    width="100%"
                                />
                            )
                        ) : (
                            <>
                                <AttachFileIcon sx={{ fontSize: 60 }} />
                                {filename !== null && <p>{filename}</p>}
                            </>
                        )
                    ) : (
                        <>
                            <AttachFileIcon sx={{ fontSize: 60 }} />
                            {filename !== null && <p>{filename}</p>}
                        </>
                    )}
                </Grid>
            )}
            <Grid item>
                <Button
                    variant="contained"
                    component="label"
                    color={
                        !!errors[name] ? 'error' : inputValueState === '' ? 'secondary' : 'success'
                    }
                >
                    {inputValueState === '' ? <ImageIcon /> : <CheckIcon />} Choisir un fichier
                    <input
                        name={name}
                        type="file"
                        accept={accept}
                        hidden
                        onChange={(e) => {
                            onChange(e.target.files[0]);
                            changeInputValue(e.target.files[0], true);
                        }}
                        value=""
                    />
                </Button>
                {!!errors[name] ? (
                    <FormHelperText error>{errors[name].message}</FormHelperText>
                ) : (
                    <FormHelperText>{inputValueState.name || ''}</FormHelperText>
                )}
            </Grid>
        </Grid>
    );
};

export default InputFichierComponent;
