import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { H2Lighter } from '../../../theming/customTheme';
import soldesService from '../../../services/soldes.service';
import CenteredPaper from '../container/centeredPaper.component';
import ResultFormComponent from '../container/resultForm.component';

const SoldeUserFormComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    // Création des constantes pour le formulaire
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    if (!props.content) {
        setMessageState('Certaines données sont manquantes');
        setSuccessState(false);
        setSubmittedState(true);
    }

    const handleForm = (dataForm) => {
        setLoadingState(true);
        setSubmittedState(true);
        soldesService
            .updateSoldeUser({
                soldes_utilisateurs_id: props.content.soldes_utilisateurs_id,
                nbJour: dataForm.inputSolde,
                commentaire: dataForm.inputCommentaire,
                user_id: props.content.utilisateur.user_id,
            })
            .then((response) => {
                if (response.error) {
                    setLoadingState(false);
                    setSuccessState(false);
                    setMessageState(response.error);
                } else {
                    setLoadingState(false);
                    setSuccessState(true);
                    setMessageState(response.message);
                }
            });
    };

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
        >
            <CenteredPaper>
                <form onSubmit={handleSubmit(handleForm)}>
                    <H2Lighter>
                        Formulaire du solde pour{' '}
                        {props.content.utilisateur.nom.toUpperCase() +
                            ' ' +
                            props.content.utilisateur.prenom.charAt(0).toUpperCase() +
                            props.content.utilisateur.prenom.slice(1)}
                    </H2Lighter>
                    <Controller
                        name="inputSolde"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Le champ solde doit être saisi.',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="number"
                                label="Nombre de jour à incrémenter / décrémenter"
                                sx={{ mt: 1 }}
                                error={!!errors.inputSolde}
                                helperText={!!errors.inputSolde && errors.inputSolde.message}
                                fullWidth
                            />
                        )}
                    />
                    <Controller
                        name="inputCommentaire"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Le champ commentaire doit être saisi.',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Raison"
                                sx={{ mt: 1 }}
                                multiline
                                rows={4}
                                error={!!errors.inputCommentaire}
                                helperText={
                                    !!errors.inputCommentaire && errors.inputCommentaire.message
                                }
                                fullWidth
                            />
                        )}
                    />
                    <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                        Valider
                    </Button>
                </form>
            </CenteredPaper>
        </ResultFormComponent>
    );
};

export default SoldeUserFormComponent;
