import { lighten } from '@mui/material';

const components = (palette) => ({
    // Permet de pouvoir scroller le body même si un Menu ou un Drawer est affiché
    MuiModal: {
        defaultProps: {
            disableScrollLock: true,
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: '10px',
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ ownerState }) => ({
                ...(ownerState.color === 'primary'
                    ? {
                          ...(ownerState.variant === 'text'
                              ? {
                                    '&:hover': {
                                        color: 'primary',
                                        textDecoration: 'underline',
                                    },
                                }
                              : {
                                    '&:hover': {
                                        color: lighten(palette[ownerState.color].main, 0.5),
                                    },
                                }),
                      }
                    : {
                          '&:hover': {
                              color: lighten(palette[ownerState.color].main, 0.5),
                          },
                      }),
            }),
        },
    },
});

export default components;
