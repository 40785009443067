import { Grid, MenuItem, Typography } from '@mui/material';

const MenuItemClientComponent = ({ autoCompleteProps, value, nom, ville }) => {
    return (
        <MenuItem {...autoCompleteProps} sx={{ whiteSpace: 'normal' }} value={value}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography>
                        <b>Nom : </b>
                        {nom.toUpperCase()}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        <b>Ville : </b>
                        {ville}
                    </Typography>
                </Grid>
            </Grid>
        </MenuItem>
    );
};

export default MenuItemClientComponent;
