import {
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Paper,
    Switch,
    TextField,
    Typography,
    Zoom,
    Alert,
    Autocomplete,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { H2Lighter } from '../../theming/customTheme';
import CenteredPaper from '../assets/container/centeredPaper.component';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import clientService from '../../services/client.service';
import regionService from '../../services/region.service';
import statusService from '../../services/status.service';
import activityService from '../../services/activity.service';
import ContactModuleComponent from './contact/contact-module.component';
import ClientModuleComponent from '../admin/clients/client-module.component';
import CriticiteModuleComponent from './criticite/criticite-module.component';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { getRandomHexaColor, InputColorComponent } from '../assets/inputs/inputColor.component';
import MenuItemClientComponent from '../assets/menuitem/MenuItemClient.component';
import MenuItemUserComponent from '../assets/menuitem/MenuItemUser.component';
import contactService from '../../services/contact.service';
import projetService from '../../services/projet.service';

const FormProjetComponent = (props) => {
    const [statusState, setStatusState] = useState([]);
    const [typeActivitysState, setTypeActivitysState] = useState([]);
    const [typeFacturationsState, setTypeFacturationsState] = useState([]);
    const [clientsState, setClientsState] = useState([]);
    const [regionsState, setRegionsState] = useState([]);
    const [usersState, setUsersState] = useState([]);
    const [isTicketState, setIsTicketState] = useState(props.isTicket || false);
    const [contactsState, setContactsState] = useState(props.contacts || []);
    const [clientState, setClientState] = useState('');
    const [checkedCriticitesState, setCheckedCriticitesState] = useState([]);
    const randomColor = props.couleur || getRandomHexaColor();
    const isUpdate = props.projectId || false;
    const isForm = !!props.handleForm;

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        // On récupère toutes les informations nécessaires au formulaire
        const fetchData = async () => {
            try {
                const [users, clients, typeActivitys, typeFacturations, regions, status] =
                    await Promise.all([
                        userService.getAllUsers(),
                        clientService.getAllClients(),
                        activityService.getAllActivityTypes(),
                        activityService.getAllTypeFacturation(),
                        regionService.getAllRegions(),
                        statusService.getAllStatus(),
                    ]);

                setStatusState(status);
                setTypeActivitysState(typeActivitys);
                setTypeFacturationsState(typeFacturations);
                setClientsState(
                    clients.map((client) => ({
                        value: client.client_id,
                        label: `${client.nom} - ${client.ville}`,
                        ville: client.ville,
                        nom: client.nom,
                    })),
                );
                setRegionsState(regions);
                setUsersState(
                    users.map((user) => ({
                        value: user.user_id,
                        label: `${user.nom.toUpperCase()} ${user.prenom}`,
                        userObject: user,
                    })),
                );
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (props.projectId) {
            const fetchProjectData = async () => {
                try {
                    const project = await projetService.getProjet(props.projectId);
                    if (project) {
                        if (project.isSupportProject) {
                            const criticites = await projetService.getCriticitesBySupportProjectId(
                                props.projectId,
                            );
                            setCheckedCriticitesState(criticites || []);

                            const contacts = await contactService.getContactsBySupportProjectId(
                                props.projectId,
                            );
                            setContactsState(contacts || []);
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des données du projet:', error);
                }
            };
            fetchProjectData();
        }
    }, [props.projectId]);

    useEffect(() => {
        setCheckedCriticitesState(props.checkedCriticites || []);
    }, [props.checkedCriticites]);

    const handleFetchClients = () => {
        clientService
            .getAllClients()
            .then((response) => {
                if (!response.error) {
                    setClientsState(
                        response.map((client) => ({
                            value: client.client_id,
                            label: `${client.nom} - ${client.ville}`,
                            ville: client.ville,
                            nom: client.nom,
                        })),
                    );
                }
            })
            .catch((err) => {
                console.log('Erreur lors de la récupération des clients:', err);
            });
    };

    const handleFetchContactsClientChange = (client) => {
        setClientState(client);
        contactService.getContactsByClient(client).then((response) => {
            if (!response.error) {
                setContactsState(response);
            } else {
                console.error('Erreur lors de la récupération des contacts:', response.error);
            }
        });
    };

    const handleUpdateCriticiteNbTickets = (elCriticiteId, nbTicket) => {
        setCheckedCriticitesState((prevState) =>
            prevState.map((criticite) =>
                criticite.criticality_id === elCriticiteId ? { ...criticite, nbTicket } : criticite,
            ),
        );
    };

    const handleCheckCriticite = (elCriticite) => {
        const exists = checkedCriticitesState.some(
            (el) => el.criticality_id === elCriticite.criticality_id,
        );

        if (exists) {
            handleRemoveCriticite(elCriticite);
        } else {
            handleAddCriticite(elCriticite);
        }
    };

    const handleAddCriticite = (elCriticite) => {
        setCheckedCriticitesState((prevState) => {
            const newState = [...prevState, { ...elCriticite, nbTicket: 1 }];
            return newState;
        });
    };

    const handleRemoveCriticite = (elCriticite) => {
        setCheckedCriticitesState((prevState) => {
            const newState = prevState.filter(
                (criticite) => criticite.criticality_id !== elCriticite.criticality_id,
            );
            return newState;
        });
    };

    const handleCheckContact = (_, elContact) => {
        if (contactsState.some((contact) => contact.contact_id === elContact.contact_id)) {
            setContactsState(
                contactsState.filter((contact) => contact.contact_id !== elContact.contact_id),
            );
        } else {
            setContactsState([...contactsState, elContact]);
        }
    };

    const handleRemoveContact = (elContact) => {
        setContactsState(
            contactsState.filter((contact) => contact.contact_id !== elContact.contact_id),
        );
    };

    // Assurez-vous que les mises à jour sont appliquées avant l'envoi des données
    const sendForm = async (dataForm) => {
        // Attendre que toutes les mises à jour d'état soient terminées
        await new Promise((resolve) => setTimeout(resolve, 0));

        props.handleForm({
            ...dataForm,
            isUpdate,
            contactsState,
            isTicketState,
            checkedCriticitesState,
        });
    };

    return (
        <CenteredPaper size="extra-large" withAlign={false} withMargin={false}>
            <H2Lighter sx={{ textAlign: 'center' }}>
                {(isUpdate ? 'Modifier le ' : 'Créer un nouveau ') + 'projet'}
            </H2Lighter>
            <form onSubmit={handleSubmit(sendForm)}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                                Détails projet
                            </Typography>
                            <Controller
                                key="inputCode"
                                name="inputCode"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le Code Projet est requis',
                                    },
                                }}
                                defaultValue={props.codeProjet || null}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="inputCode"
                                        label="Code projet"
                                        error={!!errors.inputCode}
                                        helperText={
                                            !!errors.inputCode
                                                ? errors.inputCode.message
                                                : "Le code projet doit être unique et servira d'identifiant de projet"
                                        }
                                        variant="outlined"
                                        placeholder="COD_X1034"
                                        disabled={!isForm}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                key="inputName"
                                name="inputName"
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 30,
                                        message: 'La longueur maximum de caractère est de 30',
                                    },
                                    required: {
                                        value: true,
                                        message: 'Le nom de projet est requis',
                                    },
                                }}
                                defaultValue={props.nom || null}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="inputName"
                                        label="Nom de projet"
                                        sx={{ mt: 1 }}
                                        error={!!errors.inputName}
                                        helperText={!!errors.inputName && errors.inputName.message}
                                        placeholder="Supervision Centreon"
                                        variant="outlined"
                                        disabled={!isForm}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                key="inputDateCommande"
                                name="inputDateCommande"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'La date de commande est requise',
                                    },
                                }}
                                defaultValue={props.dateCommande || Date.now()}
                                render={({ field }) => (
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale={'fr'}
                                    >
                                        <DatePicker
                                            {...field}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    sx={{ mt: 2 }}
                                                    error={!!errors.inputDateCommande}
                                                    helperText={
                                                        !!errors.inputDateCommande &&
                                                        errors.inputDateCommande.message
                                                    }
                                                    label="Date de commande"
                                                    fullWidth
                                                    disabled={!isForm}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            <Controller
                                key="inputEstPrepaye"
                                name="inputEstPrepaye"
                                control={control}
                                defaultValue={props.estPrepaye || null}
                                render={({ field: { value, ...field } }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                {...field}
                                                key="inputEstPrepaye"
                                                size="small"
                                                checked={!!value}
                                                disabled={!isForm}
                                            />
                                        }
                                        sx={{ mt: 2 }}
                                        label="Le projet est prépayé ?"
                                    />
                                )}
                            />
                            <Controller
                                key="inputCommentaire"
                                name="inputCommentaire"
                                control={control}
                                defaultValue={props.commentaire || null}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="inputCommentaire"
                                        label="Commentaire"
                                        placeholder="Insérer un commentaire"
                                        variant="outlined"
                                        sx={{ mt: 2 }}
                                        rows={4}
                                        multiline
                                        disabled={!isForm}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                key="inputStatus"
                                name="inputStatus"
                                control={control}
                                defaultValue={props.statuSelect || ''}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le Status du projet est requis',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        input="inputStatus"
                                        label="Status du projet"
                                        variant="outlined"
                                        sx={{ mt: 2 }}
                                        error={!!errors.inputStatus}
                                        helperText={
                                            !!errors.inputStatus && errors.inputStatus.message
                                        }
                                        select
                                        disabled={!isForm}
                                        fullWidth
                                    >
                                        {statusState.map((leStatus) => {
                                            return (
                                                <MenuItem
                                                    key={'status_' + leStatus.status_id}
                                                    value={leStatus.status_id}
                                                >
                                                    {leStatus.nom}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                )}
                            />
                            <Controller
                                key="inputReferent"
                                name="inputReferent"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le Référent/Chef du projet est requis',
                                    },
                                }}
                                defaultValue={props.referent || null}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        disablePortal
                                        fullWidth
                                        disabled={!isForm}
                                        options={usersState}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.value === value.value;
                                        }}
                                        getOptionLabel={(option) => option.label || ''}
                                        renderOption={(autoProps, option) => (
                                            <MenuItemUserComponent
                                                key={'menu_item_user_' + option.value}
                                                autoCompleteProps={autoProps}
                                                value={option.value}
                                                userObject={option.userObject}
                                            />
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Référent / Chef du projet"
                                                sx={{ mt: 2 }}
                                                error={!!errors.inputReferent}
                                                helperText={
                                                    !!errors.inputReferent
                                                        ? errors.inputReferent.message
                                                        : 'Le référent sera également chef du projet et verra ce projet apparaître dans son panel "manager - gérer mes projets"'
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Paper>
                        {isTicketState && (
                            <Zoom in={isTicketState}>
                                <Paper sx={{ p: 2, mt: 1 }}>
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        sx={{ textAlign: 'center' }}
                                    >
                                        Aspect Support
                                    </Typography>
                                    <FormHelperText>
                                        En ayant sélectionné le type d'activité "Ticket", vous
                                        pouvez ici paramétrer les détails inhérents à un projet type
                                        contrat support.
                                    </FormHelperText>
                                    <ContactModuleComponent
                                        handleCheckContact={handleCheckContact}
                                        handleRemoveContact={handleRemoveContact}
                                        client={clientState}
                                        contacts={contactsState}
                                        key={contactsState}
                                    />
                                    <CriticiteModuleComponent
                                        handleUpdateCriticiteNbTickets={
                                            handleUpdateCriticiteNbTickets
                                        }
                                        handleCheckCriticite={handleCheckCriticite}
                                        criticites={props.checkedCriticites || null}
                                    />
                                    <Controller
                                        key="inputGouvernance"
                                        name="inputGouvernance"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: isTicketState,
                                                message: 'La note sur la gouvernance est requise',
                                            },
                                        }}
                                        defaultValue={props.gouvernance || null}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Notes sur la gouvernance"
                                                sx={{ mt: 2 }}
                                                rows={4}
                                                error={!!errors.inputGouvernance}
                                                helperText={
                                                    !!errors.inputGouvernance
                                                        ? errors.inputGouvernance.message
                                                        : 'Cela peut être la fréquence des comités ou des réunions de suivi.'
                                                }
                                                multiline
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Paper>
                            </Zoom>
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                                Aspect client et région
                            </Typography>
                            <ClientModuleComponent handleFetchClients={handleFetchClients} />
                            <Controller
                                key="inputClient"
                                name="inputClient"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le client est requis',
                                    },
                                }}
                                defaultValue={props.client || null}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        disablePortal
                                        fullWidth
                                        disabled={!isForm}
                                        options={clientsState}
                                        onChange={(_, value) => {
                                            setClientState(value);
                                            field.onChange(value);
                                            if (value) {
                                                handleFetchContactsClientChange(value.value);
                                            } else {
                                                setContactsState([]);
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        getOptionLabel={(option) => option.label || ''}
                                        renderOption={(autoProps, option) => (
                                            <>
                                                {props.clientArchive && (
                                                    <MenuItem value={props.client} selected>
                                                        <Alert severity="warning">
                                                            Attention, le client{' "'}
                                                            {props.clientArchive + '"'} de ce projet
                                                            est archivé.
                                                        </Alert>
                                                    </MenuItem>
                                                )}
                                                <MenuItemClientComponent
                                                    key={'client_' + option.client_id}
                                                    autoCompleteProps={autoProps}
                                                    value={option.value}
                                                    nom={option.nom}
                                                    ville={option.ville}
                                                />
                                            </>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choisissez le client"
                                                sx={{ mt: 2 }}
                                                error={!!errors.inputClient}
                                                helperText={
                                                    !!errors.inputClient &&
                                                    errors.inputClient.message
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Controller
                                key="inputRegion"
                                name="inputRegion"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'La région est requise',
                                    },
                                }}
                                defaultValue={props.region || ''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Choisissez la région"
                                        select
                                        fullWidth
                                        disabled={!isForm}
                                        error={!!errors.inputRegion}
                                        helperText={
                                            !!errors.inputRegion && errors.inputRegion.message
                                        }
                                        sx={{ mt: 2 }}
                                    >
                                        {props.regionArchive && (
                                            <MenuItem value={props.region} selected>
                                                <Alert severity="warning">
                                                    Attention, la region{' "'}
                                                    {props.regionArchive + '"'} de ce projet est
                                                    archivée.
                                                </Alert>
                                            </MenuItem>
                                        )}
                                        {regionsState.map((region) => {
                                            return (
                                                <MenuItem
                                                    key={'region_' + region.region_id}
                                                    value={region.region_id}
                                                >
                                                    {region.nom.toUpperCase()}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                )}
                            />
                        </Paper>
                        <Paper sx={{ mt: 2, p: 2 }}>
                            <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                                Type d'activité
                            </Typography>
                            <Controller
                                key="inputFacturation"
                                name="inputFacturation"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le type de facturation est requis',
                                    },
                                }}
                                defaultValue={props.typeFacturation || ''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="inputFacturation"
                                        label="Choisir un type de facturation"
                                        select
                                        fullWidth
                                        disabled={!isForm}
                                        sx={{ mt: 2 }}
                                        error={!!errors.inputFacturation}
                                        helperText={
                                            !!errors.inputFacturation &&
                                            errors.inputFacturation.message
                                        }
                                    >
                                        {typeFacturationsState.map((type) => {
                                            return (
                                                <MenuItem
                                                    key={
                                                        'type_facturation_' +
                                                        type.type_facturation_id
                                                    }
                                                    value={type.type_facturation_id}
                                                >
                                                    {type.nom}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                )}
                            />
                            <FormHelperText>
                                Le type de facturation définira également la légende appliquée sur
                                les plannings d'équipe.
                            </FormHelperText>
                            <Controller
                                key="inputTypeActivity"
                                name="inputTypeActivity"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Le type d'activité est requis",
                                    },
                                }}
                                defaultValue={props.typeActivity || ''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="inputTypeActivity"
                                        label="Choisir un type d'activité"
                                        sx={{ mt: 2 }}
                                        error={!!errors.inputTypeActivity}
                                        helperText={
                                            !!errors.inputTypeActivity
                                                ? errors.inputTypeActivity.message
                                                : "Le type d'activité définira le type de temps pointé dans les planning."
                                        }
                                        select
                                        onChange={(e) => {
                                            setIsTicketState(e.target.value === 2);
                                            field.onChange(e);
                                        }}
                                        fullWidth
                                        disabled={!isForm}
                                    >
                                        {typeActivitysState.map((type) => {
                                            return (
                                                <MenuItem
                                                    key={'type_activity_' + type.activity_type_id}
                                                    value={type.activity_type_id}
                                                >
                                                    {type.nom}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                )}
                            />
                            <Controller
                                key="inputDevises"
                                name="inputDevises"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le nombre de devises est requis',
                                    },
                                    min: 0.5,
                                }}
                                defaultValue={props.devises || ''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Nombre de devises"
                                        step="0.5"
                                        min="0.5"
                                        type="number"
                                        sx={{ mt: 2 }}
                                        error={!!errors.inputDevises}
                                        helperText={
                                            !!errors.inputDevises && errors.inputDevises.message
                                        }
                                        fullWidth
                                        disabled={!isForm}
                                    />
                                )}
                            />
                        </Paper>
                        <Paper sx={{ mt: 2, p: 2 }}>
                            <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                                Visualisation Planning
                            </Typography>
                            <Controller
                                key="inputTrigramme"
                                name="inputTrigramme"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le trigramme est requis',
                                    },
                                    maxLength: {
                                        value: 5,
                                        message: 'La longueur max est de 5 caractères',
                                    },
                                }}
                                defaultValue={props.trigramme || ''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="inputTrigramme"
                                        label="Choisir un nom court"
                                        placeholder="NDT"
                                        sx={{ mt: 2 }}
                                        error={!!errors.inputTrigramme}
                                        helperText={
                                            !!errors.inputTrigramme
                                                ? errors.inputTrigramme.message
                                                : "Le nom court sera affiché dans les pins d'activité sur les planning."
                                        }
                                        fullWidth
                                        disabled={!isForm}
                                    />
                                )}
                            />
                            <Controller
                                id="inputColor"
                                name="inputColor"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le champ couleur est obligatoire',
                                    },
                                }}
                                defaultValue={randomColor}
                                render={({ field }) => (
                                    <InputColorComponent {...field} disabled={!isForm} />
                                )}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                {isForm && (
                    <Button variant="contained" type="submit" sx={{ mt: 1 }} size="large">
                        {isUpdate ? <EditIcon sx={{ mr: 1 }} /> : <AddIcon sx={{ mr: 1 }} />}
                        {isUpdate ? 'Modifier' : 'Valider'}
                    </Button>
                )}
            </form>
        </CenteredPaper>
    );
};

export default FormProjetComponent;
