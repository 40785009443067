import { Grid, MenuItem, Typography } from '@mui/material';

const MenuItemProjetComponent = ({ autoCompleteProps, value, nom, code_projet, client }) => {
    return (
        <MenuItem {...autoCompleteProps} sx={{ whiteSpace: 'normal' }} value={value} divider>
            <Grid container>
                <Grid item xs={12}>
                    <Typography>
                        <b>Nom : </b>
                        {nom}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        <b>Code projet : </b>
                        {code_projet}
                    </Typography>
                </Grid>
                {Boolean(client) && (
                    <Grid item xs={12}>
                        <Typography>
                            <b>Client : </b>
                            {client}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </MenuItem>
    );
};

export default MenuItemProjetComponent;
