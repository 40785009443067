import { Alert, AlertTitle, Button, Snackbar } from '@mui/material';

const UpdateWaiting = (props) => {
    if (!props.updateWaiting) {
        return props.children;
    } else {
        return (
            <Snackbar
                open={true}
                autoHideDuration={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                sx={{ width: '100%' }}
            >
                <Alert severity="warning" fullWidth>
                    <AlertTitle>Mise à jour</AlertTitle>
                    Une nouvelle mise à jour de neocra est disponible !
                    <Button onClick={props.handleUpdate}>Mettre à jour</Button>
                </Alert>
            </Snackbar>
        );
    }
};

export default UpdateWaiting;
