import { React } from 'react';

import NLogo from '../../images/logo.png';

import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        border: 'solid',
        borderWidth: '1px',
        marginTop: 15,
        padding: 5,
    },
    container_red: {
        border: 'solid',
        borderWidth: '1px',
        borderColor: '#cc0000',
        marginTop: 15,
    },
    flex_container: {
        flexDirection: 'row',
    },
    flex_container_final: {
        flexDirection: 'row',
        minHeight: 100,
        backgroundColor: 'rgb(240, 240, 240)',
    },
    flex_box: {
        flex: 1,
        borderBottom: '1px solid #c3c3c3',
    },
    flex_box_red: {
        flex: 1,
        backgroundColor: '#cc0000',
        color: 'white',
        textAlign: 'center',
    },
    pageFooter: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

const NewModelComponent = (props) => {
    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const { projetObject, activities, activityCount } = props.riDatas.res;
    const { dateDebut, dateFin } = props.riDatas;

    return (
        <Document>
            <Page size="A4" style={{ padding: 20, fontSize: 13, fontFamily: 'Helvetica' }} wrap>
                <View>
                    <Text style={{ fontSize: 20 }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>RI</Text>
                        <Text> - Rapport d'intervention</Text>
                    </Text>
                    <Text style={{ fontSize: 10, marginTop: 10 }}>
                        Période évaluée: {dateDebut} au {dateFin}
                    </Text>
                </View>
                <Image
                    src={NLogo}
                    alt=""
                    style={{
                        position: 'absolute',
                        top: 20,
                        right: 20,
                        width: 50,
                    }}
                />
                <View style={styles.container}>
                    <Text>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>Société: </Text>
                        <Text>{projetObject.client.nom}</Text>
                    </Text>
                    <Text style={{ position: 'absolute', right: 100, top: 5 }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>Code Projet: </Text>
                        <Text>{projetObject.code_projet}</Text>
                    </Text>
                </View>
                {projetObject.projet_contrat_supports &&
                    projetObject.projet_contrat_supports[0] &&
                    projetObject.projet_contrat_supports[0].contacts.length > 0 && (
                        <View>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                                Contact(s) client:{' '}
                            </Text>
                            {projetObject.projet_contrat_supports[0].contacts.map(
                                (contact, index) => {
                                    return (
                                        <Text key={'reporting_client' + index}>
                                            {contact.nom} {contact.prenom}
                                        </Text>
                                    );
                                },
                            )}
                        </View>
                    )}
                {activities.length > 0 &&
                activities.filter((activity) => activity.commentaire !== '').length > 0 ? (
                    <View style={styles.container} wrap>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                            Commentaire sur la mission :
                        </Text>

                        {activities.map((activity, index) => {
                            return activity.commentaire ? (
                                <Text key={'activity_text_pdf_' + index}>
                                    - {zeroPad(activity.jour.day_of_month, 2)}/
                                    {zeroPad(activity.jour.month, 2)}/{activity.jour.year} (
                                    {activity.valeur}) {'-> ' + activity.commentaire}
                                </Text>
                            ) : null;
                        })}
                    </View>
                ) : null}
                <View style={styles.container} wrap={false}>
                    <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                        Détail des dates exécutions:{' '}
                    </Text>
                    {activities.length > 0 ? (
                        <>
                            <View style={styles.flex_container}>
                                <View style={{ flex: 2 }}></View>
                                <View style={styles.flex_box}>
                                    <Text style={{ fontFamily: 'Helvetica-Bold' }}>Date</Text>
                                </View>
                                <View style={styles.flex_box}>
                                    <Text style={{ fontFamily: 'Helvetica-Bold' }}>Charge</Text>
                                </View>
                                <View style={styles.flex_box}>
                                    <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                                        Utilisateur
                                    </Text>
                                </View>
                            </View>
                            {activities.map((activity, index, array) => {
                                return array[index - 1] &&
                                    array[index - 1].fk_date === activity.fk_date ? (
                                    <View
                                        style={styles.flex_container}
                                        key={'activity_view_pdf_' + index}
                                    >
                                        <View style={{ flex: 2 }}></View>
                                        <View style={styles.flex_box}>
                                            <Text></Text>
                                        </View>
                                        <View style={styles.flex_box}>
                                            <Text>{activity.valeur}</Text>
                                        </View>
                                        <View style={styles.flex_box}>
                                            <Text>
                                                {activity.utilisateur.nom}{' '}
                                                {activity.utilisateur.prenom}
                                            </Text>
                                        </View>
                                    </View>
                                ) : (
                                    <View style={styles.flex_container}>
                                        <View style={{ flex: 2 }}></View>
                                        <View style={styles.flex_box}>
                                            <Text>
                                                {zeroPad(activity.jour.day_of_month, 2)}/
                                                {zeroPad(activity.jour.month, 2)}/
                                                {activity.jour.year}
                                            </Text>
                                        </View>
                                        <View style={styles.flex_box}>
                                            <Text>{activity.valeur}</Text>
                                        </View>
                                        <View style={styles.flex_box}>
                                            <Text>
                                                {activity.utilisateur.nom}{' '}
                                                {activity.utilisateur.prenom}
                                            </Text>
                                        </View>
                                    </View>
                                );
                            })}
                        </>
                    ) : (
                        <Text>Aucune activité à représenter</Text>
                    )}
                    <Text>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                            Charge totale ({projetObject.type_activite.nom}):{' '}
                        </Text>
                        <Text>{activityCount}</Text>
                    </Text>
                </View>
                <View style={styles.container_red} wrap={false}>
                    <View style={styles.flex_container}>
                        <View style={styles.flex_box_red}>
                            <Text>NEODT</Text>
                        </View>
                        <View style={styles.flex_box_red}>
                            <Text>Le Client</Text>
                        </View>
                    </View>
                    <View style={styles.flex_container_final}>
                        <View style={styles.flex_box}>
                            <Text>Nom du signataire (majuscule):</Text>
                            <Text style={{ marginTop: 10 }}>Date et visa</Text>
                        </View>
                        <View style={styles.flex_box}>
                            <Text>Nom du signataire (majuscule):</Text>
                            <Text style={{ marginTop: 10 }}>Date et visa</Text>
                        </View>
                    </View>
                </View>
                <Text style={styles.pageFooter} fixed>
                    {projetObject.neo_entity.nom} - Reproduction interdite
                </Text>
            </Page>
        </Document>
    );
};

export default NewModelComponent;
