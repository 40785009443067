import axiosConfig from './axiosConfig';

const API_URL = '/authorization/fichiers/';

class FichiersService {
    getAll() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    uploadFile(formData) {
        return axiosConfig
            .post('/authorization/uploadFile', formData, {
                headers: {
                    // On défini le header en undefined pour que le navigateur configure automatiquement les headers
                    'Content-Type': undefined,
                },
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    insertFichier(objectFichier) {
        return axiosConfig
            .post(API_URL, objectFichier)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    updateFichiers(objectFichier) {
        return axiosConfig
            .put(API_URL, objectFichier)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    getFichier(fichierId) {
        return axiosConfig
            .get(API_URL + fichierId)
            .then((res) => res.data)
            .catch((err) => ({
                error: err.message,
            }));
    }
    getFichierByUUID(uuid) {
        return axiosConfig
            .get(API_URL + 'UUID/' + uuid)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    getFichierFile(fichierFile) {
        return axiosConfig
            .get('/authorization/getFile/' + fichierFile)
            .then((res) => {
                return URL.createObjectURL(res.data);
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    deleteFichier(fichierId) {
        return axiosConfig
            .delete(API_URL + fichierId)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    getFichiersFromPage(infoPage) {
        return axiosConfig
            .get(API_URL + 'allByInfoPage', { params: infoPage })
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    getMaxPageFichiers(infoPage) {
        return axiosConfig
            .get(API_URL + 'maxPageFichiers', { params: infoPage })
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
}

export default new FichiersService();
