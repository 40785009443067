import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import clientService from '../../../services/client.service';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// redux
import { connect } from 'react-redux';

import CrudPageComponent from '../../assets/page/crud-page.component';

const ClientsComponent = (props) => {
    const [contentState, setContentState] = useState('');
    const [errorState, setErrorState] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [isOrderedState, setIsOrderedState] = useState(false);
    const [displayTypeState, setDisplayTypeState] = useState(true);

    const reOrderNom = () => {
        if (contentState && contentState.length > 1) {
            let ordered = contentState;
            if (isOrderedState) {
                ordered = contentState.sort((a, b) => a['nom'].localeCompare(b['nom']));
            } else {
                ordered = contentState.sort((a, b) => a['nom'].localeCompare(b['nom']) * -1);
            }
            setContentState(ordered);
            setIsOrderedState(!isOrderedState);
        }
    };

    const handleRestoreClient = (client_id) => {
        clientService
            .unarchiveClientById(client_id)
            .then(() => {
                handleFetchClients();
            })
            .catch((err) => {
                toast.error(err.response.message);
            });
    };

    const onChangeDisplayType = () => {
        setDisplayTypeState(!displayTypeState);
    };

    const handleFetchClients = async () => {
        setLoadingState(true);

        (displayTypeState
            ? clientService.getAllClients()
            : clientService.getAllArchivedClients()
        ).then((response) => {
            setLoadingState(false);

            if (!response.error) {
                setContentState(
                    response || (
                        <tr>
                            <td colSpan={5} style={{ width: 100 + '%' }}>
                                Aucunes données à afficher.
                            </td>
                        </tr>
                    ),
                );
            } else {
                setErrorState(response.error);
            }
        });
    };

    useEffect(() => {
        props.onLoad('Administrateur - Tous les clients');
        handleFetchClients();
    }, []);

    useEffect(() => {
        handleFetchClients();
    }, [displayTypeState]);

    return (
        <CrudPageComponent
            title="Gestion des clients"
            objectName="clients"
            titleAdd="Créer un client"
            linkAdd="/admin/create-client"
            onChangeDisplayType={onChangeDisplayType}
            displayType={displayTypeState}
            labelSwitchArchived="Voir les clients archivés"
            error={errorState}
            loading={loadingState}
            headers={[
                {
                    title: 'Nom',
                    filter: { filterState: isOrderedState, filterFunction: reOrderNom },
                },
                { title: 'Ville' },
                { title: 'Région' },
                { title: 'Commentaire', size: '30%' },
            ]}
            dataContent={
                !!contentState
                    ? contentState.map((client) => ({
                          clients_id: client.client_id,
                          nom: client.nom,
                          ville: client.ville,
                          region: client.region.nom.toUpperCase(),
                          commentaire: client.commentaire,
                      }))
                    : [{ id: '', nom: '', ville: '', region: '', commentaire: '' }]
            }
            linkEdit="/admin/update-client"
            handleRestore={handleRestoreClient}
        />
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedClientsComponent = connect(null, mapDispatchToProps)(ClientsComponent);
