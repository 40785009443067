import { Grid, Typography } from '@mui/material';

/**
 * Affiche le thead pour planning manager
 * Chaque TH contient le numéro de la semaine et les jours de la semaine liés
 */
const TitleRowHorizontalWeekComponent = (props) => {
    const month = props.month.filter((week) => week.jours.length > 0);
    const rowsDays = month.reduce((acc, curr) => acc + curr.jours.length, 0) + 2;

    return (
        <Grid container direction="column">
            <Grid item>
                <Grid container columns={rowsDays}>
                    <Grid item xs={2}></Grid>
                    {month.map((leWeek) => (
                        <Grid
                            item
                            key={'number_week_item_' + leWeek.numero}
                            xs={leWeek.jours.length}
                            sx={{
                                backgroundColor: 'primary.main',
                                borderColor: 'light.grey',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                color: 'light.main',
                            }}
                        >
                            <Typography sx={{ textAlign: 'center' }}>{leWeek.numero}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container columns={rowsDays}>
                    <Grid item xs={2}></Grid>
                    {month.map((leWeek) =>
                        leWeek.jours.map((leJour, index) => (
                            <Grid
                                item
                                key={'jour_item_planning_' + index}
                                xs={1}
                                sx={{
                                    textAlign: 'center',
                                    borderLeftColor: 'secondary.light',
                                    borderLeftStyle: 'solid',
                                    borderWidth: '0.5px',
                                }}
                            >
                                <Typography sx={{ fontSize: '0.5vw' }}>{leJour.nom}</Typography>
                                <Typography sx={{ fontSize: '0.5vw' }}>
                                    {leJour.dayNumber}
                                </Typography>
                            </Grid>
                        )),
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TitleRowHorizontalWeekComponent;
