import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import { Controller, useForm } from 'react-hook-form';
import { TextField, Typography, Button, Paper, Grid } from '@mui/material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import activityService from '../../../services/activity.service';

import { Link } from 'react-router-dom';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import { H2Lighter } from '../../../theming/customTheme';
import { InputColorComponent, getRandomHexaColor } from '../../assets/inputs/inputColor.component';
import ResultFormComponent from '../../assets/container/resultForm.component';

const LegendPlanningManagerComponent = ({ onLoad }) => {
    const { handleSubmit, control } = useForm();
    const [loadingState, setLoadingState] = useState(true);
    const [submittedState, setSubmittedState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [typesFacturationsState, setTypesFacturationsState] = useState([]);
    const [messageState, setMessageState] = useState('');
    const [typeFacturationFormState, setTypeFacturationFormState] = useState([]);

    const handleRefreshDatas = () => {
        activityService.getAllTypeFacturation().then((res) => {
            console.log(res);
            if (!res.error) {
                setLoadingState(false);
                setSuccessState(true);
                setTypesFacturationsState(res);
            } else {
                setLoadingState(false);
                setSubmittedState(true);
                setSuccessState(false);
                setMessageState(res.error);
            }
        });
    };

    const handleOnChangeComplete = (color, index) => {
        const newState = [...typesFacturationsState];
        newState[index].code_hexa = color.hex;
        setTypesFacturationsState(newState);
    };

    const handeUpdateLegend = () => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        activityService
            .updateFacturationTypes(typeFacturationFormState)
            .then((response) => {
                if (response.error) {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(response.error);
                } else {
                    toast.success('Légende modifiée');
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                    handleRefreshDatas();
                }
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    useEffect(() => {
        onLoad('Administrateur - Légende du planning manager');
        handleRefreshDatas();
    }, []);

    useEffect(() => {
        console.log(typeFacturationFormState);
    }, [typeFacturationFormState]);

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
        >
            <form onSubmit={handleSubmit(handeUpdateLegend)}>
                <CenteredPaper withAlign={false}>
                    <H2Lighter>Légende Planning Manager</H2Lighter>
                    <Typography>
                        Cette légende est appliquée à l'écran{' '}
                        <Link to={'/planning-manager'}>Planning manager</Link>.{' '}
                    </Typography>
                    {typesFacturationsState &&
                        typesFacturationsState.map((typeFacturation) => (
                            <Paper
                                key={'paper_legend_' + typeFacturation.type_facturation_id}
                                sx={{
                                    borderLeftColor: typeFacturation.code_hexa,
                                    borderLeftStyle: 'solid',
                                    borderLeftWidth: '2px',
                                    bgcolor: typeFacturation.code_hexa + '40',
                                    mt: 2,
                                    p: 1,
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            defaultValue={typeFacturation.nom}
                                            variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name={`inputColor_${typeFacturation.type_facturation_id}`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Le champs couleur est obligatoire',
                                                },
                                            }}
                                            defaultValue={
                                                typeFacturation.code_hexa || getRandomHexaColor()
                                            }
                                            render={({ field }) => (
                                                <InputColorComponent
                                                    {...field}
                                                    sx={{ mt: 2, ml: 9 }}
                                                    ref={null}
                                                    onChange={(value) => {
                                                        const id =
                                                            typeFacturation.type_facturation_id;
                                                        setTypeFacturationFormState({
                                                            ...typeFacturationFormState,
                                                            [id]: value,
                                                        });
                                                        handleOnChangeComplete(
                                                            { hex: value },
                                                            typesFacturationsState.findIndex(
                                                                (e) => e.type_facturation_id === id,
                                                            ),
                                                        );
                                                        field.onChange(value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}

                    <Button
                        sx={{ mt: 2 }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!Object.keys(typeFacturationFormState).length > 0}
                    >
                        Modifier la légende
                    </Button>
                </CenteredPaper>
            </form>
        </ResultFormComponent>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedLegendPlanningManagerComponent = connect(
    null,
    mapDispatchToProps,
)(LegendPlanningManagerComponent);
