import axiosConfig from './axiosConfig';

const API_URL = '/authorization/category_fichiers/';

class CategoryFichiersService {
    getAll() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    getAllArchived() {
        return axiosConfig
            .get(API_URL + 'allArchived')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    insert(objectCategory) {
        return axiosConfig
            .post(API_URL, objectCategory)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    get(categoryId) {
        return axiosConfig
            .get(API_URL + categoryId)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    update(objectCategory) {
        return axiosConfig
            .put(API_URL, objectCategory)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    delete(categoryId) {
        return axiosConfig
            .delete(API_URL + categoryId)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    restore(categoryId) {
        return axiosConfig
            .put(API_URL + 'restore/' + categoryId)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
}

export default new CategoryFichiersService();
