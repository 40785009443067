import axiosConfig from './axiosConfig';

const API_URL = '/authorization/news/';

class NewsService {
    getNewsFromPage(infoPage) {
        return axiosConfig
            .get(API_URL + 'allByInfoPage', { params: infoPage })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    /**
     * @param {{nbrElement: integer, category: integer}} infoPage
     */
    getMaxPageNews(infoPage) {
        return axiosConfig
            .get(API_URL + 'maxPageNews', { params: infoPage })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    getAllNews() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    getAllArchived() {
        return axiosConfig
            .get(API_URL + 'allArchived')
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    restore(newsId) {
        return axiosConfig
            .put(API_URL + 'restore/' + newsId)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    getNews(newsId) {
        return axiosConfig
            .get(API_URL + newsId)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    getNewsFromUUID(uuid) {
        return axiosConfig
            .get(API_URL + 'UUID/' + uuid)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }

    getImage(file) {
        return axiosConfig
            .get('/authorization/getImage/' + file)
            .then((res) => {
                return URL.createObjectURL(res.data);
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    uploadFile(formData) {
        return axiosConfig
            .post('/authorization/upload', formData, {
                headers: {
                    // On défini le header en undefined pour que le navigateur configure automatiquement les headers
                    'Content-Type': undefined,
                },
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    insertNews(objectNews) {
        return axiosConfig
            .post(API_URL, objectNews)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    updateNews(objectNews) {
        return axiosConfig
            .put(API_URL, objectNews)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    deleteNews(idNews) {
        return axiosConfig
            .delete(API_URL + idNews)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
}

export default new NewsService();
