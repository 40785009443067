import { Tab, Tabs, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import newsService from '../../../services/news.service';
import TabPanel from '../../assets/container/tabPanel.component';
import CrudPageComponent from '../../assets/page/crud-page.component';
import ListCategoryNewsComponent from './category_news/list-category-news.component';

const ListNewsComponent = (props) => {
    const [contentState, setContentState] = useState('');
    const [errorState, setErrorState] = useState(null);
    const [valueTabState, setValueTabState] = useState(0);
    const [displayTypeState, setDisplayTypeState] = useState(true);

    const handleFetchNews = () => {
        (displayTypeState ? newsService.getAllNews() : newsService.getAllArchived()).then(
            (news) => {
                if (news.error) {
                    setErrorState(news.error);
                } else {
                    setContentState(news);
                }
            },
        );
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChangeTab = (event, newValue) => {
        setValueTabState(newValue);
    };

    const handleRestoreNews = (newsId) => {
        newsService.restore(newsId).then((res) => {
            if (res.error) {
                toast.error(res.error);
            } else {
                toast.success(res.message);
                handleFetchNews();
            }
        });
    };

    useEffect(() => {
        props.onLoad('Administrateur - Toutes les news');
        handleFetchNews();
    }, [displayTypeState]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={valueTabState}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                >
                    <Tab label="News" {...a11yProps(0)} />
                    <Tab label="Catégorie de news" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={valueTabState} index={0}>
                <CrudPageComponent
                    objectName="news"
                    title="Gestion des News"
                    titleAdd="Créer une news"
                    labelSwitchArchived="Voir les news archivées"
                    onChangeDisplayType={() => setDisplayTypeState(!displayTypeState)}
                    displayType={displayTypeState}
                    linkAdd="/admin/create-news"
                    headers={['Titre', 'Catégorie', 'Créé par', 'Créé le']}
                    error={errorState}
                    dataContent={
                        !!contentState
                            ? contentState.map((contentNews) => ({
                                  id: contentNews.news_id,
                                  title: contentNews.title,
                                  categorie: contentNews.fk_category.name,
                                  owner:
                                      contentNews.fk_owner.nom + ' ' + contentNews.fk_owner.prenom,
                                  createdAt: new Date(contentNews.createdAt).toLocaleDateString(
                                      'fr-FR',
                                  ),
                              }))
                            : [{ id: '', title: '', categorie: '', createdAt: '' }]
                    }
                    linkEdit="/admin/update-news"
                    handleRestore={handleRestoreNews}
                />
            </TabPanel>
            <TabPanel value={valueTabState} index={1}>
                <ListCategoryNewsComponent />
            </TabPanel>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedListNewsComponent = connect(null, mapDispatchToProps)(ListNewsComponent);
