import { Avatar, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import fichiersService from '../../../services/fichiers.service';
import WaitingScreenComponent from '../waiting-screen.component';

/**
 * Composant affichant l'avatar d'un utilisateur ou ses initiales s'il n'en possède pas.
 *
 * @param {object} user Objet utilisateur comportant au moins le nom, le prénom, la photo et la fav_color.
 * @param {string} pref [Paramètre optionnel] Préférences de l'utilisateur pouvant contenir une photo.
 * @param {string} size "small", "medium" ou "large" (respectivement, 75px, 100px, 125px, par défaut : 50px)
 * @param {boolean} overlay [Paramètre optionnel] si true, affiche un tooltip avec le nom + prénom au hover.
 * @param {object} props Autres propriétés passées au composant.
 */
const DisplayAvatarComponent = ({ user, pref, size, overlay, ...props }) => {
    const [photoProfileState, setPhotoProfileState] = useState(null);
    const withOverlay = overlay || false;

    // Définir la taille de l'avatar
    let heightWidth = '50px';
    if (!!size) {
        switch (size) {
            case 'small':
                heightWidth = '75px';
                break;
            case 'medium':
                heightWidth = '100px';
                break;
            case 'large':
                heightWidth = '125px';
                break;
            default:
                heightWidth = size; // Utiliser la taille personnalisée fournie
        }
    }

    useEffect(() => {
        // Drapeau pour vérifier si le composant est monté
        let isMounted = true;

        // Déterminer le nom du fichier photo à télécharger
        const filenamePhoto = !!pref ? pref.photo : user.photo;

        // Vérifier si un nom de fichier est fourni
        if (!!filenamePhoto && filenamePhoto !== -1) {
            fichiersService.getFichierFile(filenamePhoto).then((photo) => {
                // Vérifier si le composant est toujours monté avant de mettre à jour l'état
                if (isMounted) {
                    if (!photo.error) {
                        setPhotoProfileState(photo); // Mettre à jour l'état avec l'image
                    } else {
                        setPhotoProfileState(-1); // Erreur de téléchargement de l'image
                    }
                }
            });
        } else {
            setPhotoProfileState(-1); // Pas de photo fournie
        }

        // Fonction de nettoyage pour éviter les mises à jour sur un composant démonté
        return () => {
            isMounted = false; // Le composant a été démonté
        };
    }, [pref, user]);

    // Afficher l'avatar ou un indicateur de chargement
    return photoProfileState !== null ? (
        <Tooltip
            title={
                withOverlay && user.prenom && user.nom
                    ? user.nom.toUpperCase() + ' ' + user.prenom
                    : ''
            }
        >
            {photoProfileState === -1 ? (
                <Avatar
                    sx={{
                        width: heightWidth,
                        height: heightWidth,
                        mt: 1,
                        backgroundColor: user.fav_color || 'primary.main',
                    }}
                    {...props}
                >
                    {user.nom.charAt(0).toUpperCase()}
                    {user.prenom.charAt(0).toUpperCase()}
                </Avatar>
            ) : (
                <Avatar
                    src={photoProfileState}
                    sx={{ width: heightWidth, height: heightWidth, mt: 1 }}
                    {...props}
                />
            )}
        </Tooltip>
    ) : (
        <WaitingScreenComponent /> // Afficher un écran d'attente pendant le chargement
    );
};

export default DisplayAvatarComponent;
