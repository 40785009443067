/** REDUX functions */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import EquipeManagerComponent from '../../assets/equipe/equipe-manager.component';

const ManagerEquipeComponent = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        props.onLoad('Manager votre équipe');
    }, []);

    return <EquipeManagerComponent managerEmail={user.email} />;
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedManagerEquipeComponent = connect(
    null,
    mapDispatchToProps,
)(ManagerEquipeComponent);
