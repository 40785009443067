import { useEffect, useState } from 'react';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import fonctionService from '../../../services/fonction.service';
import ResultFormComponent from '../../assets/container/resultForm.component';
import FormFonctionComponent from './form-fonction.component';

const CreateFonctionComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Créer une fonction');
    }, []);

    const handleCreateFonction = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        fonctionService
            .create(dataForm.inputName)
            .then((response) => {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    return (
        <ResultFormComponent
            loading={loadingState}
            success={successState}
            submitted={submittedState}
            message={messageState}
            link={'/admin/fonctions'}
        >
            <FormFonctionComponent handleForm={handleCreateFonction} />
        </ResultFormComponent>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateFonctionComponent = connect(
    null,
    mapDispatchToProps,
)(CreateFonctionComponent);
