import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import userService from '../../../services/user.service';
import DisplayAvatarComponent from '../infos/display-avatar.component';

const UserTooltipComponent = (props) => {
    const [userState, setUserState] = useState(null);

    useEffect(() => {
        if (props.userEmail) {
            userService.getMinimumUserInfos(props.userEmail).then((user) => {
                setUserState(user);
            });
        }
    }, []);

    return userState !== null ? (
        <Tooltip
            placement="top"
            followCursor
            arrow
            title={
                <Grid
                    container
                    spacing={1}
                    sx={{ minWidth: '320px', minHeight: '90px' }}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Grid item xs={4}>
                        <DisplayAvatarComponent user={userState} />
                    </Grid>
                    <Grid item xs={8}>
                        <Box>
                            <Typography
                                variant="span"
                                color="primary.dark"
                                sx={{
                                    fontWeight: 'bold',
                                    mr: 1,
                                }}
                            >
                                Nom:
                            </Typography>
                            {userState.nom.toUpperCase()}
                        </Box>
                        <Box>
                            <Typography
                                variant="span"
                                color="primary.dark"
                                sx={{
                                    fontWeight: 'bold',
                                    mr: 1,
                                }}
                            >
                                Prénom:
                            </Typography>
                            {userState.prenom.charAt(0).toUpperCase() + userState.prenom.slice(1)}
                        </Box>
                        <Box>
                            <Typography
                                variant="span"
                                color="primary.dark"
                                sx={{
                                    fontWeight: 'bold',
                                    mr: 1,
                                }}
                            >
                                Email:
                            </Typography>
                            {userState.email}
                        </Box>
                    </Grid>
                </Grid>
            }
        >
            {props.children}
        </Tooltip>
    ) : (
        props.children
    );
};

export default UserTooltipComponent;
