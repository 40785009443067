import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import DayComponent from './day.component';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';

const WeekComponent = (props) => {
    const theme = useTheme();
    const week = props.weekItem;
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container columns={{ xs: 21, md: 22 }}>
            {isUpMd && (
                <Grid item xs={1}>
                    <Grid
                        container
                        justifyContent={'center'}
                        item
                        sx={{ backgroundColor: 'secondary.main', color: '#fff' }}
                    >
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: 11,
                                    md: 'inherit',
                                },
                            }}
                        >
                            {'S' + week.numero}
                        </Typography>
                    </Grid>
                    <Grid container item justifyContent={'center'}>
                        <Typography sx={{ fontSize: '0.8rem' }}>Valider la semaine</Typography>
                        <LockOpenTwoToneIcon sx={{ mt: 1 }} color="success" />
                    </Grid>
                </Grid>
            )}
            {week.jours.map((jour, index) => {
                const status = jour.status === false || !jour.status ? false : true;
                return (
                    <Grid
                        item
                        xs={3}
                        key={'day_item_' + index}
                        sx={{
                            height: 'auto',
                            aspectRatio: {
                                xs: '0.5',
                                md: '1.1',
                                lg: '1.6',
                                xl: '1.8',
                                xxl: '2',
                            },
                        }}
                    >
                        <DayComponent
                            dayItem={jour}
                            status={status}
                            closeRightPanel={props.closeRightPanel}
                            rerenderParentCallback={props.rerenderParentCallback}
                            openRightPanel={props.openRightPanel}
                            showMultiple={props.showMultiple}
                            daysSelected={props.daysSelected}
                            activities={props.activities}
                            monthCalendar={props.monthCalendar}
                            allActivityTypes={props.allActivityTypes}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default WeekComponent;
