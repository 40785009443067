export const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];
export const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

/**
 * Retourne le mois sous forme littérale (ex Janvier)
 * @param {Date} date - Date dont on veut retourner le mois
 * @returns {string} Retourne le nom du mois
 */
export const getMonthName = (date) => {
    const formatter = new Intl.DateTimeFormat('fr', { month: 'long' });
    return formatter.format(date)[0].toUpperCase() + formatter.format(date).slice(1);
    // return formatter.format(date);
};

/**
 * Retourne le jour sous forme littérale (ex Lundi)
 * @param {Date} date - Date dont on veut retourner le jour
 * @returns {string} Retourne le nom du jour
 */
export const getDayName = (date) => {
    return dayNames[date.getDay()];
};

/**
 * Retourne l'objet Date du dernier jour du mois
 * @param {Date} date - Fulldate JS dont on veut la fin
 * @returns {Date} Date du dernier jour du mois
 */
export const getMonthEndDate = (date) => {
    let y = date.getFullYear(),
        m = date.getMonth();
    return new Date(y, m + 1, 0);
};

/**
 * Retourne l'objet Date du premier jour du mois
 * @param {Date} date - Fulldate JS dont on veut le début
 * @returns {Date} Date du premier jour du mois
 */
export const getMonthStartDate = (date) => {
    let y = date.getFullYear(),
        m = date.getMonth();
    return new Date(y, m, 1);
};

/**
 * Retourne 1 si la date à évaluer est comprise entre les 2 bornes en paramètres
 * @param {Date} date - date à évaluer
 * @param {Date} startDate - date au plus tôt
 * @param {Date} endDate - date au plus tard
 * @returns {Boolean}
 */
export const isInInterval = (date, startDate, endDate) => {
    if (date <= endDate) {
        if (date >= startDate) return 1;
    }
    return 0;
};

/**
 * Retourne le numéro de semaine
 * @param {Date} date - date dont on veut le num de semaine
 * @returns {Number}
 */
export const getWeekNumber = (dt) => {
    var tdt = new Date(dt.valueOf());
    var dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

/**
 * Retourne une date avec un nombre de jour supplémentaire
 * @param {Date} date - date de départ
 * @param {Number} days - nombre de jour à ajouter
 */
export const addDays = (date, days) => {
    var ldate = new Date(date.valueOf());
    ldate.setDate(ldate.getDate() + days);
    return ldate;
};

/**
 * Retourne un Array contenant les 7 jours de la semaine
 * @param {Date} date - date actuelle ou dans une semaine voulue
 */
export const constructorWeek = (date, monthBeginning, monthEnding, activities, tabFeries) => {
    let loopWeek = [];
    let startDate = getMonday(date);

    for (let i = 0; i <= 6; i++) {
        let nextDay = addDays(startDate, i);
        let leJourFerie = null;
        let tempDay = new Date(JSON.parse(JSON.stringify(nextDay)));
        tempDay.setHours(tempDay.getHours() + 2);

        if (tabFeries !== null) {
            if (tabFeries[tempDay.toISOString().split('T')[0]] !== undefined) {
                leJourFerie = tabFeries[tempDay.toISOString().split('T')[0]];
            }
        }

        let _status = getStatus(nextDay, monthBeginning, monthEnding);
        loopWeek = [
            ...loopWeek,
            {
                nom: getDayName(nextDay),
                date: nextDay,
                dayNumber: nextDay.getDate(),
                status: Boolean(_status),
                activities: activities
                    .map((activity) => {
                        if (activity.jour) {
                            // On test si l'activité est une activité ou un congés (on n'utilise pas la table jour pour les congés)
                            return (
                                activity.jour.day_of_month === nextDay.getDate() &&
                                activity.jour.month === nextDay.getMonth() + 1 && {
                                    activity,
                                }
                            );
                        } else {
                            // Si congés
                            return (
                                new Date(activity.date).getDate() === nextDay.getDate() &&
                                new Date(activity.date).getMonth() === nextDay.getMonth() && {
                                    activity,
                                }
                            );
                        }
                    })
                    .filter((activity) => activity !== false),
                ferie: leJourFerie,
            },
        ];
    }
    return loopWeek;
};

/**
 * Retourne un Array contenant les jours de la semaine compris dans le mois
 * @param {Date} date - date actuelle ou dans une semaine voulue
 */
export const clearConstructorWeek = (date, monthBeginning, monthEnding, activities, tabFeries) => {
    let loopWeek = [];
    let startDate = getMonday(date);
    for (let i = 0; i <= 6; i++) {
        let nextDay = addDays(startDate, i);
        let leJourFerie = null;
        let tempDay = new Date(JSON.parse(JSON.stringify(nextDay)));
        tempDay.setHours(tempDay.getHours() + 2);

        if (tabFeries !== null) {
            if (tabFeries[tempDay.toISOString().split('T')[0]] !== undefined) {
                leJourFerie = tabFeries[tempDay.toISOString().split('T')[0]];
            }
        }

        let _status = getStatus(nextDay, monthBeginning, monthEnding);
        if (isInInterval(nextDay, monthBeginning, monthEnding)) {
            loopWeek = [
                ...loopWeek,
                {
                    nom: getDayName(nextDay),
                    date: nextDay,
                    dayNumber: nextDay.getDate(),
                    status: Boolean(_status),
                    activities: activities
                        .map((activity) => {
                            if (activity.jour) {
                                // On test si l'activité est une activité ou un congés (on n'utilise pas la table jour pour les congés)
                                return (
                                    activity.jour.day_of_month === nextDay.getDate() &&
                                    activity.jour.month === nextDay.getMonth() + 1 && {
                                        activity,
                                    }
                                );
                            } else {
                                // Si congés
                                return (
                                    new Date(activity.date).getDate() === nextDay.getDate() &&
                                    new Date(activity.date).getMonth() === nextDay.getMonth() && {
                                        activity,
                                    }
                                );
                            }
                        })
                        .filter((activity) => activity !== false),
                    ferie: leJourFerie,
                },
            ];
        }
    }
    return loopWeek;
};

export const constructorMonth = (date, activities, tabFeries = null) => {
    let monthBeginning = getMonthStartDate(date);
    let monthEnding = getMonthEndDate(date);
    let breakPointDate = monthBeginning;
    let loopMonth = [];
    for (let i = 0; i <= 5; i++) {
        loopMonth = [
            ...loopMonth,
            {
                year: date.getFullYear(),
                monthName: getMonthName(date),
                numero: getWeekNumber(breakPointDate),
                jours: constructorWeek(
                    breakPointDate,
                    monthBeginning,
                    monthEnding,
                    activities,
                    tabFeries,
                ),
            },
        ];
        breakPointDate = addDays(breakPointDate, 7);
    }
    return loopMonth;
};

/**
 * Retourne un mois sans les neighbours
 * @param {Date} date - date du mois à récupérer
 * @param {Array} activities - liste d'activités à insérer
 */
export const clearMonth = (date, activities, tabFeries = null) => {
    let monthBeginning = getMonthStartDate(date);
    let monthEnding = getMonthEndDate(date);
    let breakPointDate = monthBeginning;
    let loopMonth = [];
    for (let i = 0; i <= 5; i++) {
        loopMonth = [
            ...loopMonth,
            {
                numero: getWeekNumber(breakPointDate),
                jours: clearConstructorWeek(
                    breakPointDate,
                    monthBeginning,
                    monthEnding,
                    activities,
                    tabFeries,
                ),
            },
        ];
        breakPointDate = addDays(breakPointDate, 7);
    }
    return loopMonth;
};

/**
 * Permet de retourner la date du lundi de cette semaine
 * @param {Date} date - date dont on veut récupérer le lundi
 */
export const getMonday = (date) => {
    date = new Date(date);
    var day = date.getDay(),
        diff = date.getDate() - day + (day === 0 ? -6 : 1); // ajuste quand c'est le dimanche
    return new Date(date.setDate(diff));
};

/**
 * Retourne vrai si la date est Samedi ou Dimanche
 * @param {Date} date - date à évaluer
 */
export const isWeekend = (date) => {
    let day = date.getDay();
    let _status = false;
    if (day === 0 || day === 6) _status = true;
    return _status;
};

/**
 * Retourne 0 si la date est à afficher en clair
 * @param {Date} date - date à évaluer
 * @param {Date} monthBeginning - date début interval
 * @param {Date} monthEnding - date fin interval
 */
export const getStatus = (date, monthBeginning, monthEnding) => {
    // si date = weekend retourne 1, on prend l'inverse = 0
    let _status = !isWeekend(date);
    // si pas weekend, on évalue
    if (_status) {
        // si dans interval retourne 1
        _status = isInInterval(date, monthBeginning, monthEnding);
    }
    return _status === true || _status === 1 ? 1 : 0;
};
