import { Autocomplete, Button, TextField, FormControlLabel, Switch, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { H2Lighter } from '../../../theming/customTheme';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InputFichierComponent from '../../assets/inputs/inputFichier.component';
import { useEffect, useState } from 'react';
import category_fichiersService from '../../../services/category_fichiers.service';
import { toast } from 'react-toastify';
import fichiersService from '../../../services/fichiers.service';
import SimpleModalComponent from '../../assets/modal/simple-modal.component';

const FormFichiersComponent = (props) => {
    const isUpdate = props.isUpdate || false;
    const isForm = !!props.handleForm;
    const [categoriesState, setCategoriesState] = useState([]);
    const [openModalState, setOpenModalState] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handleFetchCategories = () => {
        category_fichiersService.getAll().then((res) => {
            if (res.error) {
                toast.error('Impossible de récupérer les catégories de fichiers');
            } else {
                setCategoriesState(
                    res.map((category) => ({
                        label: category.name,
                        value: category.category_fichiers_id,
                    })),
                );
            }
        });
    };
    const submitDeleteFichier = () => {
        if (props.uuid !== null) {
            fichiersService.getFichierByUUID(props.uuid).then((news) => {
                if (news.length > 1) {
                    setOpenModalState(true);
                } else {
                    props.handleDelete(props.id);
                }
            });
        } else {
            props.handleDelete(props.id);
        }
    };

    useEffect(() => {
        handleFetchCategories();
    }, []);

    return (
        <>
            <SimpleModalComponent
                isOpen={openModalState}
                handleCloseModal={() => {
                    setOpenModalState(false);
                }}
            >
                <CenteredPaper withMargin={false} elevation={0}>
                    <H2Lighter>
                        Cet fichier est multi-entité, voulez-vous le supprimer pour toutes les
                        entités ?
                    </H2Lighter>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => props.handleDelete(props.uuid)}
                            >
                                Supprimer pour toutes les entités
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                onClick={() => props.handleDelete(props.id)}
                            >
                                Supprimer pour l'entité courante
                            </Button>
                        </Grid>
                    </Grid>
                </CenteredPaper>
            </SimpleModalComponent>

            <CenteredPaper withMargin={true} withAlign={false} size="extra-large" elevation={1}>
                <H2Lighter>{isUpdate ? 'Modifier le fichier' : 'Créer un fichier'}</H2Lighter>
                <form onSubmit={handleSubmit(props.handleForm)}>
                    <Controller
                        id="inputName"
                        name="inputName"
                        control={control}
                        rules={{
                            required: { value: true, message: 'Le champ Nom est obligatoire.' },
                        }}
                        defaultValue={props.name || null}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Nom du fichier"
                                placeholder="Nouvelle Charte Informatique"
                                sx={{ mt: 2 }}
                                error={!!errors.inputName}
                                helperText={!!errors.inputName && errors.inputName.message}
                                fullWidth
                            />
                        )}
                    />
                    <Controller
                        id="inputDescription"
                        name="inputDescription"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Le champ Description est obligatoire.',
                            },
                        }}
                        defaultValue={props.description || null}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Description du fichier"
                                placeholder="Nouvelle Charte Informatique"
                                sx={{ mt: 2 }}
                                error={!!errors.inputDescription}
                                helperText={
                                    !!errors.inputDescription && errors.inputDescription.message
                                }
                                multiline
                                rows={4}
                                fullWidth
                            />
                        )}
                    />
                    <Controller
                        name="inputCategory"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Le champ Catégorie est obligatoire',
                            },
                        }}
                        defaultValue={props.category || null}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                disablePortal
                                fullWidth
                                options={categoriesState}
                                getOptionLabel={(option) => {
                                    return option.label || null;
                                }}
                                isOptionEqualToValue={(option, value) => {
                                    return option.value === value.value;
                                }}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                }}
                                sx={{ mt: 2 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Catégorie du fichier"
                                        sx={{ mt: 1 }}
                                        variant="outlined"
                                        error={!!errors.inputCategory}
                                        helperText={
                                            !!errors.inputCategory
                                                ? errors.inputCategory.message
                                                : null
                                        }
                                    />
                                )}
                            />
                        )}
                    />
                    {!isUpdate && (
                        <Controller
                            key="checkEntity"
                            name="checkEntity"
                            control={control}
                            defaultValue={false}
                            render={({ field: { value, ...field } }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            {...field}
                                            size="small"
                                            checked={!!value}
                                            disabled={!isForm}
                                        />
                                    }
                                    sx={{ mt: 2 }}
                                    label="Publier sur toutes les entités ?"
                                />
                            )}
                        />
                    )}

                    <Controller
                        id="inputFichier"
                        name="inputFichier"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Le champ Fichier est obligatoire',
                            },
                        }}
                        defaultValue={props.fichier || null}
                        render={({ field }) => (
                            <InputFichierComponent
                                {...field}
                                errors={errors}
                                accept="image/*, .doc,.docx, .pdf"
                                isUpdate={isUpdate}
                                filename={props.filename || null}
                            />
                        )}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                            {isUpdate ? <EditIcon sx={{ mr: 1 }} /> : <AddIcon sx={{ mr: 1 }} />}
                            {isUpdate ? 'Modifier le ' : 'Créer un '}fichier
                        </Button>
                        {isUpdate && (
                            <Button
                                type="button"
                                variant="contained"
                                color="error"
                                sx={{ mt: 2, ml: 2 }}
                                onClick={handleSubmit(submitDeleteFichier)}
                            >
                                <DeleteIcon />
                                Supprimer le fichier
                            </Button>
                        )}
                    </div>
                </form>
            </CenteredPaper>
        </>
    );
};

export default FormFichiersComponent;
