import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
} from '../constants';

import AuthService from '../../services/auth.service';

export const register =
    (...datas) =>
    (dispatch) => {
        return AuthService.register(...datas).then(
            (response) => {
                dispatch({
                    type: REGISTER_SUCCESS,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.message,
                });

                return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response && error.response && error.reponse.data.message) ||
                    error.message ||
                    error.toString();

                dispatch({
                    type: REGISTER_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                return Promise.reject();
            },
        );
    };
export const login = (email) => (dispatch) => {
    return AuthService.login(email).then((res) => {
        if (res.error) {
            const message = res.error;

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject(res.error);
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: res },
            });

            return Promise.resolve();
        }
    });
};

export const manualLogin = (email) => (dispatch) => {
    return AuthService.manualLogin(email).then((res) => {
        if (res.error) {
            const message = res.error;

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject(res.error);
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: res },
            });
            return Promise.resolve(res);
        }
    });
};

export const relogin = (email) => (dispatch) => {
    localStorage.removeItem('user');

    return AuthService.manualLogin(email).then((res) => {
        if (res.error) {
            const message = res.error;

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject(res.error);
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: res },
            });

            return Promise.resolve();
        }
    });
};

export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT,
    });
};
