import { React, useState, useEffect } from 'react';
import './calendar.style.scss';
import TitleRowHorizontalWeekComponent from './tr-week-horizontal.component';

import HorizontalWeekComponent from './week-horizontal.component';
import LegendTipsComponent from '../assets/legend/legend-tips.component';
import SelectionDateComponent from './selection-date.component';

// Helpers et Services
import timesService from '../../services/times.service';
import * as DateHelper from '../../helpers/date.helper';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import activityService from '../../services/activity.service';
import RightPanelComponent from '../assets/panels/right-panel.component';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

/**
 * HorizontalCalendarComponent affiche le mois entier sous forme de table
 * à partir de la date passée en paramètre.
 * @param {Date} actualDate - La date à évaluer
 * @param {Array} activities - les activités à afficher
 */

const HorizontalCalendarComponent = (props) => {
    const theme = useTheme();
    const actualDate = props.actualDate;
    const nextMonth = props.nextMonth;
    const previousMonth = props.previousMonth;
    const setYear = props.setYear;
    const setMonth = props.setMonth;
    const setManager = props.setManager;

    //Check taille écran
    const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

    const [monthDateState] = useState(props.actualDate);
    const [monthCalendarState] = useState(
        DateHelper.constructorMonth(actualDate, props.activities, props.allJoursFeries),
    );

    const [displayMonthState] = useState(
        DateHelper.clearMonth(actualDate, props.activities, props.allJoursFeries),
    );
    const [employeesState] = useState(props.employees);
    const [sortedActivitiesState, setSortedActivitiesState] = useState(null);
    const [rightPanelDisplayState, setRightPanelDisplayState] = useState(false);
    const [panelContentState, setPanelContentState] = useState(null);
    const [yearsRangeState, setYearsRangeState] = useState([]);
    const [allActivityTypesState, setAllActivityTypesState] = useState(null);

    /** Range les activités dans des objets */
    const sortActivitiesByEmployee = () => {
        let storedActivities = {};

        // init de l'objet avec une clef par employé
        employeesState.forEach((employee) => {
            storedActivities[employee.user_id] = [];
        });

        // on parcours les activités et on les ajoutes à la bonne clef
        props.activities.forEach((activity) => {
            storedActivities[activity.utilisateur.user_id] = [
                ...storedActivities[activity.utilisateur.user_id],
                activity,
            ];
        });

        let countActivities = 0;
        for (const activities in storedActivities) {
            countActivities += storedActivities[activities].length;
        }
        const timeToDisplay = '300'; // Temps en ms pour afficher toutes les activités
        const activityTime = timeToDisplay / countActivities; // Pour que tout s'affiche en une seconde, on divise par le nombre d'activité à afficher
        for (const activities in storedActivities) {
            storedActivities[activities].forEach((activity, index) => {
                activity.timeToDisplay = (index + 1) * activityTime;
            });
        }

        return storedActivities;
    };

    const openRightPanel = (_, content) => {
        setPanelContentState(content);
        setRightPanelDisplayState(true);
    };
    const closeRightPanel = () => {
        setRightPanelDisplayState(false);
    };

    useEffect(() => {
        timesService.handleAdaptedRange(2).then((time) => {
            setYearsRangeState(timesService.fulfilledRange(time.min, time.max, 1));
        });
        setSortedActivitiesState(sortActivitiesByEmployee());
        const allActivityTypes = activityService.getAllActivityTypesOrganized();

        Promise.all([allActivityTypes]).then(([res]) => {
            setAllActivityTypesState(res);
        });
    }, []);

    return (
        <>
            <Grid container direction="column" sx={{ minHeight: '100%' }}>
                <Grid item>
                    <SelectionDateComponent
                        previousMonth={previousMonth}
                        monthDateState={monthDateState}
                        setMonth={setMonth}
                        setYear={setYear}
                        yearsRangeState={yearsRangeState}
                        nextMonth={nextMonth}
                        managers={props.managers}
                        actualManager={props.actualManager}
                        setManager={setManager}
                    />
                </Grid>
                {isUpLg && (
                    <Grid item>
                        <TitleRowHorizontalWeekComponent month={displayMonthState} />
                    </Grid>
                )}
                {sortedActivitiesState !== null && allActivityTypesState !== null ? (
                    <>
                        <Grid item sx={{ minHeight: '100%' }}>
                            <HorizontalWeekComponent
                                activities={sortedActivitiesState}
                                employees={employeesState}
                                openRightPanel={openRightPanel}
                                closeRightPanel={closeRightPanel}
                                date={monthDateState}
                                rerenderParentCallback={props.rerenderParentCallback}
                                monthCalendar={monthCalendarState}
                                allActivityTypes={allActivityTypesState}
                                allJoursFeries={props.allJoursFeries}
                            />
                        </Grid>
                    </>
                ) : (
                    <WaitingScreenComponent />
                )}
            </Grid>
            <LegendTipsComponent />
            <RightPanelComponent
                rightPanelDisplayState={rightPanelDisplayState}
                closeRightPanel={closeRightPanel}
                panelContentState={panelContentState}
            />
        </>
    );
};

export default HorizontalCalendarComponent;
