import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import activityService from '../../../services/activity.service';
import WaitingScreenComponent from '../waiting-screen.component';

const LegendTipsComponent = () => {
    const [loadingState, setLoadingState] = useState(true);
    const [typesState, setTypesState] = useState([]);

    const handleRefreshData = () => {
        activityService
            .getAllTypeFacturation()
            .then((types) => {
                setTypesState(types);
                setLoadingState(false);
            })
            .catch(() => {
                setLoadingState(false);
            });
    };

    useEffect(() => {
        handleRefreshData();
    }, []);

    return (
        <>
            {loadingState ? (
                <WaitingScreenComponent />
            ) : typesState.length > 0 ? (
                <Grid container>
                    {typesState.map((type) => {
                        return (
                            <Grid
                                item
                                key={type.type_facturation_id}
                                sx={{
                                    backgroundColor: type.code_hexa + '40',
                                    borderLeftColor: type.code_hexa,
                                    p: 1,
                                }}
                            >
                                <Typography>{type.nom}</Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Typography>Impossible de charger la légende</Typography>
            )}
        </>
    );
};

export default LegendTipsComponent;
