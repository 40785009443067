import { NavLink, useLocation } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ConnectedProtectedRouteComponent } from '../routing/protected-route.component';

const MenuRowComponent = (props) => {
    const location = useLocation();

    return (
        <ConnectedProtectedRouteComponent
            role={props.role}
            returnTo={<div style={{ display: 'none' }}></div>}
        >
            <ListItem
                component={NavLink}
                to={'/' + props.link}
                style={{ color: 'white', padding: '0.5px' }}
                selected={'/' + props.link === location.pathname}
            >
                <ListItemButton>
                    <ListItemIcon>{props.icon}</ListItemIcon>
                    <ListItemText>{props.title}</ListItemText>
                </ListItemButton>
            </ListItem>
        </ConnectedProtectedRouteComponent>
    );
};

export default MenuRowComponent;
