import axiosConfig from './axiosConfig';

const API_URL = '/authorization/notifications/';

class NotificationService {
    constructor() {
        this.pushServerPublicKey =
            'BPP3RDChtWnCLnoTnhHHHXkxaGB953op9pD-nyhkZK_c4jJutXS9aoC3ClXkElpnWizUrqInySv4DOkxlZ30NHw';
    }

    async createNotificationSubscription() {
        //wait for service worker installation to be ready
        const serviceWorker = await navigator.serviceWorker.ready;
        // subscribe and return the subscription
        return await serviceWorker.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey:
                'BPP3RDChtWnCLnoTnhHHHXkxaGB953op9pD-nyhkZK_c4jJutXS9aoC3ClXkElpnWizUrqInySv4DOkxlZ30NHw',
        });
    }

    postSubscription(subscription) {
        return axiosConfig
            .post(API_URL + 'subscribe', subscription)
            .then((res) => {
                return res.data;
            })
            .catch((err) => ({ error: err.message }));
    }

    getPushNotification(subscriptionId) {
        return axiosConfig
            .get(API_URL + 'subscribe/' + subscriptionId)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
}

export default new NotificationService();
