import { darken, lighten } from '@mui/material';

const getPalette = (fav_color) => {
    const primary = {
        main: fav_color || '#ff5c40',
        dark: fav_color ? darken(fav_color, 0.5) : '#d4381c',
        light: fav_color ? lighten(fav_color, 0.3) : '#ff8f66',
        veryLight: fav_color ? lighten(fav_color, 0.7) : '#f9c2b8',
        extraLight: fav_color ? lighten(fav_color, 0.9) : lighten('#ff5c40', 0.9),
    };

    const originalPrimary = {
        main: '#ff5c40',
        dark: '#d4381c',
        light: '#ff8f66',
        contrastText: '#fff',
        veryLight: '#f9c2b8',
        extraLight: lighten('#ff5c40', 0.9),
    };

    const secondary = {
        main: '#2a3237',
        contrastText: '#fff',
        light: '#717171',
        veryLight: lighten('#2a3237', 0.7),
        extraLight: lighten('#2a3237', 0.85),
    };
    const error = {
        main: '#ee2929',
    };

    const warning = {
        main: '#eed202',
    };

    const success = {
        main: '#2b6955',
        conges: '#2da05f',
        light: '#269773',
    };

    const light = {
        main: '#fff',
        dark: '#dddddd',
        grey: '#f3f5f9',
        contrastText: '#2a3237',
    };

    const lightDark = {
        ...secondary,
    };

    const love = {
        main: '#e73af7',
    };

    return {
        primary,
        originalPrimary,
        secondary,
        error,
        warning,
        success,
        light,
        lightDark,
        love,
    };
};

export default getPalette;
