export default function authHeader(withUser = true) {
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = localStorage.getItem('accessToken');

    // Si l'access token est bien dans le stockage local
    if (accessToken) {
        // Et si l'utilisateur est aussi dans le stockage local
        if (user && withUser) {
            // Alors on renvoie toutes les valeurs de header requises
            return {
                Authorization: `Bearer ${accessToken}`,
                utilisateursId: user.user_id || null,
                entity: user.entity || null,
            };
        } else {
            // Sinon on renvoie que le header du access token
            return {
                Authorization: `Bearer ${accessToken}`,
            };
        }
    } else {
        // Si rien du tout, on envoie aucun header
        return {};
    }
}
