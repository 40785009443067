import React, { useState, useEffect } from 'react';
import userService from '../../../services/user.service';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import { useLocation } from 'react-router-dom';
import WaitingScreenComponent from '../../assets/waiting-screen.component';
import { ConnectedUserInfosPanel } from '../../assets/panels/user-infos-panel.component';
import CenteredPaper from '../../assets/container/centeredPaper.component';

const AdminUserComponent = (props) => {
    const location = useLocation();
    const [user, setUser] = useState('');
    const [error, setError] = useState('');

    const handleRefreshDatas = (user_email) => {
        userService
            .getUserInfos(user_email)
            .then((userF) => {
                setUser(userF);
            })
            .catch(() => {
                setError("Impossible de récupérer l'utilisateur");
            });
    };

    useEffect(() => {
        props.onLoad('Administrateur - Détails utilisateur');
        // on récupère les infos de l'utilisateur via les données transmises dans le lien
        handleRefreshDatas(location.state.user_email);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {error !== '' ? (
                <p>{error}</p>
            ) : user !== '' ? (
                <CenteredPaper withMargin={false} withPadding={false}>
                    <ConnectedUserInfosPanel content={user} notCurrent editableAdmin />
                </CenteredPaper>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedAdminUserComponent = connect(null, mapDispatchToProps)(AdminUserComponent);
