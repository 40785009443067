import axiosConfig from './axiosConfig';

const API_URL = '/authorization/status/';

class StatusService {
    getAllStatus() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
}

export default new StatusService();
