import { Button, darken, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import CasinoIcon from '@mui/icons-material/Casino';
import { getConstrastColor } from '../../../theming/customTheme';

// helper
const getRandomHexaColor = () => {
    const rndColor =
        '#' +
        Math.floor(Math.random() * 2 ** 24)
            .toString(16)
            .padStart(6, '0');
    return rndColor;
};

/**
 * Composant d'input de couleur fait pour être utilisé dans un Controller react-hook-form
 * @param {boolean} disabled Désactive l'input si false.
 * @param {string} defaultColor String de couleur. Si renseigné, ajout un bouton "couleur par défaut".
 * @param {string} ref Obligatoirement à null.
 */
const InputColorComponent = (props) => {
    const [displayColorPickerState, setDisplayColorPickerState] = useState(false);
    const { onChange, value, disabled } = props;

    const popover = {
        position: 'relative',
        zIndex: '2',
    };
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };

    const handleOnChange = (value) => {
        onChange(value.hex || value);
    };

    /**
     * Fonction permettant de toggle l'affichage du color picker
     */
    const handleToggleColorPicker = () => {
        setDisplayColorPickerState(!displayColorPickerState);
    };

    return (
        <>
            <Grid container direction="row" alignItems="center" sx={{ mt: 2 }}>
                {props.label && !props.labelAfter && (
                    <Typography sx={{ color: 'primary.main', fontWeight: 'bold', mr: 1 }}>
                        {props.label + ' : '}
                    </Typography>
                )}
                <Button
                    type="button"
                    sx={{
                        backgroundColor: value,
                        color: getConstrastColor(value),
                        '&:hover': {
                            backgroundColor: darken(value, 0.5),
                        },
                    }}
                    variant="contained"
                    size="large"
                    disabled={disabled}
                    onClick={() => handleToggleColorPicker()}
                >
                    {value}
                </Button>
                <CasinoIcon
                    sx={{
                        fontSize: '50px',
                        color: !disabled ? value : 'text.disabled',
                        '&:hover': {
                            color: !disabled && darken(value, 0.5),
                        },
                    }}
                    onClick={() => {
                        !disabled && handleOnChange(getRandomHexaColor());
                    }}
                />
                {props.label && props.labelAfter && (
                    <Typography sx={{ color: 'primary.main', fontWeight: 'bold', mr: 1 }}>
                        {props.label}
                    </Typography>
                )}
                {props.defaultColor && (
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            sx={{ fontSize: '10px' }}
                            onClick={() => handleOnChange(props.defaultColor)}
                        >
                            Couleur par défaut
                        </Button>
                    </Grid>
                )}
                {displayColorPickerState && (
                    <div style={popover}>
                        <div style={cover} onClick={() => handleToggleColorPicker()} />
                        <SketchPicker color={value} onChange={handleOnChange} />
                    </div>
                )}
            </Grid>
        </>
    );
};

export { getRandomHexaColor, InputColorComponent };
