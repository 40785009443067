// import Relax from '../../images/account.svg';
// validator form
/** REDUX functions */
import { Button, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { H2Lighter } from '../../theming/customTheme';
import { setPageTitle } from '../../redux/actions/scopeAction';
/** API services */
import userService from '../../services/user.service';

import CenteredPaper from '../assets/container/centeredPaper.component';
import ResultFormComponent from '../assets/container/resultForm.component';

const PasswordFormComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Modifier votre mot de passe');
    }, []);

    // Création des constantes pour le formulaire
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handleUpdatePassword = (dataForm) => {
        if (window.confirm('Êtes-vous sûr de vouloir modifier votre mot-de-passe ?')) {
            const user = JSON.parse(localStorage.getItem('user'));
            setLoadingState(true);
            setSuccessState(false);
            setSubmittedState(true);

            userService
                .updatePassword(user.email, dataForm.inputPassword, dataForm.inputRePassword)
                .then((response) => {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                })
                .catch((err) => {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(err.message);
                });
        }
    };

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
            link={'/home'}
        >
            <CenteredPaper size="large">
                <>
                    <H2Lighter>Votre nouveau mot de passe</H2Lighter>
                    <form onSubmit={handleSubmit(handleUpdatePassword)}>
                        <Controller
                            key="inputPassword"
                            name="inputPassword"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Ce champ est obligatoire',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="password"
                                    label="Mot de passe actuel"
                                    fullWidth
                                    error={!!errors.inputPassword}
                                    helperText={
                                        !!errors.inputPassword
                                            ? errors.inputPassword.message
                                            : 'Veillez à entrer un mot-de-passe FORT.\
                                                    Il doit contenir minimum 8 caractères, 1 lettre et 1\
                                                    nombre et 1 caractère spécial.'
                                    }
                                />
                            )}
                        />
                        <Controller
                            key="inputRePassword"
                            name="inputRePassword"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Ce champ est obligatoire',
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Le mot de passe doit faire minimum 8 caractères.',
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-]).{8,}$/,
                                    message:
                                        'Le mot de passe ne correspond pas au niveau de complexité requis',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="password"
                                    label="Nouveau mot de passe"
                                    fullWidth
                                    sx={{ mt: 1 }}
                                    error={!!errors.inputRePassword}
                                    helperText={
                                        !!errors.inputRePassword && errors.inputRePassword.message
                                    }
                                />
                            )}
                        />
                        <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                            Valider
                        </Button>
                    </form>
                </>
            </CenteredPaper>
        </ResultFormComponent>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedPasswordFormComponent = connect(
    null,
    mapDispatchToProps,
)(PasswordFormComponent);
