const breakpoints = {
    values: {
        xs: 0,
        sm: 650,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 2500,
    },
};

export default breakpoints;
