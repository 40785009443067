/** API services */
import contactService from '../../../services/contact.service';

import { useState } from 'react';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import { Controller, useForm } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import ResultFormComponent from '../../assets/container/resultForm.component';

const CreateContactComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handeCreateContact = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        const { inputNomContact, inputPrenomContact, inputTelephoneContact, inputEmailContact } =
            dataForm;
        contactService
            .create(
                inputNomContact,
                inputPrenomContact,
                inputTelephoneContact,
                inputEmailContact,
                props.client,
            )
            .then((response) => {
                let isSuccess = true;
                let message = '';
                if (response.error !== undefined) {
                    isSuccess = false;
                    message = response.error;
                }
                setSuccessState(isSuccess);
                setLoadingState(false);
                setMessageState(message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
        >
            <CenteredPaper withMargin={false} size="extra-large" elevation={0}>
                <form>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item>
                            <Controller
                                key="inputNomContact"
                                name="inputNomContact"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le champ nom est requis',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Nom du contact"
                                        placeholder="Dumas"
                                        error={!!errors.inputNomContact}
                                        helperText={
                                            !!errors.inputNomContact &&
                                            errors.inputNomContact.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Controller
                                key="inputPrenomContact"
                                name="inputPrenomContact"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le champ prénom est requis',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Prénom du contact"
                                        placeholder="Charles"
                                        error={!!errors.inputPrenomContact}
                                        helperText={
                                            !!errors.inputPrenomContact &&
                                            errors.inputPrenomContact.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Controller
                                key="inputTelephoneContact"
                                name="inputTelephoneContact"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le champ téléphone est requis',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Téléphone du contact"
                                        placeholder="0313159265"
                                        error={!!errors.inputTelephoneContact}
                                        helperText={
                                            !!errors.inputTelephoneContact &&
                                            errors.inputTelephoneContact.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Controller
                                key="inputEmailContact"
                                name="inputEmailContact"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Le champ email est requis',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email du contact"
                                        type="email"
                                        placeholder="charles.dumas@neodt.fr"
                                        error={!!errors.inputEmailContact}
                                        helperText={
                                            !!errors.inputEmailContact &&
                                            errors.inputEmailContact.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <center>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={handleSubmit(handeCreateContact)}
                            sx={{ mt: 2 }}
                        >
                            Valider
                        </Button>
                    </center>
                </form>
            </CenteredPaper>
        </ResultFormComponent>
    );
};

export default CreateContactComponent;
