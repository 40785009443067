import { setPageTitle } from '../../../redux/actions/scopeAction';
import regionService from '../../../services/region.service';
import { ToastContainer, toast } from 'react-toastify';
// // redux
import { connect } from 'react-redux';

import { useEffect } from 'react';
import { useState } from 'react';
import { Alert } from '@mui/material';

import CrudPageComponent from '../../assets/page/crud-page.component';

// import { CircularProgress } from '@mui/material';

const RegionsComponent = (props) => {
    const [contentState, setContentState] = useState('');
    const [errorState, setErrorState] = useState(null);
    const [displayTypeState, setDisplayTypeState] = useState(true);
    const errorToast = () => toast.error(errorState);

    const handleFetchRegions = async () => {
        const response = await (displayTypeState
            ? regionService.getAllRegions()
            : regionService.getAllArchivedRegions());

        if (!response.error) {
            setContentState(response || null);
        } else {
            setErrorState(response.error);
        }
    };

    const handleUnArchive = (region_id) => {
        if (
            window.confirm(
                'Voulez-vous vraiment rendre à nouveau disponible cette région ? Elle sera de nouveau possible de créer des projets en lien avec celle-ci et elle sera affichée dans la liste des régions.',
            )
        ) {
            regionService
                .unarchiveRegionById(region_id)
                .then((res) => {
                    toast.success(res.message);
                    handleFetchRegions();
                })
                .catch((err) => {
                    toast.error(err.response.message);
                });
        }
    };

    useEffect(() => {
        props.onLoad('Administrateur - Toutes les regions');
        handleFetchRegions();
    }, []);

    useEffect(() => {
        handleFetchRegions();
    }, [displayTypeState]);

    const onChangeDisplayType = () => {
        setDisplayTypeState(!displayTypeState);
    };

    if (errorState) {
        return (
            <>
                <div style={{ display: 'none' }}>{errorToast()}</div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                />
                <Alert severity="error">{errorState}</Alert>
            </>
        );
    }

    return (
        <CrudPageComponent
            objectName="region"
            title="Gestion des régions"
            titleAdd="Créer une région"
            linkAdd="/admin/create-region"
            onChangeDisplayType={onChangeDisplayType}
            displayType={displayTypeState}
            labelSwitchArchived="Voir les régions archivées"
            dataContent={
                !!contentState
                    ? contentState.map((region) => ({
                          id: region.region_id,
                          nom: region.nom,
                      }))
                    : [{ id: '', nom: '' }]
            }
            linkEdit="/admin/update-region"
            handleRestore={handleUnArchive}
        />
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedRegionsComponent = connect(null, mapDispatchToProps)(RegionsComponent);
