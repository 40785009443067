import { useState, useEffect } from 'react';
import ResultFormComponent from '../../assets/container/resultForm.component';
import FormRegionComponent from './form-region.component';

// /** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
// /** API services */
import regionService from '../../../services/region.service';

const CreateRegionComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Créer une région');
    }, []);

    const handleCreateRegion = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        regionService
            .create(dataForm.inputName)
            .then((res) => {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(res.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    return (
        <ResultFormComponent
            loading={loadingState}
            success={successState}
            submitted={submittedState}
            message={messageState}
            link={'/admin/regions'}
        >
            <FormRegionComponent handleForm={handleCreateRegion} />
        </ResultFormComponent>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateRegionComponent = connect(
    null,
    mapDispatchToProps,
)(CreateRegionComponent);
