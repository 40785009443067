import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../../redux/actions/scopeAction';
import category_newsService from '../../../../services/category_news.service';
import ResultFormComponent from '../../../assets/container/resultForm.component';
import FormCategoryNewsComponent from './form-category-news.component';

const CreateCategoryNewsComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    const handleCreateCategory = (dataForm) => {
        setSubmittedState(true);
        setLoadingState(true);
        setSuccessState(false);

        category_newsService
            .insert({ name: dataForm.inputName, color: dataForm.inputColor })
            .then((res) => {
                if (res.error) {
                    setLoadingState(false);
                    setSuccessState(false);
                    setMessageState(res.error);
                } else {
                    setLoadingState(false);
                    setSuccessState(true);
                    setMessageState('Catégorie inséré avec succès');
                }
            });
    };

    useEffect(() => {
        props.onLoad('Administrateur - Créer une catégorie de news');
    }, []);

    return (
        <ResultFormComponent
            loading={loadingState}
            success={successState}
            submitted={submittedState}
            message={messageState}
            link={'/admin/list-news'}
        >
            <FormCategoryNewsComponent handleForm={handleCreateCategory} />
        </ResultFormComponent>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateCategoryNewsComponent = connect(
    null,
    mapDispatchToProps,
)(CreateCategoryNewsComponent);
