import '../../cards.style.scss';
import Relax from '../../../images/account.svg';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// redux
import { connect } from 'react-redux';
import GestionAdminService from '../../../services/gestion_admin.service';
import { Grid, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import { H2Lighter } from '../../../theming/customTheme';

const GestionComponent = () => {
    const handleCreateNotifications = async () => {
        GestionAdminService.sendNotification().then((res) => {
            if (res.error) {
                toast.error(res.error);
            } else {
                toast.success(res.message);
            }
        });
    };
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                    <CenteredPaper size="all" withMargin={false}>
                        <H2Lighter>Paramétrage des notifications</H2Lighter>

                        <Button
                            variant="contained"
                            sx={{ m: 1 }}
                            endIcon={<SendIcon />}
                            onClick={handleCreateNotifications}
                        >
                            Envoyer une notification
                        </Button>
                    </CenteredPaper>
                </Grid>
            </Grid>
            <div className="background-decoration-svg">
                <img src={Relax} height="550px" width="800px" alt="" />
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedGestionComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(GestionComponent);
