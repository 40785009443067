import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
// import WaitingScreenComponent from '../waiting-screen.component';
import rightsService from '../../../services/rights.service';
import { logout } from '../../../redux/actions/authAction';
// import rightsService from '../../../services/rights.service';

/**
 * @param {Number} role
 * Permet d'examiner les droits avant l'accès à une page
 */
const ProtectedRouteComponent = (props) => {
    let redirectTo = props.redirectTo ? props.redirectTo : '/';
    let returnTo = props.returnTo ? props.returnTo : null;
    const [loadingState, setLoadingState] = useState(true);
    const [isAllowedState, setIsAllowedState] = useState(false);

    useEffect(() => {
        rightsService.isAllowed(props.role).then((res) => {
            if (!res.error) {
                setIsAllowedState(res);
                setLoadingState(false);
            } else {
                setLoadingState(false);
                setIsAllowedState(false);
            }
        });
    }, []);

    if (!props.isLoggedIn) {
        // setLoadingState(false);
        // setIsAllowedState(false);
        return <Navigate to={'/'} />;
    }

    if (!loadingState) {
        if (!isAllowedState) {
            if (returnTo) {
                return returnTo;
            } else {
                return <Navigate to={redirectTo} />;
            }
        } else {
            return props.children;
        }
    } else {
        return <></>;
    }
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch(logout()),
});

export const ConnectedProtectedRouteComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProtectedRouteComponent);
