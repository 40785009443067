import { React, useEffect, useState } from 'react';
import FormUserComponent from './form-user.component';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import userService from '../../../services/user.service';
import fonctionService from '../../../services/fonction.service';
import roleService from '../../../services/role.service';
import buService from '../../../services/bu.service';

//images
import authService from '../../../services/auth.service';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';

const CreateUserComponent = (props) => {
    const [usersState, setUsersState] = useState('');
    const [rolesState, setRolesState] = useState('');
    const [fonctionsState, setFonctionsState] = useState('');
    const [busState, setBusState] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Créer un utilisateur');
        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const users = userService.getAllUsers();
        const roles = roleService.getAllRoles();
        const fonctions = fonctionService.getAllFonctions();
        const BUs = buService.getAllBUs();
        // on attend le réponse de toutes les requêtes et on les attribues au state local
        Promise.all([users, roles, fonctions, BUs]).then(([users, roles, fonctions, BUs]) => {
            setUsersState(
                users.map((user) => ({
                    value: user.user_id,
                    label: user.nom.toUpperCase() + ' ' + user.prenom,
                    userObject: user,
                })),
            );
            setRolesState(
                roles.map((role) => ({
                    value: role.role_id,
                    label: role.nom,
                })),
            );
            setFonctionsState(
                fonctions.map((fonction) => ({
                    value: fonction.fonction_id,
                    label: fonction.nom.toLowerCase(),
                })),
            );
            setBusState(
                BUs.map((bu) => ({
                    value: bu.business_unit_id,
                    label: bu.nom.toLowerCase(),
                })),
            );
        });
    }, []);

    const handleCreateUser = (form) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        const {
            inputBusinessUnit,
            inputEmail,
            inputEstInterne,
            inputFonction,
            inputNom,
            // inputPassword,
            inputPrenom,
            inputRole,
            inputManager,
            entity = props.user.entity,
        } = form;

        authService
            .register(
                inputNom,
                inputPrenom,
                inputEmail,
                // inputPassword,
                inputEstInterne,
                inputRole.value,
                inputFonction.value,
                inputManager.value,
                inputBusinessUnit.value,
                entity,
            )
            .then((response) => {
                if (response.error) throw new Error(response.error);
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.message);
            });
    };

    return (
        <>
            {submittedState ? (
                loadingState ? (
                    <WaitingScreenComponent />
                ) : (
                    <CenteredPaper>
                        {successState ? (
                            <CheckCircleIcon sx={{ fontSize: 100 }} color="success" />
                        ) : (
                            <CancelIcon sx={{ fontSize: 100 }} color="error" />
                        )}
                        <p>{messageState}</p>
                        <Link to={'/admin/users'}>Retour</Link>
                    </CenteredPaper>
                )
            ) : (
                <FormUserComponent
                    handleForm={handleCreateUser}
                    users={usersState}
                    roles={rolesState}
                    fonctions={fonctionsState}
                    bus={busState}
                />
            )}
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateUserComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateUserComponent);
