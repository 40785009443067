import congeService from '../../services/conge.service';
import { useEffect, useState } from 'react';
import ResultFormComponent from '../assets/container/resultForm.component';
import FormCongesComponent from './form-conge.component';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import { toast } from 'react-toastify';

const UpdateCongeComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const [leCongeState, setLeCongeState] = useState(null);

    useEffect(() => {
        congeService.getCongeByJourConge(props.activity.jour_conges_id).then((res) => {
            if (res.error) {
                setSuccessState(false);
                setMessageState(res.error);
            } else {
                setLeCongeState(res);
            }
        });
    }, []);

    const updateConges = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        congeService
            .updateConge({
                id_conges: leCongeState.new_conges_id,
                type_conges: dataForm.inputType.value,
                date_debut: new Date(
                    dataForm.inputDateDebut.getTime() -
                        dataForm.inputDateDebut.getTimezoneOffset() * 60000,
                ),
                date_fin: new Date(
                    dataForm.inputDateFin.getTime() -
                        dataForm.inputDateFin.getTimezoneOffset() * 60000,
                ),
                periode_debut: +dataForm.inputPeriodeDebut,
                periode_fin: +dataForm.inputPeriodeFin,
                commentaire: dataForm.inputCommentaire,
                user_id: props.employe ? props.employe.user_id : null,
            })
            .then((response) => {
                if (!response.error) {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                    toast.success(response.message);
                    props.rerenderParentCallback();
                } else {
                    setSuccessState(false);
                    setMessageState(response.error);
                    setLoadingState(false);
                    toast.error(response.error);
                    props.rerenderParentCallback();
                }
            });
    };

    const deleteConges = () => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        congeService.askForCancel(leCongeState.new_conges_id).then((response) => {
            if (!response.error) {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
                props.rerenderParentCallback();
                toast.success(response.message);
            } else {
                setSuccessState(false);
                setMessageState(response.error);
                setLoadingState(false);
                toast.error(response.error);
                props.rerenderParentCallback();
            }
        });
    };

    return (
        <>
            {leCongeState !== null ? (
                <ResultFormComponent
                    submitted={submittedState}
                    loading={loadingState}
                    success={successState}
                    message={messageState}
                >
                    <FormCongesComponent
                        monthCalendar={props.monthCalendar}
                        jour={props.jour}
                        openRightPanel={props.openRightPanel}
                        closeRightPanel={props.closeRightPanel}
                        rerenderParentCallback={props.rerenderParentCallback}
                        activities={props.activities}
                        employe={props.employe}
                        handleForm={updateConges}
                        handleDelete={deleteConges}
                        commentaire={leCongeState.commentaire}
                        demande_annulation={leCongeState.demande_annulation}
                        est_evalue={leCongeState.est_evalue}
                        typeConges={{
                            value: leCongeState.fk_conge_type,
                            label: leCongeState.type_conge.nom,
                        }}
                        dateDebut={new Date(leCongeState.jour_conges[0].date)}
                        dateFin={
                            new Date(
                                leCongeState.jour_conges[leCongeState.jour_conges.length - 1].date,
                            )
                        }
                        periodeDebut={
                            leCongeState.jour_conges[0].periode === 1
                                ? 2
                                : leCongeState.jour_conges[0].periode
                        }
                        periodeFin={
                            leCongeState.jour_conges[leCongeState.jour_conges.length - 1]
                                .periode === 1
                                ? 3
                                : leCongeState.jour_conges[leCongeState.jour_conges.length - 1]
                                      .periode
                        }
                        isUpdate
                    />
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

export default UpdateCongeComponent;
