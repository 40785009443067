import axiosConfig from './axiosConfig';

const API_URL = '/authorization/client/';

/**
 * Classe service de client, regroupe les appels API via Axios.
 */
class ClientService {
    /** Retourne tous les clients */
    getAllClients() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getAvailableClients() {
        return axiosConfig
            .get(API_URL + 'all/available')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /** Retourne tous les clients archivés */
    getAllArchivedClients() {
        return axiosConfig
            .get(API_URL + 'archived/all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    /**
     * Requête un client
     * @param {Number} client_id - id du client en param
     */
    getClientById(client_id) {
        return axiosConfig
            .get(API_URL + client_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }

    archiveClientById(client_id) {
        return axiosConfig
            .delete(API_URL + client_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }

    updateClientById(client_id, nom, ville, commentaire, region) {
        return axiosConfig
            .put(API_URL, { client_id, nom, ville, commentaire, region })
            .then((response) => response.data)
            .catch((error) => ({ error: ` ${error.message}` }));
    }

    /** Désarchive un client par id params */
    unarchiveClientById(client_id) {
        return axiosConfig
            .put(API_URL + 'unarchive/' + client_id)
            .then((response) => response.data)
            .catch((error) => ({ error: ` ${error.message}` }));
    }

    /**
     * Créer un client avec l'entité du token
     * @param {String} nom - Nom du client
     * @param {String} ville - Nom de la ville
     * @param {String || Null} commentaire - Commentaire peut etre nul
     * @param {Number} region - FK de region
     */
    create(nom, ville, commentaire, region) {
        return axiosConfig
            .post(API_URL, {
                nom,
                ville,
                commentaire,
                region,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }
}

export default new ClientService();
