import { Button, Grid, Grow, Modal, Paper } from '@mui/material';

/**
 * le simple modal component permet d'afficher une modal au milieu de l'écran
 * avec un backlayer gris transparent.
 * @param {Function} handleCloseModal - la fonction permettant de fermer la modal via state du parent
 * @param {Boolean} isOpen - status de la modal boolean
 * @param {object} customSx - objet destiné à ajouter du style à la grille principal du composant.
 */
const SimpleModalComponent = ({ isOpen, handleCloseModal, customSx, children }) => {
    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Grow in={isOpen}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        outline: 'none',
                        maxHeight: '85%',
                        overflow: 'auto',
                        ...customSx,
                    }}
                >
                    <Grid item xs={11} md={8}>
                        <Paper sx={{ p: 2 }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >
                                <Button onClick={handleCloseModal} color="error">
                                    Fermer
                                </Button>
                            </Grid>
                            {children}
                        </Paper>
                    </Grid>
                </Grid>
            </Grow>
        </Modal>
    );
};

export default SimpleModalComponent;
