import React, { useEffect, useState } from 'react';
import WaitingScreenComponent from '../../assets/waiting-screen.component';

import FormUserComponent from './form-user.component';

import { useLocation } from 'react-router-dom';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import userService from '../../../services/user.service';
import fonctionService from '../../../services/fonction.service';
import roleService from '../../../services/role.service';
import buService from '../../../services/bu.service';

import ResultFormComponent from '../../assets/container/resultForm.component';

export const UpdateUserComponent = (props) => {
    const location = useLocation();
    const user_email = location.state.objectId.data;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [userId, setUserId] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [estInterne, setEstInterne] = useState(false);
    const [soldeState, setSoldeState] = useState(null);
    const [role, setRole] = useState(null);
    const [roles, setRoles] = useState(null);
    const [fonction, setFonction] = useState(null);
    const [fonctions, setFonctions] = useState(null);
    const [manager, setManager] = useState(null);
    const [users, setUsers] = useState(null);
    const [bu, setBu] = useState(null);
    const [bus, setBus] = useState(null);

    //récupère les données utilisateur
    const handleRefreshDatas = () => {
        userService
            .getUserInfos(user_email)
            .then((res) => {
                const { utilisateur, role, fonction, businessUnit, manager, solde } = res;
                setUserId(utilisateur.user_id || '');
                setNom(utilisateur.nom || '');
                setPrenom(utilisateur.prenom || '');
                setEmail(utilisateur.email || '');
                setEstInterne(utilisateur.estInterne);
                setRole(role || '');
                setFonction(fonction || '');
                setManager(manager || '');
                setBu(businessUnit || '');
                setSoldeState(solde || '');
            })
            .catch(() => {
                setSuccess(false);
                setLoading(false);
                setMessage("Impossible de récupérer l'utilisateur");
            });
    };

    useEffect(() => {
        handleRefreshDatas();
        props.onLoad('Administrateur - Modifier un utilisateur');
        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const users = userService.getAllUsers();
        const roles = roleService.getAllRoles();
        const fonctions = fonctionService.getAllFonctions();
        const BUs = buService.getAllBUs();
        // on attend le réponse de toutes les requêtes et on les attribues au state local
        Promise.all([users, roles, fonctions, BUs]).then(([users, roles, fonctions, BUs]) => {
            setUsers(
                users.map((user) => {
                    return {
                        value: user.user_id,
                        label: user.nom.toUpperCase() + ' ' + user.prenom,
                        userObject: user,
                    };
                }),
            );
            setRoles(
                roles.map((role) => {
                    return {
                        value: role.role_id,
                        label: role.nom,
                    };
                }),
            );
            setFonctions(
                fonctions.map((fonction) => {
                    return {
                        value: fonction.fonction_id,
                        label: fonction.nom.toLowerCase(),
                    };
                }),
            );
            setBus(
                BUs.map((bu) => {
                    return {
                        value: bu.business_unit_id,
                        label: bu.nom.toLowerCase(),
                    };
                }),
            );
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDeleteUser = () => {
        setLoading(true);
        setSuccess(false);
        setSubmitted(true);
        try {
            userService
                .deleteUser(userId)
                .then((response) => {
                    if (response.error) throw new Error(response.error);
                    setSuccess(true);
                    setLoading(false);
                    setMessage(response.message);
                })
                .catch((err) => {
                    setSuccess(false);
                    setLoading(false);
                    setMessage(err.message);
                });
        } catch (error) {
            setSuccess(false);
            setLoading(false);
            setMessage("Erreur de l'application.");
        }
    };

    const handleUpdateUser = (form) => {
        setLoading(true);
        setSuccess(false);
        setSubmitted(true);

        try {
            // on requête l'API via nos services pour update l'utilisateur
            userService
                .updateOneUser(
                    userId,
                    form.inputNom,
                    form.inputPrenom,
                    form.inputEmail,
                    // form.inputPassword,
                    form.inputEstInterne,
                    form.inputRole.value,
                    form.inputFonction.value,
                    form.inputManager.value,
                    form.inputBusinessUnit.value,
                    form.inputSolde,
                    form.inputCommentaireSolde,
                )
                .then((response) => {
                    if (response.error) throw new Error(response.error);
                    setSuccess(true);
                    setLoading(false);
                    setMessage(response.message);
                })
                .catch((err) => {
                    setSuccess(false);
                    setLoading(false);
                    setMessage(err.message || err.response.message);
                });
        } catch (error) {
            setSuccess(false);
            setLoading(false);
            setMessage("Erreur de l'application.");
        }
    };

    return (
        <>
            {role !== null &&
            fonction !== null &&
            manager !== null &&
            bu !== null &&
            roles !== null &&
            fonctions !== null &&
            users !== null &&
            bus !== null &&
            soldeState !== null ? (
                <ResultFormComponent
                    submitted={submitted}
                    loading={loading}
                    success={success}
                    message={message}
                    link={'/admin/users'}
                >
                    <FormUserComponent
                        handleForm={handleUpdateUser}
                        handleDelete={handleDeleteUser}
                        isUpdate={true}
                        users={users}
                        roles={roles}
                        fonctions={fonctions}
                        bus={bus}
                        nom={nom}
                        prenom={prenom}
                        email={email}
                        estInterne={estInterne}
                        role={
                            role !== '' && {
                                value: role.role_id,
                                label: role.nom,
                            }
                        }
                        fonction={
                            fonction !== '' && {
                                value: fonction.fonction_id,
                                label: fonction.nom.toLowerCase(),
                            }
                        }
                        manager={
                            manager !== '' && {
                                value: manager.user_id,
                                label: manager.prenom + ' ' + manager.nom,
                            }
                        }
                        bu={
                            bu !== '' && {
                                value: bu.business_unit_id,
                                label: bu.nom.toLowerCase(),
                            }
                        }
                        solde={soldeState}
                    />
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateUserComponent = connect(null, mapDispatchToProps)(UpdateUserComponent);
