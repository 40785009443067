import { useState } from 'react';
import SimpleModalComponent from '../../assets/modal/simple-modal.component';
import CreateClientComponent from './create-client.component';

const ClientModuleComponent = (props) => {
    const [openModalState, setOpenModalState] = useState(false);

    const handleOpenModal = () => {
        setOpenModalState(true);
    };

    const handleCloseModal = () => {
        props.handleFetchClients();
        setOpenModalState(false);
    };

    return (
        <div key={openModalState}>
            <legend>
                Vous pouvez également ajouter de nouveaux clients depuis le portail
                d'administration.{' '}
                <b className="link" onClick={() => handleOpenModal()}>
                    Créer un nouveau client
                </b>
            </legend>
            <SimpleModalComponent
                modalRoot={props.modalRoot}
                isOpen={openModalState}
                handleCloseModal={handleCloseModal}
            >
                <CreateClientComponent isModule={true} />
            </SimpleModalComponent>
        </div>
    );
};

export default ClientModuleComponent;
