import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    Grid,
    Paper,
    SvgIcon,
    Tooltip,
    // Badge,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import SimpleModalComponent from '../assets/modal/simple-modal.component';
import { useState } from 'react';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { StyledBadge } from '../../theming/customTheme';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { connect } from 'react-redux';
import reactionsService from '../../services/reactions.service';
import { toast } from 'react-toastify';
import RemoveMarkdown from 'remove-markdown';

export const IntermediateNewsComponent = (props) => {
    // const [modalData, setModalData] = useState('');
    const [isOpenState, setIsOpenState] = useState(false);
    // Event qui ferme la modal
    const handleCloseModal = () => {
        setIsOpenState(false);
    };

    const triggerReaction = (reactionId) => {
        reactionsService.react({ reaction: reactionId, news: props.news.news_id }).then((res) => {
            if (res.error) {
                toast.error(res.error);
            } else {
                props.handleFetchNews();
            }
        });
    };

    return (
        <>
            <Card elevation={4}>
                {props.news.is_pinned && (
                    <Grid container direction={'row-reverse'}>
                        <Grid item>
                            <Box sx={{ m: 0.5, right: 0 }}>
                                <Tooltip title="Cette news a été mise en avant">
                                    <PushPinIcon color="primary" />
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                )}

                <Box sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 100, maxHeight: 100 }}
                        image={props.news.imageTitle}
                        title="News"
                    />
                    <CardContent sx={{ p: 1 }}>
                        <Typography gutterBottom variant="h5" component="div">
                            {props.news.fk_category.name + ' - ' + props.news.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textOverflow: 'ellipsis' }}
                        >
                            {props.news.content.length > 101
                                ? RemoveMarkdown(props.news.content).substring(0, 100) + '...'
                                : RemoveMarkdown(props.news.content)}
                        </Typography>
                    </CardContent>
                </Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 1 }}
                >
                    <Grid item>
                        {props.reactions.map((reaction) => {
                            return (
                                <Tooltip
                                    key={'svg_icon_reaction_' + reaction.reactions_id}
                                    title={reaction.name}
                                >
                                    <StyledBadge
                                        badgeContent={
                                            props.news.reducedReactions[reaction.name] || 0
                                        }
                                        color="primary"
                                        max={9}
                                        key={reaction.reactions_id}
                                    >
                                        <SvgIcon
                                            onClick={() => {
                                                triggerReaction(reaction.reactions_id);
                                            }}
                                            sx={{
                                                fontSize: 'medium',
                                                m: 0.5,
                                                borderRadius: '5px',
                                                color:
                                                    // Fonction permettant de vérifier si l'utilisateur courant à déjà réagis à la news
                                                    props.news.reactions.filter(
                                                        (reaction_news) =>
                                                            reaction_news.utilisateur.email ===
                                                                props.user.email &&
                                                            reaction_news.reaction.reactions_id ===
                                                                reaction.reactions_id,
                                                    ).length > 0
                                                        ? reaction.color
                                                        : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.veryLight' },
                                            }}
                                        >
                                            <path d={reaction.svg} />
                                        </SvgIcon>
                                    </StyledBadge>
                                </Tooltip>
                            );
                        })}
                    </Grid>
                    <Grid item>
                        <Typography
                            sx={{ fontSize: 12, fontWeight: 'bold', color: 'text.secondary' }}
                        >
                            Créée le {new Date(props.news.createdAt).toLocaleDateString('fr')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            component="a"
                            sx={{
                                color: 'primary.main',
                                '&:hover': { color: 'primary.dark' },
                            }}
                            onClick={() => setIsOpenState(true)}
                        >
                            En savoir plus
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
            <SimpleModalComponent isOpen={isOpenState} handleCloseModal={handleCloseModal}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography variant="h2">{props.news.title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: 'text.secondary',
                                mt: 1,
                            }}
                        >
                            {props.news.fk_owner.nom.toUpperCase() +
                                ' ' +
                                props.news.fk_owner.prenom}
                        </Typography>
                        <Typography
                            sx={{ fontSize: 12, fontWeight: 'bold', color: 'text.secondary' }}
                        >
                            {new Date(props.news.createdAt).toLocaleDateString('fr')}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Paper elevation={0}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{ mt: 2 }}
                        >
                            <Grid item md={3} xs={8}>
                                <Box
                                    component="img"
                                    sx={{ width: '100%' }}
                                    src={props.news.imageTitle}
                                    alt="News"
                                />
                            </Grid>
                            <Grid item md={9} xs={12}>
                                <Box data-color-mode="light" sx={{ p: 2 }}>
                                    <MarkdownEditor.Markdown source={props.news.content} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </SimpleModalComponent>
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

const NewsComponent = connect(mapStateToProps, mapDispatchToProps)(IntermediateNewsComponent);

export default NewsComponent;
