/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// service APi
import projetService from '../../services/projet.service';
import { Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CrudPageComponent from '../assets/page/crud-page.component';
import SearchIcon from '@mui/icons-material/Search';

/**
 * Appelé initialement "admin projet" c'est au final un composant générique utilisé pour partie admin ET pour la partie Manager.
 * La seule chose qui change est la fonction pour get les projets. On a donc une condition d'appliqué au cas où on a donné isManager = true dans les props
 */
const AdminProjetComponent = (props) => {
    const [contentState, setContentState] = useState('');
    const [loadingState, setLoadingState] = useState(true);
    const [errorState, setErrorState] = useState(null);
    const [isOrderedState, setIsOrderedState] = useState(false);
    const [isNomOrdoredState, setIsNomOrdoredState] = useState(false);
    const [isStatusOrdoredState, setIsStatusOrdoredState] = useState(false);
    const [isClientOrdoredState, setIsClientOrdoredState] = useState(false);
    const [displaySearchBarState, setDisplaySearchBarState] = useState(false);
    const [searchProjetState, setsearchProjetState] = useState('');
    const isManager = props.isManager || false;

    const reOrderCodeProjet = () => {
        if (contentState && contentState.length > 1) {
            const stateArray = contentState;
            let ordered = stateArray;
            if (isOrderedState) {
                ordered = stateArray.sort((a, b) =>
                    a['code_projet'].localeCompare(b['code_projet']),
                );
            } else {
                ordered = stateArray.sort(
                    (a, b) => a['code_projet'].localeCompare(b['code_projet']) * -1,
                );
            }
            setContentState(ordered);
            setIsOrderedState(!isOrderedState);
        }
    };

    const reOrderNomProjet = () => {
        if (contentState && contentState.length > 1) {
            const stateArray = contentState;
            let ordered = stateArray;
            if (isNomOrdoredState) {
                ordered = stateArray.sort((a, b) => a['nom'].localeCompare(b['nom']));
            } else {
                ordered = stateArray.sort((a, b) => a['nom'].localeCompare(b['nom']) * -1);
            }
            setContentState(ordered);
            setIsNomOrdoredState(!isNomOrdoredState);
        }
    };

    const reOrderStatusProjet = () => {
        if (contentState && contentState.length > 1) {
            const stateArray = contentState;
            let ordered = stateArray;
            if (isStatusOrdoredState) {
                ordered = stateArray.sort((a, b) =>
                    a['status']['nom'].localeCompare(b['status']['nom']),
                );
            } else {
                ordered = stateArray.sort(
                    (a, b) => a['status']['nom'].localeCompare(b['status']['nom']) * -1,
                );
            }
            setContentState(ordered);
            setIsStatusOrdoredState(!isStatusOrdoredState);
        }
    };

    const reOrderClientProjet = () => {
        if (contentState && contentState.length > 1) {
            const stateArray = contentState;
            let ordered = stateArray;
            if (isClientOrdoredState) {
                ordered = stateArray.sort((a, b) =>
                    a['client']['nom'].localeCompare(b['client']['nom']),
                );
            } else {
                ordered = stateArray.sort(
                    (a, b) => a['client']['nom'].localeCompare(b['client']['nom']) * -1,
                );
            }
            setContentState(ordered);
            setIsClientOrdoredState(!isClientOrdoredState);
        }
    };

    /** Toggle le state displaySearchBar */
    const handleToggleSearchBar = () => {
        handleClearSearchText();
        setDisplaySearchBarState(!displaySearchBarState);
    };
    const handleChangeSearch = (event) => {
        setsearchProjetState(event.target.value);
    };
    const handleClearSearchText = () => {
        setsearchProjetState('');
    };

    useEffect(() => {
        props.onLoad((isManager ? 'Manager' : 'Administrateur') + ' - Les projets');
        const laRequete = isManager
            ? projetService.getProjetByToken()
            : projetService.getAllProjets();
        laRequete.then((response) => {
            if (!response.error) {
                setContentState(response || null);
                setLoadingState(false);
            } else {
                setErrorState(response.error);
                toast.error(errorState);
            }
        });
    }, []);

    return (
        <CrudPageComponent
            title="Gestion des projets"
            objectName="project"
            titleAdd="Créer un nouveau projet"
            linkAdd="/admin/create-projet"
            displayType={true}
            loading={loadingState}
            headers={[
                {
                    title: 'Code Projet',
                    filter: {
                        filterState: isOrderedState,
                        filterFunction: reOrderCodeProjet,
                    },
                },
                {
                    title: 'Client',
                    filter: {
                        filterState: isClientOrdoredState,
                        filterFunction: reOrderClientProjet,
                    },
                },
                {
                    title: 'Région',
                },
                {
                    title: 'Status',
                    filter: {
                        filterState: isStatusOrdoredState,
                        filterFunction: reOrderStatusProjet,
                    },
                },
                {
                    title: 'Nom',
                    filter: {
                        filterState: isNomOrdoredState,
                        filterFunction: reOrderNomProjet,
                    },
                },
            ]}
            error={errorState}
            dataContent={
                !!contentState
                    ? contentState
                          .map((projet) => ({
                              project_id: projet.project_id,
                              code_projet: projet.code_projet.toUpperCase(),
                              client: projet.client.nom,
                              region: projet.region.nom,
                              status: projet.status.nom,
                              nom: projet.nom.toUpperCase(),
                          }))
                          .filter(
                              (projet) =>
                                  projet.client
                                      .toUpperCase()
                                      .includes(searchProjetState.toUpperCase()) ||
                                  projet.nom
                                      .toUpperCase()
                                      .includes(searchProjetState.toUpperCase()) ||
                                  projet.code_projet
                                      .toUpperCase()
                                      .includes(searchProjetState.toUpperCase()),
                          )
                    : [{ id: '', code_projet: '', client: '', region: '', status: '', title: '' }]
            }
            buttonsAddedContent={() => {
                return (
                    <>
                        <Button
                            variant="contained"
                            onClick={handleToggleSearchBar}
                            sx={{ mr: 1, mt: 1 }}
                        >
                            <SearchIcon sx={{ mr: 1 }} />
                            Rechercher un projet
                        </Button>
                        {displaySearchBarState && (
                            <>
                                <TextField
                                    label="Rechercher un projet"
                                    onChange={handleChangeSearch}
                                    variant="outlined"
                                    size="small"
                                    sx={{ ml: 1 }}
                                />
                                <CancelIcon
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={handleToggleSearchBar}
                                />
                            </>
                        )}
                    </>
                );
            }}
            linkEdit="/admin/update-projet"
        />
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedAdminProjetComponent = connect(
    null,
    mapDispatchToProps,
)(AdminProjetComponent);
