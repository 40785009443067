import {
    Box,
    Button,
    Divider,
    Grid,
    // Future Partie Gamif
    // LinearProgress,
    Paper,
    Typography,
    useTheme,
} from '@mui/material';
import { connect } from 'react-redux';
import { ConnectedFormPhotoAvatar } from '../form/form-photo-avatar';
import DisplayAvatarComponent from '../infos/display-avatar.component';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
// import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { H2Lighter, H2LighterDark } from '../../../theming/customTheme';
import TitleComponent from '../field/title.component';

/**
 * Pas vraiment un panel car je n'utilise pas de Drawer. Il est dans recommandé de le mettre dans un Grid avec un taille définis.
 * Voir AccountComponent pour l'exemple
 */
const UserInfosPanel = (props) => {
    const [editPhotoState, setEditPhotoState] = useState(false);
    const theme = useTheme();
    // Future Partie Gamif
    // const [progressState, setProgressState] = useState(0);

    const toggleEditPhoto = (value = !editPhotoState) => {
        setEditPhotoState(value);
    };

    // Future Partie Gamif
    // useEffect(() => {
    //     setTimeout(() => {
    //         setProgressState(60);
    //     }, 500);
    // }, []);

    return (
        <Paper
            sx={{
                height: { md: '100%' },
                backgroundColor: 'primary.light',
                color: 'primary.contrastText',
                p: 1,
            }}
        >
            <Grid container direction="column" alignItems={'flex-start'}>
                <Grid item sx={{ width: '100%' }}>
                    <Grid container alignItems="flex-end" justifyContent="center">
                        <Grid item>
                            {editPhotoState ? (
                                <ConnectedFormPhotoAvatar
                                    afterForm={() => toggleEditPhoto(false)}
                                />
                            ) : (
                                <DisplayAvatarComponent
                                    user={props.content.utilisateur}
                                    pref={!!props.notCurrent ? null : props.pref}
                                    size="large"
                                />
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {(!!props.editable || !!props.editableAdmin) &&
                                (editPhotoState ? (
                                    <Button onClick={() => toggleEditPhoto(false)}>
                                        <CloseIcon color="secondary" />
                                    </Button>
                                ) : (
                                    <Button onClick={() => toggleEditPhoto(true)}>
                                        <EditIcon color="secondary" />
                                    </Button>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ width: '100%', mt: 2 }}>
                    <Divider />
                </Grid>
                <Grid item sx={{ mt: 2, width: '100%' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        {theme.palette.mode === 'light' ? (
                            <H2Lighter>Informations personnelles</H2Lighter>
                        ) : (
                            <H2LighterDark>Informations personnelles</H2LighterDark>
                        )}
                    </Box>
                    <Box>
                        <Typography
                            variant="span"
                            color="primary.dark"
                            sx={{
                                fontWeight: 'bold',
                                mr: 1,
                            }}
                        >
                            Nom et Prénom:
                        </Typography>
                        {props.content.utilisateur.nom.toUpperCase() +
                            ' ' +
                            props.content.utilisateur.prenom.charAt(0).toUpperCase() +
                            props.content.utilisateur.prenom.slice(1)}
                    </Box>
                    <Box>
                        <Typography
                            variant="span"
                            color="primary.dark"
                            sx={{
                                fontWeight: 'bold',
                                mr: 1,
                            }}
                        >
                            Email:
                        </Typography>
                        {props.content.utilisateur.email}
                    </Box>
                    {!!props.editable && (
                        <Button
                            sx={{ mt: 1 }}
                            variant="outlined"
                            component={Link}
                            color="secondary"
                            target="_blank"
                            to={'https://ipa.neodt.local'}
                        >
                            <EditIcon /> Modifier le mot de passe
                        </Button>
                    )}

                    <Box sx={{ textAlign: 'center' }}>
                        {theme.palette.mode === 'light' ? (
                            <H2Lighter>Informations professionnelles</H2Lighter>
                        ) : (
                            <H2LighterDark>Informations professionnelles</H2LighterDark>
                        )}
                    </Box>
                    <TitleComponent
                        addedSx={theme.palette.mode !== 'light' && { color: 'primary.main' }}
                    >
                        Manager attitré
                    </TitleComponent>
                    {!!props.content.manager ? (
                        <>
                            <Box sx={{ mt: 1 }}>
                                <Typography
                                    variant="span"
                                    color="primary.dark"
                                    sx={{
                                        fontWeight: 'bold',
                                        mr: 1,
                                    }}
                                >
                                    Nom et Prénom:
                                </Typography>

                                {props.content.manager.nom.toUpperCase() +
                                    ' ' +
                                    props.content.manager.prenom.charAt(0).toUpperCase() +
                                    props.content.manager.prenom.slice(1)}
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Typography
                                    variant="span"
                                    color="primary.dark"
                                    sx={{
                                        fontWeight: 'bold',
                                        mr: 1,
                                    }}
                                >
                                    Email:
                                </Typography>
                                {props.content.manager.email}
                            </Box>
                        </>
                    ) : (
                        <p>Aucun Manager trouvé</p>
                    )}
                    <TitleComponent
                        addedSx={theme.palette.mode !== 'light' && { color: 'primary.main' }}
                    >
                        Business Unit et poste
                    </TitleComponent>
                    {!!props.content.businessUnit ? (
                        <>
                            <Box sx={{ mt: 1 }}>
                                <Typography
                                    variant="span"
                                    color="primary.dark"
                                    sx={{
                                        fontWeight: 'bold',
                                        mr: 1,
                                    }}
                                >
                                    BU:
                                </Typography>
                                {props.content.businessUnit.nom}
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Typography
                                    variant="span"
                                    color="primary.dark"
                                    sx={{
                                        fontWeight: 'bold',
                                        mr: 1,
                                    }}
                                >
                                    Poste:
                                </Typography>
                                {props.content.fonction.nom}
                            </Box>
                        </>
                    ) : (
                        <i>Aucun Manager trouvé</i>
                    )}
                    <TitleComponent
                        addedSx={theme.palette.mode !== 'light' && { color: 'primary.main' }}
                    >
                        Entité affiliée
                    </TitleComponent>
                    <p>{props.content.entity.nom}</p>
                </Grid>
                {/* Future Partie Gamif */}
                {/* <Grid item sx={{ mt: -5, width: '100%' }}>
                    <Box sx={{ textAlign: 'right' }}>
                        <Typography variant="span">niv.</Typography>
                        <Typography variant="span" sx={{ fontSize: '60px', color: 'primary.dark' }}>
                            5
                        </Typography>
                    </Box>
                    <LinearProgress variant="determinate" color="success" value={progressState} />
                    <Typography sx={{ fontWeight: 'light' }}>600/1000 xp</Typography>
                </Grid> */}
                {!!props.editableAdmin && (
                    <Grid item>
                        <Button
                            variant="contained"
                            component={Link}
                            to={'/admin/update-user'}
                            state={{ objectId: props.content.utilisateur.email }}
                        >
                            <EditIcon sx={{ mr: 1 }} />
                            Modifier
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        pref: state.prefReducer,
    };
}

export const ConnectedUserInfosPanel = connect(mapStateToProps, null)(UserInfosPanel);
