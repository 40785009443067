import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { useState } from 'react';

const CriticiteFieldComponent = (props) => {
    const [isCheckedState, setIsCheckedState] = useState(props.content.isChecked || false);

    const onChangeCriticiteCheckbox = (e) => {
        setIsCheckedState(e.target.checked);
        props.handleCheckCriticite(props.content);
    };

    return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={3} sx={{ textAlign: 'end' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name={props.content.nom}
                            id={props.content.nom}
                            checked={isCheckedState}
                            onChange={(e) => onChangeCriticiteCheckbox(e)}
                        />
                    }
                    labelPlacement="start"
                    label={props.content.nom}
                />
            </Grid>
            {isCheckedState && (
                <Grid item xs={12} lg={9}>
                    <TextField
                        name="criticite-nb-ticket"
                        id="criticite-nb-ticket"
                        type="number"
                        size="small"
                        InputProps={{ inputProps: { min: 1 } }}
                        defaultValue={
                            props.content.projet_contrat_support_criticite !== undefined
                                ? props.content.projet_contrat_support_criticite.nombre_tickets
                                : 1
                        }
                        required
                        label="Nombre de tickets associé"
                        onChange={(e) =>
                            props.handleUpdateCriticiteNbTickets(
                                props.content.criticality_id,
                                e.target.value,
                            )
                        }
                        fullWidth
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default CriticiteFieldComponent;
