import * as types from '../constants';

// permet de mettre à jour le header sur chaque page
export const setPageTitle = (title) => ({
    type: types.SET_PAGE_TITLE,
    pageTitle: title,
});
export const clearPageTitle = () => ({
    type: types.CLEAR_PAGE_TITLE,
    pageTitle: 'Bienvenue sur NeoCRA',
});
export const toggleMenu = () => ({
    type: types.TOGGLE_LEFT_MENU,
});
