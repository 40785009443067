import React, { useState, useEffect } from 'react';
import userService from '../../../services/user.service';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';
import { ConnectedUserInfosPanel } from '../../assets/panels/user-infos-panel.component';

const ManagerUserComponent = (props) => {
    const location = useLocation();

    const [user, setUser] = useState('');
    const [error, setError] = useState('');

    const handleRefreshDatas = (user_email) => {
        userService
            .getUserInfos(user_email)
            .then((userF) => {
                setUser(userF);
            })
            .catch(() => {
                setError({ error: "Impossible de récupérer l'utilisateur" });
            });
    };

    useEffect(() => {
        props.onLoad('Manager - Détails utilisateur');

        // on récupère les infos de l'utilisateur via les données transmises dans le lien
        handleRefreshDatas(location.state.user_email);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {error !== '' ? (
                <CenteredPaper>
                    <CancelIcon sx={{ fontSize: 100 }} color="error" />
                    {error}
                </CenteredPaper>
            ) : user !== '' ? (
                <CenteredPaper withMargin={false} withPadding={false}>
                    <ConnectedUserInfosPanel content={user} notCurrent />
                </CenteredPaper>
            ) : (
                <WaitingScreenComponent />
            )}
            <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button
                    component={Link}
                    to={'/planning-manager'}
                    state={{ manager: user.utilisateur }}
                    variant="contained"
                >
                    Planning
                </Button>
            </Box>
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedManagerUserComponent = connect(
    null,
    mapDispatchToProps,
)(ManagerUserComponent);
