import { Grid, MenuItem, Typography } from '@mui/material';
import DisplayAvatarComponent from '../infos/display-avatar.component';

/**
 * MenuItem à afficher UNIQUEMENT dans des AutoComplete (ou List).
 * Si on veut pouvoir utiliser ce MenuItem dans un Select (ou TextField select), il est nécessaire de faire la migration vers un AutoComplete
 * C'est pas plus mal d'ailleurs, les autocompletes ont plus d'options et sont plus modulables.
 * @param autoCompleteProps Propriétés de l'autocomplete (récupérées dans les arguments de la fonction callback de renderOption)
 * @param value Valeur de l'option
 * @param userObject Objet utilisateur (au minimum : mail, nom, prenom)
 * @param withImage Booléen, par défaut à true, pour afficher ou non la photo de profil de l'utilisateur
 */
const MenuItemUserComponent = ({
    autoCompleteProps,
    value,
    userObject,
    withImage = true,
    clickFunction = null,
}) => {
    const clickProperties = clickFunction !== null ? { onClick: clickFunction } : null;

    return (
        <MenuItem
            {...autoCompleteProps}
            sx={{ whiteSpace: 'normal' }}
            value={value}
            {...clickProperties}
        >
            <Grid container spacing={1} alignItems={'center'}>
                {withImage && (
                    <Grid item md={1} xs={2}>
                        <DisplayAvatarComponent user={userObject} loading="lazy" />
                    </Grid>
                )}
                <Grid item xs={withImage ? 10 : 12} md={withImage ? 11 : 12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>
                                <b>Nom : </b>
                                {userObject.nom.toUpperCase()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <b>Prénom : </b>
                                {userObject.prenom.charAt(0).toUpperCase() +
                                    userObject.prenom.slice(1)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MenuItem>
    );
};

export default MenuItemUserComponent;
