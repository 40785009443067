import { Drawer } from '@mui/material';

const RightPanelComponent = (props) => {
    return (
        <Drawer
            anchor="right"
            variant="temporary"
            open={props.rightPanelDisplayState}
            onClose={props.closeRightPanel}
            PaperProps={{ elevation: 1 }}
            sx={{
                '& .MuiDrawer-paper': {
                    m: 1,
                    boxSizing: 'border-box',
                    width: {
                        xs: '96%',
                        sm: '80%',
                        md: '50%',
                        lg: '25%',
                    },
                    height: 'calc(100% - 10px)',
                },
            }}
        >
            {props.panelContentState}
        </Drawer>
    );
};

export default RightPanelComponent;
