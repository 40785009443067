import axiosConfig from './axiosConfig';

const API_URL = '/authorization/contact/';

class ContactService {
    getAllContacts() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getContactsByClient(client_id) {
        return axiosConfig
            .get(`${API_URL}client/${client_id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /**
     * Créer un contact
     * @param {String} nom - Nom du contact
     * @param {String} prenom - Prenom du contact
     * @param {String} telephone - telephone du contact
     * @param {String} email - email du contact
     * @param {String} client - client du contact
     */
    create(nom, prenom, telephone, email, client) {
        return axiosConfig
            .post(API_URL, {
                nom,
                prenom,
                telephone,
                email,
                fk_client: client,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }
}

export default new ContactService();
