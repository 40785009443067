import axiosConfig from './axiosConfig';

const API_URL = '/authorization/region/';

class RegionService {
    getAllRegions() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    /** Toutes les régions archivées */
    getAllArchivedRegions() {
        return axiosConfig
            .get(API_URL + 'archived/all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    /**
     * Requête une region
     * @param {Number} region_id - id de region en param
     */
    getRegionById(region_id) {
        return axiosConfig
            .get(API_URL + region_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    deleteRegionById(region_id) {
        return axiosConfig
            .delete(API_URL + region_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    updateRegionById(region_id, nom) {
        return axiosConfig
            .put(API_URL, { region_id, nom })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    unarchiveRegionById(region_id) {
        return axiosConfig
            .put(API_URL + 'unarchive/' + region_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /**
     * Créer une region avec l'entité du token
     * @param {String} nom - Nom de la region
     */
    create(nom) {
        return axiosConfig
            .post(API_URL, {
                nom,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
}

export default new RegionService();
