import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setPageTitle } from '../../../../redux/actions/scopeAction';
import category_newsService from '../../../../services/category_news.service';
import ResultFormComponent from '../../../assets/container/resultForm.component';
import WaitingScreenComponent from '../../../assets/waiting-screen.component';
import FormCategoryNewsComponent from './form-category-news.component';

const UpdateCategoryNewsComponent = (props) => {
    const location = useLocation();
    const categoryId = location.state.objectId;

    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const [categoryState, setCategoryState] = useState(null);

    useEffect(() => {
        props.onLoad('Administrateur - Modifier une news');

        const laCategorie = category_newsService.get(categoryId);
        Promise.all([laCategorie]).then(([laCategorie]) => {
            if (laCategorie.error) {
                toast.error(laCategorie.error);
            } else {
                setCategoryState(laCategorie);
            }
        });
    }, []);

    const handleUpdateCategory = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        category_newsService
            .update({
                category_news_id: categoryState.category_news_id,
                name: dataForm.inputName,
                color: dataForm.inputColor,
            })
            .then((res) => {
                if (res.error) {
                    setLoadingState(false);
                    setSuccessState(false);
                    setMessageState(res.error);
                } else {
                    setLoadingState(false);
                    setSuccessState(true);
                    setMessageState(res.message);
                }
            });
    };

    const handleDeleteCategory = () => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        category_newsService.delete(categoryState.category_news_id).then((res) => {
            if (res.error) {
                setLoadingState(false);
                setSuccessState(false);
                setMessageState(res.error);
            } else {
                setLoadingState(false);
                setSuccessState(true);
                setMessageState(res.message);
            }
        });
    };

    return (
        <>
            {categoryState !== null ? (
                <ResultFormComponent
                    loading={loadingState}
                    success={successState}
                    submitted={submittedState}
                    message={messageState}
                    link={'/admin/list-news'}
                >
                    <FormCategoryNewsComponent
                        handleForm={handleUpdateCategory}
                        handleDelete={handleDeleteCategory}
                        isUpdate
                        name={categoryState.name}
                        color={categoryState.color}
                    />
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateCategoryComponent = connect(
    null,
    mapDispatchToProps,
)(UpdateCategoryNewsComponent);
