import '../portal.style.scss';
import PortalButtonComponent from '../../assets/portal-button.component';

import ContactPageIcon from '@mui/icons-material/ContactPage';
import LanguageIcon from '@mui/icons-material/Language';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PaletteIcon from '@mui/icons-material/Palette';
import PanToolIcon from '@mui/icons-material/PanTool';
import { Box, Grid } from '@mui/material';

const AdminPortalEntityComponent = (props) => {
    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={2} columns={15} justifyContent="center" sx={{ mt: 2 }}>
                <PortalButtonComponent
                    title="Clients"
                    size="pin"
                    icon={ContactPageIcon}
                    color="primary.main"
                    link="admin/clients"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Régions"
                    size="pin"
                    icon={LanguageIcon}
                    color="secondary.main"
                    link="admin/regions"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Business-Units"
                    size="pin"
                    icon={AccountTreeIcon}
                    link="admin/business-units"
                    color="error.main"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Légende planning"
                    size="pin"
                    icon={PaletteIcon}
                    color="secondary.light"
                    link="admin/legend"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Fonctions"
                    size="pin"
                    icon={PanToolIcon}
                    color="success.main"
                    link="admin/fonctions"
                    role={props.role}
                />
            </Grid>
        </Box>
    );
};

export default AdminPortalEntityComponent;
