import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import WaitingScreenComponent from '../waiting-screen.component';

const WeatherComponent = () => {
    const [weatherState, setWeatherState] = useState(null);

    const handleWeatherInfo = () => {
        fetch(
            'https://api.openweathermap.org/data/2.5/weather?id=6543862&lang=fr&units=metric&appid=c823c577ca8ea3c71674d2c8cf026a79',
        ).then((data) => {
            data.json().then((data2) => {
                setWeatherState(data2);
            });
        });
    };

    useEffect(() => {
        handleWeatherInfo();
    }, []);

    return weatherState !== null ? (
        <Grid container alignItems={'center'} justifyContent={'flex-start'}>
            <Grid item>
                <img
                    src={`https://openweathermap.org/img/wn/${weatherState.weather[0].icon}.png`}
                    alt="meteo"
                    style={{
                        width: '80px',
                    }}
                />
            </Grid>
            <Grid item>
                <Typography>{Math.round(weatherState.main.temp * 10) / 10} °C</Typography>
            </Grid>
        </Grid>
    ) : (
        <WaitingScreenComponent />
    );
};

export default WeatherComponent;
