import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';

const OfflineWarningComponent = () => {
    const [isOnlineState, setIsOnlineState] = useState(true);

    // https://stackoverflow.com/questions/44756154/progressive-web-app-how-to-detect-and-handle-when-connection-is-up-again
    useEffect(() => {
        const setFromEvent = function (event) {
            setIsOnlineState(event.type === 'online');
        };

        window.addEventListener('online', setFromEvent);
        window.addEventListener('offline', setFromEvent);

        return () => {
            window.removeEventListener('online', setFromEvent);
            window.removeEventListener('offline', setFromEvent);
        };
    }, []);

    return !isOnlineState ? (
        <Alert severity="warning">
            Vous n'êtes plus connecté à internet. Certaines données pourraient ne pas être à jour et
            l'accès à l'application limité.
        </Alert>
    ) : null;
};

export default OfflineWarningComponent;
