import { Alert, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const ErrorWarningComponent = ({ children, redirectTo, clickFunction }) => {
    return (
        <Alert
            severity="error"
            action={
                !!redirectTo ? (
                    <Button sx={{ mt: 1 }} component={Link} to={redirectTo.link} size="small">
                        {redirectTo.text || 'Retour'}
                    </Button>
                ) : (
                    !!clickFunction && (
                        <Button onClick={clickFunction.function || clickFunction} size="small">
                            {clickFunction.text || 'Fermer'}
                        </Button>
                    )
                )
            }
        >
            {children}
        </Alert>
    );
};

export default ErrorWarningComponent;
