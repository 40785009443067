import { Grid, List, MenuItem, Pagination, Paper, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import category_fichiersService from '../../services/category_fichiers.service';
import fichiersService from '../../services/fichiers.service';
import FichiersComponent from './fichiers.component';

const PublicFichiersComponent = () => {
    const NBR_FICHIERS = 6;
    const MAX_PAGE_DEFAULT = 5;
    const PAGE_DEFAULT = 1;

    const [fichiersState, setFichiersState] = useState([]);
    const [categoriesState, setCategoriesState] = useState([]);
    const [categoryState, setCategoryState] = useState(0);
    const [pageState, setPageState] = useState(PAGE_DEFAULT);
    const [maxFichiersPageState, setMaxFichiersPageState] = useState(MAX_PAGE_DEFAULT);

    const handleFetchFichiers = async () => {
        const resultAllFichiers = await fichiersService.getFichiersFromPage({
            page: pageState,
            nbrElement: NBR_FICHIERS,
            category: categoryState,
        });

        const resultWithFichierFile = await Promise.all(
            resultAllFichiers.map(async (fichier) => {
                const fichierFileRes = await fichiersService.getFichierFile(fichier.fichier);
                if (fichierFileRes.error) {
                    toast.error(fichierFileRes.error);
                } else {
                    const filename = fichier.fichier;
                    return { ...fichier, fichier: fichierFileRes, filename: filename };
                }
            }),
        );

        setFichiersState(resultWithFichierFile);
    };

    const handleChangePage = (_, page) => {
        setPageState((prev) => {
            if (prev === page) {
                handleFetchFichiers();
                return page;
            } else {
                return page;
            }
        });
    };

    const handleMaxPageFichier = () => {
        fichiersService
            .getMaxPageFichiers({ nbrElement: NBR_FICHIERS, category: categoryState })
            .then((res) => {
                if (res.error) {
                    toast.error(res.error);
                } else {
                    setMaxFichiersPageState(res.nbrPage);
                    handleChangePage('', 1);
                }
            });
    };

    const handleFetchCategories = () => {
        category_fichiersService.getAll().then((categories) => {
            if (categories.error) {
                toast.error(categories.error);
            } else {
                setCategoriesState(categories);
            }
        });
    };

    useEffect(() => {
        handleFetchFichiers();
    }, [pageState]);

    useEffect(() => {
        handleMaxPageFichier();
        handleFetchCategories();
    }, [categoryState]);

    return (
        <>
            <Stack>
                <Pagination
                    count={maxFichiersPageState}
                    page={pageState}
                    onChange={handleChangePage}
                    color="primary"
                    size="small"
                />
            </Stack>
            <Grid container>
                <TextField
                    select
                    fullWidth
                    value={categoryState}
                    size="small"
                    label="Sélectionner une catégorie"
                    sx={{ mt: 2 }}
                    onChange={(event) => {
                        setCategoryState(event.target.value);
                    }}
                >
                    <MenuItem value={0}>Sélectionner une catégorie</MenuItem>
                    {categoriesState.map((category) => (
                        <MenuItem
                            value={category.category_fichiers_id}
                            key={'menu_item_category_file_' + category.category_fichiers_id}
                        >
                            {category.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <List
                sx={{ width: '100', bgcolor: 'background.paper', mt: 1 }}
                component={Paper}
                elevation={4}
            >
                {fichiersState.map((fichier, index) => (
                    <FichiersComponent key={'fichier_' + index} fichier={fichier} />
                ))}
            </List>
        </>
    );
};

export default PublicFichiersComponent;
