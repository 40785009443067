import axiosConfig from './axiosConfig';

const API_URL = '/authorization/gestion_admin/';

class GestionAdminService {
    sendNotification() {
        return axiosConfig
            .post(API_URL + 'sendNotif')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
}

export default new GestionAdminService();
