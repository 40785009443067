import UpdateActivityComponent from '../activity/update-activity.component';
import UpdateCongeComponent from '../conge/update-conge.component';
import { displayStatusIcon, activityColor, displayCongeStatus } from '../../helpers/conge.helper';
import { Grid, Paper } from '@mui/material';

const DetailsPinComponent = (props) => {
    const getRightUpdateComponent = (
        isConge,
        jour,
        activity,
        openRightPanel,
        closeRightPanel,
        rerenderParentCallback,
    ) => {
        if (isConge) {
            return (
                <UpdateCongeComponent
                    jour={jour}
                    activity={activity}
                    activities={props.activities}
                    openRightPanel={openRightPanel}
                    closeRightPanel={closeRightPanel}
                    rerenderParentCallback={rerenderParentCallback}
                    monthCalendar={props.monthCalendar}
                    employe={props.employe}
                />
            );
        }
        return (
            <UpdateActivityComponent
                jour={jour}
                activity={activity}
                activities={props.activities}
                openRightPanel={openRightPanel}
                closeRightPanel={props.closeRightPanel}
                rerenderParentCallback={props.rerenderParentCallback}
                monthCalendar={props.monthCalendar}
                employe={props.employe}
            />
        );
    };
    const activity = props.activityItem.activity;
    const isConge = activity.projet.fk_activity_type === 0;
    let congeStatusLitteral = [];
    if (isConge) {
        congeStatusLitteral = displayCongeStatus(activity);
    }
    let icon = displayStatusIcon(activity);

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: activity.projet.couleur + '33',
                p: 1,
                mt: 1,
                borderLeftStyle: 'solid',
                borderLeftColor: activityColor(activity),
                ':hover': {
                    boxShadow: 2, // theme.shadows[20]
                    cursor: 'pointer',
                },
            }}
            onClick={(e) =>
                props.openRightPanel(
                    e,
                    getRightUpdateComponent(
                        isConge,
                        props.dayItem,
                        props.activityItem.activity,
                        props.openRightPanel,
                        props.closeRightPanel,
                        props.rerenderParentCallback,
                    ),
                )
            }
        >
            <Grid container spacing={1}>
                <Grid item>
                    <Grid container direction="row" alignItems="flex-start" spacing={1}>
                        <Grid item>{icon}</Grid>
                        <Grid item>
                            <b>{activity.valeur}</b>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {activity.projet.fk_activity_type !== 0 ? (
                        // Dans le cas ou le type de projet est différent de 0 (congé payé)
                        <span className="flex-container column-container">
                            <span className="title-container">
                                <span>Code projet : </span> {activity.projet.code_projet}
                            </span>
                            <span className="title-container">
                                <span>Nom du projet :</span> {activity.projet.nom}
                            </span>
                        </span>
                    ) : (
                        // Dans le cas où ce sont des congés payés
                        <span className="flex-container column-container">
                            <span className="title-container">
                                <span>Type de congé : {activity.type_conge.nom}</span>
                            </span>
                            <span className="title-container">
                                <span>Status : {congeStatusLitteral}</span>
                            </span>
                        </span>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DetailsPinComponent;
