// import axios from 'axios';
import { store } from '../index';
import { logout } from '../redux/actions/authAction';
import { clearPageTitle } from '../redux/actions/scopeAction';
import authHeader from './auth-header';
import NeoFetch from '../helpers/NeoFetch.helper';
import { toast } from 'react-toastify';
import graviteeConfig from '../components/login/gravitee.config';
import { refreshAccess } from 'react-gravitee-connexion';
import { redirect } from 'react-router';

const env = process.env.REACT_APP_ENV || 'development'; // current environment
// on détermine via le fichier .env si on est en prod et l'url à taper pour l'API
const BASE_URL = graviteeConfig[env].GATEWAY_URL;
// env === 'production'
//     ? 'https://api.neodt.fr/neocra' // production
//     : env === 'recette'
//     ? 'https://preprod-gateway.neodt.fr/api/neocra-preprod' // recette
//     : 'http://172.16.70.3:8082/neocra/'; // development

const instance = new NeoFetch({ uri: BASE_URL });
let renewTokenFunction = null;

instance.addRequestInterceptor((url, options) => {
    options.headers = { ...options.headers, ...authHeader() };

    return options;
});

instance.addRequestInterceptor(async (url, options) => {
    const timestampToken = localStorage.getItem('expiresIn');

    // Test d'expiration du token dans moins de 10min (600 sec)
    if ((timestampToken - new Date().getTime()) / 1000 < 600) {
        try {
            // Si renewTokenFunction est null, on lui donne la valeur égale à la fonction refreshToken de graviteeConnection
            if (!renewTokenFunction) {
                renewTokenFunction = refreshAccess(
                    localStorage.getItem('refreshToken'),
                    graviteeConfig,
                    env,
                );
            }
            // Ensuite on attend la réponse de renewTokenFunction, ce qui va bloquer tout les appels API même s'ils sont en parallèle
            const responseToken = await renewTokenFunction;

            localStorage.setItem('accessToken', responseToken.access_token);
            localStorage.setItem('refreshToken', responseToken.refresh_token);
            localStorage.setItem(
                'expiresIn',
                new Date().getTime() + responseToken.expires_in * 1000,
            );

            options.headers = { ...options.headers, ...authHeader() };

            return options;
        } catch (err) {
            if (store.getState().authReducer.isLoggedIn) {
                store.dispatch(logout());
                store.dispatch(clearPageTitle());
            }
            return Promise.reject(err);
        } finally {
            renewTokenFunction = null;
        }
    } else {
        return options;
    }
});

instance.setResponseInterceptor(({ response, data }) => {
    if (!response.ok) {
        if (response.status === 401) {
            // on vérifie si l'utilisateur est loggé pour le logout
            if (store.getState().authReducer.isLoggedIn) {
                store.dispatch(logout());
                store.dispatch(clearPageTitle());
            }
            // on retourne l'erreur en signalant l'échec de la Promise
            return Promise.reject({
                code: 401,
                message: data.message || response.message,
            });
        } else if (response.status === 403) {
            // Code non autorisé à accéder à la ressource
            toast.error(data.message || response.message);

            return redirect('/');
        } else if (response.error) {
            toast.error('Aucune réponse du serveur');
            return Promise.reject({ message: 'Aucune réponse du serveur.' });
        } else {
            return Promise.reject({ message: data.message || response.message });
        }
    } else {
        return Promise.resolve({ response, data });
    }
});

export default instance;
