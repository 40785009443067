import axiosConfig from './axiosConfig';

const API_URL = '/authorization/criticite/';

/**
 * Classe service de criticite, regroupe les appels API via Axios.
 */
class CriticiteService {
    /** Retourne tous les criticite */
    getAllCriticite() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /**
     * Requête un criticite
     * @param {Number} criticite - id du criticite en param
     */
    getCriticite(criticite) {
        return axiosConfig
            .get(API_URL + criticite)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }

    /**
     * Retourne les criticités non présentes
     * dans le tableau en param
     * @param {Array} criticiteArray
     */
    getOtherCriticite(criticiteArray) {
        return axiosConfig
            .post(API_URL + 'others', { criticiteArray })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }

    updateCriticite(criticite, nom, est_illimite) {
        est_illimite = est_illimite ? 1 : 0;
        return axiosConfig
            .put(API_URL, { criticite, nom, est_illimite })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }

    /**
     * Créer un criticite avec l'entité du token
     * @param {String} nom - Nom du criticite
     * @param {String} ville - Nom de la ville
     * @param {String || Null} commentaire - Commentaire peut etre nul
     * @param {Number} region - FK de region
     */
    create(nom, est_illimite) {
        est_illimite = est_illimite ? 1 : 0;
        return axiosConfig
            .post(API_URL, {
                nom,
                est_illimite,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: ` ${error.message}` };
            });
    }
}

export default new CriticiteService();
