import axiosConfig from './axiosConfig';

const API_URL = '/authorization/ri/';

class RIService {
    /**
     * Génère le rapport d'intervention format PDF via Project id, date_debut et date_fin
     * @param {Number} project_id - ID du projet à requêter
     * @param {Number} user_id - ID de l'utilisateur à requêter (null si pas d'utilisateur, -1 si tout les utilisateurs)
     * @param {Date} date_debut - date de début de recherche
     * @param {Date} date_fin - date de fin de recherche
     * @returns Promise API ou PDF
     */
    getRapportIntervention(project_id, tab_user_id, date_debut, date_fin) {
        return axiosConfig
            .get(`${API_URL}${project_id}`, {
                params: { tab_user_id, date_debut, date_fin },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
}

export default new RIService();
