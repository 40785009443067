import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import newsService from '../../../services/news.service';
import ResultFormComponent from '../../assets/container/resultForm.component';
import FormNewsComponent from './form-news.component';

const CreateNewsComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Créer une news');
    }, []);

    const handleCreateNews = (dataForm) => {
        setLoadingState(true);
        setSubmittedState(true);
        setSuccessState(false);
        // Enregistrement du fichier en tant qu'objet FormData.
        const formData = new FormData();
        formData.append('file', dataForm.inputImage);

        // Envoie du fichier au service uploadFile.
        newsService.uploadFile(formData).then((res) => {
            // Si echec de l'enregistrement, on arrête tout.
            if (res.error) {
                setLoadingState(false);
                setSuccessState(false);
                setMessageState(res.error);
            } else {
                // Si réussite d'enregistrement, on insère la news en base.
                newsService
                    .insertNews({
                        title: dataForm.inputTitle,
                        imageTitle: res.fichier.filename,
                        content: dataForm.inputContent,
                        fk_category: dataForm.inputCategory.value,
                        check_entity: !!dataForm.checkEntity,
                        is_pinned: !!dataForm.checkPin,
                    })
                    .then((res) => {
                        if (res.error) {
                            setLoadingState(false);
                            setSuccessState(false);
                            setMessageState(res.error);
                        } else {
                            setLoadingState(false);
                            setSuccessState(true);
                            setMessageState(res.message);
                        }
                    });
            }
        });
    };

    return (
        <ResultFormComponent
            loading={loadingState}
            success={successState}
            submitted={submittedState}
            message={messageState}
            link={'/admin/list-news'}
        >
            <FormNewsComponent handleForm={handleCreateNews} />
        </ResultFormComponent>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateNewsComponent = connect(null, mapDispatchToProps)(CreateNewsComponent);
