import WidgetCalendarComponent from '../components/assets/widgets/widget-calendar.component';
import WidgetProjetComponent from '../components/assets/widgets/widget-projet.component';
// import WidgetStatProjetComponent from '../components/assets/widgets/widget-stat-projet.component';
import WidgetCongesComponent from '../components/assets/widgets/widget-conges.component';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const widgetList = {
    'widget-projet': {
        title: 'Mes derniers projets',
        description: 'Liste des derniers projets saisis',
        icon: <AssignmentTurnedInIcon />,
        widget: WidgetProjetComponent,
    },
    'widget-calendar': {
        title: 'Calendrier',
        description: 'Affiche le calendrier du mois avec la charge totale des activités par jour',
        icon: <CalendarMonthIcon />,
        widget: WidgetCalendarComponent,
    },
    'widget-conges': {
        title: 'Congés',
        description: 'Affiche les congés à venir.',
        icon: <BeachAccessIcon />,
        widget: WidgetCongesComponent,
    },
};
export default widgetList;
