import { useEffect, useState } from 'react';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import fonctionService from '../../../services/fonction.service';

import { useLocation } from 'react-router-dom';

import ResultFormComponent from '../../assets/container/resultForm.component';
import FormFonctionComponent from './form-fonction.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';

const UpdateFonctionComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const [nomState, setNomState] = useState('');
    const location = useLocation();
    const fonction_id = location.state.objectId;

    useEffect(() => {
        props.onLoad('Administrateur - Modifier la fonction');

        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const fonction = fonctionService.getFonctionById(fonction_id);
        // on attend le réponse de toutes les requêtes et on les attribues au state local
        Promise.all([fonction]).then(([fonction]) => {
            let fonctionData = fonction.fonction;
            setNomState(fonctionData.nom);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateFonction = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        fonctionService
            .updateFonctionById({ fonction_id: fonction_id, nom: dataForm.inputName })
            .then((response) => {
                if (!response.error) {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                } else {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(response.error);
                }
            });
    };
    const handleDeleteFonction = () => {
        if (
            window.confirm(
                "Voulez-vous vraiment supprimer la fonction ? Cela risque d'impacter des utilisateurs ou projets liés à cette fonction.",
            )
        ) {
            setLoadingState(true);
            setSuccessState(false);
            setSubmittedState(true);

            fonctionService
                .deleteFonctionById(fonction_id)
                .then((res) => {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(res.message);
                })
                .catch((err) => {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(err.response.message || err.message || err.response.message);
                });
        }
    };

    return (
        <>
            {nomState ? (
                <ResultFormComponent
                    loading={loadingState}
                    success={successState}
                    submitted={submittedState}
                    message={messageState}
                    link={'/admin/fonctions'}
                >
                    <FormFonctionComponent
                        handleForm={handleUpdateFonction}
                        handleDelete={handleDeleteFonction}
                        inputName={nomState}
                        isUpdate={true}
                    />
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateFonctionComponent = connect(
    null,
    mapDispatchToProps,
)(UpdateFonctionComponent);
