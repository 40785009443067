import MenuRowComponent from './menu-row.component';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import './menu.style.scss';

const MenuComponent = (props) => {
    return (
        <>
            {props.allRoles !== null && (
                <>
                    <MenuRowComponent
                        title="Mon Espace"
                        icon={<MeetingRoomIcon />}
                        link="home"
                        role={'employe'}
                    />
                    <MenuRowComponent
                        title="Mes temps"
                        icon={<AccessTimeFilledIcon />}
                        link="my-times"
                        role={'employe'}
                    />
                    <MenuRowComponent
                        title="Mon R.I."
                        icon={<DescriptionIcon />}
                        link="reporting"
                        role={'employe'}
                    />
                    <MenuRowComponent
                        title="Mes congés"
                        icon={<BeachAccessIcon />}
                        link="conges"
                        role={'employe'}
                    />
                    <MenuRowComponent
                        title="Manager"
                        icon={<GroupIcon />}
                        link="portal-manager"
                        role={'commerce'}
                    />
                    <MenuRowComponent
                        title="Administration"
                        icon={<ManageAccountsIcon />}
                        link="portal-admin"
                        role={'admin'}
                    />
                </>
            )}
        </>
    );
};

export default MenuComponent;
