import { Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import soldesService from '../../../services/soldes.service';

import CrudPageComponent from '../page/crud-page.component';

/**
 * Affiche les logs du solde de l'utilisateur. Fonctionne avec un objet formaté de cette manière : {utilisateur: {nom, prenom}, soldes_utilisateurs_id}
 * @param {object} content Contenu possédant entre autre le soldes_utilisateurs_id, ainsi que l'objet de l'utilisateur
 */
const ListLogSoldesComponent = ({ content }) => {
    const [contentState, setContentState] = useState([]);

    const handleFetchLogs = () => {
        soldesService
            .getAllLogsForSoldesUtilisateurs(content.soldes_utilisateurs_id)
            .then((response) => {
                if (response.error) {
                    toast.error(response.error);
                } else {
                    setContentState(response);
                }
            });
    };

    useEffect(() => {
        handleFetchLogs();
    }, []);

    return contentState.length > 0 ? (
        <>
            <CrudPageComponent
                objectName="loggerSolde"
                title={
                    'Liste des logs pour le solde de ' +
                    content.utilisateur.nom.toUpperCase() +
                    ' ' +
                    content.utilisateur.prenom.charAt(0).toUpperCase() +
                    content.utilisateur.prenom.slice(1)
                }
                displayType={true}
                headers={[
                    'Date',
                    'Éditeur',
                    'Type',
                    'Commentaire',
                    'Date de début',
                    'Date de fin',
                    'Opération du solde',
                    'Solde à date',
                ]}
                dataContent={
                    !!contentState
                        ? contentState.map((content) => ({
                              loggerSolde_id: content.logger_id,
                              date: new Date(content.createdAt).toLocaleDateString(),
                              editor:
                                  content.utilisateur.nom.toUpperCase() +
                                  ' ' +
                                  content.utilisateur.prenom.charAt(0).toUpperCase() +
                                  content.utilisateur.prenom.slice(1),
                              type: 'Opération ' + content.type,
                              commentaire: content.commentaire,
                              dateDebut: content.date_debut
                                  ? new Date(content.date_debut).toLocaleDateString()
                                  : '',
                              dateFin: content.date_fin
                                  ? new Date(content.date_fin).toLocaleDateString()
                                  : '',
                              value: (content.nb_jour > 0 ? '+' : '') + content.nb_jour,
                              soldeADate: content.solde_a_date,
                          }))
                        : [{}]
                }
            />
        </>
    ) : (
        <Typography>Rien à afficher ici.</Typography>
    );
};

export default ListLogSoldesComponent;
