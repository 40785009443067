import { useEffect } from 'react';
import { useState } from 'react';
import criticiteService from '../../../services/criticite.service';
import CriticiteFieldComponent from './criticite-field.component';

const CriticiteModuleComponent = (props) => {
    const [availableCriticiteState, setAvailableCriticiteState] = useState(props.criticites || []);
    const [disabledState] = useState(props.disabled || false);

    /**
     * Récupère les criticités en base et les insère
     * en tant que liste à afficher en priorité (efface les props)
     */
    const handleFetchCriticite = () => {
        criticiteService.getAllCriticite().then((res) => {
            if (!res.error) {
                setAvailableCriticiteState(res);
            }
        });
    };
    /**
     * Récupère les criticités non cochées pour le moment
     * et les insère en plus de celles déjà présentes dans les props
     */
    const handleFetchNonPresentCriticite = () => {
        criticiteService.getOtherCriticite(availableCriticiteState).then((res) => {
            if (!res.error) {
                setAvailableCriticiteState(availableCriticiteState.concat(res));
            }
        });
    };

    useEffect(() => {
        !!props.isChecked === false
            ? !disabledState && handleFetchCriticite()
            : handleFetchNonPresentCriticite();
    }, []);

    return (
        <>
            <h4>Gestion de la criticité des tickets</h4>
            {!disabledState
                ? availableCriticiteState.length > 0 &&
                  availableCriticiteState.map((criticite) => {
                      return (
                          <CriticiteFieldComponent
                              handleUpdateCriticiteNbTickets={props.handleUpdateCriticiteNbTickets}
                              handleCheckCriticite={props.handleCheckCriticite}
                              content={criticite}
                              key={criticite.nom}
                              disabled={props.disabled}
                          />
                      );
                  })
                : availableCriticiteState.map((criticite) => {
                      return (
                          <div
                              key={criticite.nom}
                              className="criticite-display-field"
                          >{`${criticite.nom} -> ${criticite.projet_contrat_support_criticite.nombre_tickets} ticket(s)`}</div>
                      );
                  })}
        </>
    );
};

export default CriticiteModuleComponent;
