import '../cards.style.scss';
import Relax from '../../images/account.svg';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// redux
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { ConnectedUserInfosPanel } from '../assets/panels/user-infos-panel.component';
import { H2Lighter } from '../../theming/customTheme';
import { ConnectedCustomizationComponent } from './customization.component';
import NotificationsControlComponent from './notifications-control.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WidgetProjetComponent from '../assets/widgets/widget-projet.component';
// import WidgetStatProjetComponent from '../assets/widgets/widget-stat-projet.component';
import WidgetCalendarComponent from '../assets/widgets/widget-calendar.component';

const AccountComponent = (props) => {
    const [contentState, setContentState] = useState('');
    const [errorState, setErrorState] = useState(null);
    const [expandedPanelPrefState, setExpandedPanelPrefState] = useState(false);
    const [expandedPanelProjetState, setExpandedPanelProjetState] = useState(false);

    useEffect(() => {
        toast.error(errorState);
    }, [errorState]);

    useEffect(() => {
        props.onLoad('Gérer mon compte');

        // on récupère les infos via le service API
        userService.getUserInfos(props.user.email).then((response) => {
            if (!response.error) {
                setContentState(response);
            } else {
                setErrorState({ error: response.error });
            }
        });
    }, []);

    return (
        <>
            {errorState ? (
                <section className="body-main-container flex-container column-container">
                    <div className="padded-container error-text">
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            closeOnClick
                            pauseOnHover
                        />
                        {errorState}
                    </div>
                </section>
            ) : contentState.utilisateur ? (
                <>
                    <Grid container sx={{ minHeight: { md: '100%' } }} spacing={1}>
                        <Grid item xs={12} md={3} sx={{ minHeight: { md: '100%' } }}>
                            <ConnectedUserInfosPanel content={contentState} editable />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Accordion
                                expanded={expandedPanelPrefState}
                                onChange={() => setExpandedPanelPrefState(!expandedPanelPrefState)}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <H2Lighter>Mes Préférences</H2Lighter>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <ConnectedCustomizationComponent
                                                content={contentState}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NotificationsControlComponent
                                                user={contentState.utilisateur}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expandedPanelProjetState}
                                onChange={() =>
                                    setExpandedPanelProjetState(!expandedPanelProjetState)
                                }
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <H2Lighter>Mes Projets</H2Lighter>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <WidgetProjetComponent
                                                user={contentState.utilisateur}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <WidgetCalendarComponent
                                                user={contentState.utilisateur}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <WaitingScreenComponent />
                </>
            )}
            <div className="background-decoration-svg">
                <img src={Relax} height="550px" width="800px" alt="" />
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        pref: state.prefReducer,
    };
}

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedAccountComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountComponent);
