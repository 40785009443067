import axiosConfig from './axiosConfig';
const API_URL = '/authentification/';

class AuthService {
    // Ancienne fonction de login
    login() {
        return axiosConfig
            .post(`${API_URL}signin`)
            .then((res) => {
                if (res.data.length > 1) {
                    return { moreThanOneUser: res.data };
                } else {
                    const objectTemp = res.data[0];
                    localStorage.setItem('user', JSON.stringify(objectTemp));

                    return objectTemp;
                }
            })
            .catch((err) => {
                localStorage.removeItem('user');
                localStorage.removeItem('accessToken');
                return { error: err.message || 'Une erreur est survenue' };
            });
    }

    // Fonction de login, utilise uniquement le mail pour récupérer les informations de l'utilisateur et les stocker dans le stockage local
    // Dans le back, on vérifie que l'appelant est identique à l'utilisateur à récupérer grâce au token gravitee.
    manualLogin(email) {
        return axiosConfig
            .post(`${API_URL}signin`, { email: email })
            .then((res) => {
                localStorage.setItem('user', JSON.stringify(res.data[0]));
                return res.data[0];
            })
            .catch((err) => {
                return { error: err.message };
            });
    }

    register(
        nom,
        prenom,
        email,
        // password,
        estInterne,
        role,
        fonction,
        manager,
        bu,
        entity,
    ) {
        estInterne = estInterne ? 1 : 0;
        return axiosConfig
            .post(API_URL + 'signup', {
                nom,
                prenom,
                email,
                // password,
                estInterne,
                role,
                fonction,
                manager,
                bu,
                entity,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `Impossible de poster les données ${error.message}`,
                };
            });
    }
}

export default new AuthService();
