import React, { useEffect, useState } from 'react';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import regionService from '../../../services/region.service';

//images
import { useLocation } from 'react-router-dom';

import ResultFormComponent from '../../assets/container/resultForm.component';
import FormRegionComponent from './form-region.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';

export const UpdateRegionComponent = (props) => {
    const location = useLocation();
    const [region_idState] = useState(location.state.objectId);
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [nomState, setNomState] = useState('');
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Modifier une région');
        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const region = regionService.getRegionById(region_idState);
        // on attend le réponse de toutes les requêtes et on les attribues au state local
        Promise.all([region]).then(([region]) => {
            setNomState(region.Region.nom);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handeUpdateRegion = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        regionService
            .updateRegionById(region_idState, dataForm.inputName)
            .then((response) => {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    const handleDeleteRegion = () => {
        if (
            window.confirm(
                "Voulez-vous vraiment supprimer la région ? Cela risque d'impacter des clients ou projets liés à cette région.",
            )
        ) {
            setLoadingState(true);
            setSuccessState(false);
            setSubmittedState(true);
            regionService
                .deleteRegionById(region_idState)
                .then((res) => {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(res.message);
                })
                .catch((err) => {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(err.response.message || err.message || err.response.message);
                });
        }
    };

    return (
        <>
            {nomState !== '' ? (
                <ResultFormComponent
                    loading={loadingState}
                    success={successState}
                    submitted={submittedState}
                    message={messageState}
                    link={'/admin/regions'}
                >
                    <FormRegionComponent
                        isUpdate={true}
                        inputName={nomState}
                        handleForm={handeUpdateRegion}
                        handleDelete={handleDeleteRegion}
                    />
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateRegionComponent = connect(
    null,
    mapDispatchToProps,
)(UpdateRegionComponent);
