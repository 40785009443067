import './portal-button.style.scss';
import { useNavigate } from 'react-router-dom';
import { ConnectedProtectedRouteComponent } from './routing/protected-route.component';
import { Grid, Grow, Paper, SvgIcon, Typography, useTheme } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// /**
//  * @param {string} title - Titre de la card button
//  * @param {string} icon - Icone de fond de la card button
//  * @param {string} color - Classe pour couleur de fond de la card button (primary, secondary,...)
//  * @param {string} link - Lien utile au bouton
//  * @param {string} extlink - Lien externe utile au bouton (override le lien simple)
//  * @param {Integer} role - role à qui autoriser l'accès
//  */
const PortalButtonComponent = (props) => {
    // let buttonComponent = null;
    const theme = useTheme();
    const navigate = useNavigate();

    const gridStyle = (gridProps = null) => ({
        width: '100%',
        overflow: 'hidden',
        cursor: gridProps !== null ? gridProps.cursor || 'pointer' : 'pointer',
        p: 2,
        m: 1,
        color: theme.palette.mode === 'light' ? 'light.main' : 'originalLight.main',
        backgroundColor: gridProps.backgroundColor || props.color,
        '&:hover': {
            color: theme.palette.mode === 'light' ? 'light.dark' : 'light.veryLight',
            boxShadow: 6,
        },
    });
    /**
     * Contenu interne au bouton
     */
    const content = () => {
        return (
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={9}>
                    <Typography variant="h6">{props.title}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <SvgIcon component={props.icon || OpenInNewIcon} sx={{ fontSize: 60 }} />
                </Grid>
            </Grid>
        );
    };

    return (
        <ConnectedProtectedRouteComponent
            role={props.role}
            returnTo={<div style={{ display: 'none' }}></div>}
        >
            <Grow in={true}>
                <Grid
                    item
                    component={Paper}
                    xs={12}
                    md={5}
                    lg={3}
                    xl={2}
                    sx={
                        props.link !== undefined || props.extlink !== undefined
                            ? props.link !== undefined
                                ? gridStyle
                                : gridStyle({ backgroundColor: 'secondary.main' })
                            : props.functionButton
                            ? gridStyle
                            : gridStyle({
                                  backgroundColor: 'secondary.light',
                                  cursor: 'not-allowed',
                              })
                    }
                    elevation={12}
                    onClick={
                        props.functionButton
                            ? () => props.toggleFunction()
                            : props.link !== undefined || props.extlink !== undefined
                            ? props.link !== undefined
                                ? () => navigate('/' + props.link)
                                : undefined
                            : undefined
                    }
                >
                    {content()}
                </Grid>
            </Grow>
        </ConnectedProtectedRouteComponent>
    );
};

export default PortalButtonComponent;
