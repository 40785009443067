import { CircularProgress } from '@mui/material';
import { H2Lighter } from '../../theming/customTheme';
import CenteredPaper from './container/centeredPaper.component';

/**
 * Composant à utiliser pour les composants de pages uniquement.
 * Le composant est utile lorsque l'on doit attendre que certaines valeurs asynchrones soient chargé avant d'afficher une page.
 */
const WaitingScreenComponent = (props) => {
    const withPaper = props.withPaper === undefined ? true : props.withPaper;
    const customText = props.customText ? props.customText : 'Chargement en cours';
    const loadingState = props.loadingState || null;

    return withPaper ? (
        <CenteredPaper>
            <H2Lighter>{customText}</H2Lighter>
            {loadingState !== null ? (
                <CircularProgress variant="determinate" value={loadingState} />
            ) : (
                <CircularProgress />
            )}
        </CenteredPaper>
    ) : (
        <div style={{ textAlign: 'center' }}>
            <H2Lighter>{customText}</H2Lighter>
            <CircularProgress />
        </div>
    );
};

export default WaitingScreenComponent;
