class NeoFetch {
    constructor({ uri, headers }) {
        this.uri = uri;
        this.headers = {
            'Content-Type': 'application/json',
            ...headers,
        };
        this.requestInterceptorFunction = [];
        this.responseInterceptorFunction = null;
    }

    // Partie "private"

    parseBody(contentType, data) {
        if (data instanceof FormData) {
            return data;
        } else {
            switch (contentType) {
                case 'application/json':
                    return JSON.stringify(data);
                case 'application/x-www-form-urlencoded':
                    return new URLSearchParams(data);
                default:
                    return JSON.stringify(data);
            }
        }
    }

    parseHeader(customHeaders) {
        let tempHeaders = {
            ...this.headers,
            ...customHeaders,
        };

        if (tempHeaders['Content-Type'] === undefined) {
            delete tempHeaders['Content-Type'];
        }

        return tempHeaders;
    }

    async parseOptionsFromInterceptors(url, options) {
        for (const interceptor of this.requestInterceptorFunction) {
            options = await interceptor(url, options);
        }
        return options;
    }

    parseParamsUrl(url, params) {
        if (params) {
            return url + '?' + new URLSearchParams(params);
        } else {
            return url;
        }
    }

    async parseAllOptions(options, url, data = null) {
        options.headers = this.parseHeader(options.headers);
        options = await this.parseOptionsFromInterceptors(url, options);
        if (data !== null) {
            options.body = this.parseBody(options.headers['Content-Type'], data);
        }

        return {
            finalOptions: options,
            finalURL: url + (options.params ? '?' + new URLSearchParams(options.params) : ''),
        };
    }

    parseResponse(response, responseData, options, url) {
        if (this.responseInterceptorFunction) {
            return this.responseInterceptorFunction({
                response: response,
                data: responseData,
                config: { options, url },
            });
        } else {
            return Promise.resolve({ response: response, data: responseData });
        }
    }

    // Partie "custom requête"

    /**
     * Ajoute une fonction "interceptor" à la requête. Les fonctions seront exécutés avant le fetch.
     * Les fonctions peuvent être asynchrones et doivent dans tout les cas retourner les options de la requête (modifiées ou non).
     *
     * La fonction prend en paramètre une url et les options de la requête.
     * @param {function} interceptorFunction
     */
    addRequestInterceptor(interceptorFunction) {
        this.requestInterceptorFunction.push(interceptorFunction);
    }

    setResponseInterceptor(interceptorFunction) {
        this.responseInterceptorFunction = interceptorFunction;
    }

    /**
     *
     * @param {*} config
     * @returns Exécute une requête depuis une config NeoFetch (qui contient options et url).
     * N'éxécute aucun intercepteur.
     */
    request(config) {
        return fetch(config.url, { ...config.options })
            .then((response) => {
                const responseContentType = response.headers.get('content-type');

                if (responseContentType !== null) {
                    if (responseContentType.indexOf('application/json') !== -1) {
                        return response
                            .json()
                            .then((responseData) => {
                                return { response, data: responseData };
                            })
                            .catch((err) => {
                                throw new Error(err);
                            });
                    } else {
                        return response
                            .blob()
                            .then((responseData) => {
                                return { response, data: responseData };
                            })
                            .catch((err) => {
                                throw new Error(err.message);
                            });
                    }
                } else {
                    return { response, data: null };
                }
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    /**
     * [GET HTTP Request]
     * @param  {[string]} url [Call URI]
     * @param {[object]} options [Options for the fetch request]
     * @return {[json]}     [Response in JSON format]
     */
    get(url, options = { headers: null }) {
        options = { ...options, method: 'GET' };

        return this.parseAllOptions(options, url)
            .then(({ finalOptions, finalURL }) => {
                return this.request({ url: this.uri + '' + finalURL, options: finalOptions })
                    .then((response) => {
                        return this.parseResponse(
                            response.response,
                            response.data,
                            options,
                            this.uri + '' + finalURL,
                        )
                            .then((response) => {
                                return response;
                            })
                            .catch((err) => {
                                throw new Error(err.message);
                            });
                    })
                    .catch((err) => {
                        throw new Error(err);
                    });
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    }

    /**
     * [POST HTTP Request]
     * @param  {[string]} url [Call URI]
     * @param {[object]} data [Payload for the call]
     * @param {[object]} options [Header for the call]
     * @return {[json]}     [Response in JSON format]
     */
    post(url, data, options = { headers: null }) {
        options = { ...options, method: 'POST' };

        return this.parseAllOptions(options, url, data)
            .then(({ finalOptions, finalURL }) => {
                return this.request({ url: this.uri + '' + finalURL, options: finalOptions }).then(
                    (response) => {
                        return this.parseResponse(
                            response.response,
                            response.data,
                            options,
                            this.uri + '' + finalURL,
                        )
                            .then((response) => response)
                            .catch((err) => {
                                throw new Error(err.message);
                            });
                    },
                );
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    }

    patch(url, data, options = { headers: null }) {
        options = { ...options, method: 'PATCH' };

        return this.parseAllOptions(options, url, data)
            .then(({ finalOptions, finalURL }) => {
                return this.request({ url: this.uri + '' + finalURL, options: finalOptions })
                    .then((response) => {
                        return this.parseResponse(
                            response.response,
                            response.data,
                            options,
                            this.uri + '' + finalURL,
                        )
                            .then((response) => response)
                            .catch((err) => {
                                throw new Error(err.message);
                            });
                    })
                    .catch((err) => {
                        throw new Error(err.message);
                    });
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    }

    /**
     * [PUT HTTP Request]
     * @param  {[string]} url [Call URI]
     * @param {[object]} data [Payload for the call]
     * @param {[object]} options [Header for the call]
     * @return {[json]}     [Response in JSON format]
     */
    put(url, data, options = { headers: null }) {
        options = { ...options, method: 'PUT' };

        return this.parseAllOptions(options, url, data)
            .then(({ finalOptions, finalURL }) => {
                return this.request({ url: this.uri + '' + finalURL, options: finalOptions })
                    .then((response) => {
                        return this.parseResponse(
                            response.response,
                            response.data,
                            options,
                            this.uri + '' + finalURL,
                        )
                            .then((response) => response)
                            .catch((err) => {
                                throw new Error(err.message);
                            });
                    })
                    .catch((err) => {
                        throw new Error(err.message);
                    });
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    }

    /**
     * [DELETE HTTP Request]
     * @param  {[string]} url [Call URI]
     * @param {[object]} headers [Header for the call]
     * @return {[string]}     [Response]
     */
    delete(url, data, options = { headers: null }) {
        options = { ...options, method: 'DELETE' };
        return this.parseAllOptions(options, url, data)
            .then(({ finalOptions, finalURL }) => {
                return this.request({ url: this.uri + '' + finalURL, options: finalOptions })
                    .then((response) => {
                        return this.parseResponse(
                            response.response,
                            response.data,
                            options,
                            this.uri + '' + finalURL,
                        )
                            .then((response) => response)
                            .catch((err) => {
                                throw new Error(err.message);
                            });
                    })
                    .catch((err) => {
                        throw new Error(err.message);
                    });
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    }
}

export default NeoFetch;
