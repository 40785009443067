import CancelIcon from '@mui/icons-material/Cancel';
import React, { Component } from 'react';
import './pin.style.scss';

export default class PinComponent extends Component {
    render() {
        const content = this.props.content || {};
        const displayName =
            content.nom !== undefined && content.prenom !== undefined
                ? `${content.nom} ${content.prenom}`
                : 'Données corrompues';
        return (
            <div
                key={`${content.nom}-${content.prenom}`}
                className="pin-container"
                style={
                    this.props.disabled && {
                        textAlign: 'center',
                        width: '13ch',
                    }
                }
                onClick={() => this.props.handlePinClick()}
            >
                <div className="text-container-pin">{displayName}</div>
                {!this.props.disabled && content.nom && content.prenom && (
                    <CancelIcon onClick={() => this.props.handleRemoveContact(content)} />
                )}
            </div>
        );
    }
}
