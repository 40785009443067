import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { App } from './components/app/app.component';
import reducers from './redux/combineReducer';

// import reportWebVitals from './reportWebVitals';

// Importation pour PWA
import { register as registerServiceWorker } from './serviceWorkerRegistration';
import { ThemeProviderWrapper } from './ThemeProviderWrapper';

const middleware = [thunk];
export const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));

// const gridContainer = React.createRef();
// DOM de l'application
ReactDOM.render(
    <Provider store={store}>
        <ThemeProviderWrapper>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProviderWrapper>
    </Provider>,
    document.getElementById('root'),
);

registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
