import { Badge, Box, Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import * as DateHelper from '../../../helpers/date.helper';
import activityService from '../../../services/activity.service';
import congeService from '../../../services/conge.service';
import { WidgetCalendarBadge } from '../../../theming/customTheme';
import TitleComponent from '../field/title.component';
import { getTypeNom } from '../../../helpers/conge.helper';

const WidgetCalendarComponent = (props) => {
    const [activitiesState, setActivitiesState] = useState([]);
    const [monthState, setMonthState] = useState([]);
    const [allJoursFeriesState, setAllJoursFeriesState] = useState([]);

    const refreshJoursFeries = async () => {
        let result = [];
        let joursJSON = await fetch(
            'https://calendrier.api.gouv.fr/jours-feries/metropole/' +
                new Date().getFullYear() +
                '.json',
        );
        let joursFeries = await joursJSON.json();
        for (let i in joursFeries) {
            result[i] = joursFeries[i];
        }
        setAllJoursFeriesState(result);
    };

    /** REACT FUNCTIONS */
    const handleRefreshDatas = async (actualDate) => {
        let activities = await activityService.getEmployeeMonthActivities(
            actualDate.getMonth(),
            actualDate.getFullYear(),
            props.user.user_id,
        );
        let conges = await congeService.getEmployeeMonthConges(
            actualDate.getMonth(),
            actualDate.getFullYear(),
            props.user.user_id,
        );

        conges = conges.map((conge) => {
            return (conge = {
                ...conge,
                projet: {
                    couleur: '#2b6955',
                    trigramme: getTypeNom(conge),
                    fk_activity_type: 0,
                },
            });
        });
        const activitiesAndConge = activities.concat(conges);
        const timeToDisplay = '300'; // Temps en ms pour afficher toutes les activités
        const activityTime = timeToDisplay / activitiesAndConge.length; // Pour que tout s'affiche en une seconde, on divise par le nombre d'activité à afficher
        activitiesAndConge.forEach((activity, index) => {
            activity.timeToDisplay = (index + 1) * activityTime;
        });
        setActivitiesState(activitiesAndConge);
    };

    useEffect(() => {
        refreshJoursFeries();
        handleRefreshDatas(new Date());
    }, []);

    useEffect(() => {
        setMonthState(
            DateHelper.constructorMonth(new Date(), activitiesState, allJoursFeriesState),
        );
    }, [activitiesState, allJoursFeriesState]);

    return (
        <>
            <TitleComponent>Mon Calendrier</TitleComponent>
            <Grid container justifyContent={'flex-start'} alignItems="flex-end">
                <Grid item xs={9}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                        {['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((jour, index) => (
                            <Grid item xs={1} key={'widget_calendar_jour_' + index}>
                                <Typography sx={{ textAlign: 'center' }}>{jour}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{ mt: 1 }} variant="middle" />
                    {monthState.map((week, indexWeek) => {
                        let toDisplay = week.jours.filter((jour) => jour.status > 0);
                        return toDisplay.length > 0 ? (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                key={'widget_calendar_week_container' + indexWeek}
                            >
                                {week.jours.map((jour, indexJour) => {
                                    const charge = jour.activities.reduce((acc, curr) => {
                                        if (curr.activity) {
                                            return acc + curr.activity.valeur;
                                        }
                                        return acc;
                                    }, 0);

                                    return (
                                        <Grid
                                            item
                                            xs={1}
                                            key={'widget_calendar_jour_container' + indexJour}
                                            sx={{ mt: 1 }}
                                        >
                                            <Box
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <WidgetCalendarBadge
                                                    badgeContent={jour.dayNumber}
                                                    color={
                                                        jour.ferie
                                                            ? 'secondary'
                                                            : jour.nom === 'Samedi'
                                                            ? 'secondary'
                                                            : jour.nom === 'Dimanche'
                                                            ? 'secondary'
                                                            : charge >= 1
                                                            ? 'error'
                                                            : charge >= 0.5
                                                            ? 'originalPrimary'
                                                            : charge > 0
                                                            ? 'warning'
                                                            : 'success'
                                                    }
                                                ></WidgetCalendarBadge>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : null;
                    })}
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction="column">
                        {[
                            { title: 'Indisponible', color: 'error' },
                            { title: 'À mi-temps', color: 'originalPrimary' },
                            { title: 'Fonctionnaire ?', color: 'warning' },
                            { title: 'Libre', color: 'success' },
                            { title: 'WE et fériés', color: 'secondary' },
                        ].map((badgeItem, index) => (
                            <Grid item key={'widget_calendar_badge_item_' + index}>
                                <Badge
                                    sx={{ minWidth: '100px' }}
                                    badgeContent={badgeItem.title}
                                    color={badgeItem.color}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                ></Badge>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default WidgetCalendarComponent;
