import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Hello from '../images/404.svg';

export default class NoMatchComponent extends Component {
    render() {
        return (
            <section className="body-main-container aligned-container flex-container column-container only-container">
                <h1 className="primary">404</h1>
                La page recherchée est introuvable.
                <div className="button-container">
                    <Link to="/home" className="button">
                        Reviens en terrain connu
                    </Link>
                </div>
                <div className="background-main-svg">
                    <img src={Hello} height="450px" width="600px" alt="" />
                </div>
            </section>
        );
    }
}
