import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import category_fichiersService from '../../../../services/category_fichiers.service';
import CrudPageComponent from '../../../assets/page/crud-page.component';

const ListCategoryFichiersComponent = () => {
    const [errorState, setErrorState] = useState(null);
    const [displayTypeState, setDisplayTypeState] = useState(true);
    const [contentState, setContentState] = useState([
        {
            category_fichiers_id: '',
            name: '',
            color: '',
            createdAt: new Date(),
        },
    ]);

    const handleFetchCategory = () => {
        (displayTypeState
            ? category_fichiersService.getAll()
            : category_fichiersService.getAllArchived()
        ).then((res) => {
            if (res.error) {
                setErrorState(res.error);
            } else {
                setContentState(res);
            }
        });
    };

    const handleRestoreCategoryFichiers = (categoryId) => {
        category_fichiersService.restore(categoryId).then((res) => {
            if (res.error) {
                setErrorState(res.error);
            } else {
                toast.success(res.message);
                handleFetchCategory();
            }
        });
    };

    useEffect(() => {
        handleFetchCategory();
    }, [displayTypeState]);

    return (
        <CrudPageComponent
            objectName="categoryFichiers"
            title="Gestion des catégories de fichiers"
            titleAdd="Ajouter une catégorie"
            labelSwitchArchived="Afficher les catégories archivées"
            linkAdd={'/admin/create-category-fichiers'}
            linkEdit={'/admin/update-category-fichiers'}
            handleRestore={handleRestoreCategoryFichiers}
            headers={['Nom', { title: 'Couleur', size: '10%' }, 'Créée le']}
            dataContent={contentState.map((category) => ({
                id: category.category_fichiers_id,
                nom: category.name,
                couleur: category.color,
                createdAt: new Date(category.createdAt).toLocaleDateString('fr'),
            }))}
            error={errorState}
            displayType={displayTypeState}
            onChangeDisplayType={() => {
                setDisplayTypeState(!displayTypeState);
            }}
        />
    );
};

export default ListCategoryFichiersComponent;
