import { SET_PREF } from '../constants';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = !!user
    ? { dark_mode: user.dark_mode, fav_color: user.fav_color, photo: user.photo }
    : { dark_mode: false, fav_color: '#ff5c40', photo: -1 };
export default function prefReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_PREF:
            return {
                dark_mode: payload.dark_mode,
                fav_color: payload.fav_color,
                photo: payload.photo,
            };
        default:
            return state;
    }
}
