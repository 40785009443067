/** Constantes pour nos actions, utiles au debug */
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const TOGGLE_LEFT_MENU = 'TOGGLE_LEFT_MENU';
export const CLEAR_PAGE_TITLE = 'CLEAR_PAGE_TITLE';
/** Constantes auth */
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

/** Constantes d'UX */
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// Constantes Préférences
export const SET_PREF = 'SET_PREF';
