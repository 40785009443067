import {
    Autocomplete,
    FormControlLabel,
    Box,
    Switch,
    Button,
    FormHelperText,
    Grid,
    TextField,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { H2Lighter } from '../../../theming/customTheme';
import categoryNewsService from '../../../services/category_news.service';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import MarkdownEditor from '@uiw/react-markdown-editor';
import './markdown.css';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import newsService from '../../../services/news.service';
import SimpleModalComponent from '../../assets/modal/simple-modal.component';

const FormNewsComponent = (props) => {
    const [inputValueState, setInputValueState] = useState('');
    const [categoryNewsState, setCategoryNewsState] = useState([]);
    const [imagePreviewState, setImagePreviewState] = useState(props.image || '');
    const [contentValueState, setContentValueState] = useState(
        props.content || '# Mettre le contenu ',
    );
    const [openModalState, setOpenModalState] = useState(false);
    const isUpdate = props.isUpdate || false;
    const isForm = !!props.handleForm;

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
    } = useForm();

    useEffect(() => {
        if (props.image) {
            setValue('inputImage', -1);
        }
        const categoryNews = categoryNewsService.getAll();
        Promise.all([categoryNews]).then(([categoryNews]) => {
            setCategoryNewsState(
                categoryNews.map((laNews) => ({
                    label: laNews.name,
                    value: laNews.category_news_id,
                })),
            );
        });
    }, []);

    useEffect(() => {
        if (inputValueState !== '') {
            setImagePreviewState(URL.createObjectURL(inputValueState));
        }
    }, [inputValueState]);

    const customSubmit = (dataForm) => {
        if (contentValueState.trim() === '' || contentValueState === null) {
            setError('inputContent', {
                type: 'InvalidValue',
                message: 'Le contenu de la news doit être rempli.',
            });
        } else {
            clearErrors('inputContent');

            const data = { ...dataForm, inputContent: contentValueState };

            props.handleForm(data);
        }
    };

    const submitDeleteNews = () => {
        if (props.uuid !== null) {
            newsService.getNewsFromUUID(props.uuid).then((news) => {
                if (news.length > 1) {
                    setOpenModalState(true);
                } else {
                    props.handleDelete(props.id);
                }
            });
        } else {
            props.handleDelete(props.id);
        }
    };

    return (
        <>
            <SimpleModalComponent
                isOpen={openModalState}
                handleCloseModal={() => {
                    setOpenModalState(false);
                }}
            >
                <CenteredPaper withMargin={false} elevation={0}>
                    <H2Lighter>
                        Cette news est multi-entité, voulez-vous la supprimer pour toutes les
                        entités ?
                    </H2Lighter>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => props.handleDelete(props.uuid)}
                            >
                                Supprimer pour toutes les entités
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                onClick={() => props.handleDelete(props.id)}
                            >
                                Supprimer pour l'entité courante
                            </Button>
                        </Grid>
                    </Grid>
                </CenteredPaper>
            </SimpleModalComponent>
            <CenteredPaper withMargin={true} withAlign={false} size="extra-large" elevation={1}>
                <H2Lighter>{isUpdate ? 'Modifier la news' : 'Créer une news'}</H2Lighter>

                <form onSubmit={handleSubmit(customSubmit)}>
                    <Controller
                        key="inputTitle"
                        name="inputTitle"
                        control={control}
                        rules={{
                            required: { value: true, message: 'Le titre est obligatoire' },
                        }}
                        defaultValue={props.title || null}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Titre de la news"
                                placeholder="Un nouvel employé chez NeoDT"
                                sx={{ mt: 2 }}
                                error={!!errors.inputTitle}
                                helperText={!!errors.inputTitle && errors.inputTitle.message}
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        key="inputImage"
                        name="inputImage"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Le fichier d'illustration est obligatoire",
                            },
                        }}
                        render={({ field }) => (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                {imagePreviewState && (
                                    <Grid item xs={2}>
                                        <img
                                            src={imagePreviewState || ''}
                                            alt="News illustration"
                                            width="100%"
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color={
                                            !!errors.inputImage
                                                ? 'error'
                                                : inputValueState === ''
                                                ? 'secondary'
                                                : 'success'
                                        }
                                    >
                                        {inputValueState === '' ? <ImageIcon /> : <CheckIcon />}{' '}
                                        Image d'illustration
                                        <input
                                            {...field}
                                            type="file"
                                            accept="image/png, image/jpg, image/gif, image/jpeg"
                                            hidden
                                            onChange={(e) => {
                                                field.onChange(e.target.files[0]);
                                                setInputValueState(e.target.files[0]);
                                            }}
                                            value=""
                                        />
                                    </Button>
                                    {!!errors.inputImage ? (
                                        <FormHelperText error>
                                            {errors.inputImage.message}
                                        </FormHelperText>
                                    ) : (
                                        <FormHelperText>
                                            {inputValueState.name || ''}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    />

                    {!isUpdate && (
                        <Controller
                            key="checkEntity"
                            name="checkEntity"
                            control={control}
                            defaultValue={false}
                            render={({ field: { value, ...field } }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            {...field}
                                            size="small"
                                            checked={!!value}
                                            disabled={!isForm}
                                        />
                                    }
                                    sx={{ mt: 2 }}
                                    label="Publier sur toutes les entités ?"
                                />
                            )}
                        />
                    )}
                    <Controller
                        key="checkPin"
                        name="checkPin"
                        control={control}
                        defaultValue={props.is_pinned || false}
                        render={({ field: { value, ...field } }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        size="small"
                                        checked={!!value}
                                        disabled={!isForm}
                                    />
                                }
                                sx={{ mt: 2 }}
                                label="Epingler cette news ?"
                            />
                        )}
                    />

                    <Controller
                        key="inputCategory"
                        name="inputCategory"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Le champ fonction est obligatoire',
                            },
                        }}
                        defaultValue={props.category || null}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                disablePortal
                                fullWidth
                                options={categoryNewsState}
                                getOptionLabel={(option) => option.label || null}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                onChange={(_, value) => {
                                    field.onChange(value);
                                }}
                                sx={{ mt: 2 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Catégorie de la news"
                                        sx={{ mt: 1 }}
                                        variant="outlined"
                                        error={!!errors.inputCategory}
                                        helperText={
                                            !!errors.inputCategory
                                                ? errors.inputCategory.message
                                                : null
                                        }
                                    />
                                )}
                            />
                        )}
                    />

                    <Box
                        data-color-mode="light"
                        sx={
                            errors.inputContent
                                ? {
                                      borderColor: 'red',
                                      borderStyle: 'solid',
                                      borderWidth: '1px',
                                      borderRadius: '5px',
                                      mt: 2,
                                      p: 1,
                                  }
                                : { mt: 2 }
                        }
                    >
                        <MarkdownEditor
                            visible={true}
                            height="200px"
                            onChange={(value) => setContentValueState(value)}
                        />
                        {errors.inputContent && (
                            <FormHelperText error>{errors.inputContent.message}</FormHelperText>
                        )}
                    </Box>

                    <Box sx={{ textAlign: 'center' }}>
                        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                            {isUpdate ? <EditIcon sx={{ mr: 1 }} /> : <AddIcon sx={{ mr: 1 }} />}
                            {isUpdate ? 'Modifier la ' : 'Créer une '}News
                        </Button>
                        {isUpdate && (
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ mt: 2, ml: 2 }}
                                onClick={handleSubmit(submitDeleteNews)}
                            >
                                <DeleteIcon />
                                Supprimer la news
                            </Button>
                        )}
                    </Box>
                </form>
            </CenteredPaper>
        </>
    );
};

export default FormNewsComponent;
