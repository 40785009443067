import { useState, useEffect } from 'react';
import userService from '../../../services/user.service';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import { ToastContainer, toast } from 'react-toastify';
// redux
import { connect } from 'react-redux';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import CrudPageComponent from '../../assets/page/crud-page.component';

const UsersComponent = (props) => {
    const [error, setError] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [displayType, setDisplayType] = useState(1);
    const [contentState, setContentState] = useState('');

    const onChangeDisplayType = () => {
        setDisplayType(!displayType);
    };

    const handleRestoreUser = (userId) => {
        userService.restore(userId).then(() => {
            handleFetchUsers();
        });
    };

    const handleFetchUsers = async () => {
        setLoadingState(true);

        const response = await (displayType
            ? userService.getAllUsers()
            : userService.getDeletedUsers());
        if (!response.error) {
            setLoadingState(false);
            setContentState(response || null);
        } else {
            setLoadingState(false);
            setError(response.error);
        }
    };

    useEffect(() => {
        props.onLoad('Administrateur - Tous les utilisateurs');
        handleFetchUsers();
    }, [displayType]); // eslint-disable-line react-hooks/exhaustive-deps

    const errorToast = () => toast.error(error);
    if (error) {
        return (
            <section className="body-main-container flex-container column-container aligned-top-container">
                <div style={{ display: 'none' }}>{errorToast()}</div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                />
                <div className="padded-container error-text">
                    <div>{error}</div>
                </div>
            </section>
        );
    }

    return (
        <CrudPageComponent
            title="Gestion des utilisateurs"
            objectName="users"
            titleAdd="Créer un utilisateur"
            linkAdd="/admin/create-user"
            onChangeDisplayType={onChangeDisplayType}
            displayType={displayType}
            labelSwitchArchived="Afficher les utilisateurs archivés"
            headers={['', 'Nom', 'Prénom', 'Email', 'Interne']}
            withAvatar
            loading={loadingState}
            dataContent={
                !!contentState
                    ? contentState.map((user) => ({
                          _avatarDisplay: user,
                          users_id: user.user_id,
                          nom: user.nom.toUpperCase(),
                          prenom: user.prenom,
                          email: { data: user.email, userTooltip: true },
                          //   bu: user.bu.nom,
                          interne: user.estInterne ? 'Oui' : 'Non',
                      }))
                    : [{}]
            }
            linkEdit="/admin/update-user"
            handleRestore={handleRestoreUser}
            actionsAddedContent={(user) => {
                return (
                    <Button
                        component={Link}
                        to={'/admin/user'}
                        state={{ user_email: user.email.data }}
                        variant="contained"
                        size="small"
                        color="secondary"
                        sx={{ mr: 10 }}
                    >
                        <VisibilityIcon size="small" sx={{ mr: 1 }} />
                        Détails
                    </Button>
                );
            }}
        />
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUsersComponent = connect(null, mapDispatchToProps)(UsersComponent);
