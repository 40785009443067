import { React, useState, useEffect } from 'react';
import CalendarComponent from '../calendar/calendar.component';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import activityService from '../../services/activity.service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import congeService from '../../services/conge.service';
import { getTypeNom } from '../../helpers/conge.helper';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import { Grid } from '@mui/material';

const PersonalTimeComponent = (props) => {
    let baseDate = new Date();
    const [actualDateState, setActualDateState] = useState(baseDate);
    const [activitiesState, setActivitiesState] = useState('');
    const [keyState, setKeyState] = useState(0);
    const [errorState, setErrorState] = useState(null);
    const [allJoursFeriesState, setAllJoursFeriesState] = useState([]);
    const [joursFeriesState, setJoursFeriesState] = useState(0);

    const refreshJoursFeries = async () => {
        let result = [];
        let joursJSON = await fetch(
            'https://calendrier.api.gouv.fr/jours-feries/metropole/' +
                actualDateState.getFullYear() +
                '.json',
        );
        let joursFeries = await joursJSON.json();
        for (let i in joursFeries) {
            result[i] = joursFeries[i];
        }
        setAllJoursFeriesState(result);
    };

    useEffect(() => {
        setJoursFeriesState(1);
    }, [allJoursFeriesState]);

    /** ACTIONS */
    /**
     * Permet d'aller au mois suivant en prenant le mois actuel +1
     */
    const nextMonth = () => {
        let actualDate = actualDateState;
        let nextMonth = new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 1);
        // on construit le mois à partir de la date avec mois +1
        setActualDateState(nextMonth);
    };

    /**
     * Permet d'aller au mois précédent en prenant le mois actuel -1
     */
    const previousMonth = () => {
        let actualDate = actualDateState;
        let nextMonth = new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1);
        setActualDateState(nextMonth);
    };
    const setMonth = (event) => {
        let actualDate = actualDateState;
        let newMonth = new Date(actualDate.getFullYear(), event.target.value, 1);
        setActualDateState(newMonth);
    };
    const setYear = (event) => {
        let actualDate = actualDateState;
        let newYear = new Date(event.target.value, actualDate.getMonth(), 1);
        setActualDateState(newYear);
    };
    const rerenderParentCallback = () => {
        setKeyState(keyState + 1);
    };

    /** REACT FUNCTIONS */
    const handleRefreshDatas = async (actualDate) => {
        try {
            let activities = await activityService.getEmployeeMonthActivities(
                actualDate.getMonth(),
                actualDate.getFullYear(),
            );
            let conges = await congeService.getEmployeeMonthConges(
                actualDate.getMonth(),
                actualDate.getFullYear(),
            );

            conges = conges.map((conge) => {
                return (conge = {
                    ...conge,
                    projet: {
                        couleur: '#2b6955',
                        trigramme: getTypeNom(conge),
                        fk_activity_type: 0,
                    },
                });
            });
            const activitiesAndConge = activities.concat(conges);
            const timeToDisplay = '300'; // Temps en ms pour afficher toutes les activités
            const activityTime = timeToDisplay / activitiesAndConge.length; // Pour que tout s'affiche en une seconde, on divise par le nombre d'activité à afficher
            activitiesAndConge.forEach((activity, index) => {
                activity.timeToDisplay = (index + 1) * activityTime;
            });
            setActivitiesState(activitiesAndConge);
        } catch (error) {
            // si une erreur est détectée, on la renseigne
            setErrorState(error);
        }
    };

    /**
     * Fonction appelée au chargement initial de la page
     */
    useEffect(() => {
        props.onLoad('Gérer mes temps');
        try {
            handleRefreshDatas(actualDateState);
            refreshJoursFeries();
        } catch (err) {
            setErrorState(err);
        }
    }, []);

    /**
     * Fonction appelée au changement d'une props ou state de notre objet
     */
    useEffect(() => {
        setActivitiesState('');
        refreshJoursFeries();
        handleRefreshDatas(actualDateState);
    }, [actualDateState, keyState]);

    return (
        <>
            {errorState ? (
                <section className="body-main-container personal-time-container">
                    <div className="padded-container error-text">
                        <div
                            style={{
                                display: 'none',
                            }}
                        >
                            {toast.error(errorState)}
                        </div>
                        {errorState}
                    </div>
                </section>
            ) : (
                <Grid container direction="column">
                    {activitiesState !== '' && joursFeriesState === 1 ? (
                        <>
                            <Grid item>
                                <CalendarComponent
                                    key={actualDateState}
                                    actualDate={actualDateState}
                                    activities={activitiesState}
                                    nextMonth={nextMonth}
                                    previousMonth={previousMonth}
                                    setMonth={setMonth}
                                    setYear={setYear}
                                    rerenderParentCallback={rerenderParentCallback}
                                    allJoursFeries={allJoursFeriesState}
                                />
                            </Grid>
                        </>
                    ) : (
                        <WaitingScreenComponent />
                    )}
                </Grid>
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedPersonalTimeComponent = connect(
    null,
    mapDispatchToProps,
)(PersonalTimeComponent);
