import React, { useEffect, useState } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
// import useCustomTheme from './helpers/theming.helper';
import { connect } from 'react-redux';

// /** style global de l'application */
import './index.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// toast notification
import 'react-toastify/dist/ReactToastify.css';

import breakpoints from './theming/breakpoints';
import components from './theming/components';
import typography from './theming/typography';
import getPalette from './theming/palette';

const ThemeProviderCustom = (props) => {
    const [paletteState, setPaletteState] = useState(getPalette(props.pref.fav_color));
    const [lightThemeState, setLightThemeState] = useState(
        createTheme({ palette: { mode: 'light' } }),
    );
    const [darkThemeState, setDarkThemeState] = useState(
        createTheme({ palette: { mode: 'dark' } }),
    );

    const setAllTheme = () => {
        setLightThemeState(
            createTheme({
                palette: {
                    mode: 'light',
                    text: {
                        alert: {
                            success: '#2da05f',
                        },
                    },
                    primary: paletteState.primary,
                    originalPrimary: paletteState.originalPrimary,
                    secondary: paletteState.secondary,
                    error: paletteState.error,
                    warning: paletteState.warning,
                    success: paletteState.success,
                    light: paletteState.light,
                    love: paletteState.love,
                },
                breakpoints: breakpoints,
                components: components(paletteState),
                typography: typography,
            }),
        );

        setDarkThemeState(
            createTheme({
                palette: {
                    mode: 'dark',
                    primary: paletteState.primary,
                    originalPrimary: paletteState.originalPrimary,
                    secondary: paletteState.secondary,
                    error: paletteState.error,
                    warning: paletteState.warning,
                    success: paletteState.success,
                    light: paletteState.lightDark,
                    originalLight: paletteState.light,
                    love: paletteState.love,
                },
                components: components(paletteState),
                typography: typography,
                breakpoints: breakpoints,
            }),
        );
    };

    useEffect(() => {
        setPaletteState(getPalette(props.pref.fav_color));
    }, [props.pref]);

    useEffect(() => {
        setAllTheme();
    }, [paletteState]);

    return (
        <ThemeProvider theme={props.pref.dark_mode ? darkThemeState : lightThemeState}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    );
};

function mapStateToProps(state) {
    return {
        pref: state.prefReducer,
        user: state.authReducer,
    };
}

export const ThemeProviderWrapper = connect(mapStateToProps, null)(ThemeProviderCustom);
