import '../cards.style.scss';
// color picker
// import { SketchPicker } from 'react-color';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
/** API services */

import FormProjetComponent from './form-projet.component';
import { useState } from 'react';
import projetService from '../../services/projet.service';
import ResultFormComponent from '../assets/container/resultForm.component';

const CreateProjetComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    /**
     * Création d'un projet contrat support avec
     * les vérifications inhérentes aux champs
     * spécifiques supplémentaires à celui ci
     * @param {*} event
     */
    const handleCreateProjetSupport = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        const {
            inputClient,
            inputRegion,
            inputStatus,
            inputCode,
            inputName,
            inputDateCommande,
            inputEstPrepaye,
            inputCommentaire,
            inputTypeActivity,
            inputFacturation,
            inputReferent,
            inputTrigramme,
            inputGouvernance,
            inputColor,
            inputDevises,
            checkedCriticitesState,
            contactsState,
        } = dataForm;

        projetService
            .createProjetSupport(
                inputClient.value,
                inputRegion,
                inputStatus,
                inputCode,
                inputName,
                inputDateCommande,
                inputEstPrepaye,
                inputCommentaire || '',
                inputTypeActivity,
                inputFacturation,
                inputReferent.value,
                inputTrigramme,
                inputGouvernance,
                inputColor,
                inputDevises,
                checkedCriticitesState,
                contactsState,
                props.user.entity,
            )
            .then((response) => {
                if (response.error) {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(response.error);
                } else {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                }
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.message);
            });
    };

    /**
     * Fonction permettant de créer un projet
     * @param {*} event
     */
    const handeCreateProjet = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        const {
            inputClient,
            inputRegion,
            inputStatus,
            inputCode,
            inputName,
            inputDateCommande,
            inputEstPrepaye,
            inputCommentaire,
            inputTypeActivity,
            inputFacturation,
            inputReferent,
            inputTrigramme,
            inputColor,
            inputDevises,
        } = dataForm;

        projetService
            .createProjet(
                inputClient.value,
                inputRegion,
                inputStatus,
                inputCode,
                inputName,
                inputDateCommande,
                inputEstPrepaye,
                inputCommentaire || '',
                inputTypeActivity,
                inputFacturation,
                inputReferent.value,
                inputTrigramme,
                inputColor,
                inputDevises,
                props.user.entity,
            )
            .then((response) => {
                if (response.error) throw new Error(response.error);
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.message);
            });
    };

    const handleForm = (dataForm) => {
        if (dataForm.isTicketState) {
            handleCreateProjetSupport(dataForm);
        } else {
            handeCreateProjet(dataForm);
        }
    };
    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
            link={'/admin/projet-all'}
        >
            <FormProjetComponent handleForm={handleForm} />
        </ResultFormComponent>
    );
};
function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateProjetComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateProjetComponent);
