import axiosConfig from './axiosConfig';

const API_URL = '/authorization/bu/';

class BuService {
    getAllBUs() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    getAllArchivedBUs() {
        return axiosConfig
            .get(API_URL + 'archived/all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    /**
     * Requête une business unit
     * @param {Number} business_unit_id - id de business unit en param
     */
    getBusinessUnitById(business_unit_id) {
        return axiosConfig
            .get(API_URL + business_unit_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    deleteBusinessUnitById(business_unit_id) {
        return axiosConfig
            .delete(API_URL + business_unit_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    updateBusinessUnitById(business_unit_id, nom) {
        return axiosConfig
            .put(API_URL, { business_unit_id, nom })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    unarchiveBusinessUnitById(business_unit_id) {
        return axiosConfig
            .put(API_URL + 'unarchive/' + business_unit_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    /**
     * Créer une business unit avec l'entité du token
     * @param {String} nom - Nom de la business unit
     */
    create(nom) {
        return axiosConfig
            .post(API_URL, {
                nom,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
}

export default new BuService();
