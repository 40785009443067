import { Avatar, Button, FormHelperText, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import NiceAvatar, { genConfig } from 'react-nice-avatar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import HearingIcon from '@mui/icons-material/Hearing';
import Face2Icon from '@mui/icons-material/Face2';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Controller, useForm } from 'react-hook-form';
import { getRandomHexaColor, InputColorComponent } from './inputColor.component';
import InputFichierComponent from './inputFichier.component';
import DomToImage from 'dom-to-image';
import SimpleModalComponent from '../modal/simple-modal.component';
import { saveAs } from 'file-saver';
// import { saveAs } from 'file-saver';

const CustomAvatarComponent = (field) => {
    const {
        control,
        formState: { errors },
    } = useForm();

    const allConfig = {
        faceColor: {
            label: 'Couleur de peau',
            icon: <ColorLensIcon />,
            items: ['#FEE1D5', '#FEC3AC', '#B18878', '#914D30'],
        },
        earSize: { label: 'Oreilles', icon: <HearingIcon />, items: ['small', 'big'] },
        hairColor: 'TypeColor',
        hairStyle: {
            label: 'Cheveux',
            icon: <Face2Icon />,
            items: ['normal', 'thick', 'mohawk', 'womanLong', 'womanShort'],
            color: 'hairColor',
        },
        hatColor: 'TypeColor',
        hatStyle: {
            label: 'Chapeaux',
            icon: <PsychologyAltIcon />,
            items: ['none', 'beanie', 'turban'],
        },
        eyeStyle: { label: 'Yeux', icon: <RemoveRedEyeIcon />, items: ['circle', 'oval', 'smile'] },
        glassesStyle: {
            label: 'Lunettes',
            icon: <VoicemailIcon sx={{ transform: 'rotate(180deg)' }} />,
            items: ['none', 'round', 'square'],
        },
        noseStyle: {
            label: 'Nez',
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    fill="none"
                    viewBox="0 0 48 48"
                    id="nose"
                >
                    <path
                        fill="#fff"
                        d="M30.915 6.40344C31.1378 5.8981 30.9088 5.30781 30.4035 5.085 29.8981 4.86219 29.3078 5.09123 29.085 5.59657L29.0812 5.60522 29.0681 5.63468C29.0563 5.66113 29.0384 5.70109 29.0146 5.75381 28.967 5.85925 28.8957 6.01573 28.8022 6.21734 28.6153 6.62061 28.3397 7.20416 27.987 7.92086 27.2812 9.35509 26.2687 11.3182 25.0421 13.4346 22.5625 17.713 19.3008 22.451 16.0091 24.824 11.6647 27.9421 10.3595 31.7759 11.2741 34.9855 12.1703 38.1305 15.139 40.3726 18.6437 40.3726H21.8726C23.3237 40.3726 24.5 41.5489 24.5 43H26.5C26.5 40.4443 24.4282 38.3726 21.8726 38.3726H21.2969C21.5215 38.0367 21.8795 37.7078 22.4227 37.4545 23.4172 36.9907 25.1029 36.755 27.7739 37.3749 28.3119 37.4998 28.8493 37.1649 28.9741 36.6269 29.099 36.0889 28.7641 35.5515 28.2261 35.4267 25.2972 34.7469 23.1162 34.9244 21.5774 35.6419 20.2196 36.2751 19.4217 37.306 19.1156 38.3726H18.6437C15.9969 38.3726 13.8393 36.6893 13.1976 34.4374 12.5743 32.2502 13.3194 29.2159 17.1762 26.4481L17.1779 26.4469C20.8478 23.8016 24.2893 18.7222 26.7725 14.4375 28.0274 12.2722 29.0613 10.2673 29.7815 8.80397 30.1417 8.07185 30.424 7.4742 30.6168 7.05848 30.7131 6.85059 30.7871 6.68811 30.8373 6.57698 30.8624 6.52142 30.8816 6.47868 30.8946 6.44951L30.9095 6.41602 30.9145 6.4046 30.915 6.40344C30.9151 6.40321 30.915 6.40344 30 6.00001L30.915 6.40344zM35.7557 34.991C35.2367 37.0815 33.9392 38.9698 32.5289 39.8487L31.4711 38.1513C32.3531 37.6017 33.3938 36.2043 33.8147 34.5091 34.2236 32.8618 34.0101 31.101 32.655 29.6936L34.0958 28.3064C36.0417 30.3276 36.2866 32.8526 35.7557 34.991z"
                    ></path>
                </svg>
            ),
            items: ['short', 'long', 'round'],
        },
        mouthStyle: {
            label: 'Bouche',
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="none"
                    viewBox="0 0 48 48"
                    id="mouth"
                >
                    <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M4 24C7 18.5453 15.2 10.2545 24 14.1818C33 9.81822 41 18.5454 44 24C41 28.3636 32.8 36 24 36C15.2 36 7 28.3636 4 24ZM9.30929 23.5229C12.4149 22.4731 18.1843 21 24 21C29.7472 21 35.4492 22.4386 38.5799 23.4856C39.0367 23.6384 39.0113 24.2838 38.55 24.4225L38.5245 24.4302C35.5004 25.3398 29.9805 27 24 27C17.9208 27 12.3174 25.3748 9.32775 24.4265C8.88033 24.2845 8.86462 23.6732 9.30929 23.5229Z"
                        clipRule="evenodd"
                    ></path>
                </svg>
            ),
            items: ['laugh', 'smile', 'peace'],
        },
        shirtStyle: {
            label: 'Vêtement',
            icon: <CheckroomIcon />,
            items: ['hoody', 'short', 'polo'],
        },
        shirtColor: 'TypeColor',
        bgColor: 'TypeColor',
        eyeBrowStyle: {
            label: 'Expression',
            icon: <SentimentVerySatisfiedIcon />,
            items: ['up', 'upWoman'],
        },
    };

    const shirtColor = getRandomHexaColor();
    const hatColor = getRandomHexaColor();
    const bgColor = getRandomHexaColor();
    const hairColor = getRandomHexaColor();

    const [configAvatarState, setConfigAvatarState] = useState({
        bgColor: bgColor,
        earSize: allConfig.earSize.items[0],
        eyeBrowStyle: allConfig.eyeBrowStyle.items[0],
        eyeStyle: allConfig.eyeStyle.items[0],
        faceColor: allConfig.faceColor.items[0],
        glassesStyle: allConfig.glassesStyle.items[0],
        hairColor: hairColor,
        hairStyle: allConfig.hairStyle.items[0],
        hatColor: hatColor,
        hatStyle: allConfig.hatStyle.items[0],
        mouthStyle: allConfig.mouthStyle.items[0],
        noseStyle: allConfig.noseStyle.items[0],
        shirtColor: shirtColor,
        shirtStyle: allConfig.shirtStyle.items[1],
    });
    const [createdConfigState, setCreatedConfigState] = useState(null);
    const [isOpenState, setIsOpenState] = useState(false);

    const handleCloseModal = () => {
        setIsOpenState(false);
    };
    const handleOpenModal = () => {
        setIsOpenState(true);
    };

    const downloadAvatar = async () => {
        const scale = 2;
        const node = document.getElementById('idAvatar');
        if (node) {
            const blob = await DomToImage.toBlob(node, {
                height: node.offsetHeight * scale,
                style: {
                    transform: `scale(${scale}) translate(${node.offsetWidth / 2 / scale}px, ${
                        node.offsetHeight / 2 / scale
                    }px)`,
                    'border-radius': 0,
                },
                width: node.offsetWidth * scale,
                type: 'image/png',
            });
            saveAs(blob);
        }
    };

    const handleChangeNextConfigValue = async (key, value) => {
        if (allConfig[key] !== 'TypeColor') {
            const index = allConfig[key].items.findIndex((item) => item === value);

            setConfigAvatarState({
                ...configAvatarState,
                [`${key}`]:
                    allConfig[key].items[index === allConfig[key].items.length - 1 ? 0 : index + 1],
            });
        } else {
            setConfigAvatarState({ ...configAvatarState, [`${key}`]: value });
        }
    };

    useEffect(() => {
        setCreatedConfigState(genConfig(configAvatarState));
    }, [configAvatarState]);

    return (
        <>
            <InputFichierComponent
                {...field}
                errors={errors}
                accept="image/*"
                avatarMode
                isUpdate
                filename={field.content.utilisateur.photo || null}
            />
            <FormHelperText sx={{ textAlign: 'right', mt: 3 }}>
                Vous n'avez pas de photo de profil ?{' '}
                <Button variant="text" onClick={handleOpenModal}>
                    Créez votre avatar
                </Button>
            </FormHelperText>
            <SimpleModalComponent isOpen={isOpenState} handleCloseModal={handleCloseModal}>
                <Grid container justifyContent="center" alignItems={'center'}>
                    <div id="idAvatar">
                        <NiceAvatar
                            style={{ width: '8rem', height: '8rem' }}
                            {...createdConfigState}
                        />
                    </div>
                    <Grid item xs={7}>
                        <Grid container direction="column" alignItems="flex-end">
                            <Grid item>
                                <Controller
                                    name="inputBgColor"
                                    control={control}
                                    defaultValue={field.bgColor || bgColor}
                                    render={({ field }) => (
                                        <InputColorComponent
                                            {...field}
                                            label="Fond"
                                            ref={null}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleChangeNextConfigValue('bgColor', value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="inputColorShirt"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Le champ couleur est obligatoire',
                                        },
                                    }}
                                    defaultValue={field.shirtColor || shirtColor}
                                    render={({ field }) => (
                                        <InputColorComponent
                                            {...field}
                                            label="Habit"
                                            ref={null}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleChangeNextConfigValue('shirtColor', value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="inputHairColor"
                                    control={control}
                                    defaultValue={field.hairColor || hairColor}
                                    render={({ field }) => (
                                        <InputColorComponent
                                            {...field}
                                            label="Cheveux"
                                            ref={null}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleChangeNextConfigValue('hairColor', value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="inputHatColor"
                                    control={control}
                                    defaultValue={field.hatColor || hatColor}
                                    render={({ field }) => (
                                        <InputColorComponent
                                            {...field}
                                            label="Coiffe"
                                            ref={null}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleChangeNextConfigValue('hatColor', value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Button type="button" onClick={downloadAvatar} variant="contained">
                            Télécharger l'image
                        </Button>
                    </Grid>

                    <Grid
                        container
                        sx={{ mt: 1 }}
                        columns={
                            Object.keys(allConfig).filter((key) => allConfig[key] !== 'TypeColor')
                                .length
                        }
                    >
                        {Object.keys(allConfig).map((key, index) => {
                            return (
                                allConfig[key] !== 'TypeColor' && (
                                    <Grid item xs={1} key={'config_' + index}>
                                        <Avatar
                                            sx={{
                                                cursor: 'pointer',
                                                ':hover': {
                                                    backgroundColor: 'red',
                                                },
                                            }}
                                            onClick={() => {
                                                handleChangeNextConfigValue(
                                                    key,
                                                    configAvatarState[key],
                                                );
                                            }}
                                        >
                                            {allConfig[key].icon}
                                        </Avatar>
                                    </Grid>
                                )
                            );
                        })}
                    </Grid>
                </Grid>
            </SimpleModalComponent>
        </>
    );
};

export default CustomAvatarComponent;
