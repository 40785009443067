import { useState, useEffect } from 'react';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import buService from '../../../services/bu.service';

import ResultFormComponent from '../../assets/container/resultForm.component';
import FormBusinessUnitComponent from './form-business_unit.component';

const CreateBusinessUnitComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Créer une business unit');
    }, []);

    const handleCreateBusinessUnit = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        buService
            .create(dataForm.inputName)
            .then((response) => {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    return (
        <ResultFormComponent
            success={successState}
            loading={loadingState}
            message={messageState}
            submitted={submittedState}
            link={'/admin/business-units'}
        >
            <FormBusinessUnitComponent handleForm={handleCreateBusinessUnit} />
        </ResultFormComponent>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateBusinessUnitComponent = connect(
    null,
    mapDispatchToProps,
)(CreateBusinessUnitComponent);
