const graviteeConfig = {
    development: {
        DOMAIN: 'nprod',
        AUTH_URL: 'http://172.16.70.3:8092',
        GATEWAY_URL: 'http://172.16.70.3:8082/neocra',
        CLIENT_ID: 'c2321762-de4f-4c22-b217-62de4fdc226b',
        REDIRECT_URI: `http://${window.location.hostname}:3000`,
        BASIC_TOKEN:
            'YzIzMjE3NjItZGU0Zi00YzIyLWIyMTctNjJkZTRmZGMyMjZiOnpNLUVIc3JCNXVtY3c5TEVyS2Q5Rm41LWFtRk1tYjJyYnBnd2UtaFlMX1E=',
        SCOPE: 'openid full_profile',
        usePKCE: false,
    },
    recette: {
        DOMAIN: 'neodt-preprod',
        AUTH_URL: 'https://preprod-auth.neodt.fr',
        GATEWAY_URL: 'https://preprod-gateway.neodt.fr/api/neocra-preprod',
        CLIENT_ID: '9e9f6f27-1789-4171-9f6f-27178961711a',
        REDIRECT_URI: `https://${window.location.hostname}`,
        BASIC_TOKEN:
            'OWU5ZjZmMjctMTc4OS00MTcxLTlmNmYtMjcxNzg5NjE3MTFhOmRNeUk5RTV1SVJ5VmpYLVhDdWwzVnFaM0U4SWJsLWZGYnhEYzZ3a0x0THc=',
        SCOPE: 'openid full_profile',
        usePKCE: true,
    },
    production: {
        DOMAIN: 'neodt-prod',
        AUTH_URL: 'https://auth.neodt.fr',
        GATEWAY_URL: 'https://gateway.neodt.fr/api/neocra',
        CLIENT_ID: '3197b3c4-ebd1-47d1-97b3-c4ebd1c7d194',
        REDIRECT_URI: `https://neocra.neodt.fr`,
        BASIC_TOKEN:
            'MzE5N2IzYzQtZWJkMS00N2QxLTk3YjMtYzRlYmQxYzdkMTk0OlVCY0ZES3Q4R29jRnFnN3BOSmJ1eVUxb3pnRFpZVzMwaEdwbGZoNjgzM0E=',
        SCOPE: 'openid full_profile',
        usePKCE: true,
    },
};

export default graviteeConfig;
