import { Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import PastilleComponent from './pastille.component';
import ActivityComponent from '../activity/activitys.component';
import { useState } from 'react';
import ActivityPinComponent from './activity-pin.component';

/**
 * @param {Object} day - l'objet jour à afficher contenant les activités
 */

const DayComponent = (props) => {
    const theme = useTheme();
    const dayItem = props.dayItem;
    const activities = dayItem.activities;
    // const [ticketCounterState, setTicketCounterState] = useState(0);
    const [activitiesToDisplayState, setActivitiesToDisplayState] = useState([]);
    // const [ticketsToDisplayState, setTicketsToDisplayState] = useState([]);
    const [activitiesOverflowingState, setActivitiesOverflowingState] = useState(0);

    // Check taille écran
    const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

    /**
     * Retourne un tableau ordonné du plus grand au plus petit basé sur la valeur de l'activité
     */
    const orderActivitiesArray = (activityList) => {
        return activityList.sort(function (a, b) {
            return b.activity.valeur - a.activity.valeur;
        });
    };

    /** Insère dans le state la liste des activités à afficher */
    const getActivitiesToDisplay = (activityList) => {
        let orderedActivityList = orderActivitiesArray(activityList);
        let overflowCounter = 0;
        let tempActivitiesOverflowingState = 0;
        let tempActivitiesToDisplay = [];
        orderedActivityList.forEach((activityObject) => {
            // S'il n'y a qu'un seul élément dans les activités, on le retourne toujours
            if (orderedActivityList.length === 1) {
                return (tempActivitiesToDisplay = [
                    ...tempActivitiesToDisplay,
                    activityObject.activity,
                ]);
            }
            // sinon, on check si l'ajout de l'activité avec les autres dépasse le conteneur
            if (activityObject.activity.valeur + overflowCounter <= 1) {
                overflowCounter += activityObject.activity.valeur;
                tempActivitiesToDisplay = [...tempActivitiesToDisplay, activityObject.activity];
            } else {
                // si ça dépasse, on augmente juste l'overflow counter
                tempActivitiesOverflowingState += 1;
            }
        });
        // une fois la boucle terminée, on écrit dans le state
        setActivitiesOverflowingState(tempActivitiesOverflowingState);
        setActivitiesToDisplayState(tempActivitiesToDisplay);
    };

    /** Insère dans le state la liste des tickets à afficher */
    const getTicketsToDisplay = (ticketList) => {
        // let ticketNumber = 0;
        let overflowNumber = 0;
        ticketList.forEach(() =>
            // ticket
            {
                overflowNumber++;
                // ticketNumber += ticket.activity.valeur;
            },
        );
        setActivitiesOverflowingState(overflowNumber - 1);
        // setTicketCounterState(ticketNumber);
    };
    /*** DATA MANAGING LAYER */
    /** Retourne les activités non fausses */
    const filterFalseActivities = (activities) => {
        return activities.filter((activity) => {
            // on filtre les activités "false" (donc inexitantes)
            return activity !== false;
        });
    };

    /** Classes les activités présentes state selon leur type */
    const classifyActivities = (activities) => {
        let ticketActivities = [];
        let jourActivities = [];
        let conges = [];
        jourActivities = activities.filter((activity) => {
            if (activity.activity.projet.fk_activity_type !== 0) {
                if (activity.activity.projet.fk_activity_type === props.allActivityTypes['Ticket'])
                    ticketActivities = [...ticketActivities, activity];
                return activity.activity.projet.fk_activity_type === props.allActivityTypes['Jour'];
            } else {
                return true;
            }
        });

        return { jourActivities, ticketActivities, conges };
    };

    const createTexte = () => {
        return activities.map((activity, index) => {
            let activityTemp = activity.activity;
            let code_projet = activityTemp.projet.code_projet || activityTemp.projet.trigramme;
            let nom_projet =
                activityTemp.projet.nom === undefined ? '' : ' (' + activityTemp.projet.nom + ')';
            return (
                <div key={'tooltip_activity_' + index}>
                    {activityTemp.valeur + 'j - ' + code_projet + nom_projet}
                </div>
            );
        });
    };

    useEffect(() => {
        /** Au mount du component de ce jour, on vérifie d'abord s'il y a
         * des activités à afficher et on classe selon le type ticket ou jour */
        let activitiesToManage = filterFalseActivities(activities);

        if (activitiesToManage.length > 0) {
            // si des activités sont présentes, on les classes selon le type
            let { jourActivities, ticketActivities } = classifyActivities(activitiesToManage);
            // Promise.all([responseClassify]).then(([res]) => {
            //     let { jourActivities, ticketActivities } = res;

            // A MODIFIER POUR UNE MEILLEURE GESTION ACTIVITÉS/TICKETS
            jourActivities.length >= 1 && getActivitiesToDisplay(jourActivities);
            ticketActivities.length >= 1 && getActivitiesToDisplay(ticketActivities);
            ticketActivities.length >= 1 && getTicketsToDisplay(ticketActivities);
            // });
        }
    }, []);

    return (
        <>
            {
                // Si on est en planning manager et que la taille de l'écran est sous la taille "lg", on affiche le jour + la pastille
                !!props.employe && isDownLg && (
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                backgroundColor: props.status ? 'primary.main' : 'secondary.main',
                                color: '#fff',
                            }}
                        >
                            <Grid container>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontSize: '11',
                                        }}
                                    >
                                        {dayItem.nom} {dayItem.dayNumber}{' '}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {dayItem.ferie && (
                                        <PastilleComponent type="ferie" texte={dayItem.ferie} />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {/* Ici le container concernant le jour en lui-même et l'affichage des activités */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    height: '100%',
                    backgroundColor: !props.status
                        ? theme.palette.mode === 'light'
                            ? 'secondary.veryLight'
                            : 'secondary.dark'
                        : 'light.grey',
                    '&:hover': {
                        backgroundColor:
                            theme.palette.mode === 'light' ? 'primary.extraLight' : 'primary.dark',
                    },
                    borderColor: 'light.main',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    p: !props.employe && 1,
                }}
                onClick={(e) => {
                    props.openRightPanel(
                        e,
                        <ActivityComponent
                            key={'day_item_activity_' + dayItem.dayNumber}
                            jour={dayItem}
                            openRightPanel={props.openRightPanel}
                            rerenderParentCallback={props.rerenderParentCallback}
                            activities={props.activities}
                            monthCalendar={props.monthCalendar}
                            closeRightPanel={props.closeRightPanel}
                            employe={props.employe}
                        />,
                    );
                }}
            >
                {/* Ici le container affichant le numéro du jour, la pastille et l'activitiesOverflow */}
                {!props.employe ? (
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        columns={11}
                        sx={{
                            height: '30%',
                        }}
                    >
                        <Grid item>
                            <Typography
                                sx={{
                                    fontSize: '10px',
                                    color:
                                        new Date().toDateString() ===
                                        new Date(dayItem.date).toDateString()
                                            ? 'primary.main'
                                            : 'secondary.light',
                                    fontWeight:
                                        new Date().toDateString() ===
                                        new Date(dayItem.date).toDateString()
                                            ? 'bold'
                                            : 'inherit',
                                }}
                            >
                                {dayItem.dayNumber}
                            </Typography>
                        </Grid>
                        {dayItem.ferie && (
                            <Grid item>
                                <PastilleComponent type="ferie" texte={dayItem.ferie} />
                            </Grid>
                        )}
                        {activitiesOverflowingState > 0 && (
                            <Grid
                                item
                                sx={{
                                    mr: 0.5,
                                    mb: 0.5,
                                }}
                            >
                                <Tooltip title={createTexte()} placement="bottom">
                                    <Typography
                                        sx={{
                                            fontSize: 11,
                                            backgroundColor: 'primary.light',
                                            p: 0.5,
                                            borderRadius: '10%',
                                            width: '100%',
                                        }}
                                    >
                                        +{activitiesOverflowingState}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    activitiesOverflowingState > 0 && (
                        <Grid
                            container
                            item
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            columns={11}
                            sx={{
                                height: '30%',
                            }}
                        >
                            <Grid
                                item
                                sx={{ backgroundColor: 'secondary.veryLight', width: '100%' }}
                            >
                                <Typography sx={{ fontSize: '60%' }}>
                                    + {activitiesOverflowingState}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                )}
                {/* Ici la partie concernant l'affichage de l'activité */}
                {isUpMd ? (
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ height: '80%' }}
                    >
                        {activitiesToDisplayState.map((activity, index) => {
                            return (
                                <ActivityPinComponent
                                    activityItem={activity}
                                    key={index + '_' + activity.trigramme}
                                    isManager={!!props.employe}
                                    allActivityTypes={props.allActivityTypes}
                                />
                            );
                        })}
                    </Grid>
                ) : (
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                        {activitiesToDisplayState.map((activity, index) => {
                            return (
                                <Grid item xs={12} key={index + '_' + activity.trigramme}>
                                    <ActivityPinComponent
                                        activityItem={activity}
                                        allActivityTypes={props.allActivityTypes}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default DayComponent;
