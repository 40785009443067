/** REDUX functions */
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { setPageTitle } from '../../../redux/actions/scopeAction';
// import projetService from '../../../services/projet.service';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { CircularProgress } from '@mui/material';
import { ConnectedAdminProjetComponent } from '../../projet/adminProjet.component';

export const ManagerProjetComponent = () => {
    return <ConnectedAdminProjetComponent isManager={true} />;
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedManagerProjetComponent = connect(
    null,
    mapDispatchToProps,
)(ManagerProjetComponent);
