import { Link } from 'react-router-dom';
import userService from '../../../services/user.service';
import { useEffect, useState } from 'react';
import {
    Button,
    // CircularProgress,
    // Paper,
    // Table,
    // TableBody,
    // TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
} from '@mui/material';

// import { StripedTableRow, TableCellHead } from '../../../theming/customTheme';
import CenteredPaper from '../container/centeredPaper.component';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CrudPageComponent from '../page/crud-page.component';

const EquipeManagerComponent = (props) => {
    const [usersState, setUsersState] = useState('');
    const [errorState, setErrorState] = useState(null);

    useEffect(() => {
        userService.getManagedTeam(props.managerEmail).then((response) => {
            if (!response.error) {
                setUsersState(response);
            } else {
                setErrorState(response.error);
            }
        });
    }, []);

    return !!errorState ? (
        <CenteredPaper>
            <CancelIcon sx={{ fontSize: 100 }} color="error" />
            {errorState}
        </CenteredPaper>
    ) : (
        <CrudPageComponent
            title="Mon Équipe"
            objectName="user_team"
            displayType={true}
            headers={['', 'Nom', 'Prénom', 'Fonction']}
            error={errorState}
            withAvatar
            dataContent={
                !!usersState
                    ? usersState.map((user) => ({
                          _avatarDisplay: user,
                          nom: user.nom.toUpperCase(),
                          prenom: user.prenom.charAt(0).toUpperCase() + user.prenom.slice(1),
                          fonction: user.fonction.nom,
                      }))
                    : [{}]
            }
            actionsAddedContent={(user) => (
                <>
                    <Button
                        component={Link}
                        to={'/planning-manager'}
                        state={{ manager: user }}
                        variant="contained"
                        size="small"
                    >
                        <CalendarMonthIcon size="small" sx={{ mr: 1 }} />
                        Planning
                    </Button>
                    <Button
                        component={Link}
                        to={'/manager/user'}
                        state={{ user_email: user._avatarDisplay.email }}
                        variant="contained"
                        size="small"
                        sx={{ ml: 1 }}
                    >
                        <VisibilityIcon size="small" sx={{ mr: 1 }} />
                        Détails
                    </Button>
                </>
            )}
        />

        // <TableContainer component={Paper} elevation={4} sx={{ mt: 1 }}>
        //     <Table>
        //         <TableHead>
        //             <TableCellHead>Nom</TableCellHead>
        //             <TableCellHead>Prénom</TableCellHead>
        //             <TableCellHead>Fonction</TableCellHead>
        //             <TableCellHead>Actions</TableCellHead>
        //         </TableHead>
        //         <TableBody>
        //             {usersState !== '' ? (
        //                 usersState.length > 0 ? (
        //                     usersState.map((user) => {
        //                         return (
        //                             <StripedTableRow key={'user-' + user.user_id}>
        //                                 <TableCell>{user.nom.toUpperCase()}</TableCell>
        //                                 <TableCell>
        //                                     {user.prenom.charAt(0).toUpperCase() +
        //                                         user.prenom.slice(1)}{' '}
        //                                 </TableCell>
        //                                 <TableCell>{user.fonction.nom}</TableCell>
        //                                 <TableCell>
        //                                     <Button
        //                                         component={Link}
        //                                         to={'/planning-manager'}
        //                                         state={{ manager: user }}
        //                                         variant="contained"
        //                                         size="small"
        //                                     >
        //                                         <CalendarMonthIcon size="small" sx={{ mr: 1 }} />
        //                                         Planning
        //                                     </Button>
        //                                     <Button
        //                                         component={Link}
        //                                         to={'/manager/user'}
        //                                         state={{ user_email: user.email }}
        //                                         variant="contained"
        //                                         size="small"
        //                                         sx={{ ml: 1 }}
        //                                     >
        //                                         <VisibilityIcon size="small" sx={{ mr: 1 }} />
        //                                         Détails
        //                                     </Button>
        //                                 </TableCell>
        //                             </StripedTableRow>
        //                         );
        //                     })
        //                 ) : (
        //                     <TableRow>
        //                         <TableCell>Aucune données à afficher</TableCell>
        //                     </TableRow>
        //                 )
        //             ) : (
        //                 <TableRow>
        //                     <TableCell>
        //                         <CircularProgress />
        //                     </TableCell>
        //                 </TableRow>
        //             )}
        //         </TableBody>
        //     </Table>
        // </TableContainer>
    );
};

export default EquipeManagerComponent;
