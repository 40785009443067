import { Alert, Button, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { H2Lighter } from '../../../theming/customTheme';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import regionService from '../../../services/region.service';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FolderZipIcon from '@mui/icons-material/FolderZip';

const FormClientComponent = (props) => {
    const [regionsState, setRegionsState] = useState([]);
    const isUpdate = props.isUpdate || false;

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        const regions = regionService.getAllRegions();

        Promise.all([regions]).then(([regions]) => {
            setRegionsState(regions);
        });
    }, []);

    return (
        <CenteredPaper withMargin={true} withAlign={false} size="large" elevation={1}>
            <H2Lighter>{isUpdate ? 'Modifier le client' : 'Créer un client'}</H2Lighter>
            <form id="formClient">
                <Controller
                    key="inputNom"
                    name="inputNom"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Le nom du client est requis',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Nom du client"
                            placeholder="Société X"
                            sx={{ mt: 2 }}
                            error={!!errors.inputNom}
                            helperText={!!errors.inputNom && errors.inputNom.message}
                            fullWidth
                        />
                    )}
                    defaultValue={props.nom || null}
                />
                <Controller
                    key="inputVille"
                    name="inputVille"
                    control={control}
                    rules={{
                        required: { value: true, message: 'La ville est requise' },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Ville"
                            placeholder="Lille"
                            sx={{ mt: 2 }}
                            error={!!errors.inputVille}
                            helperText={!!errors.inputVille && errors.inputVille.message}
                            fullWidth
                        />
                    )}
                    defaultValue={props.ville || null}
                />
                <Controller
                    key="inputCommentaire"
                    name="inputCommentaire"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Commentaire (optionnel)"
                            placeholder="Commentaire éventuel"
                            sx={{ mt: 2 }}
                            rows={4}
                            multiline
                            fullWidth
                        />
                    )}
                    defaultValue={props.commentaire || null}
                />
                <Controller
                    key="inputRegion"
                    name="inputRegion"
                    control={control}
                    rules={{
                        required: { value: true, message: 'La ville est requise' },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Sélectionner une région"
                            sx={{ mt: 2 }}
                            error={!!errors.inputRegion}
                            helperText={!!errors.inputRegion && errors.inputRegion.message}
                            fullWidth
                            select
                        >
                            {props.regionArchive && (
                                <MenuItem value={props.region} selected>
                                    <Alert severity="warning">
                                        Attention, la region{' "'}
                                        {props.regionArchive + '"'} de ce projet est archivée.
                                    </Alert>
                                </MenuItem>
                            )}
                            {regionsState.map((region) => {
                                return (
                                    <MenuItem
                                        key={'region_' + region.region_id}
                                        value={region.region_id}
                                    >
                                        {region.nom}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    )}
                    defaultValue={props.region || null}
                />
                <div style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={handleSubmit(props.handleForm)}
                        sx={{ mt: 2 }}
                    >
                        {isUpdate ? <EditIcon sx={{ mr: 1 }} /> : <AddIcon sx={{ mr: 1 }} />}
                        {isUpdate ? 'Modifier' : 'Valider'}
                    </Button>
                    {isUpdate && (
                        <Button
                            type="button"
                            sx={{ mt: 2, ml: 1 }}
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit(props.handleArchiveClient)}
                        >
                            <FolderZipIcon sx={{ mr: 1 }} />
                            Archiver le client
                        </Button>
                    )}
                </div>
            </form>
        </CenteredPaper>
    );
};

export default FormClientComponent;
