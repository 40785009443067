// NOTE : les reducers ne modifient par les arguments
// NOTE : aucun appel API ou routes dans un reducer
import { combineReducers } from 'redux';

import scopeReducer from './reducers/scopeReducer';
import messageReducer from './reducers/messageReducer';
import authReducer from './reducers/authReducer';
import prefReducer from './reducers/prefReducer';

const rootReducer = combineReducers({
    scopeReducer,
    messageReducer,
    authReducer,
    prefReducer,
});

export default rootReducer;
