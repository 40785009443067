import axiosConfig from './axiosConfig';

const API_URL = '/authorization/times/';

class TimesService {
    /**
     * Retourne un Object contenant les jours feriés de l'année passé en paramètre
     * @param  {[number]} annee
     * @return {[Object]}
     */
    getFeries(annee) {
        fetch('https://calendrier.api.gouv.fr/jours-feries/metropole/' + annee + '.json')
            .then((response) => {
                response.json().then((response) => {
                    let result = [];
                    for (var i in response) result[i] = response[i];
                    return result;
                });
            })
            .catch(() => {
                return null;
            });
    }

    /**
     * Retourne un objet contenant la plus petite et la plus grande date en base
     * @returns Object{min: Integer, max: Integer}
     */
    getYearsRange() {
        return axiosConfig
            .get(API_URL + 'years-range')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    /**
     * Récupère la range des dates en bases min et max et leur ajoute un différentiel d'affichage
     * @param {Integer} intDifferentiel - entier de l'écart entre min et max et l'affichage
     */
    async handleAdaptedRange(intDifferentiel) {
        try {
            return this.getYearsRange().then((res) => {
                const range = {
                    min: (res.min || 2018) - intDifferentiel,
                    max: (res.max || new Date().getFullYear()) + intDifferentiel,
                };
                return range;
            });
        } catch (error) {
            return { min: 2018, max: new Date().getFullYear() + 1 };
        }
    }

    /**
     * Remplis l'interval entre deux entiers à partir du min jusque max avec l'incrément renseigné
     * @param {Integer} min - Année minimale
     * @param {Integer} max - Année maximale
     * @param {Integer} increment - Incrément par défaut = 1
     */
    fulfilledRange(min, max, increment = 1) {
        let fulfilledRangeArray = [];
        for (let index = min; index <= max; index += increment) {
            fulfilledRangeArray.push(index);
        }
        return fulfilledRangeArray;
    }
}

export default new TimesService();
