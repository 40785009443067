import { Typography } from '@mui/material';

const MiniTitleComponent = (props) => {
    return (
        <Typography
            variant="span"
            color="primary.dark"
            sx={{
                fontWeight: 'bold',
                mr: 1,
            }}
        >
            {props.children}
        </Typography>
    );
};

export default MiniTitleComponent;
