import axiosConfig from './axiosConfig';

const API_URL = '/authorization/soldes/';

class SoldesService {
    getAllSoldes() {
        return axiosConfig
            .get(API_URL + '')
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    createPeriodeSoldes(anneeDebut) {
        return axiosConfig
            .post(API_URL + '', { anneeDebut: anneeDebut })
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    deletePeriodeSoldes(id) {
        return axiosConfig
            .delete(API_URL + `${id}`)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    getUsersForPeriodeSoldes(index) {
        return axiosConfig
            .get(API_URL + 'usersForPeriodeSoldes/' + index)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    updateSoldeUser(objectSolde) {
        return axiosConfig
            .patch(API_URL + 'soldeUser/', objectSolde)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    getAllLogsForSoldesUtilisateurs(soldesUtilisateursId) {
        return axiosConfig
            .get(API_URL + 'allLogsForSoldesUtilisateurs/' + soldesUtilisateursId)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }
}

export default new SoldesService();
