import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import userService from '../../services/user.service';
import TitleComponent from '../assets/field/title.component';

const NotificationsControlComponent = (props) => {
    const [notification_fichiersState, setNotification_fichiersState] = useState(
        props.user.notification_fichiers,
    );
    const [notification_newsState, setNotification_newsState] = useState(
        props.user.notification_news,
    );
    const [notification_congesState, setNotification_congesState] = useState(
        props.user.notification_conges,
    );

    return (
        <>
            <TitleComponent>Gestion des notifications</TitleComponent>
            <FormControlLabel
                control={
                    <Switch
                        checked={notification_fichiersState}
                        onChange={(_, event) => {
                            setNotification_fichiersState(event);
                            userService.changeNotificationSettings({
                                notification_news: notification_newsState,
                                notification_fichiers: event,
                                notification_conges: notification_congesState,
                            });
                        }}
                    />
                }
                sx={{ mt: 1 }}
                label="Afficher les notifications de Fichiers: "
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={notification_newsState}
                        onChange={(_, event) => {
                            setNotification_newsState(event);
                            userService.changeNotificationSettings({
                                notification_news: event,
                                notification_fichiers: notification_fichiersState,
                                notification_conges: notification_congesState,
                            });
                        }}
                    />
                }
                label="Afficher les notifications de News: "
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={notification_congesState}
                        onChange={(_, event) => {
                            setNotification_congesState(event);
                            userService.changeNotificationSettings({
                                notification_news: notification_newsState,
                                notification_fichiers: notification_fichiersState,
                                notification_conges: event,
                            });
                        }}
                    />
                }
                label="Afficher les notifications de Congés: "
            />
        </>
    );
};

export default NotificationsControlComponent;
