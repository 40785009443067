import {
    Alert,
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CenteredPaper from '../assets/container/centeredPaper.component';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CancelIcon from '@mui/icons-material/Cancel';
import ActivityComponent from '../activity/activitys.component';
import { H2Lighter } from '../../theming/customTheme';
import { useEffect, useState } from 'react';
import congeService from '../../services/conge.service';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import { toast } from 'react-toastify';
import ErrorWarningComponent from '../assets/infos/error-warning.component';

const FormCongesComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [typeCongesOptionsState, setTypeCongesOptionsState] = useState([]);
    const [nbJourState, setNbJourState] = useState(0);
    const [errorNbJourState, setErrorNbJourState] = useState(false);
    const [dateDebutState, setDateDebutState] = useState(
        props.dateDebut || new Date(props.jour.date),
    );
    const [dateFinState, setDateFinState] = useState(props.dateFin || new Date(props.jour.date));
    const [periodeDebutState, setPeriodeDebutState] = useState(props.periodeDebut || 2);
    const [periodeFinState, setPeriodeFinState] = useState(props.periodeFin || 3);
    const [personalInfosState, setPersonalInfosState] = useState({
        soldeUtilisateur: { solde: 0 },
    });
    const [errorState, setErrorState] = useState(false);

    const isUpdate = props.isUpdate || false;

    const isExpiredOrCanceledOrEvaluated = {
        isEvaluated: !!props.est_evalue,
        isCanceled: !!props.demande_annulation,
        isExpired: !!(props.dateDebut && new Date(props.dateDebut) <= new Date()),
        allCombined:
            !!props.demande_annulation || //Si une demande d'annulation est faite
            !!(props.dateDebut && new Date(props.dateDebut) <= new Date()) || // Ou si la date de début est inférieur à la date actuelle
            !!props.est_evalue, // Ou si la demande a déjà été évaluée
    };

    // Création des constantes pour le formulaire
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handleFetchPersonalInfos = () => {
        congeService
            .getPersonalInfos(
                new Date(dateDebutState.getTime() - dateDebutState.getTimezoneOffset() * 60000),
                props.employe ? props.employe.user_id : null,
            )
            .then((personalInfos) => {
                if (personalInfos.error) {
                    toast.error(personalInfos.error);
                    setErrorState(personalInfos.error);
                } else {
                    setPersonalInfosState(personalInfos);
                }
            });
    };

    const handleFetchTypeConge = () => {
        congeService.getCongeTypes().then((congesTypes) => {
            setTypeCongesOptionsState(
                congesTypes.map((type) => ({
                    value: type.type_conge_id,
                    label: type.nom,
                })),
            );
        });
    };

    const handleFetchNbJour = () => {
        setLoadingState(true);
        congeService
            .getNbJour(
                new Date(dateDebutState.getTime() - dateDebutState.getTimezoneOffset() * 60000),
                new Date(dateFinState.getTime() - dateFinState.getTimezoneOffset() * 60000),
                periodeDebutState,
                periodeFinState,
            )
            .then((response) => {
                if (response.error) {
                    setErrorNbJourState(true);
                    setNbJourState('Attention : ' + response.error);
                    setLoadingState(false);
                } else {
                    setErrorNbJourState(false);
                    setNbJourState(response.nbJour);
                    setLoadingState(false);
                }
            });
    };

    const preHandleForm = (dataForm) => {
        props.handleForm({
            ...dataForm,
            inputDateDebut: new Date(
                dateDebutState.getTime() - dateDebutState.getTimezoneOffset() * 60000,
            ),
            inputDateFin: new Date(
                dateFinState.getTime() - dateFinState.getTimezoneOffset() * 60000,
            ),
            inputPeriodeDebut: periodeDebutState,
            inputPeriodeFin: periodeFinState,
        });
    };

    useEffect(() => {
        handleFetchTypeConge();
    }, []);

    useEffect(() => {
        handleFetchNbJour();
        handleFetchPersonalInfos();
    }, [dateDebutState, dateFinState, periodeDebutState, periodeFinState]);

    return (
        <CenteredPaper size="all" withMargin={false} withAlign={false} elevation={0}>
            {errorState ? (
                <ErrorWarningComponent
                    clickFunction={{ test: 'Fermer', function: props.closeRightPanel }}
                >
                    {errorState}
                </ErrorWarningComponent>
            ) : (
                <>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={1}>
                            <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                <ArrowCircleLeftIcon
                                    sx={{ '&:hover': { color: 'primary.main' } }}
                                    onClick={(e) =>
                                        props.openRightPanel(
                                            e,
                                            <ActivityComponent
                                                jour={props.jour}
                                                openRightPanel={props.openRightPanel}
                                                closeRightPanel={props.closeRightPanel}
                                                rerenderParentCallback={
                                                    props.rerenderParentCallback
                                                }
                                                activities={props.activities}
                                                monthCalendar={props.monthCalendar}
                                            />,
                                        )
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={10}>
                            <H2Lighter sx={{ textAlign: 'center' }}>
                                Créer une demande de congés
                            </H2Lighter>
                        </Grid>
                        <Grid item xs={1}>
                            <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
                                <CancelIcon
                                    sx={{ '&:hover': { color: 'primary.main' } }}
                                    onClick={props.closeRightPanel}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleSubmit(preHandleForm)}>
                        <Controller
                            name="inputType"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Le champ type de congés doit être saisi.',
                                },
                            }}
                            defaultValue={props.typeConges || null}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    fullWidth
                                    options={typeCongesOptionsState}
                                    getOptionLabel={(option) => option.label || ''}
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(_, value) => {
                                        field.onChange(value);
                                    }}
                                    disabled={isExpiredOrCanceledOrEvaluated.allCombined}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sélectionner un type de congés"
                                            sx={{ mt: 2 }}
                                            variant="outlined"
                                            error={!!errors.inputType}
                                            helperText={
                                                !!errors.inputType && errors.inputType.message
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
                            <Grid item xs={8}>
                                <Controller
                                    name="inputDateDebut"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Le champ date de début doit être saisi.',
                                        },
                                    }}
                                    defaultValue={dateDebutState}
                                    render={({ field }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={'fr'}
                                        >
                                            <DatePicker
                                                {...field}
                                                onChange={(value) => {
                                                    setDateDebutState(new Date(value));
                                                    field.onChange(value);
                                                }}
                                                disabled={
                                                    isExpiredOrCanceledOrEvaluated.allCombined
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        error={!!errors.inputDateDebut}
                                                        helperText={
                                                            !!errors.inputDateDebut
                                                                ? errors.inputDateDebut.message
                                                                : null
                                                        }
                                                        label="Saisir une date de début de congés"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl error={!!errors.inputSelectedLieu}>
                                    <Controller
                                        name="inputPeriodeDebut"
                                        defaultValue={periodeDebutState}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message:
                                                    'Le champ de période de début doit être saisi.',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                row
                                                onChange={(fk_client, value) => {
                                                    setPeriodeDebutState(value);
                                                    field.onChange(value);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={2}
                                                    control={<Radio />}
                                                    label="Matin"
                                                    disabled={
                                                        isExpiredOrCanceledOrEvaluated.allCombined
                                                    }
                                                />
                                                <FormControlLabel
                                                    value={3}
                                                    control={<Radio />}
                                                    label="Après-midi"
                                                    disabled={
                                                        isExpiredOrCanceledOrEvaluated.allCombined
                                                    }
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                    <FormHelperText>
                                        {!!errors.inputPeriodeDebut &&
                                            errors.inputPeriodeDebut.message}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
                            <Grid item xs={8}>
                                <Controller
                                    name="inputDateFin"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Le champ date de fin doit être saisi.',
                                        },
                                    }}
                                    defaultValue={dateFinState}
                                    render={({ field }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={'fr'}
                                        >
                                            <DatePicker
                                                {...field}
                                                onChange={(value) => {
                                                    setDateFinState(new Date(value));
                                                    field.onChange(value);
                                                }}
                                                disabled={
                                                    isExpiredOrCanceledOrEvaluated.allCombined
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        error={!!errors.inputDateFin}
                                                        helperText={
                                                            !!errors.inputDateFin
                                                                ? errors.inputDateFin.message
                                                                : null
                                                        }
                                                        label="Saisir une date de fin de congés"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl error={!!errors.inputPeriodeFin}>
                                    <Controller
                                        name="inputPeriodeFin"
                                        defaultValue={periodeFinState}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message:
                                                    'Le champ de période de fin doit être saisi.',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                row
                                                onChange={(_, value) => {
                                                    setPeriodeFinState(value);
                                                    field.onChange(value);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={2}
                                                    control={<Radio />}
                                                    label="Matin"
                                                    disabled={
                                                        isExpiredOrCanceledOrEvaluated.allCombined
                                                    }
                                                />
                                                <FormControlLabel
                                                    value={3}
                                                    control={<Radio />}
                                                    label="Après-midi"
                                                    disabled={
                                                        isExpiredOrCanceledOrEvaluated.allCombined
                                                    }
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                    <FormHelperText>
                                        {!!errors.inputPeriodeFin && errors.inputPeriodeFin.message}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Paper elevation={0} variant="outlined" sx={{ mt: 1, p: 1 }}>
                                    <Typography>
                                        <b>Nombre de jour à poser : </b>
                                        {nbJourState}
                                        {loadingState && (
                                            <WaitingScreenComponent
                                                withPaper={false}
                                                customText={' '}
                                            />
                                        )}
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper elevation={0} variant="outlined" sx={{ mt: 1, p: 1 }}>
                                    <Typography>
                                        <b>Solde de congés : </b>

                                        {personalInfosState.soldeUtilisateur.solde || 0}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        {(personalInfosState.soldeUtilisateur.solde || 0) - nbJourState < 0 && (
                            <Alert severity="warning" sx={{ mt: 1 }}>
                                Attention, votre solde passera en négatif
                            </Alert>
                        )}
                        <Controller
                            name="inputCommentaire"
                            control={control}
                            rules={{ required: false }}
                            defaultValue={props.commentaire || ''}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Commentaire"
                                    sx={{ mt: 1 }}
                                    error={!!errors.inputCommentaire}
                                    helperText={
                                        !!errors.inputCommentaire && errors.inputCommentaire.message
                                    }
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    disabled={isExpiredOrCanceledOrEvaluated.allCombined}
                                />
                            )}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 1 }}
                            fullWidth
                            disabled={
                                isExpiredOrCanceledOrEvaluated.allCombined ||
                                loadingState ||
                                errorNbJourState
                            }
                        >
                            {isUpdate ? (
                                <>
                                    <EditIcon sx={{ mr: 1 }} /> Modifier{' '}
                                </>
                            ) : (
                                <>
                                    <AddIcon sx={{ mr: 1 }} /> Valider
                                </>
                            )}
                        </Button>
                        {isUpdate && (
                            <Button
                                type="button"
                                variant="contained"
                                sx={{ mt: 1 }}
                                fullWidth
                                color="error"
                                disabled={
                                    (isExpiredOrCanceledOrEvaluated.allCombined
                                        ? isExpiredOrCanceledOrEvaluated.isEvaluated
                                            ? isExpiredOrCanceledOrEvaluated.isCanceled
                                                ? true
                                                : false
                                            : true
                                        : false) || loadingState
                                }
                                onClick={() => {
                                    props.handleDelete();
                                }}
                            >
                                <DeleteIcon sx={{ mr: 1 }} /> Supprimer
                            </Button>
                        )}

                        {isUpdate &&
                            isExpiredOrCanceledOrEvaluated.allCombined &&
                            (isExpiredOrCanceledOrEvaluated.isEvaluated ? (
                                isExpiredOrCanceledOrEvaluated.isCanceled ? (
                                    <FormHelperText error={true} sx={{ textAlign: 'center' }}>
                                        Ce congé fait l'objet d'une demande d'annulation. Vous ne
                                        pouvez donc pas le modifier.
                                    </FormHelperText>
                                ) : (
                                    <FormHelperText error={true} sx={{ textAlign: 'center' }}>
                                        Ce congé est déjà évalué, vous ne pouvez donc pas le
                                        modifier. Cependant, vous pouvez demander à l'annuler.
                                    </FormHelperText>
                                )
                            ) : (
                                <FormHelperText error={true} sx={{ textAlign: 'center' }}>
                                    Ce congé est expiré. Vous ne pouvez donc pas le modifier.
                                </FormHelperText>
                            ))}
                    </form>
                </>
            )}
        </CenteredPaper>
    );
};

export default FormCongesComponent;
