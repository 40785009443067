import { Box, Grid, Paper } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import congeService from '../../../services/conge.service';
import { H2Lighter } from '../../../theming/customTheme';
import MiniTitleComponent from '../field/mini-title.component';
import TitleComponent from '../field/title.component';

const WidgetCongesComponent = () => {
    const [congesState, setCongesState] = useState([]);
    const [diffDaysState, setDiffDaysState] = useState(0);

    useEffect(() => {
        congeService.getNextConges(2).then((lesConges) => {
            setCongesState(lesConges);
            if (lesConges.length > 0) {
                const diffTime = Math.abs(new Date() - new Date(lesConges[0].jour_conges[0].date));
                setDiffDaysState(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
            } else {
                setDiffDaysState('Aucun congés à venir');
            }
        });
    }, []);

    return (
        <>
            <TitleComponent>Mes prochains congés</TitleComponent>
            <H2Lighter>Nombre de jours avant les prochains congés : {diffDaysState}</H2Lighter>
            <Grid container sx={{ mt: 1 }} spacing={1}>
                {congesState.map((conge, index) => (
                    <Grid item xs={12} md={6} key={'grid_item_widget_conge_' + index}>
                        <Paper elevation={1} sx={{ p: 1, borderRadius: '0px !important' }}>
                            <Box>
                                <MiniTitleComponent>Date debut : </MiniTitleComponent>
                                {new Date(conge.jour_conges[0].date).toLocaleDateString()}
                            </Box>
                            <Box>
                                <MiniTitleComponent>Type de conge : </MiniTitleComponent>
                                {conge.type_conge.nom}
                            </Box>
                            <Box>
                                <MiniTitleComponent>Nombre de jour : </MiniTitleComponent>
                                {conge.solde}
                            </Box>
                            <Box>
                                <MiniTitleComponent>Commentaire : </MiniTitleComponent>
                                {conge.commentaire}
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default WidgetCongesComponent;
