import './portal.style.scss';
import Fixinggirl from '../../images/admin.svg';
import PortalButtonComponent from '../assets/portal-button.component';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import AdminPortalEntityComponent from './admin/admin-portal-entity.component';
import { useEffect, useState } from 'react';

import GroupIcon from '@mui/icons-material/Group';
import TaskIcon from '@mui/icons-material/Task';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Grid } from '@mui/material';
import { H2Title } from '../../theming/customTheme';

const AdminPortalComponent = (props) => {
    const [displayMenuModalState, setDisplayMenuModalState] = useState(false);
    const handleToggleMenuModal = () => {
        setDisplayMenuModalState(!displayMenuModalState);
    };
    const handleDisplayMenuModal = () => {
        return (
            displayMenuModalState && (
                <div className="menu-modal">
                    <AdminPortalEntityComponent role={props.role} />
                </div>
            )
        );
    };

    useEffect(() => {
        props.onLoad('Administrateur - Mon portail');
    }, []);

    return (
        <Box sx={{ p: 1 }}>
            <H2Title>Mes Liens</H2Title>

            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
                <PortalButtonComponent
                    title="Gestion utilisateurs"
                    icon={GroupIcon}
                    color="primary.main"
                    link="admin/users"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Gestion des projets"
                    icon={TaskIcon}
                    color="secondary.light"
                    link="admin/projet-all"
                    role={props.role}
                />

                <PortalButtonComponent
                    title="News et catégories"
                    icon={NewspaperIcon}
                    color="info.main"
                    link="admin/list-news"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Gestion des fichiers"
                    icon={AttachFileIcon}
                    color="warning.main"
                    link="admin/list-fichiers"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Gestion de l'entité"
                    icon={LensBlurIcon}
                    color="error.light"
                    functionButton={1}
                    toggleFunction={handleToggleMenuModal}
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Gestion NeoCRA"
                    icon={TuneIcon}
                    color="success.main"
                    link="admin/gestion-neocra"
                    role={props.role}
                />
            </Grid>
            {handleDisplayMenuModal()}
            <div className="background-decoration-svg">
                <img src={Fixinggirl} height="550px" width="800px" alt="" />
            </div>
        </Box>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedAdminPortalComponent = connect(
    null,
    mapDispatchToProps,
)(AdminPortalComponent);
