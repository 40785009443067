import { activityColor, displayStatusIcon } from '../../helpers/conge.helper';
import {
    Grid,
    Paper,
    Typography,
    useMediaQuery,
    keyframes,
    Tooltip,
    Box,
    lighten,
    useTheme,
} from '@mui/material';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { getConstrastColor } from '../../theming/customTheme';

const ActivityPinComponent = (props) => {
    const theme = useTheme();
    const isManager = props.isManager || false;
    const limiteCommentaire = 40;
    const popin = keyframes`
        0% {
            transform: scale(0)
        }
        75% {
            transform: scale(1.2)
        }
        100% {
            transform: scale(1)
        }
    `;

    const popinOpacity = keyframes`
        0% {
            opacity: 0
        }
        90% {
            opactive: 0
        }
        100% {
            opacity: 1
        }
    `;

    const createTexte = (activity) => {
        let texteFinal = '';
        let code_projet = activity.projet.code_projet || activity.projet.trigramme;
        let nom_projet = activity.projet.nom === undefined ? '' : ' (' + activity.projet.nom + ')';
        let commentaire = activity.commentaire || 'aucun commentaire';
        commentaire =
            commentaire.length > limiteCommentaire
                ? commentaire.substr(0, limiteCommentaire) + '...'
                : commentaire;

        texteFinal = (
            <div>
                {code_projet + ' ' + nom_projet} <br /> {commentaire}
            </div>
        );

        return texteFinal;
    };

    let activity = props.activityItem;

    let icon = displayStatusIcon(activity);
    createTexte(activity);

    return useMediaQuery(theme.breakpoints.up('md')) ? (
        <Tooltip
            title={createTexte(activity)}
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: isManager
                            ? activity.projet.type_facturation.code_hexa
                            : activityColor(activity),
                    },
                },
            }}
        >
            <Grid
                container
                sx={{
                    borderRadius: 0,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '4px',
                    borderLeftColor: isManager
                        ? activity.projet.type_facturation.code_hexa
                        : activityColor(activity),
                    backgroundColor: isManager
                        ? lighten(activity.projet.type_facturation.code_hexa, 0.7)
                        : lighten(activity.projet.couleur, 0.7),
                    color: getConstrastColor(
                        isManager
                            ? lighten(activity.projet.type_facturation.code_hexa, 0.7)
                            : lighten(activity.projet.couleur, 0.7),
                    ),
                    height:
                        activity.valeur <= 0.25
                            ? '25%'
                            : activity.valeur > 1
                            ? '90%'
                            : `${activity.valeur * 100 * 0.9}%`,
                    width: !isManager && activity.valeur < 0.25 ? '50%' : '100%',
                    transform: 'scale(1)',
                    transition: 'transform .5s',
                    ':hover': {
                        transform: 'scale(1.1)',
                    },
                    visible: 'hidden',
                    animation: `${popin} .5s ease ${activity.timeToDisplay}ms, ${popinOpacity} ${
                        (activity.timeToDisplay + 500) * 1.9
                    }ms ease 0s`,
                }}
                direction="row"
                justifyContent="space-between"
            >
                {!isManager && (
                    <Grid item>
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 0.5 }}>
                            <Typography sx={{ fontSize: '0.6rem', textAlign: 'left' }}>
                                {activity.valeur}
                            </Typography>
                            {activity.valeur !== 1 && (
                                <Typography>
                                    {activity.periode && activity.periode === 3
                                        ? ' (après-midi)'
                                        : activity.periode === 2 && ' (matin)'}
                                </Typography>
                            )}
                            {activity.projet.fk_activity_type ===
                                props.allActivityTypes['Ticket'] && (
                                <LocalOfferOutlinedIcon sx={{ fontSize: 15, ml: 1 }} />
                            )}
                        </Box>
                    </Grid>
                )}
                <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
                    <Typography variant="span" sx={{ fontWeight: 600 }}>
                        {activity.projet.trigramme}
                    </Typography>
                </Grid>
                {!isManager && (
                    <Grid item sx={{ alignItems: 'center', display: 'flex', pr: 1 }}>
                        {icon !== '' && icon}
                    </Grid>
                )}
            </Grid>
        </Tooltip>
    ) : (
        <Tooltip
            title={createTexte(activity)}
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: activityColor(activity),
                    },
                },
            }}
        >
            <Paper
                sx={{
                    backgroundColor: activity.projet.couleur,
                    p: 0.5,
                }}
            >
                <Typography sx={{ fontSize: 9, color: getConstrastColor(activity.projet.couleur) }}>
                    {activity.projet.trigramme}
                </Typography>
            </Paper>
        </Tooltip>
    );
};

export default ActivityPinComponent;
