import { React, useEffect, useState } from 'react';
import WeekComponent from './week.component';
import SelectionDateComponent from './selection-date.component';

/**
 * Helper pour les manipulations de date
 */
import * as DateHelper from '../../helpers/date.helper';
import timesService from '../../services/times.service';
import activityService from '../../services/activity.service';
import RightPanelComponent from '../assets/panels/right-panel.component';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

/**
 * CalendarComponent affiche le mois entier sous forme de table
 * à partir de la date passée en paramètre.
 * @param {Date} actualDate - La date à évaluer
 * @param {Array} activities - les activités à afficher
 */

const CalendarComponent = (props) => {
    const theme = useTheme();
    const actualDate = props.actualDate;
    const nextMonth = props.nextMonth;
    const previousMonth = props.previousMonth;
    const setYear = props.setYear;
    const setMonth = props.setMonth;
    const joursFeries = props.joursFeries;

    // Check taille écran
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [insertModalState] = useState(null);
    const [monthDateState] = useState(props.actualDate);
    const [displayMonthState] = useState(
        DateHelper.constructorMonth(actualDate, props.activities, props.allJoursFeries),
    );
    const [rightPanelDisplayState, setRightPanelDisplayState] = useState(false);
    const [panelContentState, setPanelContentState] = useState(null);
    const [yearsRangeState, setYearsRangeState] = useState([]);
    const [allActivityTypesState, setAllActivityTypesState] = useState(null);

    useEffect(() => {
        const allActivityTypes = activityService.getAllActivityTypesOrganized();

        Promise.all([allActivityTypes]).then(([res]) => {
            setAllActivityTypesState(res);
        });

        timesService.handleAdaptedRange(2).then((time) => {
            setYearsRangeState(timesService.fulfilledRange(time.min, time.max, 1));
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openRightPanel = (_, content) => {
        setPanelContentState(content);
        setRightPanelDisplayState(true);
    };
    const closeRightPanel = () => {
        setRightPanelDisplayState(false);
    };

    return (
        <Grid container direction="column">
            {insertModalState}
            <Grid item>
                <SelectionDateComponent
                    previousMonth={previousMonth}
                    monthDateState={monthDateState}
                    setMonth={setMonth}
                    setYear={setYear}
                    yearsRangeState={yearsRangeState}
                    nextMonth={nextMonth}
                />
            </Grid>
            <Grid item sx={{ mt: 1 }}>
                <Grid container columns={{ xs: 21, md: 22 }}>
                    {isUpMd && <Grid item xs={1}></Grid>}
                    {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map(
                        (jour, index) => (
                            <Grid
                                item
                                key={'jour_item_' + index}
                                xs={3}
                                md={3}
                                sx={{
                                    backgroundColor:
                                        jour === 'Samedi' || jour === 'Dimanche'
                                            ? 'secondary.main'
                                            : 'primary.main',
                                    color:
                                        jour === 'Samedi' || jour === 'Dimanche'
                                            ? 'secondary.contrastText'
                                            : 'primary.contrastText',
                                }}
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: { xs: 11, md: 'inherit' },
                                    }}
                                >
                                    {jour}
                                </Typography>
                            </Grid>
                        ),
                    )}
                </Grid>
            </Grid>
            <Grid item>
                {displayMonthState.map((week) => {
                    let toDisplay = week.jours.filter((jour) => jour.status > 0);
                    if (toDisplay.length > 0 && allActivityTypesState !== null) {
                        return (
                            <WeekComponent
                                weekItem={week}
                                key={'sm' + week.numero}
                                rerenderParentCallback={props.rerenderParentCallback}
                                openRightPanel={openRightPanel}
                                closeRightPanel={closeRightPanel}
                                showMultiple={props.clickSelectionMultiple ? true : false}
                                daysSelected={props.daysSelected}
                                joursFeries={joursFeries}
                                activities={props.activities}
                                monthCalendar={displayMonthState}
                                allActivityTypes={allActivityTypesState}
                            />
                        );
                    }
                    return null;
                })}
            </Grid>
            <RightPanelComponent
                rightPanelDisplayState={rightPanelDisplayState}
                closeRightPanel={closeRightPanel}
                panelContentState={panelContentState}
            />
        </Grid>
    );
};

export default CalendarComponent;
