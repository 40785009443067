import {
    Button,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import { connect } from 'react-redux';
import { H2Lighter } from '../../theming/customTheme';
import PublicFichiersComponent from '../fichiers/public-fichiers.component';
import { setPageTitle } from '../../redux/actions/scopeAction';
import PublicNewsComponent from '../news/public-news.component';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import widgetList from '../../helpers/widget.helper';
import userService from '../../services/user.service';
import SimpleModalComponent from '../assets/modal/simple-modal.component';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import { toast } from 'react-toastify';
import WaitingScreenComponent from '../assets/waiting-screen.component';

// import WidgetProjetComponent from '../assets/widgets/WidgetProjet.component';

const AccueilComponent = (props) => {
    const [editModeState, setEditModeState] = useState(false);
    const [widgetUserState, setWidgetUserState] = useState([]);
    const [isOpenState, setIsOpenState] = useState(false);
    const [currentWidgetState, setCurrentWidgetState] = useState(null);
    const [userState, setUserState] = useState(null);

    const handleCloseModal = () => {
        setIsOpenState(false);
    };

    const handleOpenModal = (currentWidget) => {
        setIsOpenState(true);
        setCurrentWidgetState(currentWidget);
    };

    const handleRefreshWidgets = () => {
        userService.getWidgets().then((widgets) => {
            setWidgetUserState(widgets);
        });
    };

    const handleRefreshUser = () => {
        userService.getUserInfos(props.user.email).then((res) => {
            if (!res.error) {
                setUserState(res);
            } else {
                toast.error(
                    "Une erreur est survenue lors de la récupération de l'utilisateur",
                    res.error,
                );
            }
        });
    };

    const handleSetWidget = (widgetKey) => {
        userService
            .updateUserFromObject({
                user_id: userState.utilisateur.user_id,
                [`widget${currentWidgetState + 1}`]: widgetKey,
            })
            .then((res) => {
                if (!res.error) {
                    handleCloseModal();
                    handleRefreshWidgets();
                    setEditModeState(false);
                } else {
                    toast.error('Une erreur est survenue : ' + res.error);
                }
            });
    };

    useEffect(() => {
        props.onLoad('Mon espace');
        handleRefreshWidgets();
        handleRefreshUser();
    }, []);

    const Widget1 = !!widgetUserState['widget1'] ? widgetList[widgetUserState['widget1']] : null;
    const Widget2 = !!widgetUserState['widget2'] ? widgetList[widgetUserState['widget2']] : null;
    const Widget3 = !!widgetUserState['widget3'] ? widgetList[widgetUserState['widget3']] : null;
    const Widget4 = !!widgetUserState['widget4'] ? widgetList[widgetUserState['widget4']] : null;

    return (
        <>
            {userState !== null ? (
                <>
                    <Grid container>
                        <H2Lighter>
                            Bienvenue dans votre espace !
                            <Tooltip title="Modifier mon espace">
                                <Button
                                    type="text"
                                    onClick={() => setEditModeState(!editModeState)}
                                >
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                        </H2Lighter>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} lg={5}>
                            <PublicNewsComponent />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <PublicFichiersComponent />
                        </Grid>
                    </Grid>

                    <H2Lighter>Widgets</H2Lighter>
                    <Grid container sx={{ height: '50%', mt: 2 }} spacing={1}>
                        {[Widget1, Widget2, Widget3, Widget4].map((Widget, index) => (
                            <Grid item xs={12} md={6} key={'widget_grid_accueil' + index}>
                                {editModeState ? (
                                    <Button
                                        variant="outlined"
                                        sx={{ height: '100%', width: '100%' }}
                                        onClick={() => handleOpenModal(index)}
                                    >
                                        {!!Widget ? (
                                            `Widget Présent : ${Widget.title}`
                                        ) : (
                                            <AddIcon />
                                        )}
                                    </Button>
                                ) : (
                                    !!Widget && <Widget.widget user={props.user} />
                                )}
                            </Grid>
                        ))}
                    </Grid>

                    <SimpleModalComponent isOpen={isOpenState} handleCloseModal={handleCloseModal}>
                        <List>
                            <ListItemButton onClick={() => handleSetWidget('')}>
                                <ListItemIcon>
                                    <BrowserNotSupportedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Aucun"
                                    secondary="Ne rien mettre dans cet emplacement"
                                />
                            </ListItemButton>
                            {Object.keys(widgetList).map((key, index) => {
                                const leWidget = widgetList[key];
                                return (
                                    <ListItemButton
                                        key={'widget_list_item_' + index}
                                        onClick={() => handleSetWidget(key)}
                                    >
                                        <ListItemIcon>{leWidget.icon}</ListItemIcon>
                                        <ListItemText
                                            primary={leWidget.title}
                                            secondary={leWidget.description}
                                        />
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </SimpleModalComponent>
                </>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedAccueilComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccueilComponent);
