import { Grid, MenuItem, Pagination, Slide, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import category_newsService from '../../services/category_news.service';
import newsService from '../../services/news.service';
import reactionsService from '../../services/reactions.service';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import NewsComponent from './news.component';

const PublicNewsComponent = () => {
    const NBR_NEWS = 3;
    const MAX_PAGE_DEFAULT = 5;
    const PAGE_DEFAULT = 1;
    const [allNewsState, setAllNewsState] = useState(null);
    const [pageState, setPageState] = useState(PAGE_DEFAULT);
    const [maxNewsPageState, setMaxNewsPageState] = useState(MAX_PAGE_DEFAULT);
    const [allCategoriesState, setAllCategoriesState] = useState(null);
    const [categoryState, setCategoryState] = useState(0);
    const [reactionsState, setReactionsState] = useState([{ reactions_id: '', name: '', svg: '' }]);

    const handleFetchNews = async () => {
        const resultAllNews = await newsService.getNewsFromPage({
            page: pageState,
            nbrElement: NBR_NEWS,
            category: categoryState,
        });

        const resultWithImages = await Promise.all(
            resultAllNews.map(async (news) => {
                const imageRes = await newsService.getImage(news.imageTitle);
                if (imageRes.error) {
                    toast.error(imageRes.error);
                    return news;
                } else {
                    return { ...news, imageTitle: imageRes };
                }
            }),
        );
        setAllNewsState(resultWithImages);
    };

    const handleMaxPageNews = () => {
        newsService
            .getMaxPageNews({ nbrElement: NBR_NEWS, category: categoryState })
            .then((res) => {
                if (res.error) {
                    toast.error(res.error);
                } else {
                    setMaxNewsPageState(res.nbrPage);
                    handleChangePage('', 1);
                }
            });
    };

    const handleFetchCategories = () => {
        category_newsService.getAll().then((res) => {
            if (res.error) {
                toast.error(res.error);
            } else {
                setAllCategoriesState(res);
            }
        });
    };

    const handleChangePage = (_, page) => {
        setPageState((prev) => {
            if (prev === page) {
                handleFetchNews();
                return page;
            } else {
                return page;
            }
        });
    };

    const handleFetchReactions = () => {
        reactionsService.getAll().then((res) => {
            if (res.error) {
                toast.error(res.error);
            } else {
                setReactionsState(res);
            }
        });
    };

    useEffect(() => {
        handleFetchReactions();
    }, []);

    useEffect(() => {
        handleFetchNews();
    }, [pageState]);

    useEffect(() => {
        handleMaxPageNews();
        handleFetchCategories();
    }, [categoryState]);

    return (
        <Grid container sx={{ maxHeight: '10%' }}>
            <Pagination
                count={maxNewsPageState}
                page={pageState}
                onChange={handleChangePage}
                color="primary"
                size="small"
            />
            <TextField
                select
                value={categoryState}
                label="Sélectionner une catégorie"
                fullWidth
                sx={{ mt: 2 }}
                size="small"
                onChange={(event) => {
                    setCategoryState(event.target.value);
                }}
            >
                <MenuItem value={0}>Sélectionner une catégorie</MenuItem>
                {allCategoriesState !== null &&
                    allCategoriesState.map((category) => (
                        <MenuItem
                            value={category.category_news_id}
                            key={'menu_item_category_' + category.category_news_id}
                        >
                            {category.name}
                        </MenuItem>
                    ))}
            </TextField>
            {allNewsState !== null ? (
                allNewsState.map((news, index) => {
                    return (
                        <Slide
                            key={'news_component_slide_' + index}
                            direction="right"
                            in={true}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Grid item key={'news_component_' + index} sx={{ p: 1 }} xs={12}>
                                <NewsComponent
                                    news={news}
                                    reactions={reactionsState}
                                    handleFetchNews={handleFetchNews}
                                />
                            </Grid>
                        </Slide>
                    );
                })
            ) : (
                <WaitingScreenComponent />
            )}
        </Grid>
    );
};

export default PublicNewsComponent;
