import { useEffect, useState } from 'react';
// validator form
import '../cards.style.scss';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';

//images
import { useLocation } from 'react-router-dom';
import projetService from '../../services/projet.service';
import ResultFormComponent from '../assets/container/resultForm.component';
import FormProjetComponent from './form-projet.component';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

export const ProjetComponent = (props) => {
    const location = useLocation();
    const [loadingState] = useState(false);
    const [successState] = useState(false);
    const [submittedState] = useState(false);
    const [contentState, setContentState] = useState(null);
    const [, setCheckedCriticitesState] = useState([]);
    const [, setContactsState] = useState([]);
    const [, setGouvernanceState] = useState('');
    const [messageState] = useState('');

    useEffect(() => {
        props.onLoad('Détails du projet');
        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const projet = projetService.getProjet(location.state.project_id);
        // on attend le réponse de toutes les requêtes et on les attribues au state local
        Promise.all([projet]).then(([projet]) => {
            setContentState(projet);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /* Callback function à la modif de content */
    useEffect(() => {
        if (contentState !== null) {
            handleFetchContratSupport(contentState.project_id, contentState.fk_activity_type);
        }
    }, [contentState]);

    const handleFetchContratSupport = (projectId, typeActivity) => {
        // si le type d'activité correspond à l'ID 2 (ticket)
        if (typeActivity === 2) {
            projetService.getContratSupport(projectId).then((res) => {
                if (!res.error) {
                    const datas = res;
                    setCheckedCriticitesState(datas.criticites);
                    setContactsState(datas.contacts);
                    setGouvernanceState(datas.gouvernance);
                }
            });
        }
    };

    return (
        <>
            {contentState !== null ? (
                <ResultFormComponent
                    submitted={submittedState}
                    loading={loadingState}
                    success={successState}
                    message={messageState}
                    link={'/admin/projet-all'}
                >
                    <FormProjetComponent
                        projectId={contentState.project_id}
                        referent={contentState.referent}
                        client={contentState.fk_client}
                        region={contentState.fk_region}
                        statuSelect={contentState.fk_status}
                        codeProjet={contentState.code_projet}
                        nom={contentState.nom}
                        dateCommande={contentState.date_commande}
                        estPrepaye={contentState.estPrepaye}
                        commentaire={contentState.commentaire}
                        typeActivity={contentState.fk_type_activity}
                        typeFacturation={contentState.fk_activity_facturation}
                        couleur={contentState.couleur}
                        trigramme={contentState.trigramme}
                        devises={contentState.devises_vendues}
                        isTicket={contentState.isTicketState}
                        // contacts={contentState.contacts}
                        // checkedCriticites={contentState.checkedCriticites}
                        // gouvernance={contentState.gouvernance}
                        // isUpdate={true}
                    />
                    <div dir="rtl">
                        <Button
                            component={Link}
                            variant="contained"
                            to="/admin/update-projet"
                            state={{
                                project_id: location.state.project_id,
                            }}
                        >
                            <EditIcon /> Modifier
                        </Button>
                    </div>
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedProjetComponent = connect(null, mapDispatchToProps)(ProjetComponent);
