import { Alert, Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import activityService from '../../../services/activity.service';
import MiniTitleComponent from '../field/mini-title.component';
import TitleComponent from '../field/title.component';
import WaitingScreenComponent from '../waiting-screen.component';

const WidgetProjetComponent = (props) => {
    const [activitiesState, setActivitiesState] = useState([]);
    const [errorState, setErrorState] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        activityService.getLastActivities(4).then((res) => {
            if (!res.error) {
                setActivitiesState(res);
            } else {
                setErrorState(res.error);
            }
        });
    }, []);

    return props.user && errorState === null ? (
        activitiesState !== [] ? (
            <>
                <TitleComponent>Dernières activités saisies</TitleComponent>
                <Grid container spacing={0.5} sx={{ mt: 1 }}>
                    {activitiesState.map((activity, index) => (
                        <Grid item xs={12} md={6} key={'widget_projet_grid_' + index}>
                            <Paper
                                elevation={1}
                                sx={{
                                    p: 1,
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? 'primary.extraLight'
                                            : 'primary.main',
                                    borderRadius: '0px !important',
                                }}
                            >
                                <Box>
                                    <MiniTitleComponent>Projet : </MiniTitleComponent>
                                    {activity.projet.code_projet + ' - ' + activity.projet.nom}
                                </Box>
                                <Box>
                                    <MiniTitleComponent>Durée : </MiniTitleComponent>
                                    {activity.valeur}
                                </Box>
                                <Box>
                                    <MiniTitleComponent>Saisi le :</MiniTitleComponent>
                                    <Typography variant="span"></Typography>
                                    {new Date(activity.updatedAt).toLocaleDateString()} à{' '}
                                    {new Date(activity.updatedAt).toLocaleTimeString()}
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </>
        ) : (
            <WaitingScreenComponent />
        )
    ) : (
        <Alert severity="info">Aucun projet à afficher {errorState}</Alert>
    );
};

export default WidgetProjetComponent;
