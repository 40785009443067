import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import fichiersService from '../../../services/fichiers.service';
import ResultFormComponent from '../../assets/container/resultForm.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';
import FormFichiersComponent from './form-fichiers.component';

const UpdateFichiersComponent = (props) => {
    const location = useLocation();
    const fichierId = location.state.objectId;

    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const [fichierFileState, setFichierFileState] = useState(null);
    const [fichierState, setFichierState] = useState(null);

    const handleFetchFichier = () => {
        fichiersService.getFichier(fichierId).then((leFichier) => {
            if (!leFichier.error) {
                fichiersService.getFichierFile(leFichier.fichier).then((fichierFile) => {
                    if (!fichierFile.error) {
                        console.log(leFichier);
                        setFichierState(leFichier);
                        setFichierFileState(fichierFile);
                    }
                });
            }
        });
    };

    const handleUpdateFichier = (dataForm) => {
        setLoadingState(1);
        setSubmittedState(true);
        setSuccessState(false);

        let promiseUpload = new Promise((resolve, reject) => {
            if (dataForm.inputFichier !== fichierFileState) {
                const formData = new FormData();
                formData.append('file', dataForm.inputFichier);
                fichiersService.uploadFile(formData).then((res) => {
                    if (res.error) {
                        reject(res);
                    } else {
                        resolve(res);
                    }
                });
            } else {
                resolve({ fichier: -1 });
            }
        });

        promiseUpload
            .then((res) => {
                setLoadingState(50);
                fichiersService
                    .updateFichiers({
                        fichiers_id: fichierState.fichiers_id,
                        name: dataForm.inputName,
                        description: dataForm.inputDescription,
                        fk_category: dataForm.inputCategory.value,
                        fichier: res.fichier.filename || res.fichier,
                        is_pinned: dataForm.checkPin,
                    })
                    .then((res) => {
                        if (res.error) {
                            setLoadingState(100);
                            setSuccessState(false);
                            setMessageState(res.error);
                        } else {
                            setLoadingState(100);
                            setSuccessState(true);
                            setMessageState(res.message);
                        }
                    });
            })
            .catch((err) => {
                setLoadingState(100);
                setSuccessState(false);
                setMessageState(err.error);
            });
    };

    const handleDeleteFichier = (idFichier) => {
        setLoadingState(true);
        setSubmittedState(true);
        fichiersService.deleteFichier(idFichier).then((res) => {
            if (res.error) {
                setLoadingState(false);
                setSubmittedState(true);
                setSuccessState(false);
                setMessageState(res.error);
            } else {
                setLoadingState(false);
                setSubmittedState(true);
                setSuccessState(true);
                setMessageState(res.message);
            }
        });
    };

    useEffect(() => {
        props.onLoad('Administrateur - Modifier un fichier');
        handleFetchFichier();
    }, []);

    if (fichierState !== null && fichierFileState !== null) {
        return (
            <ResultFormComponent
                loading={loadingState}
                success={successState}
                submitted={submittedState}
                message={messageState}
                link={'/admin/list-fichiers'}
            >
                <FormFichiersComponent
                    handleForm={handleUpdateFichier}
                    handleDelete={handleDeleteFichier}
                    isUpdate={true}
                    name={fichierState.name}
                    description={fichierState.description}
                    category={{
                        label: fichierState.fk_category.name,
                        value: fichierState.fk_category.category_fichiers_id,
                    }}
                    filename={fichierState.fichier}
                    fichier={fichierFileState}
                    is_pinned={fichierState.is_pinned}
                    uuid={fichierState.uuid}
                    id={fichierState.fichiers_id}
                />
            </ResultFormComponent>
        );
    } else {
        return <WaitingScreenComponent />;
    }
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateFichiersComponent = connect(
    null,
    mapDispatchToProps,
)(UpdateFichiersComponent);
