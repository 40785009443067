import { Typography, useTheme } from '@mui/material';

const TitleComponent = ({ children, addedSx }) => {
    const theme = useTheme();

    return (
        <Typography
            variant="h6"
            color={theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light'}
            sx={{ textDecoration: 'underline', ...addedSx }}
        >
            {children}
        </Typography>
    );
};

export default TitleComponent;
