import WaitingScreenComponent from '../waiting-screen.component';
import CenteredPaper from './centeredPaper.component';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * Composant centralisant la fonction de reconnaissance de l'état de validation d'un formulaire
 * Les propriétés obligatoires sont submitted, loading, success, message et content
 * Content correspond au vrai contenu de la page initiale
 * Optionnellement, il est possible de rajouter:
 *  - link pour créer un lien vers une page,
 *  - size pour gérer la taille du Paper
 *  - addedContent pour rajouter du contenu supplémentaire dans la pop up
 */
const ResultFormComponent = ({
    loading,
    submitted,
    success,
    message,
    children,
    link = null,
    addedContent = null,
    size = null,
}) => {
    let checkLoading = typeof loading === 'boolean' ? loading : loading === 100 ? false : true;

    return (
        <>
            {submitted ? (
                checkLoading ? (
                    <WaitingScreenComponent
                        loadingState={typeof loading === 'boolean' ? null : loading}
                    />
                ) : (
                    <CenteredPaper size={size}>
                        {success ? (
                            <>
                                <CheckCircleIcon sx={{ fontSize: 100 }} color="success" />
                                <Alert severity="success">{message}</Alert>
                            </>
                        ) : (
                            <>
                                <CancelIcon sx={{ fontSize: 100 }} color="error" />
                                <Alert severity="error">{message}</Alert>
                            </>
                        )}
                        {addedContent}
                        {!!link && <Link to={link}>Retour</Link>}
                    </CenteredPaper>
                )
            ) : (
                children
            )}
        </>
    );
};

export default ResultFormComponent;
