/** REDUX functions */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../../redux/actions/scopeAction';
import CrudPageComponent from '../../../assets/page/crud-page.component';
import congeService from '../../../../services/conge.service';
import './evaluate-conge.style.scss';
import { Badge, Box, Button, Tab, Tabs } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import WaitingScreenComponent from '../../../assets/waiting-screen.component';
import TabPanel from '../../../assets/container/tabPanel.component';
import EvaluateCancelComponent from './evaluate-cancel.component';
import { displayStatusIcon } from '../../../../helpers/conge.helper';
// import { toast } from 'react-toastify';

const EvaluateCongeComponent = (props) => {
    const [displayTypeState, setDisplayTypeState] = useState(true);
    const [contentState, setContentState] = useState('');
    const [errorState, setErrorState] = useState('');
    const [valueTabState, setValueTabState] = useState(0);
    const [loadingValidationState, setLoadingValidationState] = useState(false);
    const [countCategoriesState, setCountCategoriesState] = useState({
        waiting: 0,
        waitingCancel: 0,
    });

    const handleFetchConges = () => {
        (displayTypeState
            ? congeService.getAllWaitingConges()
            : congeService.getEvaluatedConges()
        ).then((conges) => {
            if (!conges.error) {
                return setContentState(conges);
            } else {
                setErrorState(conges.error);
            }
        });
    };

    const handleCountCategoryConges = () => {
        congeService.countAllCategoryConges().then((congesCategories) => {
            setCountCategoriesState(congesCategories);
        });
    };

    const handleRestore = (conges_id) => {
        congeService.restoreEvaluation(conges_id).then((response) => {
            if (!response.error) {
                toast.success(response.message);
                handleFetchConges();
            } else {
                toast.error(response.error);
                handleFetchConges();
            }
        });
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChangeTab = (event, newValue) => {
        setValueTabState(newValue);
        setDisplayTypeState(true);
    };

    const onChangeDisplayType = () => {
        setDisplayTypeState(!displayTypeState);
    };

    useEffect(() => {
        props.onLoad('Manager - Évaluer les congés');
        handleFetchConges();
        handleCountCategoryConges();
    }, [displayTypeState]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={valueTabState}
                    onChange={handleChangeTab}
                    aria-label="tabs for conges"
                    sx={{ '& .MuiTabs-flexContainer': { paddingTop: '12px' } }}
                >
                    <Tab
                        label={
                            <Badge
                                badgeContent={countCategoriesState.waiting}
                                color="primary"
                                sx={{ pt: '5px' }}
                            >
                                Demande de congés
                            </Badge>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={
                            <Badge
                                badgeContent={countCategoriesState.waitingCancel}
                                color="primary"
                                sx={{ pt: '5px' }}
                            >
                                Demande d'annulation
                            </Badge>
                        }
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>
            <TabPanel value={valueTabState} index={0}>
                <CrudPageComponent
                    title="Évaluer les congés"
                    objectName="conges"
                    onChangeDisplayType={onChangeDisplayType}
                    displayType={displayTypeState}
                    labelSwitchArchived="Afficher les congés évalués"
                    labelRestore="Annuler l'évaluation"
                    handleRestore={handleRestore}
                    error={errorState}
                    headers={[
                        'Id',
                        'Nom',
                        'Prénom',
                        'Date de début',
                        'Date de fin',
                        'Type de congé',
                        'Nombre de jour',
                        'État',
                        "Solde actuel de l'employé",
                    ]}
                    showId
                    dataContent={
                        !!contentState
                            ? contentState.map((conges) => ({
                                  conges_id: conges.conge.new_conges_id,
                                  nom: conges.conge.utilisateur.nom,
                                  prenom: conges.conge.utilisateur.prenom,
                                  date_debut: new Date(
                                      conges.conge.jour_conges[0].date,
                                  ).toLocaleDateString(),
                                  date_fin: new Date(
                                      conges.conge.jour_conges[
                                          conges.conge.jour_conges.length - 1
                                      ].date,
                                  ).toLocaleDateString(),
                                  type_conge: conges.conge.type_conge.nom,
                                  nb_jour: conges.conge.solde,
                                  validation: displayStatusIcon(conges.conge, true, true),
                                  solde: conges.soldeUtilisateur.solde,
                              }))
                            : [{}]
                    }
                    actionsAddedContent={(conges) => {
                        return (
                            <>
                                {loadingValidationState ? (
                                    <WaitingScreenComponent withPaper={false} />
                                ) : (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{ mr: 1 }}
                                            onClick={() => {
                                                setLoadingValidationState(true);
                                                congeService
                                                    .evaluateConge(conges.conges_id, true)
                                                    .then((response) => {
                                                        if (!response.error) {
                                                            toast.success(response.message);
                                                            setLoadingValidationState(false);
                                                            handleFetchConges();
                                                            handleCountCategoryConges();
                                                        } else {
                                                            toast.error(response.error);
                                                        }
                                                    });
                                            }}
                                        >
                                            <CheckIcon /> Valider
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => {
                                                setLoadingValidationState(false);
                                                congeService
                                                    .evaluateConge(conges.conges_id, false)
                                                    .then((response) => {
                                                        if (!response.error) {
                                                            toast.success(response.message);
                                                            setLoadingValidationState(false);
                                                            handleFetchConges();
                                                            handleCountCategoryConges();
                                                        } else {
                                                            toast.error(response.error);
                                                        }
                                                    });
                                            }}
                                        >
                                            <CloseIcon /> Refuser
                                        </Button>
                                    </>
                                )}
                            </>
                        );
                    }}
                />
            </TabPanel>
            <TabPanel value={valueTabState} index={1}>
                <EvaluateCancelComponent handleCountCategoryConges={handleCountCategoryConges} />
            </TabPanel>
        </>
    );
};

/** REDUX */
const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};
// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedEvaluateCongeComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EvaluateCongeComponent);
