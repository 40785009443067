import React, { useEffect, useState } from 'react';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import buService from '../../../services/bu.service';

import { useLocation } from 'react-router-dom';

import ResultFormComponent from '../../assets/container/resultForm.component';
import FormBusinessUnitComponent from './form-business_unit.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';

export const UpdateBusinessUnitComponent = (props) => {
    const location = useLocation();
    const [business_unit_idState] = useState(location.state.objectId);
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [nomState, setNomState] = useState('');
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Modifier une business unit');
        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const business_unit_id = buService.getBusinessUnitById(business_unit_idState);
        // on attend le réponse de toutes les requêtes et on les attribues au state local
        Promise.all([business_unit_id]).then(([bu]) => {
            setNomState(bu.bu.nom);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateBusinessUnit = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        buService
            .updateBusinessUnitById(business_unit_idState, dataForm.inputName)
            .then((response) => {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(response.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    const handleDeleteBusinessUnit = () => {
        if (
            window.confirm(
                "Voulez-vous vraiment archiver cette business unit ? Il ne sera plus possible de l'attribuer à des utilisateurs et elle ne sera plus affiché dans la liste.",
            )
        ) {
            setLoadingState(true);
            setSuccessState(false);
            setSubmittedState(true);

            buService
                .deleteBusinessUnitById(business_unit_idState)
                .then((res) => {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(res.message);
                })
                .catch((err) => {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(err.response.message || err.message || err.response.message);
                });
        }
    };

    return (
        <>
            {nomState !== '' ? (
                <ResultFormComponent
                    success={successState}
                    loading={loadingState}
                    message={messageState}
                    submitted={submittedState}
                    link={'/admin/business-units'}
                >
                    <FormBusinessUnitComponent
                        handleDelete={handleDeleteBusinessUnit}
                        handleForm={handleUpdateBusinessUnit}
                        isUpdate={true}
                        inputName={nomState}
                    />
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateBusinessUnitComponent = connect(
    null,
    mapDispatchToProps,
)(UpdateBusinessUnitComponent);
