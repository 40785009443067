import { useEffect, useState } from 'react';

import { getMonthStartDate, getMonthEndDate } from '../../helpers/date.helper';
import userService from '../../services/user.service';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import projetService from '../../services/projet.service';

import { PDFViewer } from '@react-pdf/renderer';
import NewModelComponent from './newModel.component';

import riService from '../../services/ri.service';

//Gestion de la date
import moment from 'moment';
import 'moment/locale/fr';

//Form et MUI
import { useForm, Controller } from 'react-hook-form';
import { H2Lighter } from '../../theming/customTheme';
import {
    Button,
    TextField,
    // Select,
    Autocomplete,
    // InputLabel,
    // OutlinedInput,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    Grid,
    Collapse,
    Divider,
    // MenuItem,
    // Typography,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import WaitingScreenComponent from '../assets/waiting-screen.component';
import CenteredPaper from '../assets/container/centeredPaper.component';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import MenuItemProjetComponent from '../assets/menuitem/MenuItemProjet.component';
import MenuItemUserComponent from '../assets/menuitem/MenuItemUser.component';

const ReportingComponent = (props) => {
    // Définition du format de date pour le date picker
    moment.locale('fr');

    const [projetsState, setProjetsState] = useState([]);
    const [usersState, setUsersState] = useState([]);
    const [selectedProjetState, setSelectedProjetState] = useState(null);
    const [selectedUsersState, setSelectedUsersState] = useState([]);
    const [date_debutState, setDate_debutState] = useState(new Date());
    const [date_finState, setDate_finState] = useState(new Date());
    const [showDateInputState, setShowDateInputState] = useState(true);
    const [loadingState, setLoadingState] = useState(false);
    const [, setErrorState] = useState(null);
    const [formStepState, setFormStepState] = useState(0);
    const [, setIsCheckedState] = useState(false);
    const [riDatasState, setRiDatasState] = useState([]);

    // Création des constantes pour le formulaire
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm();

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const onChangeProjet = (event) => {
        setSelectedProjetState(event);
    };

    const onChangeUser = (event) => {
        setSelectedUsersState(event);
    };

    const onChangeCheckedCustomDate = () => {
        setShowDateInputState(true);
    };

    const onChangeChecked = (event) => {
        handleFillInDates();
        setShowDateInputState(false);
        setIsCheckedState(event.target.checked);
    };

    const onChangeCheckedActualMonth = (event) => {
        handleFillInDates(true);
        setShowDateInputState(false);
        setIsCheckedState(event.target.checked);
    };

    const onChangeStartProject = (event) => {
        handleFillInDates(false, true);
        setShowDateInputState(false);
        setIsCheckedState(event.target.checked);
    };
    const getCorrectFormatDate = (date) => {
        let year = date.getFullYear(),
            month = zeroPad(date.getMonth() + 1, 2),
            day = zeroPad(date.getDate(), 2);
        return `${year}-${month}-${day}`;
    };
    /**
     * HANDLERS
     */
    /**
     * Dans le cas de "générer pour le mois en cours"
     * les dates sont générées pour l'api
     */
    const handleFillInDates = (actual = false, startProject = false) => {
        const actualDate = new Date();
        const precedent = new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1);
        if (actual) {
            const dateDebutValue = getCorrectFormatDate(getMonthStartDate(actualDate));
            const dateFinValue = getCorrectFormatDate(getMonthEndDate(actualDate));
            setValue('dateDebut', moment(dateDebutValue));
            setDate_debutState(dateDebutValue);
            setValue('dateFin', moment(dateFinValue));
            setDate_finState(dateFinValue);
        } else if (startProject) {
            projetService.getProjetWithFirstDate(selectedProjetState.value).then((projet) => {
                // Récupération de la date et conversion à la bonne heure parce que Pierre ne savait pas coder.
                const projetDate = new Date(projet.startDate || projet.date_commande);
                projetDate.setHours(projetDate.getHours() - 2);

                const dateDebutValue = getCorrectFormatDate(projetDate);
                const dateFinValue = getCorrectFormatDate(getMonthEndDate(actualDate));
                setValue('dateDebut', moment(dateDebutValue));
                setDate_debutState(dateDebutValue);
                setValue('dateFin', moment(dateFinValue));
                setDate_finState(dateFinValue);
            });
        } else {
            const dateDebutValue = getCorrectFormatDate(getMonthStartDate(precedent));
            const dateFinValue = getCorrectFormatDate(getMonthEndDate(precedent));
            setValue('dateDebut', moment(dateDebutValue));
            setDate_debutState(dateDebutValue);
            setValue('dateFin', moment(dateFinValue));
            setDate_finState(dateFinValue);
        }
    };
    /**
     * Range les données d'un tableau en tant que
     * ligne value/label pour générer liste options
     * pour Select
     * @param {Array} datas
     */
    const handleStoreProjetDataAsOption = (datas) => {
        let clearDatas = datas.map((data) => {
            return {
                label: data.code_projet + ' - ' + data.client.nom + ' ' + data.nom,
                nom: data.nom,
                code_projet: data.code_projet,
                client: data.client.nom,
                value: data.project_id,
            };
        });
        setProjetsState(clearDatas);
    };

    /**
     * Range les données d'un tableau en tant que
     * ligne value/label pour générer liste options
     * pour Select
     * @param {Array} datas
     */
    const handleStoreUsersDataAsOption = (datas) => {
        let clearDatas = datas.map((data) => {
            return {
                value: data.user_id,
                label: data.nom.toUpperCase() + ' ' + data.prenom,
                userObject: data,
            };
        });
        // clearDatas.unshift({ value: -1, label: 'Tout les utilisateurs' });
        setUsersState(clearDatas);
    };

    /**
     * Récupère les projets liés à ce manager
     */
    const handleFetchProjets = () => {
        projetService.getProjetByToken().then((res) => {
            if (res) {
                handleStoreProjetDataAsOption(res);
            } else if (res.error) {
                setErrorState(res.error);
            }
        });
    };

    /**
     * Récupère les projets liés à ce manager
     */
    const handleFetchUsers = () => {
        userService.getAllUsersAccessible().then((res) => {
            if (res) {
                handleStoreUsersDataAsOption(res);
            } else {
                setErrorState(res.error);
            }
        });
    };

    /**
     * Appel API création pdf et validation formulaire
     */
    const handleCreateRI = (dataForm) => {
        setLoadingState(true);
        setErrorState(false);

        riService
            .getRapportIntervention(
                dataForm.projets.value,
                (dataForm.users.length !== 0 ? dataForm.users : usersState).map(
                    (user) => user.value,
                ),
                handleConvertDate(moment(dataForm.dateDebut).format('YYYY-MM-DD')),
                handleConvertDate(moment(dataForm.dateFin).format('YYYY-MM-DD')),
            )
            .then((res) => {
                if (!res.error) {
                    return (
                        setRiDatasState({
                            res: res,
                            dateDebut: new Date(dataForm.dateDebut._d).toLocaleDateString('fr'),
                            dateFin: new Date(dataForm.dateFin._d).toLocaleDateString('fr'),
                        }),
                        setLoadingState(false),
                        setFormStepState(1)
                    );
                } else {
                    return setErrorState(res.error), setLoadingState(false);
                }
            });
    };

    /**
     * Retourne les strings sans les "-" dedans
     * @param {String} date - value des inputs date
     * @returns String clarifiée des dates adaptées au format des PK des jours
     */
    const handleConvertDate = (date) => {
        return date.replace(/-/g, '');
    };

    useEffect(() => {
        props.onLoad('Reporting projet');
        handleFetchProjets();
        handleFetchUsers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const allRadioOptions = [
        {
            key: 'reporting-check-custom-date',
            functionChange: onChangeCheckedCustomDate,
            label: 'Sélectionner des dates personnalisées',
            selected: true,
        },
        {
            key: 'reporting-check-for-month',
            functionChange: onChangeChecked,
            label: 'Générer pour le mois précédent',
        },
        {
            key: 'reporting-check-for-actual-month',
            functionChange: onChangeCheckedActualMonth,
            label: 'Générer pour le mois en cours',
        },
        {
            key: 'reporting-check-for-start-project',
            functionChange: onChangeStartProject,
            label: 'Générer pour le début du projet',
            condition: selectedProjetState !== null,
        },
    ];

    return (
        <>
            {loadingState ? (
                <WaitingScreenComponent />
            ) : (
                <CenteredPaper size="large" withAlign={false} withMargin={4}>
                    <H2Lighter sx={{ textAlign: 'center' }}>Générer un reporting</H2Lighter>

                    <form
                        onSubmit={handleSubmit(handleCreateRI)}
                        onChange={() => setFormStepState(0)}
                    >
                        <Grid container sx={{ width: '100%' }} direction="column">
                            <Grid item>
                                <Controller
                                    key={'projets' + projetsState}
                                    name="projets"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Le projet doit être saisi',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            disablePortal
                                            fullWidth
                                            options={projetsState}
                                            getOptionLabel={(option) => option.label || ''}
                                            renderOption={(autoProps, option) => (
                                                <MenuItemProjetComponent
                                                    key={
                                                        'itemProjetOption' +
                                                        option.value +
                                                        option.label
                                                    }
                                                    autoCompleteProps={autoProps}
                                                    value={option.value}
                                                    nom={option.nom}
                                                    client={option.client}
                                                    code_projet={option.code_projet}
                                                />
                                            )}
                                            onChange={(_, value) => {
                                                if (value) {
                                                    field.onChange(value);
                                                    onChangeProjet(value);
                                                }
                                            }}
                                            onInputChange={(_, value) => {
                                                if (value === '') {
                                                    field.onChange(null);
                                                    onChangeProjet(null);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Sélectionner un projet"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    error={!!errors.projets}
                                                    helperText={
                                                        !!errors.projets
                                                            ? errors.projets.message
                                                            : null
                                                    }
                                                />
                                            )}
                                            value={selectedProjetState}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                {usersState.length > 0 && (
                                    <Controller
                                        key={'users' + usersState}
                                        name="users"
                                        defaultValue={[]}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                disablePortal
                                                fullWidth
                                                options={usersState}
                                                getOptionLabel={(option) => option.label || ''}
                                                renderOption={(autoProps, option) => (
                                                    <MenuItemUserComponent
                                                        autoCompleteProps={autoProps}
                                                        value={option.value}
                                                        userObject={option.userObject}
                                                    />
                                                )}
                                                disableCloseOnSelect
                                                onChange={(_, value) => {
                                                    field.onChange(value);
                                                    onChangeUser(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Sélectionner un ou plusieurs utilisateurs (tout le monde, par défaut)"
                                                        sx={{ mt: 2 }}
                                                        variant="outlined"
                                                        error={!!errors.users}
                                                        helperText={
                                                            !!errors.users
                                                                ? errors.users.message
                                                                : null
                                                        }
                                                    />
                                                )}
                                                multiple
                                                value={selectedUsersState}
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                            <Divider variant="middle" sx={{ mt: 2 }} />
                            <Grid item sx={{ mt: 1 }}>
                                <Collapse in={showDateInputState}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Controller
                                                key={'dateDebut' + date_debutState}
                                                name="dateDebut"
                                                defaultValue={date_debutState}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            'La date de début doit être saisie',
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                        adapterLocale={'fr'}
                                                    >
                                                        <DatePicker
                                                            {...field}
                                                            onChange={(value) => {
                                                                field.onChange(value);
                                                                setDate_debutState(
                                                                    getCorrectFormatDate(
                                                                        new Date(value),
                                                                    ),
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    sx={{ mt: 2 }}
                                                                    error={!!errors.dateDebut}
                                                                    helperText={
                                                                        !!errors.dateDebut
                                                                            ? errors.dateDebut
                                                                                  .message
                                                                            : null
                                                                    }
                                                                    label="Saisir une date de début"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                key={'dateFin' + date_finState}
                                                name="dateFin"
                                                defaultValue={date_finState}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'La date de fin doit être saisie',
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                        adapterLocale={'fr'}
                                                    >
                                                        <DatePicker
                                                            {...field}
                                                            onChange={(value) => {
                                                                field.onChange(value);
                                                                setDate_finState(
                                                                    getCorrectFormatDate(
                                                                        new Date(value),
                                                                    ),
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    sx={{ mt: 2 }}
                                                                    error={!!errors.dateFin}
                                                                    helperText={
                                                                        !!errors.dateFin
                                                                            ? errors.dateFin.message
                                                                            : null
                                                                    }
                                                                    label="Saisir une date de fin"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Grid>
                            <Grid item>
                                <Controller
                                    key={'preselectedChoice'}
                                    name="preselectedChoice"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Il faut sélectionner au moins une option',
                                        },
                                    }}
                                    defaultValue={0}
                                    render={({ field }) => (
                                        <FormControl>
                                            <RadioGroup
                                                {...field}
                                                aria-labelledby="radio-group-preselected-choice"
                                            >
                                                {allRadioOptions.map((radioOption, index) => {
                                                    if (
                                                        radioOption.condition === undefined ||
                                                        radioOption.condition === true
                                                    ) {
                                                        return (
                                                            <FormControlLabel
                                                                key={radioOption.key}
                                                                value={index}
                                                                control={
                                                                    <Radio
                                                                        onChange={(value) => {
                                                                            field.onChange(value);
                                                                            radioOption.functionChange(
                                                                                value,
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label={radioOption.label}
                                                            />
                                                        );
                                                    }
                                                })}
                                            </RadioGroup>
                                            <FormHelperText error={true}>
                                                {!!errors.preselectedChoice
                                                    ? errors.preselectedChoice.message
                                                    : null}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
                            Valider pour la période du{' '}
                            {new Date(date_debutState).toLocaleDateString()} au{' '}
                            {new Date(date_finState).toLocaleDateString()}
                        </Button>
                    </form>
                    {formStepState !== 0 && (
                        <PDFViewer width="1000" height="1200" style={{ marginTop: '2%' }}>
                            <NewModelComponent
                                key={date_debutState + '_' + date_finState}
                                date_debut={date_debutState}
                                date_fin={date_finState}
                                riDatas={riDatasState}
                            />
                        </PDFViewer>
                    )}
                </CenteredPaper>
            )}
        </>
    );
};

//     render()

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedReportingComponent = connect(null, mapDispatchToProps)(ReportingComponent);
