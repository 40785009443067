import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setPref } from '../../../redux/actions/prefAction';
import fichiersService from '../../../services/fichiers.service';
import userService from '../../../services/user.service';
import CustomAvatarComponent from '../inputs/custom-avatar.component';
import WaitingScreenComponent from '../waiting-screen.component';

const FormPhotoAvatar = (props) => {
    const [photoState, setPhotoState] = useState(null);

    const { handleSubmit, control } = useForm();

    const handleForm = (dataForm) => {
        let promiseUpload = new Promise((resolve, reject) => {
            if (dataForm.inputAvatar !== photoState) {
                const formData = new FormData();
                formData.append('file', dataForm.inputAvatar);
                fichiersService.uploadFile(formData).then((res) => {
                    if (res.error) {
                        reject(res.error);
                    } else {
                        resolve(res);
                    }
                });
            } else {
                resolve({ fichier: -1 });
            }
        });

        promiseUpload
            .then((res) => {
                if (res.fichier !== -1) {
                    userService
                        .sendUserCustomization({ photo: res.fichier.filename })
                        .then((result) => {
                            if (result.error) {
                                toast.error(result.error);
                            } else {
                                props.setPrefUser({
                                    ...props.pref,
                                    photo: res.fichier.filename,
                                });

                                if (props.afterForm) {
                                    props.afterForm();
                                }
                            }
                        });
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };

    useEffect(() => {
        if (props.pref.photo !== null) {
            fichiersService.getFichierFile(props.pref.photo).then((photo) => {
                if (!photo.error) {
                    setPhotoState(photo);
                } else {
                    toast.error('Le téléchargement de la photo de profil à échoué');
                }
            });
        } else {
            setPhotoState(-1);
        }
    }, []);

    return photoState ? (
        <form onSubmit={handleSubmit(handleForm)}>
            <Controller
                name="inputAvatar"
                control={control}
                defaultValue={photoState}
                render={({ field }) => (
                    <CustomAvatarComponent
                        {...field}
                        ref={null}
                        content={{
                            utilisateur: props.pref,
                        }}
                    />
                )}
            />
            <Button variant="contained" type="submit" color="originalPrimary">
                Valider
            </Button>
        </form>
    ) : (
        <WaitingScreenComponent />
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        pref: state.prefReducer,
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPrefUser: (prefObject) => dispatch(setPref(prefObject)),
});

export const ConnectedFormPhotoAvatar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FormPhotoAvatar);
