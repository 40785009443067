/**
 * Vérifie si le switch d'une semaine doit être coché ou non
 * @param  {[type]} month                      Mois issu de this.month
 * @param  {[type]} selectedDays               SelectedDays issu de this.state.selectedDays
 * @return {[boolean]}                         Retourne true si le switch doit être coché, false sinon
 */
export const checkWeekSelected = (week, selectedDays) => {
    let retour = false;
    let compteJour = 0;
    week.jours.forEach((jour) => {
        selectedDays.forEach((jourS) => {
            if (jourS.nom !== 'Samedi' && jourS.nom !== 'Dimanche') {
                if (jour.date.getTime() === jourS.date.getTime()) compteJour++;
            }
        });
    });
    if (compteJour >= 5) retour = true;
    return retour;
};

/**
 * Vérifie si le jour en paramètre possède le même code projet que l'activité en paramètre
 * @param  {[type]} jour                      Jour à tester
 * @param  {[type]} activityRef               Activité de référence
 * @return {[type]}                           null ou l'activité
 */
export const getActivityFromDay = (jour, activityRef, willBeIntegrated = null) => {
    let retour = null;
    if (jour.activities.length > 0) {
        jour.activities.forEach((activity) => {
            if (Object.prototype.hasOwnProperty.call(activity.activity.projet, 'code_projet')) {
                if (willBeIntegrated !== null) {
                    if (activity.activity.projet.project_id === willBeIntegrated.projet) {
                        if (
                            Object.prototype.hasOwnProperty.call(activity.activity, 'utilisateur')
                        ) {
                            if (
                                activity.activity.utilisateur.user_id ===
                                willBeIntegrated.employe.user_id
                            ) {
                                retour = activity.activity;
                            }
                        }
                    }
                } else if (
                    activity.activity.projet.code_projet === activityRef.projet.code_projet
                ) {
                    if (Object.prototype.hasOwnProperty.call(activity.activity, 'utilisateur')) {
                        if (
                            activity.activity.utilisateur.user_id ===
                            activityRef.utilisateur.user_id
                        ) {
                            retour = activity.activity;
                        }
                    } else {
                        retour = activity.activity;
                    }
                }
            }
        });
    }
    return retour;
};

/**
 * Fonction d'ajout des jours dans le state selectedDays
 * @param {[type]}  selectedDays           SelectedDays provenant du this.state.selectedDays
 * @param {[type]}  jourIgnore             Le jour provenant du this.props.jour, permet d'identifier quel jour on ne peux pas décocher, si false permet de décocher le jour
 * @param {[type]}  arrayDays              Tableau de jour à ajouter à selectedDays
 * @param {Number}  [number=0]             "étape" d'incrément de la fonction (mettre à 0 par défaut)
 * @param {Boolean} [isDelete=false]       Défini si on ajout ou retire les jours donnée en paramètre de selectedDays
 * @param {Boolean} [enableWeekEnd=false]  Défini si on autorise ou non l'ajout des jours du week end dans selectedDays
 */
export const addDaysFromArray = (
    selectedDays,
    jourIgnore,
    arrayDays,
    number = 0,
    isDelete = false,
    enableWeekEnd = false,
) => {
    if (arrayDays.length > number) {
        if (!isDelete) {
            let isValid = true;

            // if(jourIgnore !== null) {
            //     if(arrayDays[number].date.getTime() === jourIgnore.date.getTime()) {
            //         isValid = false;
            //     }
            // }

            if (!enableWeekEnd) {
                if (arrayDays[number].nom === 'Samedi' || arrayDays[number].nom === 'Dimanche')
                    isValid = false;
            }

            selectedDays.forEach((day) => {
                if (day.date.getTime() === arrayDays[number].date.getTime()) {
                    isValid = false;
                }
            });

            if (isValid) {
                selectedDays = [...selectedDays, arrayDays[number]];
                return addDaysFromArray(
                    selectedDays,
                    jourIgnore,
                    arrayDays,
                    number + 1,
                    isDelete,
                    enableWeekEnd,
                );
            } else {
                return addDaysFromArray(
                    selectedDays,
                    jourIgnore,
                    arrayDays,
                    number + 1,
                    isDelete,
                    enableWeekEnd,
                );
            }
        } else {
            if (jourIgnore !== null) {
                selectedDays = selectedDays.filter(
                    (day) =>
                        day.date.getTime() !== arrayDays[number].date.getTime() ||
                        day.date.getTime() === jourIgnore.date.getTime(),
                );
            } else {
                selectedDays = selectedDays.filter(
                    (day) => day.date.getTime() !== arrayDays[number].date.getTime(),
                );
            }
            return addDaysFromArray(
                selectedDays,
                jourIgnore,
                arrayDays,
                number + 1,
                isDelete,
                enableWeekEnd,
            );
        }
    } else {
        return selectedDays;
    }
};
