import axiosConfig from './axiosConfig';

const API_URL = '/authorization/activity/';

class ActivityService {
    /** Retourne toutes les activités */
    getAllActivities() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    getAllActivityTypesOrganized() {
        return axiosConfig
            .get(API_URL + 'types/organized')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    // Récupère tous les types de facturation
    getAllActivityTypes() {
        return axiosConfig
            .get(API_URL + 'types/all')
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    getTypeFacturationConge() {
        return axiosConfig
            .get(API_URL + 'types/conge')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }

    /**Concernant les lieus d'activité */
    getAllActivityLieu() {
        return axiosConfig
            .get(API_URL + 'lieu/all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    /* Cherche l'occurence du lieu en fonction de l'id en paramètre */
    getActivityLieuById(id) {
        return axiosConfig
            .get(API_URL + 'lieu/' + id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    /** Concernant les types de facturation */
    getAllTypeFacturation() {
        return axiosConfig
            .get(API_URL + 'facturation-types/all')
            .then((response) => response.data)
            .catch((error) => ({ error: error.message }));
    }

    /**
     * Retourne toutes les activités du mois via ID en params de manager
     * @param {Number} monthNumber - numéro du mois à visualiser
     * @param {Number} yearNumber - numéro de l'année à visualiser
     * @param {Number} managerEmail - email du manager
     */
    getActivitiesByManager(monthNumber, yearNumber, managerEmail) {
        return axiosConfig
            .get(`${API_URL}team`, {
                params: {
                    monthNumber,
                    yearNumber,
                    managerEmail,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    /** Retourne les activités selon le token pour 1 mois */
    getEmployeeMonthActivities(monthNumber, yearNumber, userId = null) {
        return axiosConfig
            .get(API_URL + 'employee/month', {
                params: {
                    monthNumber: monthNumber,
                    yearNumber: yearNumber,
                    utilisateursId: userId,
                },
            })
            .then((response) => response.data)
            .catch((error) => ({ error: `${error.message}` }));
    }

    /**
     * Insère une activité à partir de l'utilisateur loggé
     * @param {Date} dateJson
     * @param {Number} valeur
     * @param {String} commentaire
     * @param {Number} projet
     */
    insertMyActivity(dateJson, valeur, commentaire, projet, lieu, email) {
        const user = JSON.parse(localStorage.getItem('user'));
        const emailToSend = email ? email : user.email;
        return axiosConfig
            .post(API_URL, {
                userEmail: emailToSend,
                dateJson: dateJson,
                valeur: valeur,
                commentaire: commentaire,
                lieu: lieu,
                fk_project: projet,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: error.message,
                };
            });
    }

    /**
     * Modifie une activité à partir de l'utilisateur loggé
     * @param {Date} dateJson
     * @param {Number} valeur
     * @param {String} commentaire
     * @param {Number} projet
     */
    updateMyActivity(activity_id, dateJson, valeur, commentaire, projet, lieu) {
        return axiosConfig
            .put(API_URL, {
                activity_id,
                dateJson,
                valeur,
                commentaire,
                projet,
                lieu,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: error.message,
                };
            });
    }
    updateFacturationTypes(facturationUpdateArray) {
        return axiosConfig
            .put(API_URL + 'facturation-types', {
                facturationUpdateArray,
            })
            .then((res) => res.data)
            .catch((err) => ({
                error: err.message,
            }));
    }

    /**
     * Supprime une activité via son id.
     * @param {Number} activity_id - ID de l'activité à supprimer
     */
    deleteOneActivity(activity_id) {
        return axiosConfig
            .delete(API_URL + `${activity_id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: error.message,
                };
            });
    }

    /**
     * Retourne 1 activité via sa fk date
     * @param {Number} full_date_raw - qui correspond à la fk de l'activité
     */
    getOneActivityByFk(full_date_raw) {
        return axiosConfig
            .post(API_URL + 'getbyfk', {
                full_date_raw: full_date_raw,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    getLastActivities(limit) {
        return axiosConfig
            .get(`${API_URL}lastActivities/${limit}`)
            .then((res) => res.data)
            .catch((err) => ({
                error: err.message,
            }));
    }
}

export default new ActivityService();
