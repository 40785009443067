import {
    Avatar,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';

// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const FichiersComponent = (props) => {
    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar>
                        <AttachFileIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.fichier.name}
                    secondary={
                        <>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {props.fichier.fk_owner.nom.toUpperCase() +
                                    ' ' +
                                    props.fichier.fk_owner.prenom +
                                    ': ' +
                                    props.fichier.description +
                                    ' - Créé le ' +
                                    new Date(props.fichier.createdAt).toLocaleDateString('fr')}
                            </Typography>
                        </>
                    }
                />
                <a download={props.fichier.filename} href={props.fichier.fichier}>
                    <Tooltip title="Télécharger">
                        <IconButton color="primary">
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </a>
                <IconButton>
                    {props.fichier.is_pinned && <PushPinIcon color="primary" />}
                </IconButton>
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
        // <Card sx={{ maxWidth: 345 }}>
        //     <Box sx={{ display: 'flex' }}>
        //         <CardMedia
        //             component="img"
        //             height="140"
        //             image={props.fichier.fichier}
        //             alt="Fichier"
        //         />
        //         <CardContent>
        //             <Typography gutterBottom variant="h5" component="div">
        //                 {props.fichier.name}
        //             </Typography>
        //             <Typography variant="body2" color="text.secondary">
        //                 {props.fichier.description}
        //             </Typography>
        //             <Typography>Télécharger</Typography>
        //         </CardContent>
        //     </Box>
        // </Card>
    );
};

export default FichiersComponent;
