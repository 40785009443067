import { React } from 'react';

import * as DateHelper from '../../helpers/date.helper';

// MUI
import { MenuItem, Grid, TextField, Autocomplete, Typography } from '@mui/material';
import { ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';
import { PaperPrimary } from '../../theming/customTheme';
import MenuItemUserComponent from '../assets/menuitem/MenuItemUser.component';

const SelectionDateComponent = (props) => {
    const { previousMonth, monthDateState, setMonth, setYear, yearsRangeState, nextMonth } = props;

    let isManager = false;

    if (props.managers && props.actualManager && props.setManager) {
        isManager = true;
    }

    return (
        <PaperPrimary>
            <Grid container direction="row" alignItems="center">
                <Grid
                    item
                    xs={1}
                    sx={{
                        paddingLeft: '10px',
                    }}
                    onClick={previousMonth}
                >
                    <ArrowBackIosNew />
                </Grid>
                <Grid item xs={isManager ? 6 : 10}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent={isManager ? 'right' : 'center'}
                    >
                        <Typography sx={{ color: 'primary.contrastText' }}>Mois de</Typography>
                        <TextField
                            select
                            value={monthDateState.getMonth()}
                            onChange={setMonth}
                            variant="standard"
                            sx={{ m: 1 }}
                        >
                            {DateHelper.monthNames.map((month, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {month}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        <TextField
                            select
                            value={monthDateState.getFullYear()}
                            onChange={setYear}
                            variant="standard"
                            sx={{ m: 1 }}
                        >
                            {yearsRangeState.map((year, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={year}
                                        {...(year === monthDateState.getFullYear()
                                            ? 'selected'
                                            : '')}
                                    >
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                </Grid>
                {isManager ? (
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            options={props.managers}
                            key={props.actualManager || ''}
                            getOptionLabel={(option) => option.label || ''}
                            renderOption={(autoProps, option) => (
                                <MenuItemUserComponent
                                    autoCompleteProps={autoProps}
                                    value={option.value}
                                    userObject={option.userObject}
                                />
                            )}
                            onChange={(_, value) => {
                                props.setManager(value);
                            }}
                            onInputChange={(_, value) => {
                                if (value === '') {
                                    props.setManager(null);
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={props.actualManager || ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{ m: 1, width: '75%' }}
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                ) : null}
                <Grid
                    item
                    xs={1}
                    style={{ textAlign: 'right', paddingRight: '10px' }}
                    onClick={nextMonth}
                >
                    <ArrowForwardIos />
                </Grid>
            </Grid>
        </PaperPrimary>
    );
};

export default SelectionDateComponent;
