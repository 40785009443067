import React, { useEffect, useState } from 'react';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
/** API services */
import clientService from '../../../services/client.service';
import { useLocation } from 'react-router-dom';

import ResultFormComponent from '../../assets/container/resultForm.component';
import FormClientComponent from './form-client.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';

export const UpdateClientComponent = (props) => {
    const location = useLocation();
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [nomState, setNomState] = useState(null);
    const [villeState, setVilleState] = useState(null);
    const [commentaireState, setCommentaireState] = useState(null);
    const [regionState, setRegionState] = useState(null);
    const [messageState, setMessageState] = useState('');
    const [regionArchiveState, setRegionArchiveState] = useState(null);
    const client_idState = location.state.objectId;

    useEffect(() => {
        props.onLoad('Administrateur - Modifier un client');
        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const client = clientService.getClientById(client_idState);
        // on attend le réponse de toutes les requêtes et on les attribues au state local
        Promise.all([client]).then(([client]) => {
            let clientData = client.client;
            setNomState(clientData.nom);
            setVilleState(clientData.ville);
            setCommentaireState(clientData.commentaire);
            setRegionState(clientData.fk_region);
            if (clientData.region.est_archive) {
                setRegionArchiveState(clientData.region.nom);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleArchiveClient = () => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        clientService
            .archiveClientById(client_idState)
            .then((res) => {
                setSuccessState(true);
                setLoadingState(false);
                setMessageState(res.message);
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    const handleUpdateClient = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        const { inputNom, inputVille, inputCommentaire, inputRegion } = dataForm;

        clientService
            .updateClientById(client_idState, inputNom, inputVille, inputCommentaire, inputRegion)
            .then((response) => {
                if (response.error) {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(response.error);
                } else {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                }
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    return (
        <>
            {client_idState !== null &&
            nomState !== null &&
            villeState !== null &&
            regionState !== null ? (
                <ResultFormComponent
                    submitted={submittedState}
                    loading={loadingState}
                    success={successState}
                    message={messageState}
                    link={'/admin/clients'}
                >
                    <FormClientComponent
                        handleForm={handleUpdateClient}
                        handleArchiveClient={handleArchiveClient}
                        isUpdate={true}
                        client={client_idState}
                        nom={nomState}
                        ville={villeState}
                        commentaire={commentaireState}
                        region={regionState}
                        regionArchive={regionArchiveState}
                    />
                </ResultFormComponent>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateClientComponent = connect(
    null,
    mapDispatchToProps,
)(UpdateClientComponent);
