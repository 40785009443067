import { CLEAR_PAGE_TITLE, SET_PAGE_TITLE, TOGGLE_LEFT_MENU } from '../constants';

const scopeReducer = (state = { pageTitle: 'Bienvenue sur NeoCRA', toggleMenu: true }, action) => {
    switch (action.type) {
        case SET_PAGE_TITLE:
            state = {
                ...state,
                pageTitle: action.pageTitle,
            };
            return state;
        case CLEAR_PAGE_TITLE:
            state = {
                ...state,
                pageTitle: action.pageTitle,
            };
            return state;
        case TOGGLE_LEFT_MENU:
            state = {
                ...state,
                toggleMenu: !state.toggleMenu,
            };
            return state;
        default:
            return state;
    }
};

export default scopeReducer;
