import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import fichiersService from '../../../services/fichiers.service';
import ResultFormComponent from '../../assets/container/resultForm.component';
import FormFichiersComponent from './form-fichiers.component';

const CreateFichiersComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Créer un fichier');
    }, []);

    const handleCreateFichier = (dataForm) => {
        setLoadingState(true);
        setSubmittedState(true);
        setSuccessState(false);

        const formData = new FormData();
        formData.append('file', dataForm.inputFichier);

        fichiersService.uploadFile(formData).then((res) => {
            if (res.error) {
                setLoadingState(false);
                setSuccessState(false);
                setMessageState(res.error);
            } else {
                fichiersService
                    .insertFichier({
                        name: dataForm.inputName,
                        description: dataForm.inputDescription,
                        fk_category: dataForm.inputCategory.value,
                        check_entity: !!dataForm.checkEntity,
                        fichier: res.fichier.filename,
                        is_pinned: !!dataForm.checkPin,
                    })
                    .then((res) => {
                        if (res.error) {
                            setLoadingState(false);
                            setSuccessState(false);
                            setMessageState(res.error);
                        } else {
                            setLoadingState(false);
                            setSuccessState(true);
                            setMessageState(res.message);
                        }
                    });
            }
        });
    };

    return (
        <ResultFormComponent
            loading={loadingState}
            success={successState}
            submitted={submittedState}
            message={messageState}
            link={'/admin/list-fichiers'}
        >
            <FormFichiersComponent handleForm={handleCreateFichier} />
        </ResultFormComponent>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateFichiersComponent = connect(
    null,
    mapDispatchToProps,
)(CreateFichiersComponent);
