import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import congeService from '../../../../services/conge.service';
import CrudPageComponent from '../../../assets/page/crud-page.component';
import WaitingScreenComponent from '../../../assets/waiting-screen.component';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const EvaluateCancelComponent = (props) => {
    const [displayTypeState, setDisplayTypeState] = useState(true);
    const [contentState, setContentState] = useState('');
    const [loadingValidationState, setLoadingValidationState] = useState(false);

    const handleFetchCancelConges = () => {
        (displayTypeState
            ? congeService.getCongesAskedForCancel()
            : congeService.getCanceledConges()
        ).then((conges) => {
            setContentState(conges);
        });
    };

    const onChangeDisplayType = () => {
        setDisplayTypeState(!displayTypeState);
    };

    useEffect(() => {
        handleFetchCancelConges();
    }, [displayTypeState]);

    return (
        <CrudPageComponent
            title="Évaluer les congés à annuler"
            objectName="conges"
            headers={[
                'Id',
                'Nom',
                'Prénom',
                'Date de début',
                'Date de fin',
                'Type de congé',
                'Nombre de jour',
                "Solde actuel de l'employé",
            ]}
            showId
            dataContent={
                !!contentState
                    ? contentState.map((conges) => ({
                          conges_id: conges.conge.new_conges_id,
                          nom: conges.conge.utilisateur.nom,
                          prenom: conges.conge.utilisateur.prenom,
                          date_debut: new Date(
                              conges.conge.jour_conges[0].date,
                          ).toLocaleDateString(),
                          date_fin: new Date(
                              conges.conge.jour_conges[conges.conge.jour_conges.length - 1].date,
                          ).toLocaleDateString(),
                          type_conge: conges.conge.type_conge.nom,
                          nb_jour: conges.conge.jour_conges.length,
                          solde: conges.soldeUtilisateur.solde,
                      }))
                    : [{}]
            }
            displayType={displayTypeState}
            onChangeDisplayType={onChangeDisplayType}
            labelSwitchArchived="Afficher les congés supprimés"
            actionsAddedContent={(conges) => {
                return (
                    <>
                        {loadingValidationState ? (
                            <WaitingScreenComponent withPaper={false} />
                        ) : (
                            <>
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{ mr: 1 }}
                                    onClick={() => {
                                        setLoadingValidationState(true);
                                        congeService
                                            .evaluateCancelConge(conges.conges_id, true)
                                            .then((response) => {
                                                if (!response.error) {
                                                    toast.success(response.message);
                                                    setLoadingValidationState(false);
                                                    handleFetchCancelConges();
                                                    props.handleCountCategoryConges();
                                                } else {
                                                    toast.error(response.error);
                                                }
                                            });
                                    }}
                                >
                                    <CheckIcon /> Valider
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        setLoadingValidationState(false);
                                        congeService
                                            .evaluateCancelConge(conges.conges_id, false)
                                            .then((response) => {
                                                if (!response.error) {
                                                    toast.success(response.message);
                                                    setLoadingValidationState(false);
                                                    handleFetchCancelConges();
                                                    props.handleCountCategoryConges();
                                                } else {
                                                    toast.error(response.error);
                                                }
                                            });
                                    }}
                                >
                                    <CloseIcon /> Refuser
                                </Button>
                            </>
                        )}
                    </>
                );
            }}
        />
    );
};

export default EvaluateCancelComponent;
