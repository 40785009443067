import roleService from './role.service';

// import roleService from './role.service';

class RightService {
    /**
     * Retourne l'utilisateur enregistré dans le local
     * storage grâce au token
     * @returns {Object}
     */
    getActualUserByLocalStorage() {
        return JSON.parse(localStorage.getItem('user'));
    }

    async isAllowed(requiredRole) {
        return roleService.getRoleAuthorization(requiredRole).then((res) => {
            if (res.error) {
                return false;
            } else {
                return res;
            }
        });
    }
}
export default new RightService();
