import axiosConfig from './axiosConfig';

const API_URL = '/authorization/role/';

class RoleService {
    getAllRoles() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getRoleAuthorization(requiredRole) {
        return axiosConfig
            .get(API_URL + `roleAuthorization`, { params: { requiredRole: requiredRole } })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: `${err.message}` };
            });
    }
}

export default new RoleService();
