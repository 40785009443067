import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../../redux/actions/scopeAction';
import category_fichiersService from '../../../../services/category_fichiers.service';
import ResultFormComponent from '../../../assets/container/resultForm.component';
import FormCategoryFichiersComponent from './form-category-fichiers.component';

const CreateCategoryFichiersComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Créer un catégorie de fichiers');
    });

    const createCategory = (dataForm) => {
        setLoadingState(true);
        setSubmittedState(true);
        setSuccessState(false);
        category_fichiersService
            .insert({ name: dataForm.inputName, color: dataForm.inputColor })
            .then((res) => {
                if (res.error) {
                    setLoadingState(false);
                    setSuccessState(false);
                    setMessageState(res.error);
                } else {
                    setLoadingState(false);
                    setSuccessState(true);
                    setMessageState(res.message);
                }
            });
    };

    return (
        <ResultFormComponent
            loading={loadingState}
            success={successState}
            submitted={submittedState}
            message={messageState}
            link={'/admin/list-fichiers'}
        >
            <FormCategoryFichiersComponent handleForm={createCategory} />
        </ResultFormComponent>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateCategoryFichiersComponent = connect(
    null,
    mapDispatchToProps,
)(CreateCategoryFichiersComponent);
