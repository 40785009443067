import { useState, useEffect } from 'react';
import contactService from '../../../services/contact.service';
import PinComponent from '../../assets/field/pin.component';
import SimpleModalComponent from '../../assets/modal/simple-modal.component';
import CreateAddContactComponent from './createAddContact.component';
import AddIcon from '@mui/icons-material/Add';

const ContactModuleComponent = (props) => {
    const [availableContactsState, setAvailableContactsState] = useState([]);
    const [openModalState, setOpenModalState] = useState(false);

    useEffect(() => {}, [availableContactsState]);

    const handleFetchContacts = () => {
        if (props.client) {
            contactService
                .getContactsByClient(props.client)
                .then((res) => {
                    if (!res.error) {
                        setAvailableContactsState(res);
                    } else {
                        console.error('Erreur lors de la récupération des contacts:', res.error);
                    }
                })
                .catch((error) => {
                    console.error('Erreur dans la requête de contacts:', error);
                });
        }
    };

    const handleOpenModal = () => {
        handleFetchContacts();
        setOpenModalState(true);
    };

    const handleCloseModal = () => {
        setOpenModalState(false);
    };

    return (
        <div>
            <div key={openModalState}>
                <SimpleModalComponent isOpen={openModalState} handleCloseModal={handleCloseModal}>
                    <CreateAddContactComponent
                        handleCheckContact={props.handleCheckContact}
                        client={props.client}
                        contacts={props.contacts}
                        availableContacts={availableContactsState}
                        disabled={props.disabled}
                    />
                </SimpleModalComponent>
            </div>

            <div>
                <h4>Gestion du/des contact(s)</h4>
                <div className="pins-container">
                    {props.contacts &&
                        props.contacts.length > 0 &&
                        props.contacts.map((contact) => (
                            <PinComponent
                                content={contact}
                                key={contact.nom}
                                handlePinClick={handleOpenModal}
                                handleRemoveContact={props.handleRemoveContact}
                                disabled={props.disabled}
                            />
                        ))}
                    {!props.disabled &&
                        (props.client ? (
                            <span onClick={handleOpenModal}>
                                <AddIcon color="primary" />
                                Ajouter contact
                            </span>
                        ) : (
                            <span className="disabled-content">
                                Sélectionner un client avant de pouvoir ajouter un contact.
                            </span>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ContactModuleComponent;
