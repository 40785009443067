import './portal.style.scss';
import PlanningGirl from '../../images/pixeltrue-plan.svg';
import PortalButtonComponent from '../assets/portal-button.component';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { H2Title } from '../../theming/customTheme';

import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TaskIcon from '@mui/icons-material/Task';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ManagerPortalCongesComponent from './manager/manager-portal-conges.component';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import './portal.style.scss';

const ManagerPortalComponent = (props) => {
    const [displayMenuModalState, setDisplayMenuModalState] = useState(false);
    const handleToggleMenuModal = () => {
        setDisplayMenuModalState(!displayMenuModalState);
    };
    const handleDisplayMenuModal = () => {
        return displayMenuModalState && <ManagerPortalCongesComponent role={'manager'} />;
    };

    useEffect(() => {
        props.onLoad('Manager - Mon portail');
    }, []);

    return (
        <Box sx={{ p: 1 }}>
            <H2Title>Mes Liens</H2Title>
            <Grid container spacing={2}>
                <PortalButtonComponent
                    title="Mon Equipe"
                    icon={GroupIcon}
                    color="primary.main"
                    link="manager/team"
                    role={'manager'}
                />
                <PortalButtonComponent
                    title="Planning d'équipe"
                    icon={CalendarMonthIcon}
                    color="success.main"
                    link="planning-manager"
                    role={'commerce'}
                />
                <PortalButtonComponent
                    title="Gérer les projets"
                    icon={TaskIcon}
                    color="secondary.main"
                    link="projects"
                    role={'commerce'}
                />
                <PortalButtonComponent
                    title="Générer un reporting"
                    icon={UploadFileIcon}
                    color="error.main"
                    link="reporting"
                    role={'manager'}
                />
                <PortalButtonComponent
                    title="Gestion des congés"
                    icon={BeachAccessIcon}
                    color="secondary.light"
                    functionButton={1}
                    toggleFunction={handleToggleMenuModal}
                    role={'manager'}
                />
            </Grid>
            {handleDisplayMenuModal()}
            <div className="background-decoration-svg">
                <img src={PlanningGirl} height="550px" width="800px" alt="" />
            </div>
        </Box>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedManagerPortalComponent = connect(
    null,
    mapDispatchToProps,
)(ManagerPortalComponent);
