import axiosConfig from './axiosConfig';

const API_URL = '/authorization/fonction/';

class FonctionService {
    getAllFonctions() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    getAllArchivedFonctions() {
        return axiosConfig
            .get(API_URL + 'archived/all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    /**
     * Requête une fonction
     * @param {Number} fonction_id - id de fonction en param
     */
    getFonctionById(fonction_id) {
        return axiosConfig
            .get(API_URL + fonction_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    deleteFonctionById(fonction_id) {
        return axiosConfig
            .delete(API_URL + fonction_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    updateFonctionById(objectFonction) {
        return axiosConfig
            .put(API_URL, objectFonction)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    unarchiveFonctionById(fonction_id) {
        return axiosConfig
            .put(API_URL + 'unarchive/' + fonction_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    /**
     * Créer la fonction avec l'entité du token
     * @param {String} nom - Nom de la fonction
     */
    create(nom) {
        return axiosConfig
            .post(API_URL, {
                nom,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
}

export default new FonctionService();
