import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import category_newsService from '../../../../services/category_news.service';
import CrudPageComponent from '../../../assets/page/crud-page.component';

const ListCategoryNewsComponent = () => {
    const [contentState, setContentState] = useState('');
    const [errorState, setErrorState] = useState(null);
    const [displayTypeState, setDisplayTypeState] = useState(true);

    const handleFetchCategoryNews = () => {
        (displayTypeState
            ? category_newsService.getAll()
            : category_newsService.getAllArchived()
        ).then((categoryNews) => {
            if (categoryNews.error) {
                setErrorState(categoryNews.error);
            } else {
                setContentState(categoryNews);
            }
        });
    };

    const handleRestoreCategory = (categoryNewsId) => {
        category_newsService.restore(categoryNewsId).then((res) => {
            if (res.error) {
                toast.error(res.error);
            } else {
                toast.success(res.message);
                handleFetchCategoryNews();
            }
        });
    };

    useEffect(() => {
        handleFetchCategoryNews();
    }, []);

    useEffect(() => {
        handleFetchCategoryNews();
    }, [displayTypeState]);

    return (
        <CrudPageComponent
            objectName="categoryNews"
            title="Gestion des catégories de news"
            titleAdd="Créer une catégorie"
            labelSwitchArchived="Voir les catégories archivées"
            onChangeDisplayType={() => {
                setDisplayTypeState(!displayTypeState);
            }}
            displayType={displayTypeState}
            linkAdd="/admin/create-category-news"
            linkEdit="/admin/update-category-news"
            error={errorState}
            headers={['Nom', { title: 'Couleur', size: '5%' }]}
            dataContent={
                !!contentState
                    ? contentState.map((content) => ({
                          id: content.category_news_id,
                          nom: content.name,
                          color: content.color,
                      }))
                    : null
            }
            handleRestore={handleRestoreCategory}
        />
    );
};

export default ListCategoryNewsComponent;
