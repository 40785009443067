import { React, useState } from 'react';

import activityService from '../../services/activity.service';
import { toast } from 'react-toastify';

//Form
import ResultFormComponent from '../assets/container/resultForm.component';
import FormActivityComponent from './form-activity.component';

//Code pas particulièrement propre suite au changement de formulaire, certaines fonction legacy
//ont été conserver afin de maintenir au mieux l'intégrité du code. Pour un bon exemple de formulaire
//fait avec react-hook-form, se référer au create-conge.component.js qui donne un bon exemple des
//différents inputs utilisable et comment les utiliser.

const CreateActivityComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');

    const handleCreateActivity = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);

        let allSuccess = 0;

        const { inputMiniCalendar, inputProjet, inputValeur, inputCommentaire, inputSelectedLieu } =
            dataForm;

        // On parcours tout le tableau de jours fournis par le mini-calendrier
        inputMiniCalendar.forEach((jour, index) => {
            // Vérification si le jour courant est déjà saisi avec la même activité, si c'est le cas, on supprime l'activité du jour (elle sera à nouveau rempli ensuite)
            if (Object.prototype.hasOwnProperty.call(jour, 'activities')) {
                jour.activities.forEach((data) => {
                    if (Object.prototype.hasOwnProperty.call(data.activity, 'activity_id')) {
                        if (data.activity.projet.project_id === inputProjet.value) {
                            if (props.employe) {
                                if (data.activity.utilisateur.user_id === props.employe.user_id) {
                                    activityService.deleteOneActivity(data.activity.activity_id);
                                }
                            } else {
                                activityService.deleteOneActivity(data.activity.activity_id);
                            }
                        }
                    }
                });
            }

            // Insertion du jour courant en base avec les données déjà vérifié venant du formulaire
            let laDateJson = new Date(
                jour.date.getTime() - jour.date.getTimezoneOffset() * 60000,
            ).toJSON();

            activityService
                .insertMyActivity(
                    laDateJson,
                    inputValeur,
                    inputCommentaire || '',
                    inputProjet.value,
                    inputSelectedLieu,
                    props.employe ? props.employe.email : undefined,
                )
                .then((response) => {
                    if (!response.error) {
                        if (inputMiniCalendar.length === 1) {
                            setSuccessState(true);
                            setLoadingState(false);
                            setMessageState(response.message);
                            props.rerenderParentCallback();
                            toast.success(response.message);
                        } else {
                            allSuccess++;
                        }
                    } else {
                        // une erreur est retournée
                        setSuccessState(false);
                        setMessageState(response.error);
                        setLoadingState(false);
                        toast.error(response.error);
                        props.rerenderParentCallback();
                    }

                    if (index === inputMiniCalendar.length - 1) {
                        if (allSuccess > 0) {
                            setSuccessState(true);
                            setLoadingState(false);
                            setMessageState('Les activités ont bien été ajouté');
                            toast.success('Les activitiés ont bien été ajouté');
                            props.rerenderParentCallback();
                        }
                    }
                });
        });
    };

    return (
        <ResultFormComponent
            submitted={submittedState}
            loading={loadingState}
            success={successState}
            message={messageState}
        >
            <FormActivityComponent
                monthCalendar={props.monthCalendar}
                jour={props.jour}
                openRightPanel={props.openRightPanel}
                closeRightPanel={props.closeRightPanel}
                rerenderParentCallback={props.rerenderParentCallback}
                activities={props.activities}
                employe={props.employe}
                handleForm={handleCreateActivity}
            />
        </ResultFormComponent>
    );
};
export default CreateActivityComponent;
