import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { H2Lighter } from '../../../../theming/customTheme';
import CenteredPaper from '../../../assets/container/centeredPaper.component';
import {
    getRandomHexaColor,
    InputColorComponent,
} from '../../../assets/inputs/inputColor.component';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const FormCategoryFichiersComponent = (props) => {
    const isUpdate = props.isUpdate || false;
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    return (
        <CenteredPaper withMargin={true} withAlign={false} size="extra-large" elevation={1}>
            <form onSubmit={handleSubmit(props.handleForm)}>
                <H2Lighter>
                    {isUpdate ? 'Modifier la ' : 'Créer une '} catégorie de fichiers
                </H2Lighter>
                <Controller
                    name="inputName"
                    control={control}
                    rules={{
                        required: { value: true, message: 'Le champs nom est obligatoire' },
                    }}
                    defaultValue={props.name || null}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Nom de la catégorie"
                            placeholder="RH"
                            error={!!errors.inputName}
                            helperText={!!errors.inputName && errors.inputName.message}
                            fullWidth
                        />
                    )}
                />
                <Controller
                    name="inputColor"
                    control={control}
                    rules={{
                        required: { value: true, message: 'Le champs couleur est obligatoire' },
                    }}
                    defaultValue={props.color || getRandomHexaColor()}
                    render={({ field }) => (
                        <InputColorComponent {...field} sx={{ mt: 2 }} ref={null} />
                    )}
                />
                <div style={{ textAlign: 'center' }}>
                    <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                        {isUpdate ? <EditIcon sx={{ mr: 1 }} /> : <AddIcon sx={{ mr: 1 }} />}
                        {isUpdate ? 'Modifier la ' : 'Créer une '}Catégorie
                    </Button>
                    {isUpdate && (
                        <Button
                            type="button"
                            variant="contained"
                            color="error"
                            sx={{ mt: 2, ml: 2 }}
                            onClick={props.handleDelete}
                        >
                            <DeleteIcon />
                            Archiver la Catégorie
                        </Button>
                    )}
                </div>
            </form>
        </CenteredPaper>
    );
};

export default FormCategoryFichiersComponent;
