import { Alert, List, ListItemText, Paper, ListItem } from '@mui/material';
import { H2Lighter } from '../../../theming/customTheme';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import CreateContactComponent from './create-contact.component';

const CreateAddContactComponent = (props) => {
    const { availableContacts = [], contacts = [], disabled = false, client } = props;

    const mappedContacts = availableContacts.map((contact) => {
        return {
            ...contact,
            isChecked: contacts.some((el) => el.contact_id === contact.contact_id),
        };
    });

    return (
        <CenteredPaper size="extra-large" withMargin={false} withAlign={false} elevation={0}>
            <H2Lighter>Gestion des contacts</H2Lighter>
            <Paper sx={{ p: 1, mt: 2 }} elevation={2}>
                <h3> Contacts disponibles</h3>
                {mappedContacts.length > 0 ? (
                    <List style={{ maxHeight: '100%', overflow: 'auto' }}>
                        {mappedContacts.map((contact) => (
                            <ListItem key={contact.contact_id}>
                                <ListItemText
                                    primary={`${contact.nom} ${contact.prenom}`}
                                    secondary={`email: ${contact.email} - tel: ${contact.telephone}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Alert severity="info">Aucun contact disponible pour ce client.</Alert>
                )}
            </Paper>
            {!disabled && (
                <Paper sx={{ p: 1, mt: 2 }} elevation={2}>
                    <h3>Créer un nouveau contact</h3>
                    <CreateContactComponent client={client} />
                </Paper>
            )}
        </CenteredPaper>
    );
};

export default CreateAddContactComponent;
