import { useEffect, useState } from 'react';
// validator form
import '../cards.style.scss';

/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';

//images
import { useLocation } from 'react-router-dom';
import projetService from '../../services/projet.service';
import userService from '../../services/user.service';
import clientService from '../../services/client.service';
import regionService from '../../services/region.service';
import statuservice from '../../services/status.service';
import activityService from '../../services/activity.service';
import ResultFormComponent from '../assets/container/resultForm.component';
import FormProjetComponent from './form-projet.component';
import WaitingScreenComponent from '../assets/waiting-screen.component';

export const UpdateProjetComponent = (props) => {
    const location = useLocation();
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [client, setClient] = useState(null);
    const [region, setRegion] = useState(null);
    const [statuSelect, setStatuSelect] = useState(null);
    const [code_projet, setCode_projet] = useState('');
    const [project_id, setProject_id] = useState();
    const [nom, setNom] = useState(null);
    const [date_commande, setDate_commande] = useState(null);
    const [estPrepaye, setEstPrepaye] = useState(null);
    const [commentaire, setCommentaire] = useState(null);
    const [typeActivity, setTypeActivity] = useState(null);
    const [typeFacturation, setTypeFacturation] = useState(null);
    const [devises, setDevises] = useState(null);
    const [referent, setReferent] = useState(null);
    const [couleur, setCouleur] = useState(null);
    const [trigramme, setTrigramme] = useState(null);
    const [contacts, setContacts] = useState(null);
    const [checkedCriticites, setCheckedCriticites] = useState(null);
    const [gouvernance, setGouvernance] = useState(null);
    const [messageState, setMessageState] = useState('');
    const [isTicketState, setIsTicketState] = useState(false);
    const [clientArchiveState, setClientArchiveState] = useState(null);
    const [regionArchiveState, setRegionArchiveState] = useState(null);

    useEffect(() => {
        props.onLoad('Administrateur - Modifier un projet');
        /**
         * On récupère toutes les informations nécessaires au formulaire
         * depuis nos services qui vont requêter l'API
         */
        const usersC = userService.getAllUsers();
        const clientsC = clientService.getAllClients();
        const regionsC = regionService.getAllRegions();
        const statusC = statuservice.getAllStatus();
        const typeFacturationsC = activityService.getAllTypeFacturation();
        const typeActivitysC = activityService.getAllActivityTypes();
        const projetC = projetService.getProjet(location.state.objectId);

        // on attend la réponse de toutes les requêtes et on les attribues au state local
        Promise.all([
            projetC,
            usersC,
            regionsC,
            statusC,
            typeActivitysC,
            typeFacturationsC,
            clientsC,
        ]).then(([projetC]) => {
            // state par défaut si pas de modifications
            setProject_id(projetC.project_id);
            setReferent({
                value: projetC.referent.user_id,
                label: projetC.referent.nom.toUpperCase() + ' ' + projetC.referent.prenom,
                userObject: projetC.referent,
            });
            // setStatusState(projetC.fk_status);
            setClient({
                value: projetC.client.client_id,
                label: projetC.client.nom + ' - ' + projetC.client.ville,
                ville: projetC.client.ville,
                nom: projetC.client.nom,
            });
            setRegion(projetC.region.region_id);
            setStatuSelect(projetC.status.status_id);
            setCode_projet(projetC.code_projet);
            setNom(projetC.nom);
            setDate_commande(projetC.date_commande);
            setEstPrepaye(projetC.est_prepaye);
            setCommentaire(projetC.commentaire);
            setTypeActivity(projetC.type_activite.activity_type_id);
            setTypeFacturation(projetC.type_facturation.type_facturation_id);
            setCouleur(projetC.couleur);
            setTrigramme(projetC.trigramme);
            setDevises(projetC.devises_vendues);
            setClientArchiveState(projetC.client.est_archive ? projetC.client.nom : false);
            handleFetchContratSupport(projetC.project_id, projetC.fk_activity_type);
            setRegionArchiveState(projetC.region.est_archive ? projetC.region.nom : false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Appel au service d'update projet et vérifie si besoin
     * de mettre à jour le contrat support également
     * @param {*} event
     * @returns
     */
    const handleUpdateProjet = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        const {
            isUpdate,
            inputClient,
            inputRegion,
            inputStatus,
            inputCode,
            inputName,
            inputReferent,
            inputDateCommande,
            inputEstPrepaye,
            inputCommentaire,
            inputTypeActivity,
            inputFacturation,
            inputColor,
            inputTrigramme,
            inputDevises,
        } = dataForm;

        projetService
            .updateProjet(
                isUpdate,
                inputClient.value,
                inputRegion,
                inputStatus,
                inputCode,
                inputName,
                inputReferent.value,
                inputDateCommande,
                inputEstPrepaye,
                inputCommentaire,
                inputTypeActivity,
                inputFacturation,
                inputColor,
                inputTrigramme,
                inputDevises,
            )
            .then((response) => {
                if (!response || response.error) {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(response.error || response.message);
                } else {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                }
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    /**
     * Récupère le contrat support lié au projet
     * via le project_id
     * @param {*} projectId
     * @param {*} typeActivityF
     */
    const handleFetchContratSupport = (projectId, typeActivityF) => {
        // si le type d'activité correspond à l'ID 2 (ticket)
        if (typeActivityF === 2) {
            projetService.getContratSupport(projectId).then((res) => {
                if (!res.error) {
                    setIsTicketState(true);
                    const datas = res;
                    if (datas) {
                        const updatedCriticites = datas.criticites.map((criticite) => {
                            return { ...criticite, isChecked: true };
                        });

                        setCheckedCriticites(updatedCriticites);
                        setContacts(datas.contacts);
                        setGouvernance(datas.gouvernance);
                    }
                }
            });
        } else {
            setCheckedCriticites([]);
            setContacts([]);
        }
    };

    /**
     * Met à jour le contrat support
     * @param {*} event
     */
    const handleUpdateProjetSupport = (dataForm) => {
        setLoadingState(true);
        setSuccessState(false);
        setSubmittedState(true);
        const {
            isUpdate,
            inputClient,
            inputRegion,
            inputStatus,
            inputCode,
            inputName,
            inputReferent,
            inputDateCommande,
            inputEstPrepaye,
            inputCommentaire,
            inputTypeActivity,
            inputFacturation,
            inputColor,
            inputTrigramme,
            inputDevises,
            contactsState,
            checkedCriticitesState,
            inputGouvernance,
        } = dataForm;
        projetService
            .updateProjetSupport(
                isUpdate,
                inputClient,
                inputRegion,
                inputStatus,
                inputCode,
                inputName,
                inputReferent,
                inputDateCommande,
                inputEstPrepaye,
                inputCommentaire,
                inputTypeActivity,
                inputFacturation,
                inputColor,
                inputTrigramme,
                inputDevises,
                checkedCriticitesState,
                contactsState,
                inputGouvernance,
            )
            .then((response) => {
                if (!response || response.error) {
                    setSuccessState(false);
                    setLoadingState(false);
                    setMessageState(response.error || response.message);
                } else {
                    setSuccessState(true);
                    setLoadingState(false);
                    setMessageState(response.message);
                }
            })
            .catch((err) => {
                setSuccessState(false);
                setLoadingState(false);
                setMessageState(err.response.message);
            });
    };

    const handleForm = (dataForm) => {
        if (dataForm.isTicketState) {
            handleUpdateProjetSupport(dataForm);
        } else {
            handleUpdateProjet(dataForm);
        }
    };

    return (
        <>
            {project_id !== null &&
            referent !== null &&
            client !== null &&
            region !== null &&
            statuSelect !== null &&
            code_projet !== null &&
            nom !== null &&
            date_commande !== null &&
            estPrepaye !== null &&
            typeActivity !== null &&
            typeFacturation !== null &&
            couleur !== null &&
            trigramme !== null &&
            devises !== null &&
            contacts !== null &&
            clientArchiveState !== null &&
            regionArchiveState !== null &&
            (typeActivity !== 2 ||
                (typeActivity === 2 && gouvernance !== null && checkedCriticites !== null)) ? (
                <ResultFormComponent
                    submitted={submittedState}
                    loading={loadingState}
                    success={successState}
                    message={messageState}
                    link={'/admin/projet-all'}
                >
                    <FormProjetComponent
                        projectId={project_id}
                        referent={referent}
                        client={client}
                        region={region}
                        statuSelect={statuSelect}
                        codeProjet={code_projet}
                        nom={nom}
                        dateCommande={date_commande}
                        estPrepaye={estPrepaye}
                        commentaire={commentaire}
                        typeActivity={typeActivity}
                        typeFacturation={typeFacturation}
                        couleur={couleur}
                        trigramme={trigramme}
                        devises={devises}
                        isTicket={isTicketState}
                        contacts={contacts}
                        checkedCriticites={checkedCriticites}
                        gouvernance={gouvernance}
                        clientArchive={clientArchiveState}
                        regionArchive={regionArchiveState}
                        isUpdate={true}
                        handleForm={handleForm}
                    />
                </ResultFormComponent>
            ) : (
                <>
                    <WaitingScreenComponent />
                </>
            )}
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateProjetComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateProjetComponent);
