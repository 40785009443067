import axiosConfig from './axiosConfig';

const API_URL = '/authorization/category_news/';

class CategoryNewsService {
    getAll() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: error.message };
            });
    }

    getAllArchived() {
        return axiosConfig
            .get(API_URL + 'allArchived')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: error.message };
            });
    }

    insert(objectCategory) {
        return axiosConfig
            .post(API_URL, objectCategory)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: error.message };
            });
    }

    update(objectCategory) {
        return axiosConfig
            .put(API_URL, objectCategory)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: error.message };
            });
    }

    get(idCategory) {
        return axiosConfig
            .get(API_URL + idCategory)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: error.message };
            });
    }

    delete(idCategory) {
        return axiosConfig
            .delete(API_URL + idCategory)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: error.message };
            });
    }

    restore(categoryNewsId) {
        return axiosConfig
            .put(API_URL + 'restore/' + categoryNewsId)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
}

export default new CategoryNewsService();
