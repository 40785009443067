import { Button, FormControlLabel, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import userService from '../../services/user.service';
import { InputColorComponent, getRandomHexaColor } from '../assets/inputs/inputColor.component';
import { toast } from 'react-toastify';
import { setPref } from '../../redux/actions/prefAction';
import { connect } from 'react-redux';

// import LightModeIcon from '@mui/icons-material/LightMode';
// import ModeNightIcon from '@mui/icons-material/ModeNight';
import { MaterialUISwitch } from '../../theming/customTheme';
import TitleComponent from '../assets/field/title.component';

const CustomizationComponent = (props) => {
    const { handleSubmit, control } = useForm();
    const theme = useTheme();

    const randomColor = props.content.utilisateur.fav_color || getRandomHexaColor();

    const handleFormCustomization = (dataForm) => {
        userService
            .sendUserCustomization({
                fav_color: dataForm.inputColor,
                dark_mode: dataForm.inputDarkMode,
            })
            .then((result) => {
                if (result.error) {
                    toast.error(result.error);
                } else {
                    props.setPrefUser({
                        ...props.pref,
                        fav_color: dataForm.inputColor,
                        dark_mode: dataForm.inputDarkMode,
                    });
                }
            });
    };

    return (
        <form onSubmit={handleSubmit(handleFormCustomization)}>
            <TitleComponent>Apparence</TitleComponent>
            <Controller
                key="inputDarkMode"
                name="inputDarkMode"
                control={control}
                render={({ field: { value, ...field } }) => (
                    <FormControlLabel
                        control={
                            <MaterialUISwitch {...field} key="inputDarkMode" checked={!!value} />
                        }
                        sx={{ mt: 1 }}
                        label={!!value ? 'Désactiver le mode nuit' : 'Activer le mode nuit'}
                    />
                )}
                defaultValue={props.content.utilisateur.dark_mode || false}
            />
            <Controller
                name="inputColor"
                control={control}
                required={false}
                defaultValue={randomColor}
                render={({ field }) => (
                    <InputColorComponent
                        {...field}
                        // label="Couleur favorite"
                        defaultColor={theme.palette.originalPrimary.main}
                        labelAfter
                        ref={null}
                    />
                )}
            />
            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                Valider
            </Button>
        </form>
    );
};

function mapStateToProps(state) {
    return {
        pref: state.prefReducer,
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPrefUser: (prefObject) => dispatch(setPref(prefObject)),
});

export const ConnectedCustomizationComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomizationComponent);
